import { useMutation } from "@tanstack/react-query";

import { createMyPharmacy } from "~/patients/api/pharmacies";
import { createScript } from "~/patients/api/scripts";
import { PharmacyFormValuesType } from "~/patients/utils";
import { PatientScriptsRequest } from "~/patients/utils/PatientScriptsTypes";

export const useCreateScript = (onSuccess: () => void, onError: () => void) => {
  const { mutate: createScriptMutation, isPending: pendingCreateScript } =
    useMutation({
      mutationFn: (script: PatientScriptsRequest) => createScript(script),
      onSuccess,
      onError,
    });

  const {
    mutate: createScriptWithPharmacy,
    isPending: pendingCreateScriptWithPharmacy,
  } = useMutation({
    mutationFn: async ({
      pharmacy,
      medicationId,
      comment,
    }: {
      pharmacy: PharmacyFormValuesType;
      medicationId: string;
      comment?: string;
    }) => {
      const { data: newPharmacyData } = await createMyPharmacy({ pharmacy });
      createScriptMutation({
        patient_medication_id: medicationId,
        patient_pharmacy_id: newPharmacyData.id,
        comment,
      });
    },
    onSuccess,
    onError,
  });
  const isPendingCreateScript =
    pendingCreateScript || pendingCreateScriptWithPharmacy;

  return {
    createScriptMutation,
    createScriptWithPharmacy,
    isPendingCreateScript,
  };
};
