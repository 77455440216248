export const ScheduleAppointmentIcon = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.542664"
        y="0.105469"
        width="32"
        height="32"
        rx="16"
        fill="#D6EDF8"
      />
      <path
        d="M17.4593 23.9958H23.2122C23.5329 23.9958 23.7544 23.6749 23.6408 23.375L23.1847 22.1712C22.9451 21.5388 23.0762 20.8278 23.4627 20.2728C24.0433 19.4388 24.7927 18.145 24.7927 17.0221M22.0427 14.7305C22.0427 18.5274 18.9646 21.6055 15.1677 21.6055H8.95645C8.63577 21.6055 8.41423 21.2846 8.52785 20.9847L9.11548 19.4338C9.3256 18.8792 9.23932 18.2643 8.98017 17.7308C8.53971 16.8242 8.29266 15.8062 8.29266 14.7305C8.29266 10.9335 11.3707 7.85547 15.1677 7.85547C18.9646 7.85547 22.0427 10.9335 22.0427 14.7305Z"
        stroke="#61A5C6"
        strokeWidth="2"
      />
    </svg>
  );
};
