import { Navigate, useNavigate } from "react-router-dom";

import { Header, LoadingState, TransitionEffect } from "~/common/components";
import { PATIENT_STATUS } from "~/common/constants";
import { ScreeningCheckList } from "~/patients/components/consultations";
import { useGetCurrentScreeningBundle } from "../../../hooks";
import { useGetCurrentPatient } from "../../../hooks/queries/useGetCurrentPatient";
import { ROUTES } from "../../../router";
import { getHomePath } from "../../../utils";

export const CheckListScreenScreening = () => {
  const { data: user } = useGetCurrentPatient();
  const navigate = useNavigate();
  const homePath = getHomePath(user?.status);

  const { data, isLoading } = useGetCurrentScreeningBundle();

  if (isLoading) return <LoadingState />;

  if (user?.status === PATIENT_STATUS.ongoing_care || !data)
    return <Navigate to={ROUTES.notFound} />;

  const subtitle = `${data.sections_completed}/${data.total_sections} sections completed`;

  return (
    <TransitionEffect className="flex h-screen-calculated w-full flex-col text-sm">
      <Header
        title="Help me understand your story."
        subtitle={subtitle}
        handleGoBack={() => navigate(homePath)}
      />
      <ScreeningCheckList {...data} />
    </TransitionEffect>
  );
};
