import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { z } from "zod";

import PasswordRequirements from "~/common/components/PasswordRequirements";
import Input from "~/common/components/ui/Input";
import { passwordSchema } from "~/patients/utils/PatientSchemas";
import { FormErrors } from "~/patients/utils/types/forms";
import SignUpFormLayout from "../SignUpFormLayout";
import SignUpProgress from "../SignUpProgress";

export type PasswordsSchemaType = z.infer<typeof passwordSchema>;
interface PatientPasswordFormProps {
  handleGoBack: () => void;
  totalSteps: number;
  register: (data: keyof PasswordsSchemaType) => UseFormRegisterReturn<string>;
  onNext: () => Promise<void>;
  errors: FormErrors<PasswordsSchemaType> | undefined;
  enteredPassword: string;
  step: number;
  pendingRequest?: boolean;
}

const PatientPasswordForm = ({
  onNext,
  register,
  handleGoBack,
  totalSteps,
  errors,
  enteredPassword,
  step,
  pendingRequest,
}: PatientPasswordFormProps) => {
  return (
    <>
      <SignUpProgress step={step} totalSteps={totalSteps} />
      <SignUpFormLayout
        title="Create password"
        handleGoBack={handleGoBack}
        handleData={onNext}
        pendingRequest={pendingRequest}
        submitLabel="Sign up"
      >
        <Input
          id="password"
          label="Password"
          type="password"
          {...register("password")}
          error={errors?.password?.message}
        />
        <Input
          id="passwordConfirmation"
          label="Confirm password"
          type="password"
          {...register("passwordConfirmation")}
          error={errors?.passwordConfirmation?.message}
        />
        <PasswordRequirements password={enteredPassword} />
      </SignUpFormLayout>
    </>
  );
};

export default PatientPasswordForm;
