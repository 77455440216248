import { useMutation } from "@tanstack/react-query";

import { deleteConsultationAudio } from "~/patients/api";

export const useDeleteConsultationAudioS3 = (basePath: string) => {
  const { mutateAsync: deleteAudioMutation, isPending: pendingDeleteAudio } =
    useMutation({
      mutationFn: () => deleteConsultationAudio(basePath),
    });

  return {
    deleteAudioMutation,
    pendingDeleteAudio,
  };
};
