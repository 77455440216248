import { api } from "./axios";

export const resetPassword = async (newPassword: string, token: string) => {
  const response = await api.post<string>(
    "v1/auth/reset-password",
    { password: newPassword },
    {
      headers: {
        token: token,
      },
    },
  );
  return response.data;
};

export const requestEmail = async (email: string) => {
  const response = await api.post<string>(
    "/v1/auth/email/patient/reset-password",
    {
      email,
    },
  );
  return response.data;
};
