import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

import { BUTTON_VARIANTS } from "../constants";
import { Button, SectionHeader } from "./ui";

interface ImagePromptCardProps extends PropsWithChildren {
  title?: string;
  handleContinue: () => void;
  handleGoBack?: () => void;
  goBackLabel?: string;
  continueLabel?: string;
  pendingContinue?: boolean;
  className?: string;
  imageContainerClassName?: string;
  subtitle?: string;
  content?: React.ReactNode;
  disableContinue?: boolean;
}

export const ImagePromptCard = ({
  title,
  subtitle,
  handleContinue,
  handleGoBack,
  pendingContinue = false,
  goBackLabel,
  continueLabel,
  imageContainerClassName,
  className,
  children,
  content,
  disableContinue = false,
}: ImagePromptCardProps) => (
  <>
    <div
      className={twMerge(
        "z-50 -mb-12 flex size-32 items-center justify-center rounded-full p-2 md:-mb-16 lg:-mb-20 xl:-mb-24",
        imageContainerClassName,
      )}
    >
      {children}
    </div>
    <div
      className={twMerge(
        "flex w-full max-w-96 flex-col items-center gap-6 rounded-3xl bg-white p-6 pt-16",
        className,
      )}
    >
      {title && (
        <SectionHeader className="w-full text-center text-3xl">
          {title}
        </SectionHeader>
      )}
      {subtitle && (
        <p className="text-center text-base leading-5 text-gray-70">
          {subtitle}
        </p>
      )}
      {content && content}
      <div className="flex w-full flex-col gap-2">
        <Button
          isLoading={pendingContinue}
          onClick={handleContinue}
          disabled={pendingContinue || disableContinue}
        >
          {continueLabel ?? "Continue"}
        </Button>
        {handleGoBack && (
          <Button
            onClick={handleGoBack}
            variant={BUTTON_VARIANTS.outline_none_underline}
          >
            {goBackLabel ?? "Go Back"}
          </Button>
        )}
      </div>
    </div>
  </>
);
