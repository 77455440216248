import type { PropsWithChildren, ReactNode } from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { twMerge } from "tailwind-merge";

import CrossIcon from "~/common/components/icons/CrossIcon";

export interface ModalProps extends PropsWithChildren {
  className?: string;
  titleClassName?: string;
  description?: string | ReactNode;
  show?: boolean;
  onClose: () => void;
  title?: string | ReactNode;
  closeOnClickOutside?: boolean;
  hideCloseIcon?: boolean;
}

const Modal = ({
  children,
  className,
  titleClassName,
  description,
  show = false,
  onClose,
  title,
  closeOnClickOutside = true,
  hideCloseIcon = false,
}: ModalProps) => {
  const handleClickOutside = () => {
    closeOnClickOutside && onClose();
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleClickOutside}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-700 bg-opacity-20 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 flex h-screen w-screen items-center justify-center p-4 text-center sm:items-center sm:p-0.5">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel
              className={twMerge(
                "relative flex w-full transform flex-col gap-4 rounded-2xl bg-white p-6 text-left shadow-xl transition-all sm:w-2/3 md:w-2/5 xl:w-1/3",
                className,
              )}
            >
              <div className="flex items-center justify-between">
                <h2
                  className={twMerge(
                    "font-outfit text-2xl font-medium tracking-wide text-gray-70",
                    titleClassName,
                  )}
                >
                  {title}
                </h2>
                {!hideCloseIcon && (
                  <button onClick={onClose}>
                    <CrossIcon className="text-gray-500" />
                  </button>
                )}
              </div>
              {description && (
                <p className="leading-5 text-gray-70">{description}</p>
              )}
              {children}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
