import { CONSULTATION_STATUSES } from "~/common/utils";

export const isCompletedConsultation = (status: string) =>
  status === CONSULTATION_STATUSES.completed;

export const isUnavailableConsultation = (status: string) =>
  status === CONSULTATION_STATUSES.unavailable;

export const isNextConsultation = (status: string) =>
  status === CONSULTATION_STATUSES.in_progress ||
  status === CONSULTATION_STATUSES.pending;
