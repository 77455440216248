import { PropsWithChildren } from "react";

import { ErrorText, TransitionEffect, Typography } from "~/common/components";
import { CancelSubmitButtons } from "~/common/components/ui/CancelSubmitButtons";
import { ANIMATION_DURATIONS } from "~/common/constants";
import { QuestionData } from "~/common/utils/types/index";

interface QuestionsLayoutProps extends PropsWithChildren {
  question: QuestionData;
  handleNext: () => void;
  handleBack: () => void;
  disableNext: boolean;
  errors?: string;
  isLastQuestion: boolean;
  isSubmitting?: boolean;
}

export const QuestionsLayout = ({
  children,
  question,
  handleNext,
  handleBack,
  disableNext,
  errors,
  isLastQuestion,
  isSubmitting = false,
}: QuestionsLayoutProps) => (
  <div className="relative size-full md:w-1/2 xl:w-1/4">
    <TransitionEffect
      duration={ANIMATION_DURATIONS.slow}
      className="absolute size-full"
    >
      <div className="items-left flex h-full w-full flex-col gap-6">
        <Typography className="text-lg font-medium">
          {question?.title}
        </Typography>
        <form
          className="flex h-full w-full flex-col gap-3 text-gray-70 "
          onSubmit={handleNext}
        >
          {question.subtitle && question.subtitle}
          <div className="flex h-full flex-1 flex-col justify-between">
            <div className="flex flex-col gap-2">
              <div className="relative top-8 flex h-full w-full flex-col gap-3">
                {children}
              </div>
              {errors && <ErrorText>{errors}</ErrorText>}
            </div>
            <CancelSubmitButtons
              variant="separated-outline-none"
              cancelButtonLabel="Back"
              isSubmitting={isSubmitting}
              handleCancel={handleBack}
              disableSubmit={disableNext}
              submitButtonLabel={isLastQuestion ? "Submit" : "Next"}
            />
          </div>
        </form>
      </div>
    </TransitionEffect>
  </div>
);
