import { twMerge } from "tailwind-merge";

interface InformationIconProps {
  className?: string;
}

export const InformationIcon = ({ className }: InformationIconProps) => (
  <svg
    className={twMerge("color-primary h-5 w-5", className)}
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5001 16.5832V11.0832M11.5001 8.33317V8.324M21.5834 11.9998C21.5834 17.5687 17.069 22.0832 11.5001 22.0832C5.93121 22.0832 1.41675 17.5687 1.41675 11.9998C1.41675 6.43097 5.93121 1.9165 11.5001 1.9165C17.069 1.9165 21.5834 6.43097 21.5834 11.9998Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
