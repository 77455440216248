import { useMutation, useQueryClient } from "@tanstack/react-query";

import { intakeIntroScreenVisit } from "~/patients/api";

export const useIntakeIntroScreenVisit = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  const { mutate: intakeIntroScreenVisitMutation, isPending } = useMutation({
    mutationFn: intakeIntroScreenVisit,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["getCurrentIntakeBundle"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["getCurrentPatient"],
      });

      onSuccess?.();
    },
  });
  return { intakeIntroScreenVisitMutation, isPending };
};
