import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";

import { TransitionEffect } from "~/common/components";
import {
  CompletedListHospitalsAnswer,
  ListHospitalsQuestionData,
} from "~/common/utils/consultations/intake/interfaces";
import {
  AddElementButton,
  ConsultationQuestionsLayout,
} from "~/patients/components/consultations/shared";
import {
  HospitalType,
  listHospitalsSchema,
  ListHospitalsType,
} from "~/patients/utils/consultations/intakes";
import { HospitalForm } from "./inputs/HospitalForm";

export interface ListHospitalsFormProps {
  question: ListHospitalsQuestionData;
  handleGoBack: () => void;
  onSubmit: (answer: ListHospitalsType) => void;
  isSubmitting: boolean;
  disableBack: boolean;
  completedAnswers: CompletedListHospitalsAnswer;
}

export const ListHospitalsForm = ({
  question,
  onSubmit,
  handleGoBack,
  isSubmitting,
  disableBack,
  completedAnswers,
}: ListHospitalsFormProps) => {
  const { title } = question;

  const {
    handleSubmit,
    watch,
    register,
    control,
    formState: { errors },
  } = useForm<ListHospitalsType>({
    defaultValues: { answer: { ...completedAnswers } },
    resolver: zodResolver(listHospitalsSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "answer.hospitals_admissions",
  });

  const addEmptyHospital = () =>
    append({
      name: "",
      reasons_for_admission: "",
      date_started: "",
      date_completed: "",
    });

  const saveAnswers: SubmitHandler<ListHospitalsType> = (data) =>
    onSubmit(data);

  const isEmptyHospitalInput = (index: number) => {
    if (index >= fields.length) return true;
    const hospitalAdmission = watch(`answer.hospitals_admissions.${index}`);
    return (
      !hospitalAdmission.name &&
      !hospitalAdmission.reasons_for_admission &&
      !hospitalAdmission.date_started &&
      !hospitalAdmission.date_completed
    );
  };

  const hospital_admissions = watch("answer.hospitals_admissions") || [];
  const disableNext = hospital_admissions.length === 0;

  return (
    <ConsultationQuestionsLayout
      handleNext={handleSubmit(saveAnswers)}
      handleBack={handleGoBack}
      disableNext={disableNext}
      isSubmitting={isSubmitting}
      title={title}
      disableBack={disableBack}
      cancelSubmitButtonsClassName="pb-2"
    >
      {fields.map((item, index) => (
        <TransitionEffect key={item.id}>
          <HospitalForm
            handleDelete={() => remove(index)}
            isHospitalDataEmpty={isEmptyHospitalInput(index)}
            register={(data: keyof HospitalType) =>
              register(`answer.hospitals_admissions.${index}.${data}`)
            }
            errors={errors.answer?.hospitals_admissions?.[index]}
          />
        </TransitionEffect>
      ))}
      <AddElementButton onClick={addEmptyHospital} title="Add hospital" />
    </ConsultationQuestionsLayout>
  );
};
