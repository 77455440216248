export const VitalSignsIcon = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.495605"
        y="-0.0078125"
        width="32"
        height="32"
        rx="16"
        fill="#FBDEEA"
      />
      <path
        d="M8.24561 15.9918H10.6377C10.8481 15.9918 11.0314 15.8486 11.0824 15.6446L12.3843 10.437C12.5 9.97412 13.1579 9.97412 13.2736 10.437L16.051 21.5465C16.1667 22.0094 16.8245 22.0094 16.9403 21.5465L18.2421 16.3389C18.2932 16.1349 18.4765 15.9918 18.6868 15.9918H25.6623"
        stroke="#EC5D88"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
