import {
  Button,
  LoadingState,
  SectionHeader,
  Typography,
} from "~/common/components";
import { Mail } from "~/patients/assets";
import { useSendEmailVerification } from "~/patients/hooks/queries/useSendEmailVerification";
import { useGetCurrentPatient } from "../hooks/queries/useGetCurrentPatient";
import { useLogoutPatient } from "../hooks/queries/useLogoutPatient";

export const VerifyEmail = () => {
  const { data: user, isFetching } = useGetCurrentPatient();

  const { sendEmail, isPending } = useSendEmailVerification();

  const { logoutMutation } = useLogoutPatient();

  if (isFetching) return <LoadingState />;

  const handleSignOut = () => {
    logoutMutation();
  };

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-10 bg-primary-50">
      <div className="flex h-screen w-full flex-col items-center justify-center gap-8 bg-primary-50 p-8 md:w-1/2">
        <img src={Mail} alt="Mail icon" />
        <SectionHeader className="w-3/4 text-center text-3xl">
          Please verify your email
        </SectionHeader>
        <div className="space-y-4">
          <Typography className="text-center">
            You’re almost there! We sent an email to <br />
            <span className="text-lg font-semibold">{user?.email}</span>
          </Typography>
          <Typography className="text-center">
            Click the link in the email to verify your address. If you don’t see
            the email, check you spam folder.
          </Typography>
        </div>
        <div className="flex w-full flex-col items-center gap-2">
          <Button
            onClick={() => sendEmail({ showSuccessMessage: true })}
            className="w-full md:w-1/2"
            isLoading={isPending}
          >
            Resend verification email
          </Button>
          <Button onClick={handleSignOut} variant="outline-none-underline">
            Log out
          </Button>
        </div>
      </div>
    </div>
  );
};
