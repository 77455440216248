import { useState } from "react";

import { SideBarContent, SidebarLayout } from "~/common/components";
import { Item } from "~/common/utils/types/sideBarTypes";
import { useSideBarItems } from "~/patients/hooks";
import { useLogoutPatient } from "~/patients/hooks/queries/useLogoutPatient";
import UnsavedPatientModal from "~/patients/modals/UnsavedPatientModal";
import { ROUTES } from "~/patients/router";
import { useUiStore } from "~/patients/stores";

const PatientSideBar = () => {
  const {
    setSideBarOpen,
    sideBarOpen,
    unsavedPatientAccountChanges,
    setUnsavedPatientAccountChanges,
  } = useUiStore();

  const [leaveSettingsTo, setLeaveSettingsTo] = useState<Item | undefined>();

  const actualPath = window.location.pathname;

  const { logoutMutation } = useLogoutPatient();

  const { menuItems, bottomMenuItems } = useSideBarItems(actualPath, () => {
    void logoutMutation();
  });

  const handleItemChange = (item: Item) => {
    if (unsavedPatientAccountChanges) {
      setSideBarOpen(false);
      setLeaveSettingsTo(item);
      return;
    }
    item.onClick();
  };

  const handleOnClose = () => setSideBarOpen(false);

  return (
    <SidebarLayout isOpen={sideBarOpen} onClose={handleOnClose}>
      <SideBarContent
        menuItems={menuItems}
        bottomMenuItems={bottomMenuItems}
        handleItemChange={handleItemChange}
        onClose={handleOnClose}
        homePath={ROUTES.home}
      >
        <UnsavedPatientModal
          show={!!leaveSettingsTo}
          closeModal={() => setLeaveSettingsTo(undefined)}
          confirmLeaveSettings={() => {
            setUnsavedPatientAccountChanges(false);
            leaveSettingsTo?.onClick();
          }}
        />
      </SideBarContent>
    </SidebarLayout>
  );
};

export default PatientSideBar;
