import React, { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { z } from "zod";

import {
  Button,
  CardInputsContainer,
  LoadingState,
  TransitionEffect,
  Typography,
} from "~/common/components";
import Input from "~/common/components/ui/Input";
import { BUTTON_SIZES } from "~/common/constants";
import { TeamMember } from "~/common/utils";
import { useUpdateTeamMemberDetails } from "~/providers/hooks";
import { useGetCurrentTeamMember } from "~/providers/hooks/useGetCurrentTeamMember";
import { useTeamMemberStore } from "~/providers/stores";

const schedulingSchema = z.object({
  acuity_client_id: z.string(),
});

type SchedulingValues = z.infer<typeof schedulingSchema>;

export const Scheduling = () => {
  const { setTeamMember } = useTeamMemberStore();
  const [integrateWithAcuityClicked, setIntegrateWithAcuityClicked] =
    useState(false);

  const { data: teamMember, isLoading } = useGetCurrentTeamMember();

  const handleIntegrateWithAcuityClick = () =>
    setIntegrateWithAcuityClicked(true);

  const queryClient = useQueryClient();

  const onSuccess = (data: TeamMember) => {
    setTeamMember(data);
    toast.success("Acuity id was successfully saved");

    void queryClient.invalidateQueries({
      queryKey: ["getCurrentTeamMember"],
    });
  };

  const { updateTeamMemberDataMutation, isPending } =
    useUpdateTeamMemberDetails(onSuccess);

  const onSubmit = (formData: SchedulingValues) => {
    updateTeamMemberDataMutation(formData);
  };

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm<SchedulingValues>({
    values: {
      acuity_client_id: teamMember?.acuity_client_id ?? "",
    },
    resolver: zodResolver(schedulingSchema),
  });

  if (isLoading) return <LoadingState />;

  return (
    <div className="flex size-full flex-col items-center">
      <CardInputsContainer title="Acuity Integration">
        <div className="flex flex-col gap-3 py-3">
          <div className="flex flex-col gap-4">
            <Typography className="text-gray-700">
              Integrate your scheduling with Acuity to simplify appointment
              management. Sync your availability, automate reminders, and enable
              easy rescheduling.
            </Typography>

            <Typography className="text-gray-700">
              If you use this integration, a BAA with Acuity must be sign to
              stay HIPAA compliant.
            </Typography>
          </div>
          {!integrateWithAcuityClicked && (
            <div className="w-1/4">
              <Button
                size={BUTTON_SIZES.sm}
                className="whitespace-nowrap"
                onClick={handleIntegrateWithAcuityClick}
              >
                Integrate with Acuity
              </Button>
            </div>
          )}
          {integrateWithAcuityClicked && (
            <TransitionEffect>
              <form
                className="flex items-end gap-2"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Input
                  label="Acuity ID"
                  placeholder="Enter Acuity ID"
                  className="h-10"
                  {...register("acuity_client_id")}
                  error={errors.acuity_client_id?.message}
                />
                <Button
                  type="submit"
                  size={BUTTON_SIZES.sm}
                  className="w-1/4"
                  disabled={isPending || !isDirty}
                >
                  Save changes
                </Button>
              </form>
            </TransitionEffect>
          )}
        </div>
      </CardInputsContainer>
    </div>
  );
};
