import React from "react";

export const SideEffectsIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="16" fill="#FDE9DD" />
    <path
      d="M8.75056 23.4648C9.46183 24.1761 10.4444 24.616 11.5298 24.616C13.7005 24.616 15.4602 22.8563 15.4602 20.6855C15.4602 19.6002 15.0203 18.6176 14.309 17.9063M8.75056 23.4648C8.03929 22.7535 7.59937 21.7709 7.59937 20.6855C7.59937 18.5148 9.35908 16.7551 11.5298 16.7551C12.6152 16.7551 13.5978 17.195 14.309 17.9063M8.75056 23.4648L14.309 17.9063M22.0399 11.9032L20.0747 8.49931C19.2607 7.08938 17.4578 6.60631 16.0479 7.42033C14.638 8.23435 14.1549 10.0372 14.9689 11.4471L16.9341 14.851M22.0399 11.9032L24.0052 15.307C24.8192 16.7169 24.3361 18.5198 22.9262 19.3338C21.5162 20.1478 19.7134 19.6648 18.8994 18.2548L16.9341 14.851M22.0399 11.9032L16.9341 14.851"
      stroke="#EC882B"
      strokeWidth="2"
    />
  </svg>
);
