import {
  INTAKE_QUESTION_CODES,
  INTAKE_QUESTION_TYPES,
} from "../intakeQuestionsData";

export const dateQuestions = {
  [INTAKE_QUESTION_CODES.when_is_your_expected_delivery_date]: {
    title: "When is your expected delivery date?",
    type: INTAKE_QUESTION_TYPES.date,
  },
  [INTAKE_QUESTION_CODES.when_was_your_last_menstrual]: {
    title: "When was your last menstrual period?",
    type: INTAKE_QUESTION_TYPES.date,
  },
} as const;
