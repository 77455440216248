import { PATIENT_STATUS } from "~/common/constants";
import {
  AppointmentCompletedCard,
  AppointmentScheduledCard,
  CompletedConsultationCard,
  IntakeSubmittedCard,
  ScreeningCompletedAndIntakeStartedCard,
} from "~/patients/components";
import { useGetCurrentPatient } from "~/patients/hooks";

interface ConsultationCardProps {
  title: string;
}

export const AppointmentConsultationCard = ({
  title,
}: ConsultationCardProps) => {
  const { data: user } = useGetCurrentPatient();
  const { status, provider } = { ...user };

  const renderCard = () => {
    switch (status) {
      case PATIENT_STATUS.screening_completed:
      case PATIENT_STATUS.intake_started:
        return <ScreeningCompletedAndIntakeStartedCard title={title} />;

      case PATIENT_STATUS.intake_submitted:
        return <IntakeSubmittedCard title={title} />;

      case PATIENT_STATUS.screening_started:
        return <AppointmentScheduledCard title={title} />;

      case PATIENT_STATUS.appointment_scheduled:
        return <CompletedConsultationCard title={title} />;

      case PATIENT_STATUS.consultation_completed:
        return provider ? (
          <AppointmentCompletedCard title={title} provider={provider} />
        ) : null;

      default:
        return null;
    }
  };

  return <>{renderCard()}</>;
};
