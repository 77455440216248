import { appetiteChangesSymptom } from "./appetiteChangesSymptom";
import { depressionSymptom } from "./depressionSymptom";
import { foodWeightPreoccupationsForm } from "./foodWeightPreoccupationsSymptom";
import { sleepIssuesForm } from "./sleepIssuesSymptom";
import { suicidalHarmfulThoughtsForm } from "./suicidalHarmfulSymptom";
import { SymptomBaseForm } from "./symptomsBasicTypes";
import {
  anxietySymptom,
  attentionFocusIssuesSymptom,
  compulsionsSymptom,
  diminishedInterestsSymptom,
  flashbacksSymptom,
  grandIdeasSymptom,
  impulsivitySymptom,
  increasedSpendingOrShoppingSymptom,
  irritabilitySymptom,
  memoryProblemsSymptom,
  nightMaresSymptom,
  noneOfTheAboveSymptom,
  obsessionsSymptom,
  otherSymptom,
  panicAttacksSymptom,
} from "./toggleTextExtraDataForms";

export const symptomsBaseForm: SymptomBaseForm[] = [
  depressionSymptom,
  sleepIssuesForm,
  attentionFocusIssuesSymptom,
  anxietySymptom,
  panicAttacksSymptom,
  increasedSpendingOrShoppingSymptom,
  appetiteChangesSymptom,
  nightMaresSymptom,
  flashbacksSymptom,
  memoryProblemsSymptom,
  foodWeightPreoccupationsForm,
  obsessionsSymptom,
  compulsionsSymptom,
  diminishedInterestsSymptom,
  irritabilitySymptom,
  grandIdeasSymptom,
  suicidalHarmfulThoughtsForm,
  impulsivitySymptom,
  otherSymptom,
  noneOfTheAboveSymptom,
];
