import { CheckInCodes, CompletedAnswerData } from "../types/checkInTypes";
import { getFilteredAnswers } from "./getFilteredAnswers";
import { getSortedAnswers } from "./getSortedAnswers";

export const getFormattedAnswers = (
  checkinCode?: CheckInCodes,
  answers?: CompletedAnswerData[],
) => {
  const filteredAnswers = checkinCode
    ? getFilteredAnswers(checkinCode, answers)
    : answers;

  return getSortedAnswers(filteredAnswers);
};
