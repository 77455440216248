import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

import { CancelSubmitButtons, ErrorText } from "~/common/components";
import TextArea from "../Textarea";
import { NoteLayout } from "./NoteLayout";

interface EditNoteProps {
  content?: string;
  handleClose: () => void;
  handleSubmitNote: (data: string | null) => void;
}

const noteSchema = z.object({
  note: z
    .string()
    .max(2000, "Please ensure the note does not exceed 2000 characters.")
    .nullable()
    .transform((value) => (!value ? null : value)),
});

type NoteFormValues = z.infer<typeof noteSchema>;

export const EditNote = ({
  handleClose,
  handleSubmitNote,
  content,
}: EditNoteProps) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<NoteFormValues>({
    resolver: zodResolver(noteSchema),
    defaultValues: {
      note: content,
    },
  });

  const onSubmit: SubmitHandler<NoteFormValues> = ({ note }) =>
    handleSubmitNote(note);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <NoteLayout>
        <div className="flex flex-row items-center justify-between">
          <h2 className="text-lg font-bold text-gray-800">Note</h2>
          <CancelSubmitButtons
            containerClassName="w-1/4"
            variant="right-outline-black"
            handleCancel={handleClose}
          />
        </div>
        <div className="flex w-full flex-col gap-1">
          <TextArea
            rows={5}
            placeholder="Add note here.."
            id="note"
            {...register("note")}
          />
          <ErrorText>{errors.note?.message}</ErrorText>
        </div>
      </NoteLayout>
    </form>
  );
};
