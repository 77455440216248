import { IntakeTextQuestionValues } from "~/common/utils/consultations/intake";
import {
  INTAKE_MULTI_CHECK_VALUES,
  INTAKE_QUESTION_TYPES,
} from "~/common/utils/consultations/intake/data/intakeQuestionsData";
import {
  SCREENING_MULTI_CHECK_VALUES,
  SCREENING_QUESTION_TYPES,
} from "~/common/utils/consultations/screening/data";
import { ScreeningTextQuestionValues } from "../screening";

export const CONSULTATIONS_QUESTION_TYPES = {
  ...SCREENING_QUESTION_TYPES,
  ...INTAKE_QUESTION_TYPES,
} as const;

export const MULTI_CHECK_VALUES = {
  ...SCREENING_MULTI_CHECK_VALUES,
  ...INTAKE_MULTI_CHECK_VALUES,
} as const;

export type ConsultationMultiCheckValues =
  (typeof MULTI_CHECK_VALUES)[keyof typeof MULTI_CHECK_VALUES];

export const CONSULTATIONS_INPUTS_TYPES = {
  text: "text",
  text_area: "text_area",
  phone_number: "phone_number",
} as const;

export type SingleInputTextQuestionValue = "value";

export type ConsultationInputsTypes =
  (typeof CONSULTATIONS_INPUTS_TYPES)[keyof typeof CONSULTATIONS_INPUTS_TYPES];

export type TextQuestionValues =
  | SingleInputTextQuestionValue
  | IntakeTextQuestionValues
  | ScreeningTextQuestionValues;
