import { z } from "zod";

import { PRESCRIBE_ORDER_OPTIONS } from "~/providers/constants";

export const discontinueMedicationSchema = z.object({
  discontinued_at: z
    .string()
    .min(1, "Please select a date for discontinuing this medication."),
  discontinued_internal_note: z
    .string()
    .min(
      1,
      "Please provide a justification for discontinuing this medication.",
    ),
  discontinued_message_to_patient: z
    .string()
    .min(
      1,
      "Please provide a justification for discontinuing this medication.",
    ),
});

export const intGraterThanCero = (field: string) =>
  z
    .string()
    .min(1, `Please specify ${field}`)
    .refine((val) => parseInt(val) > 0, {
      message: `${field} must be greater than 0.`,
    });

export const positiveInt = (field: string) =>
  z
    .string()
    .min(1, `Please specify ${field}`)
    .refine((val) => parseInt(val) >= 0, {
      message: `${field} must be greater than or equal to 0.`,
    });

export const positiveFloat = (field: string) =>
  z
    .string()
    .min(1, `Please specify ${field}`)
    .refine((val) => parseFloat(val) > 0, {
      message: `${field} must be greater than 0.`,
    });

export const patientMedicationSchema = z
  .object({
    medication_strength_id: z.string({
      required_error: "Please select medication's strength.",
    }),
    range: z.boolean(),
    take: positiveFloat("quantity"),
    take_max: z.string().optional(),
    as_needed: z.boolean(),
    frequency_id: z.string({
      required_error: "Please select medication's frequency.",
    }),
    prescribed_quantity: intGraterThanCero("medication's quantity"),
    number_of_refills: positiveInt("quantity of refills"),
    submit_prescription_order: z.string({
      invalid_type_error: "Please select an option.",
    }),
    internal_note: z
      .string()
      .min(1, "Please provide an internal note for this medication."),
    message_to_patient: z
      .string()
      .min(1, "Please provide a message to patient for this medication."),
    submit_prescription_order_reason: z.string().default(""),
    start_medication_change_order: z.boolean().default(false),
    start_date: z
      .string()
      .min(1, "Please select a start date for this medication."),
  })
  .refine(
    (data) =>
      !(
        data.range &&
        data.take_max !== undefined &&
        parseFloat(data.take_max) <= parseFloat(data.take)
      ),
    {
      path: ["take_max"],
      message: "Maximum quantity must be greater than the specified quantity.",
    },
  )
  .refine(
    (data) =>
      data.submit_prescription_order !== PRESCRIBE_ORDER_OPTIONS.false.value ||
      !!data.submit_prescription_order_reason,
    {
      path: ["submit_prescription_order_reason"],
      message: "Please provide a justification for this option.",
    },
  );

export const addMedicationSchema = z.object({
  medication_id: z.string({ required_error: "Please select a medication." }),
  patient_medications_detail: patientMedicationSchema,
});
