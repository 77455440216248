import { useQuery } from "@tanstack/react-query";

import { getPatientCheckIn } from "~/patients/api";

export const useGetActualPatientCheckIn = (patientId?: string) => {
  const { data, isLoading } = useQuery({
    queryFn: () => getPatientCheckIn(patientId ?? ""),
    queryKey: ["getPatientCheckIn", patientId],
    enabled: !!patientId,
  });
  return { data, isLoading };
};
