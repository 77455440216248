import { CONSULTATIONS_QUESTION_TYPES } from "~/common/utils/consultations/shared";
import { replaceUnderscores } from "~/common/utils/replaceUnderscores";
import {
  INTAKE_MULTI_CHECK_VALUES,
  INTAKE_QUESTION_CODES,
} from "../intakeQuestionsData";

export const multiCheckQuestions = {
  [INTAKE_QUESTION_CODES.have_you_experienced_any_of_the_following_medical_issues]:
    {
      title:
        "Have you experienced any of the following medical issues? Please select all that apply.",
      type: CONSULTATIONS_QUESTION_TYPES.multi_check,
      options: [
        {
          value: INTAKE_MULTI_CHECK_VALUES.asthma,
          label: replaceUnderscores(INTAKE_MULTI_CHECK_VALUES.asthma),
        },
        {
          value: INTAKE_MULTI_CHECK_VALUES.liver_issues,
          label: replaceUnderscores(INTAKE_MULTI_CHECK_VALUES.liver_issues),
        },
        {
          value: INTAKE_MULTI_CHECK_VALUES.seizures,
          label: replaceUnderscores(INTAKE_MULTI_CHECK_VALUES.seizures),
        },
        {
          value: INTAKE_MULTI_CHECK_VALUES.heart_history,
          label: replaceUnderscores(INTAKE_MULTI_CHECK_VALUES.heart_history),
        },
        {
          value: INTAKE_MULTI_CHECK_VALUES.diabetes,
          label: replaceUnderscores(INTAKE_MULTI_CHECK_VALUES.diabetes),
        },
        {
          value: INTAKE_MULTI_CHECK_VALUES.sleep_apnea,
          label: replaceUnderscores(INTAKE_MULTI_CHECK_VALUES.sleep_apnea),
        },
        {
          value: INTAKE_MULTI_CHECK_VALUES.excessive_snoring,
          label: replaceUnderscores(
            INTAKE_MULTI_CHECK_VALUES.excessive_snoring,
          ),
        },
        {
          value: INTAKE_MULTI_CHECK_VALUES.kidney_issues,
          label: replaceUnderscores(INTAKE_MULTI_CHECK_VALUES.kidney_issues),
        },
        {
          value: INTAKE_MULTI_CHECK_VALUES.head_traumas_losses_of_consciousness,
          label: "Head traumas / losses of consciousness",
        },
        {
          value: INTAKE_MULTI_CHECK_VALUES.none_of_the_above,
          label: replaceUnderscores(
            INTAKE_MULTI_CHECK_VALUES.none_of_the_above,
          ),
          isExclusiveOption: true,
        },
      ],
    },
} as const;
