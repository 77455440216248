import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";

import {
  CompletedTextAnswer,
  ConsultationInputData,
  ConsultationTextAnswer,
  formatTextAnswer,
  TextQuestionData,
  TextQuestionValues,
} from "~/common/utils/consultations/shared";
import { renderQuestionTitle } from "~/common/utils/renderQuestionTitle";
import {
  ConsultationQuestionsLayout,
  InputRenderer,
} from "~/patients/components/consultations";
import { useGetCurrentPatient } from "~/patients/hooks";
import { createStringSchema } from "~/patients/utils";

export interface TextFormProps {
  question: TextQuestionData;
  handleGoBack: () => void;
  onSubmit: (answer: ConsultationTextAnswer) => void;
  isSubmitting: boolean;
  disableBack: boolean;
  completedAnswer?: CompletedTextAnswer;
  isOptional: boolean;
}

export const TextForm = ({
  question,
  handleGoBack,
  onSubmit,
  isSubmitting,
  disableBack,
  completedAnswer,
  isOptional,
}: TextFormProps) => {
  const { data: user } = useGetCurrentPatient();

  const { inputs, title, supertitle } = question;
  const schema = createStringSchema(inputs);
  const defaultAnswer = formatTextAnswer(inputs, completedAnswer);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ConsultationTextAnswer>({
    defaultValues: { answer: { ...defaultAnswer } },
    resolver: zodResolver(schema),
  });

  const saveAnswers: SubmitHandler<ConsultationTextAnswer> = (data) => {
    const input: ConsultationTextAnswer = data;
    onSubmit(input);
  };

  const answerValues = watch("answer") ?? {};
  const hasValue = Object.values(answerValues).some((value) => value);
  const isNextDisabled = !isOptional && !hasValue;

  const getInputValue = (input: ConsultationInputData<TextQuestionValues>) => {
    return inputs.length === 1 ? "value" : input.value;
  };

  return (
    <ConsultationQuestionsLayout
      handleNext={handleSubmit(saveAnswers)}
      handleBack={handleGoBack}
      disableNext={isNextDisabled}
      isSubmitting={isSubmitting}
      title={renderQuestionTitle(title, user?.pronouns)}
      supertitle={supertitle}
      disableBack={disableBack}
      submitButtonLabel={isOptional && !hasValue ? "Skip" : undefined}
    >
      {inputs.map((input) => (
        <InputRenderer
          key={input.id}
          input={input}
          register={register}
          watch={watch}
          value={getInputValue(input)}
          error={errors.answer?.[getInputValue(input)]?.message}
        />
      ))}
    </ConsultationQuestionsLayout>
  );
};
