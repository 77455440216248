import { create } from "zustand";

interface RecordingStore {
  userRecording: boolean;
  setUserRecording: (userRecording: boolean) => void;
}

export const useRecordingStore = create<RecordingStore>((set) => ({
  userRecording: false,
  setUserRecording: (userRecording: boolean) => set({ userRecording }),
}));
