import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { ConsultationIntroScreen } from "~/patients/components/consultations";
import { useScreeningIntroScreenVisit } from "~/patients/hooks/queries";
import { useGetCurrentPatient } from "~/patients/hooks/queries/useGetCurrentPatient";
import { ROUTES } from "~/patients/router/routes";
import { PatientInformation } from "~/patients/utils";

const getScreeningIntroScreenMessage = (user?: PatientInformation) => (
  <div className="flex flex-col gap-4 text-base font-light text-gray-700">
    <p>Hi {user?.name}!</p>
    <p>I’m looking forward to getting to know you.</p>
    <p>
      Your consultation begins now with some online questions, followed by an
      in-person or virtual meeting with me.
    </p>
    <p>
      The online questions are an essential part of the consultation. It’s
      important that your responses be as thorough and as thoughtful as
      possible.
    </p>
    <div className="space-y-0">
      <p>Looking forward to meeting you</p>
      <p className="text-xl font-semibold">{user?.provider.signature_name}</p>
    </div>
  </div>
);

export const ScreeningIntroScreen = () => {
  const navigate = useNavigate();
  const { data: user } = useGetCurrentPatient();

  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await queryClient.invalidateQueries({
      queryKey: ["getCurrentPatient"],
    });
    navigate(ROUTES.leadPatientHome);
  };

  const { screeningIntroScreenVisitMutation } =
    useScreeningIntroScreenVisit(onSuccess);

  return (
    <ConsultationIntroScreen
      handleContinue={() => screeningIntroScreenVisitMutation()}
      content={getScreeningIntroScreenMessage(user)}
      profilePicturePath={user?.provider.profile_picture_path}
    />
  );
};
