import { PropsWithChildren, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ConfirmActionModal } from "~/common/components";
import CrossIcon from "~/common/components/icons/CrossIcon";
import { Button } from "~/common/components/ui/button";
import { useRecordingStore } from "~/common/stores/useRecordingStore";
import { ROUTES } from "~/patients/router";
import ProgressBar from "../ProgressBar";

interface CheckInFormLayoutProps extends PropsWithChildren {
  actualQuestion: number;
  totalQuestions: number;
  description?: string;
  title?: string;
}

export const CheckInFormLayout = ({
  children,
  actualQuestion,
  totalQuestions,
  title,
}: CheckInFormLayoutProps) => {
  const [showCloseModal, setShowCloseModal] = useState(false);
  const { userRecording, setUserRecording } = useRecordingStore();
  const navigate = useNavigate();
  const toggleModal = () => setShowCloseModal((prev) => !prev);
  const handleClose = () => {
    toggleModal();
    userRecording && setUserRecording(false);
  };

  const handleLeaveCheckIn = () => navigate(ROUTES.home);

  return (
    <div className="h-screen-calculated w-full bg-primary-50 px-6 py-4 text-gray-70 md:p-9">
      <Button
        onClick={handleClose}
        variant="outline-none"
        className="absolute right-6 w-fit p-0 md:flex md:items-start"
      >
        <CrossIcon className="size-5" />
        <span className="hidden md:flex">Close</span>
      </Button>
      <div className="flex h-full flex-col items-center gap-14 bg-primary-50 text-gray-70 md:gap-12">
        <div className="flex w-full flex-col items-center justify-center gap-3">
          <div className="flex w-full flex-col items-center justify-center gap-2.5">
            <p className="w-full text-center text-sm font-medium text-gray-5">
              {actualQuestion} of {totalQuestions}
            </p>
            <ProgressBar
              progress={Math.floor((actualQuestion / totalQuestions) * 100)}
              className="md:w-3/4 xl:w-2/3"
            />
          </div>
          <p className="text-sm  text-primary-600 md:text-base">{title}</p>
        </div>
        {children}
      </div>
      <ConfirmActionModal
        title="Are you sure you want to leave the check-in?"
        onClose={toggleModal}
        onConfirm={handleLeaveCheckIn}
        show={showCloseModal}
      />
    </div>
  );
};
