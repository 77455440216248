import React from "react";
import { twMerge } from "tailwind-merge";

interface BackArrowProps {
  className?: string;
}
const BackArrow = ({ className }: BackArrowProps) => {
  return (
    <svg
      className={twMerge("h-8 w-8", className)}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 12H6M11 6L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11 18"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default BackArrow;
