import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { completeSchedule } from "~/patients/api/consultations/scheduling";

export const useCompleteSchedule = (onSuccess?: () => Promise<void>) => {
  const { mutate: completeScheduleMutation, isPending } = useMutation({
    mutationFn: completeSchedule,
    onSuccess,
    onError: () =>
      toast.error(
        "An error ocurred while trying to complete the schedule. Please try again",
      ),
  });

  return { completeScheduleMutation, isPending };
};
