import { PronounsOptions } from "~/patients";
import { ConsultationQuestionCodes } from "./consultations";
import { getGeneratedTitle } from "./getGeneratedTitle";

export const renderQuestionTitle = (
  title?: string | ((pronounObject?: PronounsOptions) => string),
  pronouns?: PronounsOptions,
  questionCode?: ConsultationQuestionCodes,
) => {
  if (!title) return getGeneratedTitle(questionCode);

  if (typeof title === "function") {
    return title(pronouns);
  }

  return title;
};
