import { api } from "./axios";

export const resendEmailVerification = async () => {
  const response = await api.post<void>(
    "/v1/patients/send-email-validation",
    null,
  );
  return response.data;
};

export const verifyEmail = async (patientId: string, emailToken: string) => {
  const response = await api.post<string>(
    `/v1/patients/${patientId}/verify-email`,
    null,
    {
      headers: {
        "email-token": emailToken,
      },
    },
  );
  return response.data;
};
