import { Avatar, PsychNowLogo } from "~/common/assets";
import {
  ImagePromptCard,
  LoadingState,
  WaveBackgroundLayout,
} from "~/common/components";

interface ConsultationCompletedProps {
  handleContinue: () => void;
  content: React.ReactNode;
  profilePicturePath?: string;
  isLoading?: boolean;
}

export const ConsultationIntroScreen = ({
  handleContinue,
  content,
  profilePicturePath,
  isLoading = false,
}: ConsultationCompletedProps) => {
  if (isLoading) return <LoadingState />;

  return (
    <WaveBackgroundLayout className="relative p-6 md:gap-0">
      <div className="flex flex-col items-center py-8">
        <img src={PsychNowLogo} alt="PsychNow logo" className="w-full" />
      </div>
      <ImagePromptCard
        handleContinue={handleContinue}
        pendingContinue={false}
        continueLabel="Got it!"
        imageContainerClassName="bg-primary-30"
        content={content}
      >
        <img
          className="w-32 rounded-full"
          src={profilePicturePath ?? Avatar}
          alt="Provider"
        />
      </ImagePromptCard>
    </WaveBackgroundLayout>
  );
};
