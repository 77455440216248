import { useMutation, useQueryClient } from "@tanstack/react-query";
import { generatePath, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { CompletePatientIntakeResponse } from "~/common/utils/consultations/intake/interfaces";
import { completeIntake } from "~/patients/api";
import { ROUTES } from "~/patients/router";

export const useCompleteIntake = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: completeIntakeMutation, isPending } = useMutation({
    mutationFn: (patientIntakeId: string) => completeIntake(patientIntakeId),
    onSuccess: async (data: CompletePatientIntakeResponse | null) => {
      const path = data
        ? generatePath(ROUTES.startIntake, {
            id: data.id,
          })
        : ROUTES.leadPatientHome;
      await queryClient.invalidateQueries({ queryKey: ["getCurrentPatient"] });
      navigate(path);
    },
    onError: () =>
      toast.error(
        "An error occurred while submitting this section. If the issue persists, please refresh the page.",
      ),
  });

  return { completeIntakeMutation, isPending };
};
