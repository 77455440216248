import React from "react";
import { twMerge } from "tailwind-merge";

interface ErrorIconProps {
  className?: string;
}
const ErrorIcon = ({ className }: ErrorIconProps) => {
  return (
    <svg
      className={twMerge("h-14 w-14", className)}
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="29" cy="29" r="28.5" fill="#FDDDDC" />
      <circle cx="28.9997" cy="29" r="20.6765" fill="#FEA19B" />
      <path
        d="M35 23.9999L29.5 29.4999M29.5 29.4999L24 35M29.5 29.4999L35 35M29.5 29.4999L24 23.9999"
        stroke="#C8322B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ErrorIcon;
