import { CancelSubmitButtons } from "~/common/components";
import Modal from "~/common/components/Modal";

interface ConfirmExclusiveOptionModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
  exclusiveOptionName?: string;
}

export const ConfirmExclusiveOptionModal = ({
  isOpen,
  onClose,
  onConfirm,
  exclusiveOptionName = "None",
}: ConfirmExclusiveOptionModalProps) => {
  return (
    <Modal
      className="h-fit w-96"
      title="Confirm selection"
      show={isOpen}
      onClose={onClose}
    >
      <div className="flex flex-col gap-6">
        <p className="font-light">
          By selecting &apos;{exclusiveOptionName}&apos;, all other options will
          be deselected. Do you want to proceed?
        </p>
        <CancelSubmitButtons
          handleCancel={onClose}
          handleSubmit={onConfirm}
          submitButtonLabel="Confirm"
          submitButtonType="button"
        />
      </div>
    </Modal>
  );
};
