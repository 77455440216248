import { useQuery } from "@tanstack/react-query";

import { getCheckInWithAnswers } from "~/patients/api/checkIn";

interface UsePatientCheckInAnswersProps {
  patientCheckInId?: string;
}

export const useGetPatientCheckInWithAnswers = ({
  patientCheckInId,
}: UsePatientCheckInAnswersProps) => {
  const { data, isLoading } = useQuery({
    queryFn: () => getCheckInWithAnswers(patientCheckInId ?? ""),
    queryKey: ["getCheckInAnswers", patientCheckInId],
    enabled: !!patientCheckInId,
  });

  return { data, isLoading };
};
