import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

import Input from "~/common/components/ui/Input";
import Select from "~/common/components/ui/select/Select";
import { checkInUtilities } from "~/common/utils/checkIn/checkInUtilities";
import {
  CheckInFormProps,
  INPUT_TYPES,
} from "~/common/utils/types/checkInTypes";
import { questionTextByCodes } from "~/patients/constants";
import { weightSchema } from "~/patients/utils";
import { QuestionsLayout } from "../QuestionsLayout";

export type WeightFormValues = z.infer<typeof weightSchema>;

const weightUnitOptions = [
  {
    label: "lbs",
    value: "lbs",
  },
  {
    label: "kgs",
    value: "kgs",
  },
];

export const WeightForm = ({
  question,
  handleGoBack,
  handleNext,
  existingAnswer,
  isLastQuestion,
  isSubmitting = false,
}: CheckInFormProps) => {
  const { getAnswerOfType, getInputOfType } = checkInUtilities();

  const defaultWeightAnswer = getAnswerOfType(
    INPUT_TYPES.weight,
    question.inputs,
    existingAnswer,
  );

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    getValues,
  } = useForm<WeightFormValues>({
    resolver: zodResolver(weightSchema),
    defaultValues: {
      weight: defaultWeightAnswer?.value,
      weightUnit: defaultWeightAnswer?.unit ?? "lbs",
    },
  });

  const formatAnswer = (data: WeightFormValues) => {
    return {
      question_id: question.id,
      inputs: [
        {
          id: getInputOfType(INPUT_TYPES.weight, question.inputs)?.id,
          answer: {
            answer_type: INPUT_TYPES.weight,
            value: data.weight,
            unit: data.weightUnit,
          },
        },
      ],
    };
  };

  const goBack = () => {
    const data = getValues();
    handleGoBack(formatAnswer(data));
  };

  const saveAnswers: SubmitHandler<WeightFormValues> = (data) => {
    handleNext(formatAnswer(data));
  };

  return (
    <QuestionsLayout
      question={questionTextByCodes[question.code]}
      handleNext={handleSubmit(saveAnswers)}
      handleBack={goBack}
      disableNext={!watch(INPUT_TYPES.weight)}
      isLastQuestion={isLastQuestion}
      isSubmitting={isSubmitting}
    >
      <div className="flex gap-2">
        <Input
          id={INPUT_TYPES.weight}
          placeholder="Weight"
          error={errors.weight?.message}
          {...register(INPUT_TYPES.weight, {
            setValueAs: (value) => Number(value),
          })}
          type="number"
          step=".01"
        />
        <Select
          containerClassName="w-1/2"
          id="weightUnit"
          options={weightUnitOptions}
          control={control}
          checkSelectedOption
        />
      </div>
    </QuestionsLayout>
  );
};
