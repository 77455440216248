import { APPOINTMENT_DURATIONS } from "~/common/constants/shared.constants";
import { HEAlTH_FACTORS_CODES } from "~/common/utils/types/checkInTypes";

export const FREQUENCIES_INTERVALS = {
  weekly: "weekly",
  monthly: "monthly",
  two_weeks: "two_weeks",
  three_weeks: "three_weeks",
  two_months: "two_months",
  three_months: "three_months",
} as const;

export type FrequencyType = keyof typeof FREQUENCIES_INTERVALS;

export const FREQUENCIES_INTERVALS_VALUES = [
  FREQUENCIES_INTERVALS.weekly,
  FREQUENCIES_INTERVALS.two_weeks,
  FREQUENCIES_INTERVALS.three_weeks,
  FREQUENCIES_INTERVALS.monthly,
  FREQUENCIES_INTERVALS.two_months,
  FREQUENCIES_INTERVALS.three_months,
] as const;

export const frequencyOptions = {
  [FREQUENCIES_INTERVALS.weekly]: {
    value: FREQUENCIES_INTERVALS.weekly,
    label: "Every one week",
  },
  [FREQUENCIES_INTERVALS.two_weeks]: {
    value: FREQUENCIES_INTERVALS.two_weeks,
    label: "Every two weeks",
  },
  [FREQUENCIES_INTERVALS.three_weeks]: {
    value: FREQUENCIES_INTERVALS.three_weeks,
    label: "Every three weeks",
  },
  [FREQUENCIES_INTERVALS.monthly]: {
    value: FREQUENCIES_INTERVALS.monthly,
    label: "Every month",
  },
  [FREQUENCIES_INTERVALS.two_months]: {
    value: FREQUENCIES_INTERVALS.two_months,
    label: "Every two months",
  },
  [FREQUENCIES_INTERVALS.three_months]: {
    value: FREQUENCIES_INTERVALS.three_months,
    label: "Every three months",
  },
} as const;

export const DURATION_INTERVALS_VALUES = [
  APPOINTMENT_DURATIONS.minutes_5,
  APPOINTMENT_DURATIONS.minutes_10,
  APPOINTMENT_DURATIONS.minutes_15,
  APPOINTMENT_DURATIONS.minutes_20,
  APPOINTMENT_DURATIONS.minutes_25,
  APPOINTMENT_DURATIONS.minutes_30,
] as const;

export const durationOptions = DURATION_INTERVALS_VALUES.map((value) => ({
  value: value,
  label: value,
}));

export const frequencies = {
  [FREQUENCIES_INTERVALS.weekly]: {
    interval: FREQUENCIES_INTERVALS.weekly,
    frequency: 1,
  },
  [FREQUENCIES_INTERVALS.two_weeks]: {
    interval: FREQUENCIES_INTERVALS.weekly,
    frequency: 2,
  },
  [FREQUENCIES_INTERVALS.three_weeks]: {
    interval: FREQUENCIES_INTERVALS.weekly,
    frequency: 3,
  },
  [FREQUENCIES_INTERVALS.monthly]: {
    interval: FREQUENCIES_INTERVALS.monthly,
    frequency: 1,
  },
  [FREQUENCIES_INTERVALS.two_months]: {
    interval: FREQUENCIES_INTERVALS.monthly,
    frequency: 2,
  },
  [FREQUENCIES_INTERVALS.three_months]: {
    interval: FREQUENCIES_INTERVALS.monthly,
    frequency: 3,
  },
} as const;

export const ordersFrequencies = {
  [HEAlTH_FACTORS_CODES.basic_labs]: [
    frequencyOptions.weekly,
    frequencyOptions.two_weeks,
    frequencyOptions.three_weeks,
    frequencyOptions.monthly,
    frequencyOptions.two_months,
    frequencyOptions.three_months,
  ],
  [HEAlTH_FACTORS_CODES.blood_pressure_heart_rate]: [
    frequencyOptions.weekly,
    frequencyOptions.two_weeks,
    frequencyOptions.three_weeks,
    frequencyOptions.monthly,
    frequencyOptions.two_months,
    frequencyOptions.three_months,
  ],
  [HEAlTH_FACTORS_CODES.blood_pressure_heart_rate_recurrent]: [
    frequencyOptions.weekly,
    frequencyOptions.two_weeks,
    frequencyOptions.three_weeks,
    frequencyOptions.monthly,
    frequencyOptions.two_months,
    frequencyOptions.three_months,
  ],
  [HEAlTH_FACTORS_CODES.status_check]: [
    frequencyOptions.weekly,
    frequencyOptions.two_weeks,
    frequencyOptions.three_weeks,
    frequencyOptions.monthly,
    frequencyOptions.two_months,
    frequencyOptions.three_months,
  ],
  [HEAlTH_FACTORS_CODES.weight]: [
    frequencyOptions.weekly,
    frequencyOptions.two_weeks,
    frequencyOptions.three_weeks,
    frequencyOptions.monthly,
    frequencyOptions.two_months,
    frequencyOptions.three_months,
  ],
};

export const repeatUntilOptions = {
  forEver: "recurringForEver",
  ends: "recurringEnds",
} as const;

export const REPEAT_UNTIL_VALUES = [
  repeatUntilOptions.ends,
  repeatUntilOptions.forEver,
] as const;
