export const patientWelcomeText =
  " we are on a transformative journey to ensure that every individual in need can find an expert provider within their means—a provider united in the shared objective of achieving wellness.";

export const PRONOUNS = {
  he: "he/him/his",
  she: "she/her/hers",
  they: "they/them/theirs",
  other: "other",
} as const;

export const SCRIPT_LIFE = {
  current: "current",
  past: "past",
  all: "all",
} as const;

export const COMMENT_TITLE = {
  OPENED_BY_PATIENT: "Prescription Request opened by patient",
  CANCELED_BY_PATIENT: "Prescription Request canceled by patient",
  REOPENED_BY_PATIENT: "Prescription Request re-opened by patient",
} as const;
