import { H2, NoteData, QuestionAnswerLayout } from "~/common/components";
import { CheckInWithAnswers } from "~/common/utils";
import { useGetCurrentPatient } from "~/patients/hooks/queries/useGetCurrentPatient";

interface ScheduledAppointmentProps {
  details?: CheckInWithAnswers;
}

export const ScheduledAppointment = ({
  details,
}: ScheduledAppointmentProps) => {
  const { note, extra_data } = details ?? {};
  const { data: user } = useGetCurrentPatient();

  const { signature_name: providerName } = { ...user?.provider };
  const duration = extra_data?.duration.replace("min", "minute");

  return (
    <div className="flex w-full flex-col gap-4 overflow-auto">
      <H2>Schedule appointment</H2>
      <div className="flex flex-col gap-4">
        {note && (
          <NoteData
            seeMoreButton
            title={`Message from ${providerName}`}
            note={note}
          />
        )}
        <QuestionAnswerLayout
          answerClassName="p-2"
          question={`Schedule a ${duration} appointment`}
          answer="Done"
        />
      </div>
    </div>
  );
};
