import {
  PatientScreeningBundle,
  screeningSectionTitle,
} from "~/common/utils/consultations/screening";
import { CheckListCard } from "../shared/ui/cards/CheckListCard";
import { ScreeningCheckListCard } from "./ScreeningCheckListCard";

export const ScreeningCheckList = ({ ...props }: PatientScreeningBundle) => {
  const { screenings, total_sections: totalScreenings } = { ...props };

  return (
    <div className="relative flex size-full h-full flex-col items-center overflow-y-auto bg-primary-20 px-6 py-3 md:px-14 md:py-8">
      {screenings.map(({ id, status, order, code, ...screening }) => (
        <CheckListCard
          key={id}
          id={id}
          status={status}
          order={order}
          total={totalScreenings}
        >
          <ScreeningCheckListCard
            screening={{ id, status, order, code, ...screening }}
            title={screeningSectionTitle[code]}
          />
        </CheckListCard>
      ))}
    </div>
  );
};
