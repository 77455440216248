import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { uploadFile } from "~/patients/api/services/settingsService";

export const useUploadDriverLicense = (
  handleSuccess: (url: string) => void,
) => {
  const { mutate: uploadImageMutation, isPending } = useMutation({
    mutationFn: ({ image }: { image: File }) => uploadFile(image),
    onError: () =>
      toast.error(
        "There was an issue uploading the image. Please try again later.",
      ),
    onSuccess: ({ url }) => {
      toast.success("Image upload successfully.");
      handleSuccess(url);
    },
  });

  return { uploadImageMutation, isPending };
};
