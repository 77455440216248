import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import { Button } from "~/common/components/ui/button";

interface SignUpFormLayoutProps {
  children: ReactNode;
  handleData: () => void;
  title: string;
  handleGoBack?: () => void;
  description?: string;
  submitLabel?: string;
  disableContinue?: boolean;
  pendingRequest?: boolean;
}

const SignUpFormLayout = ({
  children,
  handleData,
  title,
  handleGoBack,
  description,
  disableContinue,
  submitLabel,
  pendingRequest = false,
}: SignUpFormLayoutProps) => {
  return (
    <div className="flex h-full w-full flex-1 flex-col items-center justify-center gap-10">
      <div className="flex flex-col items-center gap-4 px-8 md:px-0">
        <h2 className="text-center font-outfit text-3xl font-medium leading-9">
          {title}
        </h2>
        {description && (
          <p className="text-center font-medium text-gray-10">{description}</p>
        )}
      </div>
      <div className="flex w-full flex-grow flex-col gap-10 bg-transparent px-8 md:w-2/3 md:px-0 xl:h-fit xl:w-1/4 xl:px-0">
        <div className="flex w-full flex-grow flex-col justify-between gap-4 xl:flex-grow-0 xl:justify-normal xl:gap-5">
          <div className="flex size-full flex-grow flex-col gap-4">
            {children}
          </div>
          <div
            className={twMerge(
              "flex justify-between py-5",
              !handleGoBack && "justify-end",
            )}
          >
            {handleGoBack && (
              <Button
                size="sm"
                variant="outline-none"
                className="w-fit px-2"
                onClick={handleGoBack}
              >
                Back
              </Button>
            )}
            <Button
              size="sm"
              disabled={disableContinue}
              onClick={handleData}
              className={twMerge("w-1/3", !handleGoBack && "w-full")}
              isLoading={pendingRequest}
            >
              {submitLabel ?? "Next"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpFormLayout;
