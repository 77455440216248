import {
  SCREENING_QUESTION_CODES,
  SCREENING_QUESTION_TYPES,
} from "~/common/utils/consultations/screening/data";

export const dateQuestions = {
  [SCREENING_QUESTION_CODES.date_of_birth]: {
    title: "Please provide your date of birth.",
    type: SCREENING_QUESTION_TYPES.age,
  },
} as const;
