import { ComponentPropsWithoutRef, PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

import { IconWrapper } from "~/patients/ui";

type CircleProps = PropsWithChildren & ComponentPropsWithoutRef<"div">;

export const Circle = ({ children, className, ...props }: CircleProps) => (
  <IconWrapper>
    <div
      className={twMerge(
        "flex size-3 items-center justify-center rounded-full bg-primary-500",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  </IconWrapper>
);
