import {
  INTAKE_SYMPTOM_DETAIL_QUESTIONS_RADIO_GROUP_VALUES,
  YES_NO_NA_TYPES,
  YES_NO_NOT_SURE_TYPES,
} from "./data";

export const YesNoNA = [
  YES_NO_NA_TYPES.yes,
  YES_NO_NA_TYPES.no,
  YES_NO_NA_TYPES.na,
] as const;

export const YesNoNotSure = [
  YES_NO_NOT_SURE_TYPES.yes,
  YES_NO_NOT_SURE_TYPES.no,
  YES_NO_NOT_SURE_TYPES.notSure,
] as const;

export const RadioGroupTrendOptions = [
  INTAKE_SYMPTOM_DETAIL_QUESTIONS_RADIO_GROUP_VALUES.steady,
  INTAKE_SYMPTOM_DETAIL_QUESTIONS_RADIO_GROUP_VALUES.getting_better,
  INTAKE_SYMPTOM_DETAIL_QUESTIONS_RADIO_GROUP_VALUES.getting_worse,
] as const;

export const RadioGroupNatureOfEpisodesOptions = [
  INTAKE_SYMPTOM_DETAIL_QUESTIONS_RADIO_GROUP_VALUES.intermittent_and_fully_better_between_episodes,
  INTAKE_SYMPTOM_DETAIL_QUESTIONS_RADIO_GROUP_VALUES.intermittent_and_not_fully_better_between_episodes,
] as const;

export const RadioGroupFrequencyOptions = [
  INTAKE_SYMPTOM_DETAIL_QUESTIONS_RADIO_GROUP_VALUES.less_frequent_episodes,
  INTAKE_SYMPTOM_DETAIL_QUESTIONS_RADIO_GROUP_VALUES.more_frequent_episodes,
  INTAKE_SYMPTOM_DETAIL_QUESTIONS_RADIO_GROUP_VALUES.unpredictably_variable,
] as const;

export const intakeRadioOptions = [...YesNoNA, ...YesNoNotSure] as const;

export type IntakeOptionsValues = (typeof intakeRadioOptions)[number];

export type YesNoNAType = typeof YesNoNA;

export type YesNoNotSureType = typeof YesNoNotSure;

export type RadioGroupTrendType = typeof RadioGroupTrendOptions;

export type RadioGroupNatureOfEpisodesType =
  typeof RadioGroupNatureOfEpisodesOptions;

export type RadioGroupFrequencyType = typeof RadioGroupFrequencyOptions;

export type IntakeRadioValues = YesNoNAType | YesNoNotSureType;

export type RadioGroupValues =
  | RadioGroupTrendType
  | RadioGroupNatureOfEpisodesType
  | RadioGroupFrequencyType;
