import { matchPath, NavigateFunction } from "react-router-dom";

import { ROUTES } from "../router";

interface PatientHomeMenuOptions {
  label: string;
  current?: boolean;
  onClick: () => void;
  notificationAmount: number;
  enabled: boolean;
}

export const getHomeLayoutMenuOptions = (
  navigate: NavigateFunction,
  notificationAmount?: number,
) => {
  const actualPath = window.location.pathname;

  const menuOptions: PatientHomeMenuOptions[] = [
    {
      label: "To do",
      current: !!matchPath(ROUTES.home, actualPath),
      onClick: () => navigate(ROUTES.home),
      notificationAmount: notificationAmount ?? 0,
      enabled: true,
    },
    {
      label: "Completed",
      current: !!matchPath(ROUTES.completedCheckins, actualPath),
      onClick: () => navigate(ROUTES.completedCheckins),
      notificationAmount: 0,
      enabled: true,
    },
  ];

  return menuOptions;
};
