import { z } from "zod";

export const optionalTextValidation = z.string().default("");

export const baseOptionSchema = z.object({
  toggled: z.boolean().default(false),
});

export const toggleSchema = baseOptionSchema.default({ toggled: false });

export const toggleTextExtraDataSchema = baseOptionSchema
  .extend({
    extra_data: optionalTextValidation,
  })
  .default({ toggled: false, extra_data: undefined });

export const toggleRequiredExtraDataSchema = toggleTextExtraDataSchema.refine(
  (data) => {
    if (data.toggled) return data.extra_data !== "";
    return true;
  },
  {
    message: "Please provide your justification",
    path: ["extra_data"],
  },
);
