import { GreenTickIcon, MicrophoneIcon, StopIcon } from "~/common/components";
import { RoundButton } from "../RoundButton";

interface AudioRecorderButtonProps {
  audioUrl?: string;
  isRecording: boolean;
  recordingTime: number;
  totalDuration: number;
  maxDuration?: number;
  handleStopRecording: () => void;
  handleStartRecording: () => void;
}

export const AudioRecorderButton = ({
  audioUrl,
  isRecording,
  recordingTime,
  totalDuration,
  maxDuration = 60,
  handleStopRecording,
  handleStartRecording,
}: AudioRecorderButtonProps) => {
  if (totalDuration === maxDuration && audioUrl) {
    return <GreenTickIcon className="size-24" />;
  }

  return isRecording ? (
    <RoundButton
      onClick={handleStopRecording}
      type="button"
      disabled={recordingTime < 1}
    >
      <StopIcon />
    </RoundButton>
  ) : (
    <RoundButton
      onClick={handleStartRecording}
      disabled={!!audioUrl}
      type="button"
    >
      <MicrophoneIcon />
    </RoundButton>
  );
};
