import { generatePath, useNavigate } from "react-router-dom";

import { EmptyState, LoadingState } from "~/common/components";
import { PATIENT_MEDICATION_STATUS } from "~/common/constants/medications";
import { formatMedicationName } from "~/common/utils";
import { useGetPatientMedications } from "~/patients/hooks/queries/medications/useGetPatientMedications";
import { ROUTES } from "~/patients/router";
import { generateDatesRange } from "~/providers/utils";
import { PatientMedicationCard } from "./PatientMedicationCard";

export const PastMedications = () => {
  const { data: discontinuedMedications, isLoading } = useGetPatientMedications(
    PATIENT_MEDICATION_STATUS.discontinued,
  );

  const navigate = useNavigate();

  if (isLoading) return <LoadingState />;

  if (!discontinuedMedications?.data.length) {
    return (
      <EmptyState
        message="You have no past medications."
        className="min-h-80"
      />
    );
  }

  return (
    <div className="flex flex-col gap-4">
      {discontinuedMedications?.data.map((med) => (
        <PatientMedicationCard
          key={med.id}
          title={formatMedicationName(med.medication)}
          status={PATIENT_MEDICATION_STATUS.discontinued}
          subtitle={generateDatesRange(med.start_date, med.discontinued_at)}
          handleClick={() =>
            navigate(
              generatePath(ROUTES.medicationDetails, {
                id: med.id,
              }),
            )
          }
        />
      ))}
    </div>
  );
};
