import { YES_NO_TYPES } from "~/common/utils/consultations/screening/data/index";
import {
  RadioGroupFrequencyOptions,
  RadioGroupNatureOfEpisodesOptions,
  RadioGroupTrendOptions,
} from "../../intakeTypes";
import {
  INTAKE_MULTI_CHECK_VALUES,
  INTAKE_QUESTION_CODES,
  INTAKE_QUESTION_TYPES,
  TERTIARY_EDUCATION_TYPE,
  YES_NO_NA_TYPES,
} from "../intakeQuestionsData";

const SYMPTOMS_OPTIONS = [
  {
    title: "Trend",
    group: RadioGroupTrendOptions,
  },
  {
    title: "Nature of episodes",
    group: RadioGroupNatureOfEpisodesOptions,
  },
  {
    title: "Frequency",
    group: RadioGroupFrequencyOptions,
  },
];

export const specialQuestions = {
  [INTAKE_QUESTION_CODES.college_details]: {
    title: "Did you attend college?",
    type: INTAKE_QUESTION_TYPES.radio_with_tertiary_education,
    options: [
      {
        value: YES_NO_TYPES.yes,
        asksExtraInfo: true,
      },
      {
        value: YES_NO_TYPES.no,
      },
    ],
    tertiaryType: TERTIARY_EDUCATION_TYPE.college,
  },
  [INTAKE_QUESTION_CODES.graduate_school_details]: {
    title: "Did you attend graduate school?",
    type: INTAKE_QUESTION_TYPES.radio_with_tertiary_education,
    options: [
      {
        value: YES_NO_TYPES.yes,
        asksExtraInfo: true,
      },
      {
        value: YES_NO_TYPES.no,
      },
    ],
    tertiaryType: TERTIARY_EDUCATION_TYPE.graduate_school,
  },
  [INTAKE_QUESTION_CODES.medical_or_psychiatric_hospitalization_please_provide_more_details]:
    {
      title: "Please provide more details.",
      type: INTAKE_QUESTION_TYPES.medical_or_psychiatric_hospitalization_please_provide_more_details,
    },
  [INTAKE_QUESTION_TYPES.jobs_you_have_held]: {
    title: "Please list all the jobs you’ve held in the past.",
    type: INTAKE_QUESTION_TYPES.jobs_you_have_held,
  },
  [INTAKE_QUESTION_CODES.you_indicated_you_have_seen_a_therapist]: {
    title:
      "You indicated you have seen a therapist in the past. Please provide more details.",
    type: INTAKE_QUESTION_TYPES.you_indicated_you_have_seen_a_therapist,
  },
  [INTAKE_QUESTION_CODES.you_indicated_you_are_currently_taking_medications]: {
    title:
      "You indicated you are currently taking medications. Please provide some details",
    type: INTAKE_QUESTION_TYPES.you_indicated_taking_medications,
  },
  [INTAKE_QUESTION_CODES.cut_down_alcohol_use]: {
    title:
      "Have you ever thought that you should cut down on your alcohol use?",
    type: INTAKE_QUESTION_TYPES.yes_no_na_with_audio_on_yes,
    options: [
      {
        value: YES_NO_NA_TYPES.yes,
        label: "Yes",
        asksExtraInfo: true,
      },
      {
        value: YES_NO_NA_TYPES.no,
        label: "No",
        asksExtraInfo: false,
      },
      {
        label: "No alcohol use",
        value: YES_NO_NA_TYPES.na,
        asksExtraInfo: false,
      },
    ],
  },
  [INTAKE_QUESTION_CODES.high_school_details]: {
    title: "Did you attend highschool?",
    type: INTAKE_QUESTION_TYPES.radio_with_highschool,
    options: [
      {
        value: YES_NO_TYPES.yes,
        asksExtraInfo: true,
      },
      {
        value: YES_NO_TYPES.no,
      },
    ],
  },
  [INTAKE_QUESTION_CODES.association_instructions]: {
    title:
      "Please complete the following sentences with the first thought that comes to mind.",
    type: INTAKE_QUESTION_TYPES.instructions,
  },
  [INTAKE_QUESTION_CODES.jobs_you_have_held_in_the_past]: {
    title: "Please list all the jobs you’ve held in the past.",
    type: INTAKE_QUESTION_TYPES.jobs_you_have_held,
  },
  [INTAKE_QUESTION_CODES.mind_playing_tricks]: {
    title: "Is your mind playing tricks on you in some fashion?",
    type: INTAKE_QUESTION_TYPES.multi_check,
    commonExtraDataLabel: "What are they saying?",
    options: [
      {
        value:
          INTAKE_MULTI_CHECK_VALUES.hearing_voices_when_nobody_else_is_around,
        label: "Hearing voices when nobody else is around",
        asksCommonExtraData: true,
      },
      {
        value:
          INTAKE_MULTI_CHECK_VALUES.thinking_people_are_talking_to_you_or_about_you_when_nobody_else_is_around,
        label:
          "Thinking people are talking to you or about you when nobody else is around",
        asksCommonExtraData: true,
      },
      {
        value:
          INTAKE_MULTI_CHECK_VALUES.thinking_people_you_may_see_or_that_may_be_on_tv_are_talking_about_you,
        label:
          "Thinking people you may see or that may be on TV are talking about you",
        asksCommonExtraData: true,
      },
      {
        value: INTAKE_MULTI_CHECK_VALUES.no,
        label: "No",
        isExclusiveOption: true,
      },
    ],
  },
  [INTAKE_QUESTION_CODES.depression_symptoms]: {
    title: `Please indicate how "Depression" has progressed from the time it began until today?`,
    type: INTAKE_QUESTION_TYPES.radio_group,
    options: SYMPTOMS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.sleep_issues_symptoms]: {
    title: `Please indicate how "Sleep issues" has progressed from the time it began until today?`,
    type: INTAKE_QUESTION_TYPES.radio_group,
    options: SYMPTOMS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.panic_attack_symptoms]: {
    title: `Please indicate how "Panic attacks" has progressed from the time it began until today?`,
    type: INTAKE_QUESTION_TYPES.radio_group,
    options: SYMPTOMS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.nightmare_symptoms]: {
    title: `Please indicate how "Nightmares" has progressed from the time it began until today?`,
    type: INTAKE_QUESTION_TYPES.radio_group,
    options: SYMPTOMS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.attention_and_focus_symptoms]: {
    title: `Please indicate how "Attention and focus issues" has progressed from the time it began until today?`,
    type: INTAKE_QUESTION_TYPES.radio_group,
    options: SYMPTOMS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.anxiety_symptoms]: {
    title: `Please indicate how "Anxiety" has progressed from the time it began until today?`,
    type: INTAKE_QUESTION_TYPES.radio_group,
    options: SYMPTOMS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.increased_spending_symptoms]: {
    title: `Please indicate how "Increased spending or shopping" has progressed from the time it began until today?`,
    type: INTAKE_QUESTION_TYPES.radio_group,
    options: SYMPTOMS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.appetite_changes_symptoms]: {
    title: `Please indicate how "Appetite changes" has progressed from the time it began until today?`,
    type: INTAKE_QUESTION_TYPES.radio_group,
    options: SYMPTOMS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.flashbacks_symptoms]: {
    title: `Please indicate how "Flashbacks" has progressed from the time it began until today?`,
    type: INTAKE_QUESTION_TYPES.radio_group,
    options: SYMPTOMS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.preoccupation_weight_symptoms]: {
    title: `Please indicate how "Preoccupation with weight or food" has progressed from the time it began until today?`,
    type: INTAKE_QUESTION_TYPES.radio_group,
    options: SYMPTOMS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.obsessions_symptoms]: {
    title: `Please indicate how "Obsessions" has progressed from the time it began until today?`,
    type: INTAKE_QUESTION_TYPES.radio_group,
    options: SYMPTOMS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.compulsions_symptoms]: {
    title: `Please indicate how "Compulsions" has progressed from the time it began until today?`,
    type: INTAKE_QUESTION_TYPES.radio_group,
    options: SYMPTOMS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.diminished_interest_symptoms]: {
    title: `Please indicate how "Diminished interest in previously enjoyable things" has progressed from the time it began until today?`,
    type: INTAKE_QUESTION_TYPES.radio_group,
    options: SYMPTOMS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.irritability_symptoms]: {
    title: `Please indicate how "Irritability" has progressed from the time it began until today?`,
    type: INTAKE_QUESTION_TYPES.radio_group,
    options: SYMPTOMS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.grand_ideas_symptoms]: {
    title: `Please indicate how "Grand ideas / feeling on top of the world" has progressed from the time it began until today?`,
    type: INTAKE_QUESTION_TYPES.radio_group,
    options: SYMPTOMS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.suicidal_thoughts_symptoms]: {
    title: `Please indicate how "Suicidal and/or harmful thoughts" has progressed from the time it began until today?`,
    type: INTAKE_QUESTION_TYPES.radio_group,
    options: SYMPTOMS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.impulsivity_symptoms]: {
    title: `Please indicate how "Impulsivity" has progressed from the time it began until today?`,
    type: INTAKE_QUESTION_TYPES.radio_group,
    options: SYMPTOMS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.other_symptoms]: {
    title: `Please indicate how "Other symptoms" has progressed from the time it began until today?`,
    type: INTAKE_QUESTION_TYPES.radio_group,
    options: SYMPTOMS_OPTIONS,
  },
} as const;
