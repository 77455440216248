import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { deleteFile } from "~/patients/api/services/settingsService";

export const useDeleteDriverLicense = (handleSuccess: () => void) => {
  const { mutate: deleteImageMutation, isPending } = useMutation({
    mutationFn: () => deleteFile(),
    onError: () =>
      toast.error(
        "There was an issue deleting the image. Please try again later.",
      ),
    onSuccess: () => {
      toast.success("Image deleted successfully.");
      handleSuccess();
    },
  });

  return { deleteImageMutation, isPending };
};
