import { z } from "zod";

export const authenticateProviderSchema = z.object({
  email: z
    .string()
    .min(1, { message: "Email is required" })
    .email({ message: "Please enter a valid email address." }),
  password: z.string().min(1, { message: "Password is required" }),
});

export const passwordSchema = z
  .object({
    password: z.string(),
    passwordConfirmation: z.string(),
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    path: ["passwordConfirmation"],
    message:
      "Passwords don't match. Please make sure to enter the same password in both fields.",
  })
  .refine(
    (data) => {
      if (data.password !== data.passwordConfirmation) return true;
      const passwordRegex =
        // eslint-disable-next-line  no-useless-escape
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`!@#$%^&*()_\-+=\[\]{};':"\\|,.<>\/?~ ])[A-Za-z\d`!@#$%^&*()_\-+=\[\]{};':"\\|,.<>\/?~ ]{8,}$/;
      const valid = passwordRegex.test(data.password);
      return valid;
    },
    {
      path: ["password"],
      message: "Passwords don't meet the requirements.",
    },
  );

export const emailSchema = z.object({
  email: z
    .string()
    .min(1, "Email is required")
    .email({ message: "Please enter a valid email address." }),
});

export const providerDetailsSchema = z.object({
  signature_name: z.string().min(1, "Please enter signature name."),
  preferred_reference: z.string().min(1, "Please enter preferred reference."),
});
