import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

import { checkInUtilities } from "~/common/utils/checkIn/checkInUtilities";
import {
  CheckInFormProps,
  INPUT_TYPES,
  InputAnswer,
  Statuses,
} from "~/common/utils/types/checkInTypes";
import { questionTextByCodes } from "~/patients/constants";
import { statusSchema } from "~/patients/utils";
import RadioButtonOption from "../../ui/RadioButtonOption";
import { QuestionsLayout } from "../QuestionsLayout";

export type StatusFormValues = z.infer<typeof statusSchema>;

export const StatusForm = ({
  question,
  handleGoBack,
  handleNext,
  existingAnswer,
  isLastQuestion,
  isSubmitting = false,
}: CheckInFormProps) => {
  const { getInputOfType, getAnswerOfType } = checkInUtilities();

  const defaultStatusAnswer = getAnswerOfType(
    INPUT_TYPES.status,
    question.inputs,
    existingAnswer,
  );

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<StatusFormValues>({
    resolver: zodResolver(statusSchema),
    defaultValues: {
      answer: defaultStatusAnswer?.value,
    },
  });

  const saveAnswers: SubmitHandler<StatusFormValues> = (data) => {
    const inputs: InputAnswer[] = [
      {
        id: getInputOfType(INPUT_TYPES.status, question.inputs)?.id,
        answer: {
          answer_type: INPUT_TYPES.status,
          value: data.answer,
        },
      },
    ];

    handleNext({
      question_id: question.id,
      inputs,
    });
  };

  return (
    <QuestionsLayout
      question={questionTextByCodes[question.code]}
      handleNext={handleSubmit(saveAnswers)}
      handleBack={() => handleGoBack()}
      disableNext={!watch("answer")}
      errors={errors.answer?.message}
      isLastQuestion={isLastQuestion}
      isSubmitting={isSubmitting}
    >
      {Statuses.map((option, index) => (
        <RadioButtonOption
          key={index}
          label={option}
          value={option}
          isSelected={watch("answer") === option}
          {...register("answer")}
        />
      ))}
    </QuestionsLayout>
  );
};
