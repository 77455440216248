import { CancelSubmitButtons } from "~/common/components";
import Modal from "~/common/components/Modal";

interface ConfirmActionModalProps {
  cancelButtonClassName?: string;
  cancelButtonText?: string;
  confirmButtonClassName?: string;
  confirmButtonText?: string;
  description?: string;
  onClose: () => void;
  onConfirm?: () => void;
  show: boolean;
  title: string;
  isLoading?: boolean;
}

export const ConfirmActionModal = ({
  cancelButtonClassName,
  cancelButtonText,
  confirmButtonClassName,
  confirmButtonText,
  description,
  onClose,
  onConfirm,
  show,
  isLoading = false,
  title,
}: ConfirmActionModalProps) => (
  <Modal
    className="h-fit w-96"
    titleClassName="text-lg font-medium w-2/3 text-gray-70"
    title={title}
    description={description}
    show={show}
    onClose={onClose}
  >
    <div className="flex flex-col gap-3 self-end">
      <CancelSubmitButtons
        cancelClassName={cancelButtonClassName}
        handleCancel={onClose}
        cancelButtonLabel={cancelButtonText ?? "Cancel"}
        submitClassName={confirmButtonClassName}
        handleSubmit={onConfirm}
        submitButtonLabel={confirmButtonText ?? "Confirm"}
        isSubmitting={isLoading}
      />
    </div>
  </Modal>
);
