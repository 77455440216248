import { SubmitHandler, useForm } from "react-hook-form";

import {
  InstructionsQuestionAnswer,
  InstructionsQuestionData,
} from "~/common/utils/consultations/intake/interfaces";
import { ConsultationQuestionsLayout } from "~/patients/components/consultations/shared";

export interface InstructionsScreenProps {
  question: InstructionsQuestionData;
  handleGoBack: () => void;
  onSubmit: (answer: InstructionsQuestionAnswer) => void;
  isSubmitting: boolean;
  disableBack: boolean;
}

export const InstructionsScreen = ({
  question,
  onSubmit,
  handleGoBack,
  isSubmitting,
  disableBack,
}: InstructionsScreenProps) => {
  const { title } = question;

  const { handleSubmit } = useForm<InstructionsQuestionAnswer>();

  const saveAnswers: SubmitHandler<InstructionsQuestionAnswer> = () =>
    onSubmit({ answer: null });

  return (
    <ConsultationQuestionsLayout
      handleNext={handleSubmit(saveAnswers)}
      handleBack={handleGoBack}
      isSubmitting={isSubmitting}
      title={title}
      disableBack={disableBack}
    />
  );
};
