export const BASE_SETTINGS_PATH = "/settings";
export const BASE_PATIENT = "/patient";
export const BASE_PATIENT_PROFILE = `${BASE_PATIENT}/:patientId`;
export const BASE_CONSULTATION_REQUEST = "/consultation-requests/:patientId";
export const CONSULTATION_PATH = "/consultation";

export const ROUTES = {
  login: "/login",
  signUp: "/sign-up",
  home: "/",
  forgotPassword: "/forgot-password",
  resetPassword: "/password-reset",
  activityFeed: `${BASE_PATIENT_PROFILE}/activity-feed`,
  medication: `${BASE_PATIENT_PROFILE}/medication`,
  ouraRing: `${BASE_PATIENT_PROFILE}/oura-ring`,
  orders: `${BASE_PATIENT_PROFILE}/orders`,
  notFound: "/not-found",
  internalError: "/internal-error",
  prescriptionRequest: "/prescription-requests",
  consultationRequests: "/consultation-requests",
  account: `${BASE_SETTINGS_PATH}/account`,
  scheduling: `${BASE_SETTINGS_PATH}/scheduling`,
  paymentAndCarePlans: `${BASE_SETTINGS_PATH}/payment-and-care-plans`,
  info: `${BASE_CONSULTATION_REQUEST}/info`,
  consultationOverview: `${BASE_CONSULTATION_REQUEST}${CONSULTATION_PATH}/overview`,
  consultationResponses: `${BASE_CONSULTATION_REQUEST}${CONSULTATION_PATH}/responses`,
  consultationComplete: `${BASE_CONSULTATION_REQUEST}${CONSULTATION_PATH}/complete`,
} as const;
