import React from "react";
import { twMerge } from "tailwind-merge";

interface TickCircleIconProps {
  className?: string;
}
const TickCircleIcon = ({ className }: TickCircleIconProps) => {
  return (
    <svg
      className={twMerge("h-4 w-4 text-gray-400", className)}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 7C0.5 3.41015 3.41015 0.5 7 0.5C10.5899 0.5 13.5 3.41015 13.5 7C13.5 10.5899 10.5899 13.5 7 13.5C3.41015 13.5 0.5 10.5899 0.5 7Z"
        fill="#F9F9F9"
      />
      <path
        d="M0.5 7C0.5 3.41015 3.41015 0.5 7 0.5C10.5899 0.5 13.5 3.41015 13.5 7C13.5 10.5899 10.5899 13.5 7 13.5C3.41015 13.5 0.5 10.5899 0.5 7Z"
        stroke="currentColor"
      />
    </svg>
  );
};

export default TickCircleIcon;
