import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { twMerge } from "tailwind-merge";
import { z } from "zod";

import { InformationIcon } from "~/common/components/icons/InformationIcon";
import { Button } from "~/common/components/ui/button";
import Input from "~/common/components/ui/Input";
import { checkInUtilities } from "~/common/utils/checkIn/checkInUtilities";
import {
  CheckInFormProps,
  INPUT_TYPES,
  InputAnswer,
} from "~/common/utils/types/checkInTypes";
import { questionTextByCodes } from "~/patients/constants";
import { VitalSignsModal } from "~/patients/modals";
import { vitalSignsSchema } from "~/patients/utils";
import { QuestionsLayout } from "../QuestionsLayout";

export type VitalSignsFormValues = z.infer<typeof vitalSignsSchema>;

export const VitalSignsForm = ({
  question,
  handleGoBack,
  handleNext,
  existingAnswer,
  isLastQuestion,
  isSubmitting = false,
}: CheckInFormProps) => {
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const { getAnswerOfType, getInputOfType } = checkInUtilities();
  const defaultBloodPressureValues = getAnswerOfType(
    INPUT_TYPES.blood_pressure,
    question.inputs,
    existingAnswer,
  );

  const defaultHeartRate = getAnswerOfType(
    INPUT_TYPES.heart_rate,
    question.inputs,
    existingAnswer,
  );

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
  } = useForm<VitalSignsFormValues>({
    resolver: zodResolver(vitalSignsSchema),
    defaultValues: {
      diastolicValue: defaultBloodPressureValues?.diastolic,
      systolicValue: defaultBloodPressureValues?.systolic,
      heartRate: defaultHeartRate?.value,
    },
  });

  const formatAnswer = (data: VitalSignsFormValues) => {
    const inputs: InputAnswer[] = [
      {
        id: getInputOfType(INPUT_TYPES.heart_rate, question.inputs)?.id,
        answer: {
          answer_type: INPUT_TYPES.heart_rate,
          value: data.heartRate,
        },
      },
      {
        id: getInputOfType(INPUT_TYPES.blood_pressure, question.inputs)?.id,
        answer: {
          answer_type: INPUT_TYPES.blood_pressure,
          systolic: data.systolicValue,
          diastolic: data.diastolicValue,
        },
      },
    ];
    return {
      question_id: question.id,
      inputs,
    };
  };

  const goBack = () => {
    const data = getValues();
    handleGoBack(formatAnswer(data));
  };

  const saveAnswers: SubmitHandler<VitalSignsFormValues> = (data) => {
    handleNext(formatAnswer(data));
  };

  const toggleOpenModal = () => setImageModalOpen((prev) => !prev);

  const disabled =
    !watch("diastolicValue") || !watch("systolicValue") || !watch("heartRate");

  return (
    <QuestionsLayout
      question={questionTextByCodes[question.code]}
      handleNext={handleSubmit(saveAnswers)}
      handleBack={goBack}
      disableNext={disabled}
      isLastQuestion={isLastQuestion}
      isSubmitting={isSubmitting}
    >
      <div className="flex flex-col gap-4">
        <Button
          variant="outline-none"
          className="flex w-fit items-center gap-1 p-0 text-primary-600 hover:text-primary-600 focus:text-primary-600"
          onClick={toggleOpenModal}
        >
          <InformationIcon /> Information
        </Button>
        <div className="flex w-full flex-col gap-2">
          <div className="flex h-full items-end gap-2">
            <Input
              label="Systolic"
              id="systolicValue"
              {...register("systolicValue", {
                setValueAs: (value) => Number(value),
              })}
              placeholder="100"
              type="number"
              min={0}
              isError={!!errors.systolicValue}
            >
              <p
                className={twMerge(
                  "px-2 text-sm text-gray-10",
                  errors.systolicValue && "text-error",
                )}
              >
                mmHG
              </p>
            </Input>
            <p className="py-2 text-lg font-light text-gray-70">/</p>
            <Input
              label="Diastolic"
              id="diastolicValue"
              placeholder="70"
              {...register("diastolicValue", {
                setValueAs: (value) => Number(value),
              })}
              min={0}
              type="number"
              isError={!!errors.diastolicValue}
            >
              <p className="px-3 text-sm text-gray-10">mmHG</p>
            </Input>
          </div>
          <div className="flex flex-col gap-0.5">
            <p className="text-xs text-error">
              {errors.systolicValue?.message}
            </p>
            <p className="text-xs text-error">
              {errors.diastolicValue?.message}
            </p>
          </div>
        </div>
        <Input
          id="heartRate"
          label="Heart rate"
          placeholder="60"
          error={errors.heartRate?.message}
          {...register("heartRate", {
            setValueAs: (value) => Number(value),
          })}
          type="number"
          min={0}
        >
          <p className="px-3 text-sm text-gray-10">BPM</p>
        </Input>
      </div>
      <VitalSignsModal show={imageModalOpen} onClose={toggleOpenModal} />
    </QuestionsLayout>
  );
};
