import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { z } from "zod";

import { TransitionEffect } from "~/common/components";
import TextArea from "~/common/components/ui/Textarea";
import { replaceUnderscores } from "~/common/utils";
import {
  CompletedMultiCheckAnswer,
  ConsultationMultiCheckAnswer,
  ConsultationMultiCheckValues,
  MultiCheckOptions,
  MultiCheckQuestionData,
} from "~/common/utils/consultations/shared";
import {
  ConfirmExclusiveOptionModal,
  ConsultationQuestionsLayout,
  MultiCheckOption,
} from "~/patients/components/consultations";
import { useExclusiveOptionHandler } from "~/patients/hooks";
import { createMultiCheckSchema } from "~/patients/utils";

export interface MultiCheckFormProps {
  question: MultiCheckQuestionData;
  handleGoBack: () => void;
  onSubmit: (answer: ConsultationMultiCheckAnswer) => void;
  isSubmitting: boolean;
  disableBack: boolean;
  completedAnswer?: CompletedMultiCheckAnswer;
  currentQuestionOptions?: string[];
}

export const MultiCheckForm = ({
  question,
  handleGoBack,
  onSubmit,
  isSubmitting,
  disableBack,
  completedAnswer,
  currentQuestionOptions,
}: MultiCheckFormProps) => {
  const { title, subtitle, commonExtraDataLabel } = question;

  const options: MultiCheckOptions[] = currentQuestionOptions
    ? currentQuestionOptions.map((option) => ({
        value: option as ConsultationMultiCheckValues,
        label: replaceUnderscores(option),
      }))
    : question.options;

  const schema = createMultiCheckSchema(options);

  type MultiCheckFormValues = z.infer<typeof schema>;

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm<MultiCheckFormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      answer: {
        ...completedAnswer,
      },
    },
  });

  const { append, remove } = useFieldArray({
    control,
    name: "answer.values",
  });

  const selectedValues = watch("answer.values") || [];

  const saveAnswers: SubmitHandler<MultiCheckFormValues> = (data) => {
    onSubmit(data);
  };

  const commonExtraDataRequired = selectedValues.some(
    (value) =>
      options.find((option) => option.value === value.value)
        ?.asksCommonExtraData,
  );

  const {
    handleCheck,
    onConfirmIsExclusiveOption,
    onCloseIsExclusiveOption,
    isExclusiveOptionModalOpen,
  } = useExclusiveOptionHandler({
    selectedValues,
    options,
    reset,
    remove,
    append,
  });

  return (
    <ConsultationQuestionsLayout
      handleNext={handleSubmit(saveAnswers)}
      handleBack={handleGoBack}
      disableNext={!selectedValues.length}
      isSubmitting={isSubmitting}
      title={title}
      subtitle={subtitle}
      disableBack={disableBack}
    >
      <div className="flex flex-col gap-2 pb-6">
        {options.map((option) => (
          <MultiCheckOption
            key={option.value}
            option={option}
            selectedValues={selectedValues}
            handleCheck={handleCheck}
            register={register}
          />
        ))}
      </div>
      <TransitionEffect show={commonExtraDataRequired}>
        <TextArea
          rows={4}
          label={commonExtraDataLabel}
          placeholder="Enter further description"
          className="text-base font-normal placeholder:text-base placeholder:not-italic placeholder:text-gray-60"
          error={errors.answer?.extra_data?.message}
          {...register(`answer.extra_data`)}
        />
      </TransitionEffect>
      <ConfirmExclusiveOptionModal
        isOpen={isExclusiveOptionModalOpen}
        onClose={onCloseIsExclusiveOption}
        onConfirm={onConfirmIsExclusiveOption}
      />
    </ConsultationQuestionsLayout>
  );
};
