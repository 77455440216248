import { useEffect } from "react";
import { Navigate, useSearchParams } from "react-router-dom";

import { LoadingState } from "~/common/components";
import { PATIENT_STATUS } from "~/common/constants";
import { PaymentFinalMessage } from "~/patients/components";
import {
  useConfirmPayment,
  useGetCurrentPatient,
  useIntakeIntroScreenVisit,
} from "~/patients/hooks";
import { ROUTES } from "../router";

export const ConfirmPayment = () => {
  const [searchParams] = useSearchParams();
  const paymentIntent = searchParams.get("payment_intent");
  const { data: user, isFetching } = useGetCurrentPatient();

  const { intakeIntroScreenVisitMutation } = useIntakeIntroScreenVisit();

  const { confirmPaymentIntentMutation, isSuccess, isError } =
    useConfirmPayment(intakeIntroScreenVisitMutation);

  useEffect(() => {
    paymentIntent && confirmPaymentIntentMutation(paymentIntent);
  }, [paymentIntent]);

  if (isFetching) return <LoadingState />;

  if (user?.status === PATIENT_STATUS.ongoing_care)
    return <Navigate to={ROUTES.notFound} />;

  if (isSuccess || isError)
    return (
      <PaymentFinalMessage paymentIntent={paymentIntent} isError={isError} />
    );

  return <LoadingState className="h-screen-calculated" />;
};
