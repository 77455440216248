import { H2, Typography } from "~/common/components";
import SuccessIcon from "~/common/components/layouts/SuccessIcon";
import { TYPOGRAPHY_VARIANTS } from "~/common/utils";

interface CompletedConsultationCardProps {
  title: string;
  description?: string;
}

export const CompletedConsultationCard = ({
  title,
  description,
}: CompletedConsultationCardProps) => (
  <div className="flex w-full rounded-lg border border-success-200 bg-success-50 p-4">
    <div className="flex w-full flex-col gap-2 text-gray-700">
      <H2 className="text-xl">{title}</H2>
      <Typography
        variant={TYPOGRAPHY_VARIANTS.light}
        className="w-3/4 text-base text-gray-700"
      >
        {description ?? "Completed"}
      </Typography>
    </div>
    <div className="flex h-full items-center">
      <SuccessIcon />
    </div>
  </div>
);
