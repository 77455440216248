import React, { ComponentPropsWithoutRef } from "react";
import { twMerge } from "tailwind-merge";

import { DotIcon } from "~/patients/components";

interface QuestionaryProgressProps extends ComponentPropsWithoutRef<"div"> {
  totalSteps: number;
  step: number;
}

export const QuestionaryProgress = ({
  step,
  totalSteps,
  className,
}: QuestionaryProgressProps) => {
  const array = Array.from({ length: totalSteps - 1 }, (_, index) => index + 1);
  return (
    <div
      className={twMerge("flex h-fit w-full items-center md:pt-10", className)}
    >
      <DotIcon className="text-primary-500" />
      {array.map((_, index) => (
        <React.Fragment key={index}>
          <div
            className={twMerge(
              "h-0.5 w-full bg-primary-300",
              index < step - 1 ? "bg-primary-500" : "bg-primary-300",
            )}
          />
          <DotIcon
            className={twMerge(
              index < step - 1 ? "text-primary-500" : "text-primary-300",
            )}
          />
        </React.Fragment>
      ))}
    </div>
  );
};
