import { create } from "zustand";

import { OnBoardingNewPatientData } from "../utils/patientOnBoardings/types";

export interface OnBoardingNewPatientStoreState {
  newPatient?: OnBoardingNewPatientData;
  onBoardingNewPatientStep: number;
  setNewPatient(newPatient?: OnBoardingNewPatientData): void;
  setOnBoardingNewPatientStep(onBoardingNewPatientStep: number): void;
}

export const useOnBoardingNewPatientStore =
  create<OnBoardingNewPatientStoreState>()((set) => ({
    newPatient: undefined,
    setNewPatient: (newPatient?: OnBoardingNewPatientData) => {
      set(() => ({ newPatient }));
    },
    onBoardingNewPatientStep: 0,
    setOnBoardingNewPatientStep: (onBoardingNewPatientStep: number) => {
      set(() => ({ onBoardingNewPatientStep }));
    },
  }));
