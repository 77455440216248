import { useQuery } from "@tanstack/react-query";

import { getPatientIsAuth } from "~/patients/api";

export const usePatientIsAuth = () => {
  const { data, isLoading, isFetching, refetch } = useQuery({
    queryFn: () => getPatientIsAuth(),
    queryKey: ["getPatientIsAuth"],
    staleTime: Infinity,
    retry: 2,
  });
  return { data, isLoading, isFetching, refetch };
};
