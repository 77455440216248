import { z } from "zod";

import {
  ADMIT_TO_PRACTICE,
  ADMIT_TO_PRACTICE_VALUES,
  LARGE_TEXT_LENGTH,
  REFER_PATIENT,
  REFER_PATIENT_VALUES,
  TEXT_MAX_LENGTH,
} from "~/providers/constants";

export const PRICE_TYPES = {
  default: "default",
  custom: "custom",
} as const;

const defaultFeeSchema = z.object({
  priceType: z.literal(PRICE_TYPES.default),
});

const customFeeSchema = z.object({
  priceType: z.literal(PRICE_TYPES.custom),
  price: z.string().regex(/^\d+(\.\d{1,2})?$/, "Price must be a valid number"),
});

export const consultationDetailSchema = z.discriminatedUnion("priceType", [
  customFeeSchema,
  defaultFeeSchema,
]);

export type ConsultationDetailType = z.infer<typeof consultationDetailSchema>;

export const completeAndSignSchema = z
  .object({
    diagnostic_considerations: z
      .string()
      .min(1, "Please enter a diagnostic.")
      .max(
        LARGE_TEXT_LENGTH,
        `Please limit diagnostic considerations to ${LARGE_TEXT_LENGTH} characters.`,
      ),
    treatment_plan: z
      .string()
      .min(1, "Please enter treatment plan.")
      .max(
        LARGE_TEXT_LENGTH,
        `Please limit treatment plan to ${LARGE_TEXT_LENGTH} characters.`,
      ),
    admit_to_practice: z.enum(ADMIT_TO_PRACTICE_VALUES, {
      message: "Please select an option.",
    }),
    provider_care_plan_id: z.string().optional(),
    refer_patient: z.enum(REFER_PATIENT_VALUES, {
      message: "Please select an option.",
    }),
    refer_note: z
      .string()
      .max(TEXT_MAX_LENGTH, `Max length is ${TEXT_MAX_LENGTH} characters.`)
      .optional()
      .nullable(),
  })
  .superRefine((data, ctx) => {
    if (
      data.admit_to_practice === ADMIT_TO_PRACTICE.admit &&
      !data.provider_care_plan_id
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["provider_care_plan_id"],
        message: "Please select a plan to admit to the practice.",
      });
    }

    if (
      data.refer_patient === REFER_PATIENT.referral_provided &&
      (!data.refer_note || data.refer_note.length === 0)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["refer_note"],
        message: "Please enter a referral note.",
      });
    }
  });

export type CompleteAndSignFormValuesType = z.infer<
  typeof completeAndSignSchema
>;

export const saveConsultationCompletionSchema = z.object({
  diagnostic_considerations: z
    .string()
    .max(
      LARGE_TEXT_LENGTH,
      `Please limit diagnostic considerations to ${LARGE_TEXT_LENGTH} characters.`,
    )
    .optional(),
  treatment_plan: z
    .string()
    .max(
      LARGE_TEXT_LENGTH,
      `Please limit treatment plan to ${LARGE_TEXT_LENGTH} characters.`,
    )
    .optional(),
  admit_to_practice: z.enum(ADMIT_TO_PRACTICE_VALUES).nullable(),
  provider_care_plan_id: z.string().optional(),
  refer_patient: z.enum(REFER_PATIENT_VALUES).nullable(),
  refer_note: z
    .string()
    .max(
      TEXT_MAX_LENGTH,
      `Please limit referral note to ${TEXT_MAX_LENGTH} characters.`,
    )
    .optional()
    .nullable(),
});

export type SaveConsultationCompletionFormValuesType = z.infer<
  typeof saveConsultationCompletionSchema
>;

export const consultationCompletionDataSchema = z.discriminatedUnion(
  "completeAndSign",
  [
    z.object({
      completeAndSign: z.literal(false),
      data: saveConsultationCompletionSchema,
    }),
    z.object({ completeAndSign: z.literal(true), data: completeAndSignSchema }),
  ],
);

export type ConsultationCompletionDataType = z.infer<
  typeof consultationCompletionDataSchema
>;
