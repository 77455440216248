import { Button } from "~/common/components/ui/button";
import { SubmitStatusLogo } from "~/patients/assets/index";
import { useCreateOneTimeStatus } from "~/patients/hooks/queries/check-ins";
import { useGetCurrentPatient } from "~/patients/hooks/queries/useGetCurrentPatient";

export const SubmitStatus = () => {
  const { data: user } = useGetCurrentPatient();

  const { preferred_reference: providerName } = { ...user?.provider };
  const { createCheckInMutation, isPending } = useCreateOneTimeStatus();

  return (
    <div className="flex w-full flex-col items-center gap-3 p-6 text-gray-70">
      <img src={SubmitStatusLogo} alt="Submit status logo" />
      <h1 className="w-3/4 text-center">
        {`Let ${providerName} know what's going on at any time.`}
      </h1>
      <Button
        variant="outline-black"
        className="w-fit px-6"
        onClick={() => createCheckInMutation()}
        isLoading={isPending}
      >
        Submit status
      </Button>
    </div>
  );
};
