import { create } from "zustand";
import { persist } from "zustand/middleware";

import { TEAM_MEMBER_VALUES } from "~/common/constants";
import { TeamMember } from "~/common/utils";

export interface TeamMemberStoreState {
  teamMember: TeamMember | null;
  setTeamMember(teamMember: TeamMember | null): void;
  logout: () => void;
  isStaff: boolean;
}

export const useTeamMemberStore = create<TeamMemberStoreState>()(
  persist(
    (set) => ({
      teamMember: null,
      setTeamMember: (teamMember: TeamMember | null) => {
        set(() => ({
          teamMember: teamMember,
          isStaff: teamMember?.type === TEAM_MEMBER_VALUES.staff,
        }));
      },
      logout: () =>
        set((state) => ({
          ...state,
          teamMember: null,
        })),
      isStaff: false,
    }),
    {
      name: "feedbackTeamMemberStore",
    },
  ),
);
