import { useMutation } from "@tanstack/react-query";

import { screeningIntroScreenVisit } from "~/patients/api";

export const useScreeningIntroScreenVisit = (
  onSuccess: () => Promise<void>,
) => {
  const { mutate: screeningIntroScreenVisitMutation, isPending } = useMutation({
    mutationFn: screeningIntroScreenVisit,
    onSuccess,
  });
  return { screeningIntroScreenVisitMutation, isPending };
};
