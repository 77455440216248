import { useMutation } from "@tanstack/react-query";

import { confirmPayment } from "~/patients/api/payments";

export const useConfirmPayment = (onSuccess: () => void) => {
  const {
    mutate: confirmPaymentIntentMutation,
    isPending,
    isSuccess,
    isError,
  } = useMutation({
    mutationFn: confirmPayment,
    onSuccess,
  });

  return { confirmPaymentIntentMutation, isPending, isSuccess, isError };
};
