import { useMutation } from "@tanstack/react-query";
import { generatePath, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { createOneTimeStatusCheckIn } from "~/patients/api/checkIn";
import { ROUTES } from "~/patients/router";

export const useCreateOneTimeStatus = () => {
  const navigate = useNavigate();
  const { mutate: createCheckInMutation, isPending } = useMutation({
    mutationFn: createOneTimeStatusCheckIn,
    onSuccess: (data) => {
      const checkInPath = generatePath(ROUTES.checkIn, {
        id: data.id,
      });
      navigate(checkInPath);
    },
    onError: () =>
      toast.error(
        "We had trouble creating your check-in, please try again later!",
      ),
  });

  return { createCheckInMutation, isPending };
};
