import { ComponentPropsWithoutRef } from "react";

export const RoundButton = ({
  children,
  onClick,
  disabled,
}: ComponentPropsWithoutRef<"button">) => (
  <button
    className="flex size-24 items-center justify-center rounded-full bg-orange-100 disabled:bg-opacity-50 "
    onClick={onClick}
    type="button"
    disabled={disabled}
  >
    {children}
  </button>
);
