import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Navigate, useSearchParams } from "react-router-dom";

import { WaveBackgroundLayout } from "~/common/components";
import { NewPatientSignUpRenderer } from "../components/signUp/signUpNewPatient/NewPatientSignUpRenderer";
import { useSignUpNewPatient } from "../hooks/signUp/useSignUpNewPatient";
import { ROUTES } from "../router";
import { useOnBoardingNewPatientStore } from "../stores/useOnBoardingNewPatientStore";
import {
  NewPatientSchemaType,
  PATIENTS_TYPES,
  signUpPatientSchema,
  SignUpPatientSchemaType,
} from "../utils";

export const SignUpNewPatient = () => {
  const { newPatient } = useOnBoardingNewPatientStore();

  const [searchParams] = useSearchParams();
  const dataToken = searchParams.get("data-token");

  const {
    formState: { errors },
    register,
    control,
    getValues,
    watch,
    trigger,
    resetField,
  } = useForm<SignUpPatientSchemaType>({
    defaultValues: {
      ...newPatient,
      type: PATIENTS_TYPES.newPatient,
    },
    resolver: zodResolver(signUpPatientSchema),
    mode: "all",
  });

  const values = getValues() as NewPatientSchemaType;

  const { onNext, onBack, isPending, onSubmitSignUp } = useSignUpNewPatient({
    values,
    trigger,
    dataToken,
  });

  if (!dataToken) return <Navigate to={ROUTES.notFound} />;

  return (
    <WaveBackgroundLayout>
      <NewPatientSignUpRenderer
        onNext={onNext}
        handleRegistration={onSubmitSignUp}
        handleGoBack={onBack}
        register={register}
        resetField={resetField}
        errors={errors}
        control={control}
        watch={watch}
        isPending={isPending}
        dataToken={dataToken}
      />
    </WaveBackgroundLayout>
  );
};
