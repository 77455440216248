import * as jose from "jose";
import { UseFormTrigger } from "react-hook-form";

import { useOnBoardingPatientStore } from "~/patients/stores";
import {
  ActivePatientSchemaType,
  SignUpPatientSchemaType,
} from "~/patients/utils";
import { useSubmitActivePatientSignUp } from "./useSubmitActivePatientSignUp";

interface useSignUpActivePatientProps {
  values: ActivePatientSchemaType;
  trigger: UseFormTrigger<SignUpPatientSchemaType>;
  dataToken: string;
}
export const useSignUpActivePatient = ({
  values,
  trigger,
  dataToken,
}: useSignUpActivePatientProps) => {
  const { setPatient, onBoardingStep, setOnBoardingStep } =
    useOnBoardingPatientStore();

  const userJWT = jose.decodeJwt(dataToken);
  const patientEmail = userJWT?.user_email as string;

  const { onSubmitSignUp, isPending } = useSubmitActivePatientSignUp({
    values,
    trigger,
    dataToken,
    patientEmail,
  });

  const onBack = () => {
    setOnBoardingStep(onBoardingStep - 1);
  };

  const updatePatientData = () => {
    setPatient({ ...values, email: patientEmail });
    setOnBoardingStep(onBoardingStep + 1);
  };

  const onNext = async (schemaName: keyof ActivePatientSchemaType) => {
    const isValid = await trigger(schemaName);
    if (isValid) {
      updatePatientData();
    }
  };

  return {
    onNext,
    onBack,
    isPending,
    onSubmitSignUp,
  };
};
