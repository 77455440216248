import { twMerge } from "tailwind-merge";

import { IconProps } from "~/common/utils";

export const GreenTickIcon = ({ className, ...props }: IconProps) => (
  <svg
    className={twMerge("size-5", className)}
    viewBox="0 0 110 110"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="55.1039" cy="54.7274" r="54.7274" fill="#91E99E" />
    <path
      d="M79.5005 38.875L47.3352 71.0403C46.1148 72.2607 44.1362 72.2607 42.9158 71.0403L29.5005 57.625"
      stroke="#2AA63C"
      strokeWidth="7"
      strokeLinecap="round"
    />
  </svg>
);
