import {
  PatientCheckInQuestion,
  QuestionAnswer,
} from "~/common/utils/types/checkInTypes";
import { QUESTION_TYPES } from "~/common/utils/types/questionTypes";
import { AudioForm } from "./AudioForm";
import { FileForm } from "./FileForm";
import { RadioButtonForm } from "./RadioButtonForm";
import { StatusForm } from "./StatusForm";
import { VitalSignsForm } from "./VitalSignsForm";
import { WeightForm } from "./WeightForm";

interface FormRendererProps {
  question: PatientCheckInQuestion;
  handleNext: (data: QuestionAnswer) => void;
  handleGoBack: (answer?: QuestionAnswer) => void;
  existingAnswer: QuestionAnswer | undefined;
  isLastQuestion: boolean;
  removeQuestionAnswerById: (questionId: string) => void;
  isSubmitting?: boolean;
}
export const FormRenderer = ({
  question,
  handleNext,
  handleGoBack,
  existingAnswer,
  isLastQuestion,
  removeQuestionAnswerById,
  isSubmitting = false,
}: FormRendererProps) => {
  const formContent = {
    [QUESTION_TYPES.yes_no_with_free_text]: (
      <RadioButtonForm
        key={question.id}
        question={question}
        handleNext={handleNext}
        handleGoBack={handleGoBack}
        existingAnswer={existingAnswer}
        isLastQuestion={isLastQuestion}
        isSubmitting={isSubmitting}
      />
    ),
    [QUESTION_TYPES.weight]: (
      <WeightForm
        key={question.id}
        question={question}
        handleNext={handleNext}
        handleGoBack={handleGoBack}
        existingAnswer={existingAnswer}
        isLastQuestion={isLastQuestion}
        isSubmitting={isSubmitting}
      />
    ),
    [QUESTION_TYPES.file]: (
      <FileForm
        key={question.id}
        question={question}
        handleNext={handleNext}
        handleGoBack={handleGoBack}
        existingAnswer={existingAnswer}
        isLastQuestion={isLastQuestion}
        removeQuestionAnswerById={removeQuestionAnswerById}
        isSubmitting={isSubmitting}
      />
    ),
    [QUESTION_TYPES.status]: (
      <StatusForm
        key={question.id}
        question={question}
        handleNext={handleNext}
        handleGoBack={handleGoBack}
        existingAnswer={existingAnswer}
        isLastQuestion={isLastQuestion}
        isSubmitting={isSubmitting}
      />
    ),
    [QUESTION_TYPES.vital_signs]: (
      <VitalSignsForm
        key={question.id}
        question={question}
        handleNext={handleNext}
        handleGoBack={handleGoBack}
        existingAnswer={existingAnswer}
        isLastQuestion={isLastQuestion}
        isSubmitting={isSubmitting}
      />
    ),
    [QUESTION_TYPES.audio]: (
      <AudioForm
        existingAnswer={existingAnswer}
        key={question.id}
        question={question}
        handleNext={handleNext}
        handleGoBack={handleGoBack}
        isLastQuestion={isLastQuestion}
        removeQuestionAnswerById={removeQuestionAnswerById}
        isSubmitting={isSubmitting}
      />
    ),
  };

  return formContent[question.type];
};
