import { Script } from "~/common/types/scriptsTypes";
import { CancelRequestFormValues } from "../components";
import { ScriptLifeType } from "../utils";
import {
  PatientResolveIssue,
  PatientScriptsRequest,
} from "../utils/PatientScriptsTypes";
import { api, PaginatedServiceResponse } from "./axios";

export const getPatientScripts = async (scriptLife: ScriptLifeType) => {
  const { data } = await api.get<PaginatedServiceResponse<Script[]>>(
    `/v1/patients/scripts`,
    {
      params: {
        page_size: 100,
        page: 1,
        order_by: "created_at",
        script_life: scriptLife,
      },
    },
  );

  return data.data;
};

export const createScript = async (formData: PatientScriptsRequest) => {
  const { data } = await api.post<void>(`/v1/patients/scripts`, formData);

  return { data };
};

export const cancelScript = async (
  formData: CancelRequestFormValues,
  id?: string,
) => {
  const { data } = await api.post<Script>(
    `v1/patients/scripts/${id}/cancel`,
    formData,
  );

  return { data };
};

export const resolveScriptIssue = async (
  formData: PatientResolveIssue,
  id?: string,
) => {
  const { data } = await api.post<Script>(
    `v1/patients/scripts/${id}/resolve-issue`,
    formData,
  );

  return { data };
};
