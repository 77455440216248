import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import {
  createPaymentIntent,
  PaymentIntentResponse,
} from "~/patients/api/payments";
import { ROUTES } from "~/patients/router";

export const useCreatePaymentIntent = (
  onSuccess: (data: PaymentIntentResponse) => Promise<void>,
) => {
  const navigate = useNavigate();
  const { mutate: createPaymentIntentMutation, isPending } = useMutation({
    mutationFn: createPaymentIntent,
    onSuccess: (response) => onSuccess(response.data),
    onError: () => {
      navigate(ROUTES.notFound);
    },
  });

  return { createPaymentIntentMutation, isPending };
};
