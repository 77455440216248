import React from "react";
import { twMerge } from "tailwind-merge";

import { IconProps } from "~/common/utils/types";

export const PlayIcon = ({ className }: IconProps) => (
  <svg
    className={twMerge("h-5 w-5 text-gray-5", className)}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.32903 1.91359C7.09916 1.76029 6.83462 1.67503 6.56299 1.66669C6.29136 1.65835 6.0226 1.72725 5.78471 1.86619C5.54682 2.00513 5.34853 2.20903 5.21051 2.45663C5.07249 2.70423 4.99979 2.98646 5 3.27389V15.8915C4.99979 16.1789 5.07249 16.4611 5.21051 16.7087C5.34853 16.9563 5.54682 17.1602 5.78471 17.2992C6.0226 17.4381 6.29136 17.507 6.56299 17.4987C6.83462 17.4903 7.09916 17.4051 7.32903 17.2518L16.7909 10.9419C17.0081 10.7972 17.187 10.5966 17.311 10.3589C17.435 10.1212 17.5 9.85416 17.5 9.58268C17.5 9.31121 17.435 9.04416 17.311 8.80647C17.187 8.56878 17.0081 8.3682 16.7909 8.22345L7.32903 1.91466V1.91359Z"
      fill="currentColor"
    />
  </svg>
);
