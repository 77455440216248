import {
  baseOptionSchema,
  optionalTextValidation,
  toggleRequiredExtraDataSchema,
} from "./baseSchemas";
import { SymptomBaseForm, SYMPTOMS_INPUT_TYPES } from "./symptomsBasicTypes";

export const decreasedNeedExtraDataSchema = baseOptionSchema
  .extend({
    hours_of_sleep: optionalTextValidation,
    happened_before: optionalTextValidation,
    harder_to_stay_asleep: optionalTextValidation,
    early_morning_awakenings: optionalTextValidation,
    fatigue_during_day: optionalTextValidation,
    start_date: optionalTextValidation,
  })
  .superRefine((data, ctx) => {
    if (data.toggled) {
      const keys = [
        "hours_of_sleep",
        "happened_before",
        "harder_to_stay_asleep",
        "early_morning_awakenings",
        "fatigue_during_day",
        "start_date",
      ] as const;

      keys.forEach((key) => {
        if (data[key] === "") {
          ctx.addIssue({
            code: "custom",
            path: [key],
            message: `Please provide justification.`,
          });
        }
      });
    }
  })
  .default({
    toggled: false,
    hours_of_sleep: undefined,
    happened_before: undefined,
    harder_to_stay_asleep: undefined,
    early_morning_awakenings: undefined,
    fatigue_during_day: undefined,
    start_date: undefined,
  });

export const sleepIssuesSchema = baseOptionSchema
  .extend({
    difficulty_in_falling_asleep: toggleRequiredExtraDataSchema,
    harder_to_fall_back_asleep_once_awake: toggleRequiredExtraDataSchema,
    inability_to_easily_wake_up_sleeping_late: toggleRequiredExtraDataSchema,
    decreased_need_for_sleep: decreasedNeedExtraDataSchema,
  })
  .refine(
    (data) => {
      if (data.toggled)
        return (
          data.difficulty_in_falling_asleep.toggled ||
          data.harder_to_fall_back_asleep_once_awake.toggled ||
          data.inability_to_easily_wake_up_sleeping_late.toggled ||
          data.decreased_need_for_sleep.toggled
        );

      return true;
    },
    {
      message: "Please select at least one sleep issue are you experiencing.",
      path: ["toggled"],
    },
  );

export const sleepIssuesForm: SymptomBaseForm = {
  label: "Sleep Issues",
  value: "symptoms.sleep_issues.toggled",
  type: SYMPTOMS_INPUT_TYPES.check,
  extra_data: {
    title: "What patterns of sleep issues are you experiencing?",
    children: [
      {
        label: "Difficulty in falling asleep",
        value: "symptoms.sleep_issues.difficulty_in_falling_asleep.toggled",
        type: SYMPTOMS_INPUT_TYPES.check,
        extra_data: {
          title: undefined,
          children: [
            {
              value:
                "symptoms.sleep_issues.difficulty_in_falling_asleep.extra_data",
              label: "When did the sleep issues start?",
              type: SYMPTOMS_INPUT_TYPES.text,
              extra_data: undefined,
            },
          ],
        },
      },
      {
        label: "Harder to fall back asleep once awake",
        value:
          "symptoms.sleep_issues.harder_to_fall_back_asleep_once_awake.toggled",
        type: SYMPTOMS_INPUT_TYPES.check,
        extra_data: {
          title: undefined,
          children: [
            {
              value:
                "symptoms.sleep_issues.harder_to_fall_back_asleep_once_awake.extra_data",
              label: "When did the sleep issues start?",
              type: SYMPTOMS_INPUT_TYPES.text,
              extra_data: undefined,
            },
          ],
        },
      },
      {
        label: "Inability to wake up easily, sleeping late",
        value:
          "symptoms.sleep_issues.inability_to_easily_wake_up_sleeping_late.toggled",
        type: SYMPTOMS_INPUT_TYPES.check,
        extra_data: {
          title: undefined,
          children: [
            {
              value:
                "symptoms.sleep_issues.inability_to_easily_wake_up_sleeping_late.extra_data",
              label: "When did the sleep issues start?",
              type: SYMPTOMS_INPUT_TYPES.text,
              extra_data: undefined,
            },
          ],
        },
      },
      {
        label: "Decreased need for sleep",
        value: "symptoms.sleep_issues.decreased_need_for_sleep.toggled",
        type: SYMPTOMS_INPUT_TYPES.check,
        extra_data: {
          title: undefined,
          children: [
            {
              value:
                "symptoms.sleep_issues.decreased_need_for_sleep.hours_of_sleep",
              label: "How many hours of sleep are you needing?",
              type: SYMPTOMS_INPUT_TYPES.text,
              extra_data: undefined,
            },
            {
              value:
                "symptoms.sleep_issues.decreased_need_for_sleep.happened_before",
              label: "Has this happened before in your life?",
              type: SYMPTOMS_INPUT_TYPES.text,
              extra_data: undefined,
            },
            {
              value:
                "symptoms.sleep_issues.decreased_need_for_sleep.harder_to_stay_asleep",
              label:
                "Is it harder to stay asleep or do you have mid sleep awakenings?",
              type: SYMPTOMS_INPUT_TYPES.text,
              extra_data: undefined,
            },
            {
              value:
                "symptoms.sleep_issues.decreased_need_for_sleep.early_morning_awakenings",
              label:
                "Do you experience early morning awakenings before you want to wake up?",
              type: SYMPTOMS_INPUT_TYPES.text,
              extra_data: undefined,
            },
            {
              value:
                "symptoms.sleep_issues.decreased_need_for_sleep.fatigue_during_day",
              label: "Do you experience fatigue during the day? Do you nap?",
              type: SYMPTOMS_INPUT_TYPES.text,
              extra_data: undefined,
            },
            {
              value:
                "symptoms.sleep_issues.decreased_need_for_sleep.start_date",
              label: "When did the sleep issues start?",
              type: SYMPTOMS_INPUT_TYPES.text,
              extra_data: undefined,
            },
          ],
        },
      },
    ],
  },
};
