import { useEffect } from "react";
import {
  generatePath,
  Navigate,
  useNavigate,
  useParams,
} from "react-router-dom";

import { LoadingState } from "~/common/components";
import { PATIENT_STATUS } from "~/common/constants";
import {
  SCREENING_STATUSES,
  ScreeningCode,
  screeningSectionTitle,
} from "~/common/utils/consultations/screening";
import { StartConsultationSection } from "~/patients/components/consultations";
import {
  useGetCurrentPatient,
  useGetScreening,
  useStartScreening,
} from "~/patients/hooks";
import { ROUTES } from "~/patients/router";

export const StartScreening = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: user } = useGetCurrentPatient();

  const providerImage = user?.provider.profile_picture_path;
  const userCanAccessScreening =
    user?.status !== PATIENT_STATUS.ongoing_care && !!user?.email_verified_at;

  const { data: screeningData, isLoading } = useGetScreening(
    id,
    userCanAccessScreening,
  );

  const { startScreeningMutation, isPending } = useStartScreening();
  const { screening, patient_screening_id, status } = {
    ...screeningData,
  };

  const redirectToScreeningIfStarted = () => {
    if (status === SCREENING_STATUSES.in_progress && patient_screening_id) {
      navigate(
        generatePath(ROUTES.screening, {
          id: patient_screening_id,
        }),
      );
    }
    if (status === SCREENING_STATUSES.completed) {
      navigate(ROUTES.screeningAlreadyCompleted);
    }
  };

  useEffect(redirectToScreeningIfStarted, [redirectToScreeningIfStarted]);

  if (!userCanAccessScreening) return <Navigate to={ROUTES.notFound} />;

  if (isLoading) return <LoadingState className="h-screen-calculated" />;

  return (
    screening && (
      <StartConsultationSection
        section={screening.order}
        duration={screening.duration}
        title={screeningSectionTitle[screening.code as ScreeningCode]}
        handleContinue={() => startScreeningMutation(screening.id)}
        handleGoBack={() => navigate(ROUTES.leadPatientHome)}
        pendingContinue={isPending}
        providerImageSrc={providerImage}
      />
    )
  );
};
