import * as questions from "./questions";

export const screeningQuestionDataByCodes = {
  ...questions.yesNoQuestions,
  ...questions.textQuestions,
  ...questions.relationshipQuestions,
  ...questions.specialQuestions,
  ...questions.frequenciesQuestions,
  ...questions.sexQuestions,
  ...questions.dailyFrequenciesQuestions,
  ...questions.ceroToFiveQuestions,
  ...questions.multiCheckQuestions,
  ...questions.audioQuestions,
  ...questions.dateQuestions,
} as const;
