import { UseFormRegisterReturn } from "react-hook-form";
import { Link } from "react-router-dom";

import CheckBox from "~/patients/components/ui/CheckBox";
import { NamesAndEmailSchemaType } from "~/patients/utils";
import { TermsAndConditionsSchemaType } from "../signUpActivePatient/IntroductionPatientForm";

interface TermsAndConditionsProps {
  register: (
    data: keyof (NamesAndEmailSchemaType | TermsAndConditionsSchemaType),
  ) => UseFormRegisterReturn<string>;
  error?: string;
}

export const TermsAndConditions = ({
  register,
  error,
}: TermsAndConditionsProps) => (
  <CheckBox
    id="termsAndConditions"
    {...register("termsAndConditions")}
    error={error}
    labelClassName="text-sm md:text-base"
    containerClassName="items-start md:items-center"
  >
    <span className="w-full text-left text-gray-600">
      I agree to Psychnow’s{" "}
      <Link
        target="_blank"
        to="https://psychnow.com/termsandconditions"
        className="cursor-pointer border-b border-gray-600 text-sm font-medium text-gray-600 md:text-base"
      >
        Terms and Conditions
      </Link>
      {" and "}
      <Link
        target="_blank"
        to="https://psychnow.com/privacypolicy"
        className="cursor-pointer border-b border-gray-600 text-sm font-medium text-gray-600 md:text-base"
      >
        Privacy Policy
      </Link>
    </span>
  </CheckBox>
);
