import { useState } from "react";
import { FieldErrors, UseFormRegisterReturn } from "react-hook-form";
import { z } from "zod";

import { TrashIcon } from "~/common/components";
import Input from "~/common/components/ui/Input";
import { ConfirmDeleteListItem } from "~/patients/components/consultations/shared/ui";
import { medicationSchema } from "~/patients/utils";

export type MedicationInputValues = z.infer<typeof medicationSchema>;

interface MedicationInputProps {
  register: (
    data: keyof MedicationInputValues,
  ) => UseFormRegisterReturn<string>;
  handleDelete: () => void;
  isMedicationDataEmpty: boolean;
  errors?: FieldErrors<MedicationInputValues>;
}

export const MedicationInput = ({
  register,
  handleDelete,
  isMedicationDataEmpty,
  errors,
}: MedicationInputProps) => {
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const toggleConfirmDeleteModal = () =>
    setShowConfirmDeleteModal((prev) => !prev);

  return (
    <div className="flex w-full flex-col items-end gap-4 border-b border-neutral-700 pb-4">
      <Input
        id="name"
        label="Medication name"
        error={errors?.name?.message}
        {...register(`name`)}
      />
      <div className="flex w-full gap-2">
        <Input
          label="Dose"
          error={errors?.dose?.message}
          {...register(`dose`)}
        />
        <Input
          label="Frequency"
          error={errors?.frequency?.message}
          {...register(`frequency`)}
        />
      </div>
      <button
        className="flex w-fit items-center gap-1.5 text-gray-10"
        type="button"
        onClick={
          isMedicationDataEmpty ? handleDelete : toggleConfirmDeleteModal
        }
      >
        Delete <TrashIcon className="text-gray-10" />
      </button>
      <ConfirmDeleteListItem
        onClose={toggleConfirmDeleteModal}
        show={showConfirmDeleteModal}
        onConfirm={handleDelete}
        description="Are you sure you want to delete this medication?"
      />
    </div>
  );
};
