import { useRef, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { z } from "zod";

import { ErrorText } from "~/common/components";
import {
  CheckinFileFormProps,
  checkInUtilities,
  INPUT_TYPES,
  QUESTION_TYPES,
} from "~/common/utils";
import { AudioRecorder, AudioRecorderHandle } from "~/patients/components";
import { questionTextByCodes } from "~/patients/constants";
import { audioSchema } from "~/patients/utils";
import { QuestionsLayout } from "../QuestionsLayout";

export type AudioFormValues = z.infer<typeof audioSchema>;

export const AudioForm = ({
  question,
  handleGoBack,
  handleNext,
  isLastQuestion,
  removeQuestionAnswerById,
  existingAnswer,
  isSubmitting = false,
}: CheckinFileFormProps) => {
  const { getInputOfType, getAnswerOfType } = checkInUtilities();
  const audioRecorderRef = useRef<AudioRecorderHandle>(null);

  const audioInput = getInputOfType(INPUT_TYPES.audio, question.inputs);
  const existingAudio = getAnswerOfType(
    QUESTION_TYPES.audio,
    question.inputs,
    existingAnswer,
  );

  const audioAnswer = getAnswerOfType(
    INPUT_TYPES.audio,
    question.inputs,
    existingAnswer,
  );

  const [audioFile, setAudioFile] = useState<File | undefined>(
    audioAnswer?.file,
  );
  const {
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<AudioFormValues>({
    resolver: zodResolver(audioSchema),
    defaultValues: {
      audioSrc: existingAudio?.audio_src,
      audioDuration: existingAudio?.duration,
    },
  });

  const setFile = (audioBlob: Blob, duration: number) => {
    const file = new File([audioBlob], "audio.wav", {
      type: audioBlob.type,
    });
    if (file.size === 0) {
      toast.error("We shoot trouble saving your recording, please try again!");
      return;
    }
    setAudioFile(file);
    setValue("audioSrc", URL.createObjectURL(audioBlob));
    setValue("audioDuration", duration);
  };

  const deleteFile = () => {
    reset({ audioDuration: undefined, audioSrc: undefined });
    removeQuestionAnswerById(question.id);
  };

  const formatAnswer = () => {
    return {
      question_id: question.id,
      inputs: [
        {
          id: audioInput?.id,
          answer: {
            answer_type: INPUT_TYPES.audio,
            file: audioFile,
            audio_src: getValues("audioSrc"),
            duration: getValues("audioDuration"),
          },
        },
      ],
    };
  };

  const goBack = () => {
    if (audioRecorderRef.current?.isRecording) {
      audioRecorderRef.current.stopRecording();
      audioRecorderRef.current.handleDeleteRecording();
    }
    handleGoBack(formatAnswer());
  };

  const saveAnswers: SubmitHandler<AudioFormValues> = () => {
    if (!audioFile) return;
    handleNext(formatAnswer());
  };

  const disableContinue = !watch("audioDuration");

  return (
    <QuestionsLayout
      question={questionTextByCodes[question.code]}
      handleNext={handleSubmit(saveAnswers)}
      handleBack={goBack}
      disableNext={disableContinue}
      isLastQuestion={isLastQuestion}
      isSubmitting={isSubmitting}
    >
      <AudioRecorder
        ref={audioRecorderRef}
        setAudio={(audioBlob, duration) => setFile(audioBlob, duration)}
        handleDeleteAudio={deleteFile}
        defaultAudioSrc={existingAudio?.audio_src}
        defaultAudioDuration={existingAudio?.duration}
      />
      {errors.audioDuration && (
        <ErrorText>{errors.audioDuration.message}</ErrorText>
      )}
    </QuestionsLayout>
  );
};
