import {
  PatientInformation,
  SignUpNewPatientDataRequest,
  SignUpUserDataRequest,
} from "~/patients/utils/types";
import { api } from "./axios";

export interface SignUpResponse {
  access_token: string;
}

export interface SignUpNewPatientResponse {
  patient: PatientInformation;
  token: {
    access_token: string;
  };
}

export const signUp = async (
  userData: SignUpUserDataRequest,
  emailToken: string,
) => {
  const response = await api.post<SignUpResponse>(
    "/v1/patients/signup",
    userData,
    {
      headers: {
        "email-token": emailToken,
      },
    },
  );
  return response.data;
};

export const signUpNewPatient = async (
  newPatient: SignUpNewPatientDataRequest,
) => {
  const response = await api.post<SignUpNewPatientResponse>(
    "/v1/patients/signup/new",
    newPatient,
  );
  return response.data;
};
