import {
  SCREENING_QUESTION_CODES,
  SCREENING_QUESTION_TYPES,
} from "~/common/utils/consultations/screening";

export const specialQuestions = {
  [SCREENING_QUESTION_CODES.list_medications]: {
    title: "Please list all the medications you are currently taking.",
    type: SCREENING_QUESTION_TYPES.list_medications,
  },
} as const;
