import { useQuery } from "@tanstack/react-query";

import { getPatientScripts } from "~/patients/api/scripts";
import { ScriptLifeType } from "~/patients/utils";

export const useGetPatientScripts = (scriptLife: ScriptLifeType) => {
  const { data, isLoading } = useQuery({
    queryFn: () => getPatientScripts(scriptLife),
    queryKey: ["getPatientScripts", scriptLife],
  });

  return { data, isLoading };
};
