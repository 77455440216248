import React from "react";
import { twMerge } from "tailwind-merge";

interface SuccessIconProps {
  className?: string;
}
const SuccessIcon = ({ className }: SuccessIconProps) => {
  return (
    <svg
      className={twMerge("h-14, w-14", className)}
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="29" cy="29" r="28.5" fill="#B1EEB9" />
      <circle cx="29.0002" cy="29" r="20.6765" fill="#71E382" />
      <path
        d="M37.5 24.5L26.8333 34.5L21.5 29.5781"
        stroke="#268E34"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SuccessIcon;
