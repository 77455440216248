import React, { useRef, useState } from "react";
import { toast } from "react-toastify";

import { TrashIcon, UploadIcon } from "~/common/components/icons";
import DeleteFileModal from "./DeleteFileModal";

interface UploadFileInputProps {
  uploadedFile: File | undefined;
  setUploadedFile: (file: File) => void;
  deleteFile: () => void;
}

const UploadFileInput = ({
  uploadedFile,
  setUploadedFile,
  deleteFile,
}: UploadFileInputProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [deleteFileModal, setDeleteFileModal] = useState(false);

  const selectFile = () => {
    fileInputRef.current?.click();
  };

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const file = target.files?.[0];
    if (file && file.size <= 10 * 1024 * 1024) {
      setUploadedFile(file);
    } else {
      toast.error("File size exceeds maximum allowed limit of 10 MB.");
    }
  };

  const toggleDeleteFileModal = () => setDeleteFileModal((prev) => !prev);

  return (
    <div className="w-full rounded-md border border-gray-20 bg-white">
      {!uploadedFile ? (
        <button onClick={selectFile} className="w-full" type="button">
          <div className="flex gap-2 px-4 py-3.5">
            <div className="flex h-full items-start py-1">
              <UploadIcon className="h-5 w-5 stroke-2 text-primary-600" />
            </div>
            <div className="flex w-full flex-col items-start gap-1">
              <p className="font-medium leading-5 text-primary-600">
                Upload file
              </p>
              <p className="text-xs text-gray-10">
                PDF, JPG or PNG up to 10 MB
              </p>
            </div>
          </div>
          <input
            ref={fileInputRef}
            accept="application/pdf, image/png, image/jpg"
            name="image"
            type="file"
            className="hidden"
            onChange={handleOnChange}
          />
        </button>
      ) : (
        <div className="flex w-full justify-between px-4 py-3.5">
          <p className="text-sm font-medium text-gray-70">
            {uploadedFile.name}
          </p>
          <button type="button" onClick={toggleDeleteFileModal}>
            <TrashIcon className="h-5 w-5 text-gray-60" />
          </button>
        </div>
      )}
      {uploadedFile && (
        <DeleteFileModal
          onClose={toggleDeleteFileModal}
          show={deleteFileModal}
          handleDelete={deleteFile}
          fileName={uploadedFile.name}
        />
      )}
    </div>
  );
};

export default UploadFileInput;
