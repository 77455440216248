import {
  CurrentPatientData,
  UpdateCurrentPatientData,
} from "~/patients/utils/types";
import { api } from "../axios";

export const updatePatientData = async (data: UpdateCurrentPatientData) => {
  const res = await api.patch<CurrentPatientData>("/v1/patients", data);
  return res.data;
};

export const uploadFile = async (image: File) => {
  const formdata = new FormData();
  formdata.append("file", image);
  const res = await api.post<{ url: string }>("/v1/patients/files", formdata, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res.data;
};

export const deleteFile = async () => {
  const res = await api.delete<{ url: string }>("/v1/patients/files");
  return res.data;
};
