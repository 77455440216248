import { ChangeEvent } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import { ApproximateDatesMessage, H2 } from "~/common/components";
import DatePicker from "~/common/components/ui/DatePicker";
import TextArea from "~/common/components/ui/Textarea";
import { getTodayDate } from "~/common/utils";
import {
  CompletedIndicatedTakingMedicationsAnswer,
  IndicatedTakingMedicationsAnswer,
  IndicatedTakingMedicationsQuestionData,
} from "~/common/utils/consultations/intake/interfaces";
import { ConsultationQuestionsLayout } from "~/patients/components/consultations/shared";
import {
  indicatedTakingMedicationsFormSchema,
  IndicatedTakingMedicationsFormValues,
} from "~/patients/utils/consultations/intakes";

export interface IndicatedTakingMedicationsFormProps {
  question: IndicatedTakingMedicationsQuestionData;
  handleGoBack: () => void;
  onSubmit: (answer: IndicatedTakingMedicationsAnswer) => void;
  isSubmitting: boolean;
  disableBack: boolean;
  currentQuestionOptions?: string[];
  completedAnswer?: CompletedIndicatedTakingMedicationsAnswer;
}

const BASE_MED_PATH = "answer.medications_data";

export const IndicatedTakingMedicationsForm = ({
  question,
  onSubmit,
  handleGoBack,
  isSubmitting,
  disableBack,
  currentQuestionOptions,
  completedAnswer,
}: IndicatedTakingMedicationsFormProps) => {
  const { title, subtitle } = question;

  const {
    handleSubmit,
    watch,
    setValue,
    register,
    formState: { errors },
  } = useForm<IndicatedTakingMedicationsFormValues>({
    resolver: zodResolver(indicatedTakingMedicationsFormSchema),
    defaultValues: { answer: completedAnswer },
  });

  const saveAnswers: SubmitHandler<IndicatedTakingMedicationsFormValues> = (
    data,
  ) => {
    const medicationsWithMedication = data.answer.medications_data.map(
      (medicationData, index) => ({
        ...medicationData,
        date_stopped: medicationData.date_stopped?.length
          ? medicationData.date_stopped
          : null,
        medication: currentQuestionOptions ? currentQuestionOptions[index] : "",
      }),
    );

    onSubmit({ answer: { medications_data: medicationsWithMedication } });
  };

  const medications = watch(BASE_MED_PATH) || [];

  const today = getTodayDate();

  const onDateStoppedChange = (
    e: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const currentDateSelected = e.target.value;
    if (!currentDateSelected)
      setValue(`${BASE_MED_PATH}.${index}.why_it_stopped`, undefined);
  };

  return (
    <ConsultationQuestionsLayout
      handleNext={handleSubmit(saveAnswers)}
      handleBack={handleGoBack}
      disableNext={!medications.length}
      isSubmitting={isSubmitting}
      title={title}
      subtitle={subtitle}
      disableBack={disableBack}
    >
      {currentQuestionOptions?.map((item, index) => (
        <div
          key={item}
          className={twMerge(
            "flex w-full flex-col items-start gap-4  border-neutral-700 pb-8",
            index !== medications.length - 1 && "border-b",
          )}
        >
          <H2>{item}</H2>
          <TextArea
            rows={3}
            id="how_did_it_work"
            label="How did this medication work for you?"
            placeholder="Please describe (e.g side effects)."
            {...register(`${BASE_MED_PATH}.${index}.how_did_it_work`)}
            error={
              errors?.answer?.medications_data?.[index]?.how_did_it_work
                ?.message
            }
            className="border border-gray-20 placeholder:not-italic"
          />
          <div className="flex w-full gap-3">
            <DatePicker
              id="date_started"
              label="Date started*"
              max={today}
              {...register(`${BASE_MED_PATH}.${index}.date_started`)}
              error={
                errors?.answer?.medications_data?.[index]?.date_started?.message
              }
            />
            <DatePicker
              id="date_stopped"
              label="Date stopped*"
              isOptional
              max={today}
              {...register(`${BASE_MED_PATH}.${index}.date_stopped`, {
                onChange: (e: ChangeEvent<HTMLInputElement>) =>
                  onDateStoppedChange(e, index),
              })}
              error={
                errors?.answer?.medications_data?.[index]?.date_stopped?.message
              }
            />
          </div>
          <ApproximateDatesMessage />
          <TextArea
            rows={3}
            id="why_it_stopped"
            label="If you stopped taking this medication, please tell me why."
            placeholder="Enter reason why you stopped taking this medication."
            {...register(`${BASE_MED_PATH}.${index}.why_it_stopped`)}
            disabled={!medications?.[index]?.date_stopped}
            error={
              errors?.answer?.medications_data?.[index]?.why_it_stopped?.message
            }
            className="border border-gray-20 placeholder:not-italic"
          />
        </div>
      ))}
    </ConsultationQuestionsLayout>
  );
};
