import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { z } from "zod";

import FormLayout from "~/common/components/layouts/FormLayout";
import Input from "~/common/components/ui/Input";
import { LOGIN_ERRORS } from "~/common/constants";
import { ErrorResponse, login } from "~/patients/api";
import { ROUTES } from "~/patients/router";
import { loginUserSchema } from "../utils/PatientSchemas";

type LogInFormValues = z.infer<typeof loginUserSchema>;
const LogInForm = () => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    setError,
  } = useForm<LogInFormValues>({
    resolver: zodResolver(loginUserSchema),
  });
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { mutate: loginMutation, isPending } = useMutation({
    mutationFn: login,
    onError: (error: ErrorResponse) => {
      if (error.response?.status === 401) {
        setError("email", {
          type: "custom",
          message: LOGIN_ERRORS.INVALID_CREDENTIALS,
        });
        setError("password", {
          type: "custom",
          message: LOGIN_ERRORS.INVALID_CREDENTIALS,
        });
      } else {
        toast.error("Oops, there was an issue logging in, try again later!");
      }
    },
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ["getCurrentPatient"],
        exact: true,
      });
      queryClient.clear();
      navigate(ROUTES.home);
    },
  });

  const onSubmit: SubmitHandler<LogInFormValues> = (data) => {
    loginMutation(data);
  };

  return (
    <FormLayout
      handleSubmit={handleSubmit(onSubmit)}
      submitLabel="Log in"
      title="Log in"
      pendingRequest={isPending}
    >
      <Input
        id="email"
        label="Email address"
        {...register("email")}
        error={errors.email?.message}
      />
      <div className="flex w-full flex-col items-end gap-2">
        <Input
          id="password"
          type="password"
          label="Password"
          {...register("password")}
          error={errors.password?.message}
        />
        <Link
          to={ROUTES.forgotPassword}
          className="border-b border-gray-500 text-sm"
        >
          Forgot password
        </Link>
      </div>
    </FormLayout>
  );
};

export default LogInForm;
