import * as jose from "jose";
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormResetField,
  UseFormWatch,
} from "react-hook-form";

import { OTHER_VALUE } from "~/common/constants";
import { QuestionAnswer } from "~/common/utils/types/checkInTypes";
import { PRONOUNS } from "~/patients/constants";
import { useOnBoardingPatientStore } from "~/patients/stores";
import {
  ActivePatientSchemaType,
  AddressSchemaType,
  NamesSchemaType,
  PronounsSchemaType,
  SignUpPatientSchemaType,
} from "~/patients/utils";
import PatientAddressForm from "../commonForms/PatientAddressForm";
import PatientEmergencyContactForm from "../commonForms/PatientEmergencyContactForm";
import PatientMobileForm from "../commonForms/PatientMobileForm";
import PatientPasswordForm, {
  PasswordsSchemaType,
} from "../commonForms/PatientPasswordForm";
import PatientPronounsForm from "../commonForms/PatientPronounsForm";
import { DefaultPharmacyForm } from "./DefaultPharmacyForm";
import IntroductionPatientForm, {
  TermsAndConditionsSchemaType,
} from "./IntroductionPatientForm";
import PatientNameForm from "./PatientNameForm";

interface ActiveSignUpRendererProps {
  onNext: (schemaName: keyof ActivePatientSchemaType) => Promise<void>;
  handleRegistration: () => Promise<void>;
  handleGoBack: (answer?: QuestionAnswer) => void;
  register: UseFormRegister<SignUpPatientSchemaType>;
  resetField: UseFormResetField<SignUpPatientSchemaType>;
  watch: UseFormWatch<SignUpPatientSchemaType>;
  errors: FieldErrors<ActivePatientSchemaType>;
  dataToken: string;
  control?: Control<SignUpPatientSchemaType>;
  isPending?: boolean;
}
export const ActivePatientSignUpRenderer = ({
  register,
  onNext,
  handleGoBack,
  errors,
  handleRegistration,
  resetField,
  dataToken,
  control,
  watch,
  isPending = false,
}: ActiveSignUpRendererProps) => {
  const { onBoardingStep } = useOnBoardingPatientStore();
  const userJWT = jose.decodeJwt(dataToken);

  const patientEmail = userJWT?.user_email as string;
  const patientName = userJWT?.user_name as string;

  const steps = [
    () => (
      <IntroductionPatientForm
        onNext={() => onNext("termsAndConditions")}
        register={(data: keyof TermsAndConditionsSchemaType) =>
          register(`termsAndConditions.${data}`)
        }
        errors={errors.termsAndConditions}
        email={patientEmail}
        name={patientName}
      />
    ),
    (totalSteps: number) => (
      <PatientNameForm
        handleGoBack={handleGoBack}
        onNext={() => onNext("names")}
        totalSteps={totalSteps}
        register={(data: keyof NamesSchemaType) => register(`names.${data}`)}
        errors={errors.names}
        step={onBoardingStep}
      />
    ),
    (totalSteps: number) => (
      <PatientPronounsForm
        handleGoBack={handleGoBack}
        onNext={() => onNext("pronouns")}
        totalSteps={totalSteps}
        register={(data: keyof PronounsSchemaType) =>
          register(`pronouns.${data}`)
        }
        otherPronouns={watch("pronouns.pronouns") === PRONOUNS.other}
        errors={errors.pronouns}
        control={control}
        resetField={() => resetField("pronouns")}
        step={onBoardingStep}
        pronounsKey="pronouns.pronouns"
      />
    ),
    (totalSteps: number) => (
      <PatientMobileForm
        handleGoBack={handleGoBack}
        onNext={() => onNext("phone_number")}
        totalSteps={totalSteps}
        register={register}
        errors={errors.phone_number}
        step={onBoardingStep}
      />
    ),
    (totalSteps: number) => (
      <PatientAddressForm
        handleGoBack={handleGoBack}
        onNext={() => onNext("address")}
        totalSteps={totalSteps}
        register={(data: keyof AddressSchemaType) =>
          register(`address.${data}`)
        }
        errors={errors.address}
        control={control}
        step={onBoardingStep}
        addressStateKey="address.state"
      />
    ),
    (totalSteps: number) => (
      <PatientEmergencyContactForm
        handleGoBack={handleGoBack}
        onNext={() => onNext("emergencyContact")}
        totalSteps={totalSteps}
        register={register}
        errors={errors.emergencyContact}
        customRelationship={
          watch("emergencyContact.relationship") === OTHER_VALUE
        }
        control={control}
        step={onBoardingStep}
        relationshipKey="emergencyContact.relationship"
      />
    ),
    (totalSteps: number) => (
      <DefaultPharmacyForm
        handleGoBack={handleGoBack}
        onNext={() => onNext("pharmacy")}
        totalSteps={totalSteps}
        register={register}
        errors={errors.pharmacy}
        control={control}
        step={onBoardingStep}
        pharmacyStateKey="pharmacy.address.state"
      />
    ),
    (totalSteps: number) => (
      <PatientPasswordForm
        handleGoBack={handleGoBack}
        onNext={handleRegistration}
        totalSteps={totalSteps}
        register={(data: keyof PasswordsSchemaType) =>
          register(`password.${data}`)
        }
        errors={errors.password}
        enteredPassword={watch("password.password")}
        pendingRequest={isPending}
        step={onBoardingStep}
      />
    ),
  ];

  return steps[onBoardingStep](steps.length);
};
