import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

import { Styled } from "~/common/utils";

export const ApproximateDatesMessage = ({
  className,
}: PropsWithChildren<Styled>) => (
  <p className={twMerge("self-start text-sm text-gray-10", className)}>
    *Dates can be approximate.
  </p>
);
