import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import { twMerge } from "tailwind-merge";

import { UploadIcon } from "~/common/components";
import Modal from "~/common/components/Modal";
import { CancelSubmitButtons } from "./ui";

interface UploadImageModalProps {
  show: boolean;
  onClose: () => void;
  handleUpload: (image: File) => void;
  setPreview: (preview?: string) => void;
  className?: string;
  src?: string;
  preview?: string;
  loadingSubmit?: boolean;
  imageClassName?: string;
}

export const UploadImageModal = ({
  className,
  handleUpload,
  show,
  onClose,
  setPreview,
  src,
  preview,
  loadingSubmit,
  imageClassName,
}: UploadImageModalProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<File>();

  const selectImage = () => {
    fileInputRef.current?.click();
  };

  const handleClose = () => {
    onClose();
    setImage(undefined);
    setPreview(src ?? undefined);
  };

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const file = target.files?.[0];
    if (file && file.size < 10 * 1024 * 1024) {
      const reader = new FileReader();
      setImage(file);
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      toast.error("The file size should be less than 10 MB.");
    }
  };

  return (
    <Modal
      className="h-fit w-[29.1rem]"
      show={show}
      onClose={handleClose}
      titleClassName="text-black text-xl"
      title="Upload image"
    >
      <div className={twMerge("flex flex-col items-end gap-4", className)}>
        <button
          onClick={selectImage}
          className={twMerge(
            "flex w-full flex-col items-center gap-1 rounded-lg py-16",
            "border border-dashed border-gray-300 transition-all duration-300 hover:bg-gray-100",
          )}
        >
          {!preview && (
            <div className="flex flex-col items-center gap-2">
              <div className="w-fit rounded-full bg-gray-200 p-2">
                <UploadIcon className="size-5 stroke-2 text-gray-500" />
              </div>
              <p>Select image file</p>
              <p className="text-sm text-gray-500">
                Any JPG, JPEG or PNG up to 10MB
              </p>
            </div>
          )}
          <input
            ref={fileInputRef}
            accept="image/png, image/jpg, image/jpeg"
            name="image"
            type="file"
            className="hidden"
            onChange={handleOnChange}
          />
          {preview && (
            <img src={preview} alt="preview" className={imageClassName} />
          )}
        </button>
        <CancelSubmitButtons
          variant="right-outline-black"
          submitButtonLabel="Upload"
          handleSubmit={() => image && handleUpload(image)}
          handleCancel={handleClose}
          isSubmitting={loadingSubmit}
          disableSubmit={!image || !preview || loadingSubmit}
          containerClassName="w-3/4 lg:w-2/5"
        />
      </div>
    </Modal>
  );
};
