import React from "react";
import {
  Control,
  FieldPath,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";

import Input from "~/common/components/ui/Input";
import Select from "~/common/components/ui/select/Select";
import { relationshipsOptions } from "~/patients/constants/relationships";
import {
  EmergencyContactSchemaType,
  formatPhoneNumberField,
  SignUpPatientSchemaType,
} from "~/patients/utils";
import { FormErrors } from "~/patients/utils/types/forms";
import SignUpFormLayout from "../SignUpFormLayout";
import SignUpProgress from "../SignUpProgress";

interface PatientEmergencyContactFormProps<T extends FieldValues> {
  handleGoBack: () => void;
  totalSteps: number;
  register: UseFormRegister<SignUpPatientSchemaType>;
  onNext: () => Promise<void>;
  step: number;
  customRelationship: boolean;
  relationshipKey: FieldPath<T>;
  errors?: FormErrors<EmergencyContactSchemaType>;
  control?: Control<T>;
}

const PatientEmergencyContactForm = <T extends FieldValues>({
  onNext,
  register,
  handleGoBack,
  totalSteps,
  errors,
  control,
  step,
  relationshipKey,
  customRelationship,
}: PatientEmergencyContactFormProps<T>) => {
  return (
    <>
      <SignUpProgress step={step} totalSteps={totalSteps} />
      <SignUpFormLayout
        title="Emergency contact"
        handleGoBack={handleGoBack}
        handleData={onNext}
      >
        <div className="flex flex-col gap-4 md:flex-row md:gap-2">
          <Input
            id="name"
            label="First name"
            {...register("emergencyContact.name")}
            error={errors?.name?.message}
          />
          <Input
            id="last_name"
            label="Last name"
            {...register("emergencyContact.last_name")}
            error={errors?.last_name?.message}
          />
        </div>

        <div className="flex flex-col gap-4 md:flex-row md:justify-between md:gap-2">
          <Select
            error={errors?.relationship?.message}
            label="Relationship"
            placeholder={"Select relationship"}
            id={relationshipKey}
            options={relationshipsOptions}
            control={control}
            containerClassName="gap-2"
          />
          {customRelationship && (
            <div className="flex h-full w-full flex-col items-end">
              <div className="hidden h-7 md:block" />
              <Input
                id="customPronouns"
                placeholder="Enter relationship"
                {...register("emergencyContact.customRelationship")}
                error={errors?.customRelationship?.message}
              />
            </div>
          )}
        </div>
        <Input
          id="email"
          label="Email address"
          {...register("emergencyContact.email")}
          error={errors?.email?.message}
        />

        <Input
          id="phone_number"
          label="Mobile number"
          {...register("emergencyContact.phone_number")}
          {...formatPhoneNumberField<SignUpPatientSchemaType>(
            "emergencyContact.phone_number",
            register,
          )}
          error={errors?.phone_number?.message}
        />
      </SignUpFormLayout>
    </>
  );
};

export default PatientEmergencyContactForm;
