import { CHECK_IN_CODES, PatientCheckInBase } from "~/common/utils";
import { SquaredTick } from "../icons";
import { DateSquaredLayout } from "../layouts";

interface SquaredInformationProps {
  patientCheckIn: PatientCheckInBase;
}

export const SquaredInformation = ({
  patientCheckIn,
}: SquaredInformationProps) => {
  const { completed_at, checkin } = patientCheckIn;
  return checkin.code === CHECK_IN_CODES.schedule_appointment ? (
    <SquaredTick className="h-full" />
  ) : (
    <DateSquaredLayout date={completed_at} />
  );
};
