import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { twMerge } from "tailwind-merge";

import { CircularProgressBar, ErrorText } from "~/common/components";
import { useAudioRecorder } from "~/common/hooks";
import {
  formatDuration,
  getDescriptiveText,
  MAX_RECORDING_TIME,
} from "~/patients/utils";
import { AudioRecorderButton, RecordingAnimation } from "../checkIn";
import AudioPlayer from "./AudioPlayer";

interface AudioRecorderProps {
  setAudio: (blob: Blob, duration: number) => void;
  handleDeleteAudio: () => void;
  defaultAudioSrc?: string;
  defaultAudioDuration?: number;
  error?: string;
  pendingDeleteAudio?: boolean;
  supertitle?: string;
}

export interface AudioRecorderHandle {
  stopRecording: () => void;
  handleDeleteRecording: () => void;
  isRecording: boolean;
}

export const AudioRecorder = forwardRef<
  AudioRecorderHandle,
  AudioRecorderProps
>(
  (
    {
      setAudio,
      handleDeleteAudio,
      defaultAudioSrc,
      defaultAudioDuration,
      error,
      supertitle,
    },
    ref,
  ) => {
    const [audioUrl, setAudioUrl] = useState(defaultAudioSrc);
    const [totalDuration, setTotalDuration] = useState(
      defaultAudioDuration ?? 0,
    );

    const {
      recordingTimer,
      isRecording,
      startRecording,
      stopRecording,
      recordedBlob,
    } = useAudioRecorder();

    useEffect(() => {
      if (recordingTimer < MAX_RECORDING_TIME) return;
      stopRecording();
    }, [recordingTimer]);

    useEffect(() => {
      if (!recordedBlob) return;
      const audioUrl = URL.createObjectURL(recordedBlob);
      setTotalDuration(recordingTimer);
      setAudioUrl(audioUrl);
      setAudio(recordedBlob, recordingTimer);
    }, [recordedBlob]);

    const handleDeleteRecording = () => {
      setAudioUrl(undefined);
      setTotalDuration(0);
      handleDeleteAudio();
    };

    useImperativeHandle(ref, () => ({
      stopRecording,
      handleDeleteRecording,
      isRecording,
    }));

    const audioDuration = isRecording ? recordingTimer : totalDuration;

    return (
      <div className="flex w-full flex-grow flex-col items-center justify-center gap-6">
        <div className="flex size-full flex-col items-center justify-center gap-4">
          <div className="flex h-16 w-3/4 items-center justify-center text-center text-gray-70">
            {getDescriptiveText(audioDuration, isRecording, supertitle)}
          </div>
          <CircularProgressBar
            progress={isRecording ? recordingTimer : 0}
            maxValue={MAX_RECORDING_TIME}
          >
            <AudioRecorderButton
              totalDuration={totalDuration}
              audioUrl={audioUrl ?? undefined}
              isRecording={isRecording}
              recordingTime={recordingTimer}
              handleStopRecording={stopRecording}
              handleStartRecording={startRecording}
            />
          </CircularProgressBar>
          {isRecording && (
            <div
              className={twMerge(
                "flex  w-full flex-col items-center justify-center gap-8 text-xl font-semibold text-gray-70",
                recordingTimer >= (MAX_RECORDING_TIME * 3) / 4 && "text-error",
              )}
            >
              {formatDuration(recordingTimer)}
              <RecordingAnimation />
            </div>
          )}
        </div>
        {audioUrl && (
          <div className="flex w-full flex-col gap-2">
            <AudioPlayer
              src={audioUrl}
              deleteAudio={handleDeleteRecording}
              totalDuration={totalDuration}
            />
            <ErrorText>{error}</ErrorText>
          </div>
        )}
      </div>
    );
  },
);

AudioRecorder.displayName = "AudioRecorder";
