import { baseOptionSchema, toggleSchema } from "./baseSchemas";
import { SymptomBaseForm, SYMPTOMS_INPUT_TYPES } from "./symptomsBasicTypes";

export const harmingYourselfExtraDataSchema = baseOptionSchema
  .extend({
    cutting_skin_or_body: toggleSchema,
    pulling_hair_out: toggleSchema,
    burning_yourself: toggleSchema,
    overdose: toggleSchema,
  })
  .refine(
    (data) => {
      if (data.toggled)
        return (
          data.cutting_skin_or_body.toggled ||
          data.pulling_hair_out.toggled ||
          data.burning_yourself.toggled ||
          data.overdose.toggled
        );

      return true;
    },
    {
      message: "Please select at least one pattern you experiencing.",
      path: ["toggled"],
    },
  )
  .default({
    toggled: false,
    cutting_skin_or_body: { toggled: false },
    pulling_hair_out: { toggled: false },
    burning_yourself: { toggled: false },
    overdose: { toggled: false },
  });

export const suicidalHarmfulThoughtsSchema = baseOptionSchema
  .extend({
    hitting_damaging_objects: toggleSchema,
    harming_others_through_violence: toggleSchema,
    harming_yourself_through_violence: harmingYourselfExtraDataSchema,
    suicidal_thoughts: toggleSchema,
    suicide_attempts: toggleSchema,
  })
  .refine(
    (data) => {
      if (data.toggled)
        return (
          data.hitting_damaging_objects.toggled ||
          data.harming_others_through_violence.toggled ||
          data.harming_yourself_through_violence.toggled ||
          data.suicidal_thoughts.toggled ||
          data.suicide_attempts.toggled
        );

      return true;
    },
    {
      message: "Please select at least one pattern you experiencing.",
      path: ["toggled"],
    },
  );

export const suicidalHarmfulThoughtsForm: SymptomBaseForm = {
  label: "Suicidal or Harmful Thoughts",
  value: "symptoms.suicidal_harmful_thoughts.toggled",
  type: SYMPTOMS_INPUT_TYPES.check,
  extra_data: {
    title: "What patterns of thoughts or actions are you experiencing?",
    children: [
      {
        label: "Thoughts of hitting objects or damaging objects ",
        value:
          "symptoms.suicidal_harmful_thoughts.hitting_damaging_objects.toggled",
        type: SYMPTOMS_INPUT_TYPES.check,
        extra_data: undefined,
      },
      {
        label: "Thoughts of harming others through violence",
        value:
          "symptoms.suicidal_harmful_thoughts.harming_others_through_violence.toggled",
        type: SYMPTOMS_INPUT_TYPES.check,
        extra_data: undefined,
      },
      {
        label: "Thoughts of harming yourself through self-injurious behaviors",
        value:
          "symptoms.suicidal_harmful_thoughts.harming_yourself_through_violence.toggled",
        type: SYMPTOMS_INPUT_TYPES.check,
        extra_data: {
          title: "What type of self-harm are you experiencing?",
          children: [
            {
              label: "Cutting skin or body",
              value:
                "symptoms.suicidal_harmful_thoughts.harming_yourself_through_violence.cutting_skin_or_body.toggled",
              type: SYMPTOMS_INPUT_TYPES.check,
              extra_data: undefined,
            },
            {
              label: "Pulling your hair out",
              value:
                "symptoms.suicidal_harmful_thoughts.harming_yourself_through_violence.pulling_hair_out.toggled",
              type: SYMPTOMS_INPUT_TYPES.check,
              extra_data: undefined,
            },
            {
              label: "Burning yourself",
              value:
                "symptoms.suicidal_harmful_thoughts.harming_yourself_through_violence.burning_yourself.toggled",
              type: SYMPTOMS_INPUT_TYPES.check,
              extra_data: undefined,
            },
            {
              label:
                "Taking an overdose of medications or dangerous substances",
              value:
                "symptoms.suicidal_harmful_thoughts.harming_yourself_through_violence.overdose.toggled",
              type: SYMPTOMS_INPUT_TYPES.check,
              extra_data: undefined,
            },
          ],
        },
      },
      {
        label: "Suicidal thoughts",
        value: "symptoms.suicidal_harmful_thoughts.suicidal_thoughts.toggled",
        type: SYMPTOMS_INPUT_TYPES.check,
        extra_data: undefined,
      },
      {
        label: "Suicide attempts",
        value: "symptoms.suicidal_harmful_thoughts.suicide_attempts.toggled",
        type: SYMPTOMS_INPUT_TYPES.check,
        extra_data: undefined,
      },
    ],
  },
};
