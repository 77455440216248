import { UseFormRegisterReturn } from "react-hook-form";
import { Link } from "react-router-dom";

import { ArrowIcon, Typography } from "~/common/components";
import Input from "~/common/components/ui/Input";
import { DIRECTIONS } from "~/common/constants";
import { ROUTES } from "~/patients/router";
import { NamesAndEmailSchemaType } from "~/patients/utils";
import { FormErrors } from "~/patients/utils/types/forms";
import { TermsAndConditions } from "../commonForms";
import SignUpFormLayout from "../SignUpFormLayout";
import SignUpProgress from "../SignUpProgress";

interface PatientNameAndEmailFormProps {
  totalSteps: number;
  register: (
    data: keyof NamesAndEmailSchemaType,
  ) => UseFormRegisterReturn<string>;
  onNext: () => Promise<void>;
  step: number;
  errors?: FormErrors<NamesAndEmailSchemaType>;
}

export const NewPatientNameAndEmailForm = ({
  onNext,
  register,
  totalSteps,
  errors,
  step,
}: PatientNameAndEmailFormProps) => (
  <>
    <SignUpProgress step={step} totalSteps={totalSteps} />
    <SignUpFormLayout
      title="To request a consultation, please create an account"
      handleData={onNext}
      submitLabel="Continue"
    >
      <div className="flex size-full flex-grow flex-col justify-between lg:justify-normal lg:gap-12">
        <div className="flex w-full flex-col items-center gap-8">
          <div className="flex w-full flex-col gap-4 md:gap-2">
            <Input
              id="name"
              label="First name"
              {...register("name")}
              error={errors?.name?.message}
            />
            <Input
              id="last_name"
              label="Last name"
              {...register("last_name")}
              error={errors?.last_name?.message}
            />
            <Input
              id="email"
              label="Email address"
              {...register("email")}
              error={errors?.email?.message}
            />
          </div>
          <div className="flex w-full items-center justify-center gap-2">
            <Typography>Already have an account?</Typography>
            <Link
              to={ROUTES.login}
              className="flex items-center gap-1 font-medium text-primary-600 hover:underline"
            >
              Log in <ArrowIcon direction={DIRECTIONS.right} />
            </Link>
          </div>
        </div>
        <TermsAndConditions
          register={(data: keyof NamesAndEmailSchemaType) =>
            register(`${data}`)
          }
          error={errors?.termsAndConditions?.message}
        />
      </div>
    </SignUpFormLayout>
  </>
);
