import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";

import Input from "~/common/components/ui/Input";
import { NamesSchemaType } from "~/patients/utils/PatientSchemas";
import { FormErrors } from "~/patients/utils/types/forms";
import SignUpFormLayout from "../SignUpFormLayout";
import SignUpProgress from "../SignUpProgress";

interface PatientNameFormProps {
  handleGoBack: () => void;
  totalSteps: number;
  register: (data: keyof NamesSchemaType) => UseFormRegisterReturn<string>;
  onNext: () => Promise<void>;
  errors: FormErrors<NamesSchemaType> | undefined;
  step: number;
}

const PatientNameForm = ({
  onNext,
  register,
  handleGoBack,
  totalSteps,
  errors,
  step,
}: PatientNameFormProps) => {
  return (
    <>
      <SignUpProgress step={step} totalSteps={totalSteps} />
      <SignUpFormLayout
        title="Your full name"
        handleGoBack={handleGoBack}
        handleData={onNext}
      >
        <div className="md-gap-2 flex flex-col gap-4 md:flex-row">
          <Input
            id="name"
            label="First name"
            {...register("name")}
            error={errors?.name?.message}
          />
          <Input
            id="last_name"
            label="Last name"
            {...register("last_name")}
            error={errors?.last_name?.message}
          />
        </div>
      </SignUpFormLayout>
    </>
  );
};

export default PatientNameForm;
