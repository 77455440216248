import {
  ConsultationMultiCheckValues,
  MultiCheckOptions,
} from "~/common/utils/consultations/shared";

export const extractMultiCheckValues = (
  options: readonly MultiCheckOptions[],
) =>
  options.map((option) => option.value) as [
    ConsultationMultiCheckValues,
    ...ConsultationMultiCheckValues[],
  ];
