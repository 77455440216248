import {
  BloodPressureAnswer,
  WeightAnswer,
} from "~/common/utils/types/checkInTypes";

export const capitalizeFirstLetter = (sentence: string) =>
  sentence.charAt(0).toUpperCase() + sentence.slice(1);

export const removeQuestionMark = (question?: string) => {
  if (question && question.endsWith("?")) {
    question = question.slice(0, -1);
  }
  return question;
};

export const formatWeightAnswer = (answer: WeightAnswer) =>
  `${answer.value} ${answer.unit}`;

export const formatBloodPressureAnswer = (answer: BloodPressureAnswer) =>
  `${answer.systolic}/${answer.diastolic} mmHG`;
