import { UseFormRegister } from "react-hook-form";

import Input from "~/common/components/ui/Input";
import {
  ConsultationInputData,
  ConsultationTextAnswer,
} from "~/common/utils/consultations/shared";
import { formatPhoneNumberField } from "~/patients/utils";

export interface PhoneNumberInputProps {
  input: ConsultationInputData;
  register: UseFormRegister<ConsultationTextAnswer>;
  error?: string;
}

export const PhoneNumberInput = ({
  input,
  register,
  error,
}: PhoneNumberInputProps) => {
  const { id, value, label, placeholder } = input;
  return (
    <Input
      key={id}
      id={value}
      label={label}
      placeholder={placeholder}
      {...register(`answer.${value}`)}
      {...formatPhoneNumberField<ConsultationTextAnswer>(
        `answer.${value}`,
        register,
      )}
      error={error}
    />
  );
};
