import {
  FieldErrors,
  UseFormClearErrors,
  UseFormGetFieldState,
  UseFormRegister,
  UseFormResetField,
  UseFormWatch,
} from "react-hook-form";
import { twMerge } from "tailwind-merge";

import { ErrorText, Typography } from "~/common/components";
import TextArea from "~/common/components/ui/Textarea";
import { useSymptoms } from "~/patients/hooks/consultations";
import {
  SymptomBaseForm,
  SymptomRadioForm,
  SYMPTOMS_INPUT_TYPES,
  SymptomsFormValues,
} from "~/patients/utils/consultations/intake";
import { CheckSymptom } from "./CheckSymptom";
import { RadioButtonSymptom } from "./RadioButtonSymptom";

interface SymptomRendererProps {
  symptom: SymptomBaseForm;
  register: UseFormRegister<SymptomsFormValues>;
  watch: UseFormWatch<SymptomsFormValues>;
  errors: FieldErrors<SymptomsFormValues>;
  clearErrors: UseFormClearErrors<SymptomsFormValues>;
  resetField: UseFormResetField<SymptomsFormValues>;
  getFieldState: UseFormGetFieldState<SymptomsFormValues>;
  toggleExclusiveOptionModal: () => void;
  isNested?: boolean;
}

export const SymptomRenderer = ({
  symptom,
  register,
  watch,
  ...props
}: SymptomRendererProps) => {
  const {
    isSelected,
    isParentSelected,
    isNestedParentWithTextChildren,
    handleCheckboxChange,
    errorMessage,
  } = useSymptoms({
    symptom,
    register,
    watch,
    ...props,
  });

  const { type, value, extra_data, label } = symptom;

  const symptomContent = {
    [SYMPTOMS_INPUT_TYPES.check]: (
      <CheckSymptom
        symptom={symptom}
        register={register}
        isParentSelected={isParentSelected}
        isNestedParentWithTextChildren={isNestedParentWithTextChildren}
        isSelected={isSelected}
        handleCheckboxChange={handleCheckboxChange}
        key={value}
      >
        {isParentSelected && (
          <>
            {extra_data?.title && (
              <Typography className="px-3 text-sm">
                {extra_data?.title}
              </Typography>
            )}
            {extra_data?.children?.map((extraItem) => (
              <div
                key={extraItem.value}
                className={twMerge(
                  "flex flex-col",
                  !isNestedParentWithTextChildren && "px-3",
                )}
              >
                <SymptomRenderer
                  symptom={extraItem}
                  register={register}
                  watch={watch}
                  isNested
                  {...props}
                />
              </div>
            ))}
            {errorMessage && (
              <ErrorText className="px-3 text-red-700">
                {errorMessage}
              </ErrorText>
            )}
          </>
        )}
      </CheckSymptom>
    ),
    [SYMPTOMS_INPUT_TYPES.text]: (
      <TextArea
        key={value}
        id={value}
        label={label ?? ""}
        placeholder="Please describe further."
        containerClassName={twMerge(!label && "gap-0")}
        error={errorMessage ?? ""}
        {...register(value)}
      />
    ),
    [SYMPTOMS_INPUT_TYPES.radio]: (
      <RadioButtonSymptom
        symptom={symptom as SymptomRadioForm}
        watch={watch}
        register={register}
        errorMessage={errorMessage}
      />
    ),
  };

  return symptomContent[type];
};
