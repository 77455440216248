import { SCREENING_CODES } from "./screeningQuestionsData";

export const screeningSectionTitle = {
  [SCREENING_CODES.how_can_we_help]: "How can I help?",
  [SCREENING_CODES.getting_to_know_you]: "Getting to know you",
  [SCREENING_CODES.your_health_history]: "Your health story",
  [SCREENING_CODES.asrs]: "ASRS",
  [SCREENING_CODES.phq9]: "PHQ-9",
  [SCREENING_CODES.gad7]: "GAD-7",
  [SCREENING_CODES.more_about_you]: "More about you",
} as const;
