import React from "react";
import {
  Control,
  FieldPath,
  FieldValues,
  UseFormRegisterReturn,
} from "react-hook-form";

import Input from "~/common/components/ui/Input";
import Select from "~/common/components/ui/select/Select";
import { AddressSchemaType } from "~/patients/utils";
import { getUsaStates } from "~/patients/utils/getUsaStates";
import { FormErrors } from "~/patients/utils/types/forms";
import SignUpFormLayout from "../SignUpFormLayout";
import SignUpProgress from "../SignUpProgress";

interface PatientAddressFormProps<T extends FieldValues> {
  handleGoBack: () => void;
  totalSteps: number;
  register: (data: keyof AddressSchemaType) => UseFormRegisterReturn<string>;
  onNext: () => Promise<void>;
  step: number;
  addressStateKey: FieldPath<T>;
  errors?: FormErrors<AddressSchemaType>;
  control?: Control<T>;
}

const PatientAddressForm = <T extends FieldValues>({
  onNext,
  register,
  handleGoBack,
  totalSteps,
  errors,
  addressStateKey,
  control,
  step,
}: PatientAddressFormProps<T>) => {
  const usaStates = getUsaStates();

  return (
    <>
      <SignUpProgress step={step} totalSteps={totalSteps} />
      <SignUpFormLayout
        title="Your address"
        handleGoBack={handleGoBack}
        handleData={onNext}
      >
        <Input
          id="street"
          label="Street number and name"
          {...register("street")}
          error={errors?.street?.message}
        />
        <Input
          id="apartment"
          label="Apartment, suite, etc."
          {...register("apartment")}
          error={errors?.apartment?.message}
        />
        <div className="flex flex-col gap-4 md:flex-row md:gap-2">
          <Input
            id="city"
            label="City"
            {...register("city")}
            error={errors?.city?.message}
          />
          <Select
            control={control}
            error={errors?.state?.message}
            id={addressStateKey}
            label="State"
            options={usaStates}
            placeholder={"Select State"}
            containerClassName="gap-2"
          />
          <Input
            id="zipcode"
            label="ZIP Code"
            {...register("zipcode")}
            error={errors?.zipcode?.message}
          />
        </div>
      </SignUpFormLayout>
    </>
  );
};

export default PatientAddressForm;
