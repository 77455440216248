import React, { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

interface TableHeaderProps
  extends PropsWithChildren,
    React.ThHTMLAttributes<HTMLTableCellElement> {
  className?: string;
}

export const TableHeader = ({
  children,
  className,
  ...props
}: TableHeaderProps) => (
  <th
    className={twMerge("text-normal p-4 font-medium leading-4", className)}
    {...props}
  >
    {children}
  </th>
);
