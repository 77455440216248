import { api } from "./axios";

export interface PaymentIntentResponse {
  amount: number;
  client_secret: string;
  provider_publishable_key: string;
}

export interface ConfigStripeKeysResponse {
  publishableKey: string;
}
export const createPaymentIntent = async () => {
  const { data } = await api.post<PaymentIntentResponse>(
    `/v1/patients/payments/payment-intent`,
    {},
  );

  return { data };
};

export const confirmPayment = async (payment_id: string) => {
  const { data } = await api.post<null>(
    `/v1/patients/payments/${payment_id}/confirm`,
    {},
  );

  return { data };
};
