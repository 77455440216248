import { subYears } from "date-fns";
import { z } from "zod";

import {
  MultiCheckOptions,
  RadioGroupOptions,
} from "~/common/utils/consultations/shared";
import {
  dateSchema,
  extractMultiCheckValues,
} from "~/patients/utils/consultations";
import { extractRadioGroupValues } from "../intake/symptoms/extractRadioGroupValues";

export const createRadioButtonSchema = <
  T extends readonly [string, ...string[]],
>(
  options: T,
) => {
  return z.object({
    answer: z.enum(options, {
      errorMap: () => ({ message: "Please select an answer to continue." }),
    }),
  });
};

export const createMultiCheckSchema = (
  options: readonly MultiCheckOptions[],
) => {
  const values = extractMultiCheckValues(options);

  const valuesSchema = z.array(
    z.object({
      value: z.enum(values),
      extraData: z.string().optional(),
    }),
  );

  return z
    .object({
      answer: z.object({
        values: valuesSchema,
        extra_data: z.string().optional(),
      }),
    })
    .refine(
      (data) => {
        const commonExtraDataRequired = data.answer.values.some(
          (value) =>
            options.find((option) => option.value === value.value)
              ?.asksCommonExtraData,
        );

        const hasCommonExtraData =
          commonExtraDataRequired && !data.answer.extra_data;

        return !hasCommonExtraData;
      },
      {
        message: "Please provide a valid justification.",
        path: ["answer", "extra_data"],
      },
    );
};

export const medicationSchema = z.object({
  name: z.string().min(1, "Please enter medication name."),
  dose: z.string().min(1, "Please specify dose."),
  frequency: z.string().min(1, "Please specify frequency."),
});

export const listMedicationSchema = z.object({
  answer: z.object({ medications: z.array(medicationSchema) }),
});

const today = new Date();

export const dateAdultRequiredSchema = dateSchema.extend({
  date: dateSchema.shape.date.refine(
    (val) => {
      const date = new Date(val);
      return !isNaN(date.getTime()) && date <= subYears(today, 18);
    },
    {
      message: "You must be at least 18 years old.",
    },
  ),
});

export const dateAdultRequiredFormSchema = z.object({
  answer: dateAdultRequiredSchema,
});

export type DateType = z.infer<typeof dateAdultRequiredSchema>;

export const createRadioGroupSchema = (
  options: readonly RadioGroupOptions[],
) => {
  const values = extractRadioGroupValues(options);
  const valuesSchema = z.array(
    z.object({
      value: z.enum(values, {
        errorMap: () => ({
          message: "Please select an option",
        }),
      }),
    }),
  );

  return z.object({
    answer: z.object({
      values: valuesSchema,
    }),
  });
};
