import { ReactNode } from "react";
import { Link } from "react-router-dom";

import { TransitionEffect, Typography } from "~/common/components";
import BackArrow from "~/common/components/icons/BackArrow";
import { Button } from "~/common/components/ui/button";
import {
  CHECK_IN_CODES,
  CheckInCodes,
  SIDE_EFFECTS_CODES,
  SideEffectCodes,
} from "~/common/utils/types/checkInTypes";
import { ROUTES } from "~/patients/router";
import { IntroductionCheckinProviderMessage } from "../IntroductionCheckinProviderMessage";

interface IntroductionCheckInProps {
  handleStart: () => void;
  description: ReactNode;
  title: string;
  note?: string;
  code: CheckInCodes;
}

export const IntroductionCheckIn = ({
  handleStart,
  note,
  description,
  title,
  code,
}: IntroductionCheckInProps) => {
  const isScheduleAppointmentCheckIn =
    code === CHECK_IN_CODES.schedule_appointment;

  const isMedicationStatusCheckIn = (code: string) => {
    return Object.values(SIDE_EFFECTS_CODES).includes(code as SideEffectCodes);
  };

  const submitButtonLabel = isScheduleAppointmentCheckIn
    ? "Mark as done"
    : "Start";
  const sideEffects = "Side Effects";

  return (
    <TransitionEffect>
      <div className="flex h-screen w-full flex-col items-center gap-8 bg-primary-50 px-6 py-4 text-gray-70 md:gap-0">
        <Link
          to={ROUTES.home}
          className="flex w-fit items-center gap-1 self-start px-0 py-3 font-medium"
        >
          <BackArrow className="h-5 w-5" />
          Back
        </Link>
        <div className="flex h-full flex-col items-center gap-10 md:w-2/3 md:justify-center md:p-8 xl:w-1/3">
          <div className="flex w-full flex-col items-start gap-2">
            <h2 className="font-outfit text-2xl font-semibold">Check-in</h2>

            {isMedicationStatusCheckIn(code) ? (
              <h1 className="leading-2.5 font-outfit text-4xl font-semibold md:w-3/4">
                <p className="break-all">{title.split(sideEffects)}</p>
                <p>{sideEffects}</p>
              </h1>
            ) : (
              <h1 className="leading-2.5 font-outfit text-4xl font-semibold md:w-3/4">
                {title}
              </h1>
            )}
          </div>
          <div>
            <Typography>{description}</Typography>
          </div>
          {note && (
            <IntroductionCheckinProviderMessage greeting="Thank you,">
              {<Typography className="break-all">{note}</Typography>}
            </IntroductionCheckinProviderMessage>
          )}
          <div className="flex w-full flex-1 items-end md:w-3/4">
            <Button onClick={handleStart}>{submitButtonLabel}</Button>
          </div>
        </div>
      </div>
    </TransitionEffect>
  );
};
