import { checkInBasicData } from "~/providers/constants";
import { HealthFactorsIcon } from "../components/icons";
import { CHECK_IN_CODES } from "../utils/types/checkInTypes";

export const BUNDLES_CODES = {
  antidepressants_bundle: "antidepressants-bundle",
  benzodiazepines_bundle: "benzodiazepines-bundle",
  psychostimulants_bundle: "psychostimulants-bundle",
} as const;

export const BUNDLES_CODES_VALUES = [
  BUNDLES_CODES.antidepressants_bundle,
  BUNDLES_CODES.benzodiazepines_bundle,
  BUNDLES_CODES.psychostimulants_bundle,
] as const;

export type BundleCodes = (typeof BUNDLES_CODES)[keyof typeof BUNDLES_CODES];

export const healthFactorsBasicData = {
  [BUNDLES_CODES.antidepressants_bundle]: {
    bgColor: "bg-interaction-health-factors",
    icon: HealthFactorsIcon,
    name: "Antidepressant health factors",
    description:
      "asks patients to report basic labs and weight at pre-determined intervals.",
  },
  [BUNDLES_CODES.psychostimulants_bundle]: {
    bgColor: "bg-interaction-health-factors",
    name: "Psychostimulants health factors",
    icon: HealthFactorsIcon,
    description:
      "asks patients to report basic labs, blood pressure and heart rate, and weight at pre-determined intervals.",
  },
  [BUNDLES_CODES.benzodiazepines_bundle]: {
    bgColor: "bg-interaction-health-factors",
    name: "Benzodiazepines health factors",
    icon: HealthFactorsIcon,
    description:
      " asks patients to report basic labs at pre-determined intervals.",
  },
} as const;

export const bundlesData = {
  [BUNDLES_CODES.antidepressants_bundle]: {
    label: "Medication Change - Antidepressants",
    basicData: [
      checkInBasicData[CHECK_IN_CODES.antidepressants_side_effects],
      healthFactorsBasicData[BUNDLES_CODES.antidepressants_bundle],
    ],
  },
  [BUNDLES_CODES.psychostimulants_bundle]: {
    label: "Medication Change - Psychostimulants",
    basicData: [
      checkInBasicData[CHECK_IN_CODES.psychostimulants_side_effects],
      healthFactorsBasicData[BUNDLES_CODES.psychostimulants_bundle],
    ],
  },
  [BUNDLES_CODES.benzodiazepines_bundle]: {
    label: "Medication Change - Benzodiazepines",
    basicData: [
      checkInBasicData[CHECK_IN_CODES.benzodiazepines_side_effects],
      healthFactorsBasicData[BUNDLES_CODES.benzodiazepines_bundle],
    ],
  },
} as const;

export const BUNDLES_STATUS = {
  deleted: "deleted",
  assigned: "assigned",
} as const;

export type BundlesStatus =
  (typeof BUNDLES_STATUS)[keyof typeof BUNDLES_STATUS];
