import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

import { Styled } from "~/common/utils/types/styledInterface";

export const SectionHeader = ({
  children,
  className,
}: PropsWithChildren<Styled>) => (
  <h2
    className={twMerge(
      "font-outfit text-2xl font-medium leading-7 tracking-wide text-gray-70",
      className,
    )}
  >
    {children}
  </h2>
);
