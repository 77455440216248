import { PRONOUNS } from "../constants/constants";
import {
  RELATIONSHIPS,
  relationshipsOptions,
} from "../constants/relationships";
import { PronounsSchemaType } from "../utils";
import { getPronouns } from "../utils/getPronouns";
import {
  CurrentPatientData,
  PatientInformation,
  UpdateCurrentPatientData,
} from "../utils/types";

export const useFormatHook = () => {
  const formatPhoneNumber = (value: string) => {
    const phoneNumber = value.replace(/[^\d]/g, "");
    if (phoneNumber.length < 4) return phoneNumber;
    if (phoneNumber.length < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6,
    )}-${phoneNumber.slice(6, 10)}`;
  };

  const pronounsAsObject = (data: PronounsSchemaType) => {
    let stringPronouns = data?.pronouns;
    if (stringPronouns === PRONOUNS.other && data?.customPronouns) {
      stringPronouns = data.customPronouns;
    }
    if (stringPronouns) {
      const [subject, object, possessive] = stringPronouns.split("/");
      return { subject, object, possessive };
    } else {
      return null;
    }
  };

  const transformedPatientData = (
    data: UpdateCurrentPatientData | CurrentPatientData | PatientInformation,
  ) => {
    const pronounsValues = Object.values(data?.pronouns ?? {}).join("/");
    const defaultPronouns =
      data?.pronouns &&
      pronounsValues.length > 0 &&
      getPronouns().some((pronoun) => pronoun.value === pronounsValues)
        ? pronounsValues
        : PRONOUNS.other;
    const customPronouns =
      defaultPronouns === PRONOUNS.other ? pronounsValues : "";

    const relationship = data.emergency_contacts?.[0]?.relationship;

    const contactRelationship = relationshipsOptions.some(
      (relation) => relation.value === relationship,
    )
      ? relationship
      : RELATIONSHIPS.OTHER;

    const customRelationship =
      contactRelationship === RELATIONSHIPS.OTHER ? relationship : "";

    const formattedData = {
      ...data,
      phone_number: data.phone_number && formatPhoneNumber(data.phone_number),
      pronouns: data.pronouns
        ? { pronouns: defaultPronouns, customPronouns: customPronouns }
        : undefined,
      emergency_contacts:
        data.emergency_contacts.length > 0
          ? [
              {
                ...data.emergency_contacts?.[0],
                phone_number:
                  data.emergency_contacts?.[0].phone_number &&
                  formatPhoneNumber(data.emergency_contacts?.[0].phone_number),
                relationship: contactRelationship,
                customRelationship: customRelationship,
              },
            ]
          : [],
      addresses: data.addresses
        ? [
            {
              ...data.addresses[0],
            },
          ]
        : undefined,
    };
    return { ...formattedData };
  };
  return { formatPhoneNumber, transformedPatientData, pronounsAsObject };
};
