import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { createMyPharmacy } from "~/patients/api/pharmacies";
import { resolveScriptIssue } from "~/patients/api/scripts";
import { PharmacyFormValuesType } from "~/patients/utils";
import { PatientResolveIssue } from "~/patients/utils/PatientScriptsTypes";

interface ResolveIssueProps {
  id?: string;
  formData: PatientResolveIssue;
}

interface ResolveIssueWithPharmacyProps {
  id?: string;
  formData: Omit<PatientResolveIssue, "patient_pharmacy_id">;
  pharmacy: PharmacyFormValuesType;
}

export const useResolveIssue = (handleCloseModal: () => void) => {
  const queryClient = useQueryClient();

  const { mutate: resolveIssueMutation, isPending: isResolveIssuePending } =
    useMutation({
      mutationFn: ({ id, formData }: ResolveIssueProps) =>
        resolveScriptIssue(formData, id),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ["getPatientScripts"],
        });
        handleCloseModal();
      },
      onError: () =>
        toast.error(
          "There was an issue sending the issue. Please try again later",
        ),
    });

  const {
    mutate: resolveIssueWithPharmacy,
    isPending: isResolveIssueWithPharmacyPending,
  } = useMutation({
    mutationFn: async ({
      id,
      formData,
      pharmacy,
    }: ResolveIssueWithPharmacyProps) => {
      const { data: newPharmacyData } = await createMyPharmacy({ pharmacy });
      resolveIssueMutation({
        id,
        formData: {
          ...formData,
          patient_pharmacy_id: newPharmacyData.id,
        },
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["getPatientScripts"],
      });
      handleCloseModal();
    },
    onError: () =>
      toast.error(
        "There was an issue sending the issue. Please try again later",
      ),
  });

  const isPending = isResolveIssuePending || isResolveIssueWithPharmacyPending;

  return { resolveIssueMutation, resolveIssueWithPharmacy, isPending };
};
