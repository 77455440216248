import React, { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

import { PATIENT_MEDICATION_STATUS } from "~/common/constants/medications";
import { PatientMedicationStatus } from "~/common/types";

interface PatientMedicationDetailsLayoutProps extends PropsWithChildren {
  treatmentStatus: PatientMedicationStatus;
}

export const PatientMedicationDetailsCard = ({
  children,
  treatmentStatus,
}: PatientMedicationDetailsLayoutProps) => {
  return (
    <div
      className={twMerge(
        "flex flex-col gap-4 rounded-lg border border-primary-200 bg-primary-50 px-4 py-4 text-gray-70",
        treatmentStatus === PATIENT_MEDICATION_STATUS.discontinued &&
          "gap-2 border-neutral-700 bg-white",
      )}
    >
      {children}
    </div>
  );
};
