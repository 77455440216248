import React, { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

interface TableHeadProps
  extends PropsWithChildren,
    React.HTMLAttributes<HTMLTableSectionElement> {
  className?: string;
}

export const TableHead = ({
  children,
  className,
  ...props
}: TableHeadProps) => {
  return (
    <thead
      className={twMerge(
        "h-fit w-full border-b border-gray-200 bg-neutral-50 text-left text-sm text-gray-10",
        className,
      )}
      {...props}
    >
      {children}
    </thead>
  );
};
