import {
  Button,
  ChevronIcon,
  H2,
  SquaredInformation,
} from "~/common/components";
import { DIRECTIONS } from "~/common/constants";
import { checkInUtilities, PatientCheckInBase } from "~/common/utils";

interface CompletedCheckinCardProps {
  patientCheckIn: PatientCheckInBase;
  handleViewCheckin: () => void;
}
export const CompletedCheckinCard = ({
  patientCheckIn,
  handleViewCheckin,
}: CompletedCheckinCardProps) => {
  const { formatCheckInCode } = checkInUtilities();
  const { title, med_type } = patientCheckIn.checkin;

  return (
    <Button
      variant="outline-none"
      className="w-full p-0"
      onClick={handleViewCheckin}
    >
      <div className="flex w-full rounded-lg border border-neutral-80 text-gray-70">
        <div className="flex w-full gap-3 p-3 text-left">
          <SquaredInformation patientCheckIn={patientCheckIn} />
          <div className="flex w-full flex-col items-start justify-center">
            <H2>{formatCheckInCode(title)}</H2>
            {med_type && (
              <p className="line-clamp-1 text-left font-light">
                {formatCheckInCode(med_type)}
              </p>
            )}
          </div>
        </div>
        <div className="flex items-center border-l border-neutral-80 px-3.5">
          <ChevronIcon
            direction={DIRECTIONS.right}
            className="h-5 w-2 text-gray-70"
          />
        </div>
      </div>
    </Button>
  );
};
