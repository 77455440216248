import { useQuery } from "@tanstack/react-query";

import { getPatientPharmacies } from "~/patients/api/pharmacies";

export const useGetPatientPharmacies = (config?: { enabled: boolean }) => {
  const { data, isLoading } = useQuery({
    queryFn: () => getPatientPharmacies(),
    queryKey: ["getPatientPharmacies"],
    ...config,
  });

  return { data, isLoading };
};
