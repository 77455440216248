import { twMerge } from "tailwind-merge";

import { formatDateDayMonth } from "~/common/utils/formatDate";

interface DateSquareProps {
  date: string;
  className?: string;
}

export const DateSquaredLayout = ({ date, className }: DateSquareProps) => {
  const formattedDate = formatDateDayMonth(date);
  return (
    <div
      className={twMerge(
        "flex w-16 flex-col items-center rounded-md bg-primary-100 p-3 text-primary-600",
        className,
      )}
    >
      <p className="text-sm leading-none">{formattedDate.month}</p>
      <p className="font-outfit text-2xl font-medium leading-none">
        {formattedDate.day}
      </p>
    </div>
  );
};
