import { ComponentPropsWithoutRef, PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

import { ClockIcon, H2, Typography } from "~/common/components";
import { TYPOGRAPHY_VARIANTS } from "~/common/utils";

interface CheckListCardLayoutProps
  extends ComponentPropsWithoutRef<"div">,
    PropsWithChildren {
  title: string;
  subtitle?: string;
  isPending: boolean;
  isUnavailable: boolean;
  isCompleted: boolean;
}

export const CheckListCardLayout = ({
  title,
  subtitle,
  isPending,
  isUnavailable,
  isCompleted,
  children,
  ...props
}: CheckListCardLayoutProps) => (
  <div
    className={twMerge(
      "my-2 flex w-full flex-col gap-2 rounded-lg border-1.5 border-primary-100 bg-white p-4",
      isPending && "border-primary-300 outline outline-2 outline-primary-10",
      isUnavailable && "border-primary-200 opacity-55",
    )}
    {...props}
  >
    <H2>{title}</H2>
    <div className="flex w-full items-center gap-1.5">
      {!isCompleted && (
        <ClockIcon className="size-4 text-gray-10" strokeWidth={1.5} />
      )}
      {subtitle && (
        <Typography
          variant={TYPOGRAPHY_VARIANTS.secondary}
          className="text-base"
        >
          {subtitle}
        </Typography>
      )}
    </div>
    {children}
  </div>
);
