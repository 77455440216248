import { PRONOUNS } from "../constants/constants";

export const getPronouns = () => [
  { value: PRONOUNS.he, label: "He/Him/His" },
  { value: PRONOUNS.she, label: "She/Her/Hers" },
  { value: PRONOUNS.they, label: "They/Them/Theirs" },
];

export const getPronounsWithOther = () => [
  ...getPronouns(),
  { value: PRONOUNS.other, label: "Other" },
];
