import { OTHER_OPTION } from "~/common/constants";
import { Option } from "~/common/utils";
import { PharmacyQueryType } from "~/common/utils/types/pharmacyTypes";

export const generatePharmaciesOptions = (
  data?: PharmacyQueryType[],
): Option[] => [
  ...(data
    ? data.map(({ pharmacy, id }) => ({
        label: `${pharmacy.name} - ${pharmacy.address.street} ${pharmacy.address.city}`,
        value: id,
      }))
    : []),
  OTHER_OPTION,
];
