import { z } from "zod";

import { baseOptionSchema } from "./baseSchemas";
import { SymptomBaseForm, SYMPTOMS_INPUT_TYPES } from "./symptomsBasicTypes";

export const APPETITE_CHANGES_PATTERNS_OPTIONS = {
  weight_gain: "weight_gain",
  weight_loss: "weight_loss",
} as const;

export const APPETITE_CHANGES = Object.keys(
  APPETITE_CHANGES_PATTERNS_OPTIONS,
) as [keyof typeof APPETITE_CHANGES_PATTERNS_OPTIONS];

export const appetiteChangesSchema = baseOptionSchema
  .extend({
    extra_data: z
      .enum(APPETITE_CHANGES, {
        message: "Please select an option for appetite changes.",
      })
      .optional(),
  })
  .refine(
    (data) => {
      if (data.toggled) return !!data.extra_data;
      return true;
    },
    {
      message: "Please select an option for appetite changes.",
      path: ["extra_data"],
    },
  )
  .default({ toggled: false, extra_data: undefined });

export const appetiteChangesSymptom: SymptomBaseForm = {
  label: "Appetite changes",
  value: "symptoms.appetite_changes.toggled",
  type: SYMPTOMS_INPUT_TYPES.check,
  extra_data: {
    title: "What patterns of appetite changes are you experiencing?",
    children: [
      {
        label: "What patterns of appetite changes are you experiencing?",
        value: "symptoms.appetite_changes.extra_data",
        type: SYMPTOMS_INPUT_TYPES.radio,
        extra_data: undefined,
        options: [
          {
            label: "Weight gain",
            value: APPETITE_CHANGES_PATTERNS_OPTIONS.weight_gain,
          },
          {
            label: "Weight loss",
            value: APPETITE_CHANGES_PATTERNS_OPTIONS.weight_loss,
          },
        ],
      },
    ],
  },
};
