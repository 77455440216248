import { PropsWithChildren } from "react";

import { CancelSubmitButtons } from "~/common/components";
import Modal from "~/common/components/Modal";

interface ConfirmDeleteListItemProps extends PropsWithChildren {
  onClose: () => void;
  show: boolean;
  onConfirm?: () => void;
  description: string;
}

export const ConfirmDeleteListItem = ({
  onClose,
  show,
  onConfirm,
  description,
}: ConfirmDeleteListItemProps) => (
  <Modal className="h-fit w-96" title="Delete" show={show} onClose={onClose}>
    <div className="flex flex-col gap-6">
      <p className="font-light">{description}</p>
      <CancelSubmitButtons
        handleCancel={onClose}
        handleSubmit={onConfirm}
        submitButtonLabel="Delete"
      />
    </div>
  </Modal>
);
