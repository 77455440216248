import { ComponentPropsWithoutRef, PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

import { Circle } from "~/common/components";
import TickIcon from "~/common/components/icons/TickIcon";
import {
  isCompletedConsultation,
  isUnavailableConsultation,
} from "~/patients/utils";

interface CheckListCardProps
  extends ComponentPropsWithoutRef<"div">,
    PropsWithChildren {
  status: string;
  order: number;
  total: number;
}

export const CheckListCard = ({
  id,
  status,
  order,
  total,
  children,
}: CheckListCardProps) => {
  const isUnavailable = isUnavailableConsultation(status);
  const isCompleted = isCompletedConsultation(status);
  const isFirst = order === 1;
  const isLast = order === total;

  return (
    <div
      key={id}
      className="relative flex w-full items-center gap-3 md:w-2/3 lg:w-1/3"
    >
      <Circle
        className={twMerge("z-10 size-6", isUnavailable && "bg-primary-200")}
      >
        {isCompleted && <TickIcon className="text-white" />}
      </Circle>
      {!isFirst && (
        <div className="absolute left-3 top-0 h-1/2 w-px bg-primary-300" />
      )}
      {!isLast && (
        <div className="absolute bottom-0 left-3 h-1/2 w-px bg-primary-300" />
      )}
      {children}
    </div>
  );
};
