import type {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormWatch,
} from "react-hook-form";
import { z } from "zod";

import { CardInputsContainer } from "~/common/components";
import DatePicker from "~/common/components/ui/DatePicker";
import Input from "~/common/components/ui/Input";
import Select from "~/common/components/ui/select/Select";
import { getTodayDate } from "~/common/utils";
import { PRONOUNS } from "~/patients/constants";
import {
  getPronounsWithOther,
  updatePatientDataSchema,
} from "~/patients/utils";
import type { AccountSchemaType } from "./Account";

export type PatientSchemaType = z.infer<typeof updatePatientDataSchema>;

interface UserDetailsProps {
  register: UseFormRegister<AccountSchemaType>;
  control?: Control<AccountSchemaType>;
  watch: UseFormWatch<AccountSchemaType>;
  customPronouns?: string;
  selectedPronoun?: string;
  errors: FieldErrors<PatientSchemaType> | undefined;
}

const UserDetails = ({
  register,
  control,
  customPronouns,
  selectedPronoun,
  errors,
}: UserDetailsProps) => {
  const pronouns = getPronounsWithOther();
  const today = getTodayDate();

  return (
    <CardInputsContainer title="User Details">
      <div className="flex w-full flex-col gap-4">
        <div className="flex w-full flex-col gap-4 md:flex-row md:gap-2">
          <Input
            id="name"
            label="First name"
            {...register("name")}
            error={errors?.name?.message}
          />
          <Input
            id="name"
            label="Last name"
            {...register("last_name")}
            error={errors?.last_name?.message}
          />
        </div>
        <div className="flex w-full flex-col gap-4 md:flex-row md:gap-2">
          <Input
            id="email"
            label="Email address"
            disabled
            {...register("email")}
            error={errors?.email?.message}
          />
          <Input
            id="mobile"
            label="Mobile number"
            {...register("phone_number")}
            error={errors?.phone_number?.message}
          />
        </div>
        <div className="flex flex-col gap-3 md:flex-row md:gap-2">
          <div className="md:w-1/2">
            <DatePicker
              id="birthday"
              label="Date of birth"
              max={today}
              {...register("birthday")}
              error={errors?.birthday?.message}
            />
          </div>
          <div className="flex flex-col gap-3 md:w-1/2 md:flex-row md:gap-2">
            <Select
              error={errors?.pronouns?.message}
              label="Pronouns"
              placeholder={"Select pronouns"}
              id="pronouns.pronouns"
              options={pronouns}
              control={control}
              buttonClassName="py-3"
              containerClassName="gap-2"
            />
            {selectedPronoun === PRONOUNS.other && (
              <div className="flex h-full w-full flex-col items-end">
                <div className="md:h-5" />
                <Input
                  id="customPronouns"
                  placeholder="She/Her/Hers"
                  {...register("pronouns.customPronouns")}
                  defaultValue={customPronouns}
                  error={errors?.pronouns?.customPronouns?.message}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </CardInputsContainer>
  );
};

export default UserDetails;
