import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import { BackgroundWaves, PsychNowLogo } from "~/common/assets";
import { PsychNowLogoMobile } from "~/patients/assets/index";

interface AuthenticationLayout {
  className?: string;
  children: ReactNode;
  hideHeaderMobile?: boolean;
  welcomeText: string;
}
const AuthenticationLayout = ({
  className,
  children,
  hideHeaderMobile,
  welcomeText,
}: AuthenticationLayout) => (
  <div className="flex h-screen-calculated w-full flex-col items-center justify-center gap-[5.5rem] overflow-hidden bg-white p-8 text-gray-700 xl:h-screen xl:w-screen xl:flex-row xl:gap-0 xl:p-0">
    <div
      className={twMerge(
        "flex w-full flex-col max-xl:!bg-none xl:flex xl:h-screen xl:w-1/2 xl:gap-8 xl:rounded-none xl:bg-gradient-to-b xl:bg-cover xl:bg-bottom xl:pl-24 xl:pt-32",
        hideHeaderMobile && "hidden xl:flex",
      )}
      style={{ backgroundImage: `url(${BackgroundWaves})` }}
    >
      <div className="hidden w-1/3 xl:flex">
        <img src={PsychNowLogo} alt="PsychNow Logo" width="100%" />
      </div>
      <div className="flex w-full justify-around xl:hidden">
        <img src={PsychNowLogoMobile} alt="PsychNow Logo" />
      </div>

      <p className="hidden xl:block xl:w-3/4">
        At <strong>PsychNow</strong>, {welcomeText}
      </p>
    </div>
    <div
      className={twMerge(
        "flex w-full flex-col items-center justify-center overflow-hidden xl:h-screen xl:flex-1",
        className,
      )}
    >
      {children}
    </div>
  </div>
);
export default AuthenticationLayout;
