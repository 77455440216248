import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";

import Input from "~/common/components/ui/Input";
import { replaceUnderscores } from "~/common/utils";
import { TERTIARY_EDUCATION_TYPE } from "~/common/utils/consultations/intake";
import {
  RadioWithTertiaryEducationAnswer,
  RadioWithTertiaryEducationQuestionData,
} from "~/common/utils/consultations/intake/interfaces";
import { YES_NO_TYPES } from "~/common/utils/consultations/screening";
import { ConsultationQuestionsLayout } from "~/patients/components";
import CheckBox from "~/patients/components/ui/CheckBox";
import RadioButtonOption from "~/patients/components/ui/RadioButtonOption";
import {
  radioWithTertiaryEducationAnswerSchema,
  RadioWithTertiaryEducationAnswerType,
  RadioWithTertiaryEducationType,
} from "~/patients/utils/consultations/intakes";

export interface RadioWithTertiaryEducationProps {
  question: RadioWithTertiaryEducationQuestionData;
  handleGoBack: () => void;
  onSubmit: (answer: RadioWithTertiaryEducationAnswer) => void;
  isSubmitting: boolean;
  disableBack: boolean;
  completedAnswer: RadioWithTertiaryEducationType;
}

export const RadioWithTertiaryEducationForm = ({
  question,
  handleGoBack,
  onSubmit,
  isSubmitting,
  disableBack,
  completedAnswer,
}: RadioWithTertiaryEducationProps) => {
  const { options, title, tertiaryType } = question;
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<RadioWithTertiaryEducationAnswerType>({
    defaultValues: {
      answer: { ...completedAnswer },
    },
    resolver: zodResolver(radioWithTertiaryEducationAnswerSchema),
  });

  const saveAnswers: SubmitHandler<RadioWithTertiaryEducationAnswerType> = (
    data,
  ) => {
    onSubmit(data);
  };

  const didYouAttendValue = watch("answer.did_you_attend");

  useEffect(() => {
    if (didYouAttendValue === YES_NO_TYPES.no) {
      reset({
        answer: {
          did_you_attend: YES_NO_TYPES.no,
        },
      });
    }
  }, [didYouAttendValue, reset]);

  const optionSelected = options.find(
    ({ value }) => value === didYouAttendValue,
  );

  const isCollage = tertiaryType === TERTIARY_EDUCATION_TYPE.college;

  const tertiaryEducation = isCollage
    ? TERTIARY_EDUCATION_TYPE.college
    : replaceUnderscores(TERTIARY_EDUCATION_TYPE.graduate_school);

  return (
    <ConsultationQuestionsLayout
      handleNext={handleSubmit(saveAnswers)}
      handleBack={handleGoBack}
      disableNext={!didYouAttendValue}
      errors={errors.answer?.message}
      isSubmitting={isSubmitting}
      title={title}
      disableBack={disableBack}
    >
      {options.map(({ value }) => (
        <RadioButtonOption
          key={value}
          label={value}
          value={value}
          isSelected={didYouAttendValue === value}
          {...register("answer.did_you_attend")}
        />
      ))}
      {optionSelected?.asksExtraInfo && (
        <div className="flex w-full flex-col items-start gap-4 py-6">
          <Input
            id="name"
            label={`${tertiaryEducation} name`}
            placeholder={`Enter ${tertiaryEducation} name`}
            {...register("answer.name")}
            error={errors?.answer?.name?.message}
            className="border border-gray-20 placeholder:not-italic"
          />
          <Input
            id="degree"
            label="Degree"
            placeholder={`Enter degree type / ${
              isCollage ? "area of study" : "major(s)"
            }`}
            {...register("answer.degree")}
            error={errors?.answer?.degree?.message}
            className="border border-gray-20 placeholder:not-italic"
          />
          <div className="flex w-full gap-3">
            <Input
              id="year_started"
              label="Year started"
              placeholder="YYYY"
              {...register("answer.year_started")}
              error={errors?.answer?.year_started?.message}
            />
            <Input
              id="year_ended"
              label="Year ended (optional)"
              placeholder="YYYY"
              {...register("answer.year_ended")}
              error={errors?.answer?.year_ended?.message}
            />
          </div>
          <CheckBox
            id="graduated"
            {...register("answer.graduated")}
            error={errors?.answer?.graduated?.message}
            labelClassName="text-sm md:text-base"
            containerClassName="items-start items-center"
          >
            <span className="w-full text-left text-gray-600">
              {`I have graduated from ${tertiaryEducation}`}
            </span>
          </CheckBox>
        </div>
      )}
    </ConsultationQuestionsLayout>
  );
};
