import { twMerge } from "tailwind-merge";

import { passwordRequirements } from "~/common/utils/passwordRequirements";
import { PasswordRequirement } from "../utils/types";
import { CheckIcon } from "./icons/CheckIcon";
import TickCircleIcon from "./icons/TickCircleIcon";

interface PasswordRequirementsProps {
  className?: string;
  password: string | null;
}

const PasswordRequirements = ({
  className,
  password,
}: PasswordRequirementsProps) => {
  return (
    <div className="flex flex-col gap-1">
      <h3 className="py-1 font-bold">Password minimum requirements</h3>
      {passwordRequirements.map((requirement: PasswordRequirement, index) => (
        <div
          className={twMerge(
            "flex items-center gap-3 text-sm font-light text-gray-700",
            className,
          )}
          key={index}
        >
          {!!password && requirement.valid(password) ? (
            <>
              <CheckIcon />
              <strong>{requirement.condition}</strong>
            </>
          ) : (
            <>
              <TickCircleIcon />
              {requirement.condition}
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default PasswordRequirements;
