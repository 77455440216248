import React from "react";
import { twMerge } from "tailwind-merge";

interface HomeIconProps {
  className?: string;
}
const HomeIcon = ({ className }: HomeIconProps) => {
  return (
    <svg
      className={twMerge("h-[21] w-[22]", className)}
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.916748 8.43517L9.48683 1.80494C10.3769 1.13908 11.6233 1.13908 12.5133 1.80494L21.0834 8.43517M3.60564 6.35492V17.2681C3.60564 18.5775 4.70917 19.6389 6.07045 19.6389H7.30286H14.6973H15.9297C17.291 19.6389 18.3945 18.5775 18.3945 17.2681V6.35492M8.53527 19.6389V12.5266C8.53527 11.872 9.08703 11.3413 9.76767 11.3413H12.2325C12.9131 11.3413 13.4649 11.872 13.4649 12.5266V19.6389"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default HomeIcon;
