import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

import { checkInUtilities } from "~/common/utils/checkIn/checkInUtilities";
import {
  CheckinFileFormProps,
  INPUT_TYPES,
} from "~/common/utils/types/checkInTypes";
import { questionTextByCodes } from "~/patients/constants";
import { fileSchema } from "~/patients/utils";
import UploadFileInput from "../../ui/UploadFileInput";
import { QuestionsLayout } from "../QuestionsLayout";

export type FileFormValues = z.infer<typeof fileSchema>;

export const FileForm = ({
  question,
  handleGoBack,
  handleNext,
  isLastQuestion,
  existingAnswer,
  removeQuestionAnswerById,
  isSubmitting = false,
}: CheckinFileFormProps) => {
  const { getInputOfType, getAnswerOfType } = checkInUtilities();
  const fileInput = getInputOfType(INPUT_TYPES.file, question.inputs);

  const fileAnswer = getAnswerOfType(
    INPUT_TYPES.file,
    question.inputs,
    existingAnswer,
  );

  const [uploadedFile, setUploadedFile] = useState<File | undefined>(
    fileAnswer?.file,
  );

  const { handleSubmit, reset, setValue } = useForm<FileFormValues>({
    resolver: zodResolver(fileSchema),
    defaultValues: {
      file: fileAnswer?.file?.size,
    },
  });

  const deleteFile = () => {
    setUploadedFile(undefined);
    removeQuestionAnswerById(question.id);
    reset();
  };

  const formatAnswer = () => {
    if (!uploadedFile) return;
    return {
      question_id: question.id,
      inputs: [
        {
          id: fileInput?.id,
          answer: {
            answer_type: INPUT_TYPES.file,
            file: uploadedFile,
            fileName: uploadedFile.name,
          },
        },
      ],
    };
  };

  const saveAnswers: SubmitHandler<FileFormValues> = () => {
    const answer = formatAnswer();
    answer && handleNext(answer);
  };

  const setFile = (file: File) => {
    setUploadedFile(file);
    setValue(INPUT_TYPES.file, file.size);
  };

  return (
    <QuestionsLayout
      question={questionTextByCodes[question.code]}
      handleNext={handleSubmit(saveAnswers)}
      handleBack={() => handleGoBack(formatAnswer())}
      disableNext={!uploadedFile}
      isLastQuestion={isLastQuestion}
      isSubmitting={isSubmitting}
    >
      <UploadFileInput
        uploadedFile={uploadedFile}
        setUploadedFile={setFile}
        deleteFile={deleteFile}
      />
    </QuestionsLayout>
  );
};
