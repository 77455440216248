import { useQueryClient } from "@tanstack/react-query";
import * as jose from "jose";
import { UseFormTrigger } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useSendEmailVerification } from "~/patients/hooks/queries/useSendEmailVerification";
import { ROUTES } from "~/patients/router";
import { useOnBoardingNewPatientStore } from "~/patients/stores/useOnBoardingNewPatientStore";
import {
  NewPatientSchemaType,
  SignUpNewPatientDataRequest,
  SignUpPatientSchemaType,
} from "~/patients/utils";
import { useCreateNewPatient } from "./useCreateNewPatient";

interface useSubmitNewPatientSignUpProps {
  values: NewPatientSchemaType;
  trigger: UseFormTrigger<SignUpPatientSchemaType>;
  dataToken?: string | null;
}
export const useSubmitNewPatientSignUp = ({
  values,
  trigger,
  dataToken,
}: useSubmitNewPatientSignUpProps) => {
  const { setNewPatient, setOnBoardingNewPatientStep } =
    useOnBoardingNewPatientStore();
  const { sendEmail } = useSendEmailVerification();

  const navigate = useNavigate();

  const userJWT = dataToken ? jose.decodeJwt(dataToken) : undefined;
  const providerId = userJWT?.provider_id as string;

  const queryClient = useQueryClient();

  const onSignUpSuccess = () => {
    queryClient.clear();
    navigate(ROUTES.verifyEmail);
    setNewPatient();
    setOnBoardingNewPatientStep(0);
    sendEmail({ showSuccessMessage: false });
  };

  const { signUpMutation, isPending } = useCreateNewPatient(onSignUpSuccess);

  const onSubmitSignUp = async () => {
    const isValidData = await trigger();

    if (!isValidData) return;

    const newPatient: SignUpNewPatientDataRequest = {
      ...values.phone_number,
      ...values.namesAndEmail,
      password: values.password.password,
      address: values.address,
      provider_id: providerId,
    };

    signUpMutation({ newPatient });
  };

  return {
    onSubmitSignUp,
    isPending,
  };
};
