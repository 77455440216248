import { useQuery } from "@tanstack/react-query";

import { intakeAvailable } from "~/common/utils/consultations/intake";
import { getCurrentIntakeBundle } from "~/patients/api";
import { useGetCurrentPatient } from "~/patients/hooks";

export const useGetCurrentIntakeBundle = () => {
  const { data: user } = useGetCurrentPatient();

  const { data, isLoading, isFetching } = useQuery({
    queryFn: () => getCurrentIntakeBundle(),
    queryKey: ["getCurrentIntakeBundle", user],
    enabled: !!user && intakeAvailable(user.status),
  });

  return { data, isLoading, isFetching };
};
