import { generatePath, Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { ChevronIcon, H2 } from "~/common/components";
import { DIRECTIONS } from "~/common/constants";
import {
  checkInUtilities,
  PatientCheckInBase,
  SideEffectsCheckinCodes,
} from "~/common/utils";
import { useBreakpoint } from "~/patients/hooks";
import { ROUTES } from "~/patients/router";
import { ALERT_DUE_DATES, getDateText, getOverdueText } from "~/patients/utils";

interface CheckInCardProps {
  patientCheckIn: PatientCheckInBase;
}

export const CheckInCard = ({ patientCheckIn }: CheckInCardProps) => {
  const { getImagesByCode, formatCheckInCode } = checkInUtilities();
  const { patient_bundle, checkin } = { ...patientCheckIn };
  const { generic_name: medicationName } = {
    ...patient_bundle?.patient_medication.medication,
  };
  const { title, code } = { ...checkin };
  const [mobileImage, desktopImage] = getImagesByCode(code);
  const dateText = getDateText(patientCheckIn.due_date);
  const isDueToday = dateText === ALERT_DUE_DATES.today;
  const isOverdue = dateText === ALERT_DUE_DATES.overdue;
  const isDesktop = useBreakpoint("md");

  if (!desktopImage || !mobileImage) {
    return null;
  }

  return (
    <Link
      className="w-full"
      to={generatePath(ROUTES.checkIn, {
        id: patientCheckIn.id,
      })}
    >
      <div className="flex h-full flex-row items-stretch justify-between overflow-hidden rounded-lg border text-left">
        <div className="flex flex-row gap-2 md:w-56 md:flex-col md:gap-0">
          <img
            src={isDesktop ? desktopImage : mobileImage}
            alt=""
            className="h-[6.5rem] w-[6.5rem] object-cover md:h-40 md:w-auto"
          />
          <div className="flex h-[6.5rem] flex-col justify-between gap-2 py-3 md:h-full md:p-3">
            <div className="flex flex-col">
              <H2>{formatCheckInCode(title)}</H2>
              {SideEffectsCheckinCodes.includes(code) && (
                <p className="line-clamp-1 break-all text-base">
                  {medicationName}
                </p>
              )}
            </div>
            <div className="flex w-full flex-col items-start">
              <p
                className={twMerge(
                  "text-base font-light leading-5 text-gray-500",
                  isDueToday && "font-medium text-primary-600",
                  isOverdue && "font-medium text-warning",
                )}
              >
                {dateText}
              </p>
              {isOverdue && (
                <p className="text-sm leading-4 text-gray-60">
                  {getOverdueText(patientCheckIn.expiration_date)}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="flex items-center border-l px-3.5 md:hidden">
          <ChevronIcon direction={DIRECTIONS.right} />
        </div>
      </div>
    </Link>
  );
};
