export const BalanceIcon = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.664062"
        y="-0.0078125"
        width="32"
        height="32"
        rx="16"
        fill="#FBDEEA"
      />
      <path
        d="M21.4054 13.7635C21.7779 13.5022 21.8715 12.9843 21.5659 12.6472C21.055 12.084 20.4489 11.6117 19.7735 11.2533C18.8548 10.7658 17.8337 10.5026 16.7937 10.4853C15.7538 10.468 14.7245 10.6971 13.7901 11.1538C13.1031 11.4895 12.4817 11.9413 11.9524 12.4873C11.6356 12.8139 11.712 13.3348 12.0756 13.6083L14.0347 15.082C14.3983 15.3556 14.9104 15.2671 15.2872 15.012C15.3836 14.9467 15.4848 14.8881 15.5901 14.8366C15.9433 14.664 16.3324 14.5774 16.7256 14.5839C17.1187 14.5905 17.5047 14.6899 17.852 14.8743C17.9555 14.9292 18.0547 14.9911 18.1489 15.0596C18.5169 15.3271 19.0259 15.4325 19.3984 15.1713L21.4054 13.7635Z"
        stroke="#EC5D88"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.132 24.5486H23.1964C23.7639 24.5486 24.2327 24.1052 24.2642 23.5385L25.0959 8.56625C25.13 7.95326 24.6421 7.4375 24.0282 7.4375H9.30019C8.68624 7.4375 8.19834 7.95326 8.23239 8.56625L9.06418 23.5385C9.09567 24.1052 9.5644 24.5486 10.132 24.5486Z"
        stroke="#EC5D88"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};
