import { ComponentPropsWithoutRef, PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

import { TYPOGRAPHY_VARIANTS, TypographyVariants } from "~/common/utils";

export interface TypographyProps
  extends PropsWithChildren,
    ComponentPropsWithoutRef<"p"> {
  variant?: TypographyVariants;
}

export const Typography = ({
  children,
  className,
  variant = TYPOGRAPHY_VARIANTS.primary,
}: TypographyProps) => (
  <p
    className={twMerge(
      variant === TYPOGRAPHY_VARIANTS.primary &&
        "text-base leading-5 text-gray-70",
      variant === TYPOGRAPHY_VARIANTS.secondary && "text-gray-10",
      variant === TYPOGRAPHY_VARIANTS.light && "font-light text-gray-60",
      variant === TYPOGRAPHY_VARIANTS.italic &&
        "font-light italic text-gray-10",
      className,
    )}
  >
    {children}
  </p>
);
