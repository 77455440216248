import { useOutlet } from "react-router-dom";

import PatientSideBar from "../components/sidebar/PatientSideBar";

export const Layout = () => {
  const outlet = useOutlet();

  return (
    <div>
      <PatientSideBar />
      <div className="lg:pl-64">
        <main>{outlet}</main>
      </div>
    </div>
  );
};
