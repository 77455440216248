import {
  baseOptionSchema,
  toggleSchema,
  toggleTextExtraDataSchema,
} from "./baseSchemas";
import { SymptomBaseForm, SYMPTOMS_INPUT_TYPES } from "./symptomsBasicTypes";

export const depressionSchema = baseOptionSchema
  .extend({
    worse_in_the_morning: toggleSchema,
    worse_at_night: toggleSchema,
    no_pattern: toggleSchema,
    none: toggleTextExtraDataSchema,
  })
  .refine(
    (data) => {
      if (data.toggled) {
        return (
          data.worse_in_the_morning?.toggled ||
          data.worse_at_night?.toggled ||
          data.no_pattern?.toggled ||
          data.none.toggled
        );
      }

      return true;
    },
    {
      message:
        "Please select at least one pattern of depression are you experiencing.",
      path: ["toggled"],
    },
  );

export const depressionSymptom: SymptomBaseForm = {
  label: "Depression",
  value: "symptoms.depression.toggled",
  type: SYMPTOMS_INPUT_TYPES.check,
  extra_data: {
    title: "What patterns of depression are you experiencing?",
    children: [
      {
        label: "Worse in the morning",
        value: "symptoms.depression.worse_in_the_morning.toggled",
        type: SYMPTOMS_INPUT_TYPES.check,
        extra_data: undefined,
        excludedBy: ["symptoms.depression.none.toggled"],
      },
      {
        value: "symptoms.depression.worse_at_night.toggled",
        label: "Worse at night",
        type: SYMPTOMS_INPUT_TYPES.check,
        extra_data: undefined,
        excludedBy: ["symptoms.depression.none.toggled"],
      },
      {
        label: "No pattern",
        value: "symptoms.depression.no_pattern.toggled",
        type: SYMPTOMS_INPUT_TYPES.check,
        extra_data: undefined,
        excludedBy: ["symptoms.depression.none.toggled"],
      },
      {
        label: "None of the above",
        value: "symptoms.depression.none.toggled",
        type: SYMPTOMS_INPUT_TYPES.check,
        excludeOptions: [
          "symptoms.depression.worse_in_the_morning.toggled",
          "symptoms.depression.worse_at_night.toggled",
          "symptoms.depression.no_pattern.toggled",
        ],
        extra_data: {
          title: undefined,
          children: [
            {
              value: "symptoms.depression.none.extra_data",
              label: undefined,
              type: SYMPTOMS_INPUT_TYPES.text,
              extra_data: undefined,
            },
          ],
        },
      },
    ],
  },
};
