import React from "react";
import { twMerge } from "tailwind-merge";

interface ProgressBarProps {
  progress: number;
  className?: string;
}
const ProgressBar = ({ progress, className }: ProgressBarProps) => {
  return (
    <div
      className={twMerge("h-1.5 w-full rounded-full bg-primary-300", className)}
    >
      <div
        className={twMerge(
          "h-1.5 rounded-full bg-primary-500 transition-all duration-500",
        )}
        style={{ width: `${progress}%` }}
      />
    </div>
  );
};

export default ProgressBar;
