import { SectionHeader, Typography } from "~/common/components";
import { TYPOGRAPHY_VARIANTS } from "~/common/utils";

interface HeaderInfoProps {
  subtitle: string;
  title: string;
}

export const HeaderInfo = ({ subtitle, title }: HeaderInfoProps) => (
  <div className="flex w-full flex-col gap-2">
    <Typography className="text-lg" variant={TYPOGRAPHY_VARIANTS.secondary}>
      {subtitle}
    </Typography>
    <SectionHeader className="text-3xl font-semibold">{title}</SectionHeader>
  </div>
);
