import {
  CompletedTextAnswer,
  ConsultationInputData,
  TextQuestionValues,
} from ".";

export const formatTextAnswer = (
  inputs: readonly ConsultationInputData<TextQuestionValues>[],
  completedAnswer?: CompletedTextAnswer,
) => {
  if (inputs.length === 1) {
    const value = completedAnswer?.value;
    return { value };
  }

  return inputs.reduce((acc, input) => {
    const key = input.value;
    const value = completedAnswer?.[key];
    return { ...acc, [key]: value };
  }, {});
};
