import { Navigate } from "react-router-dom";

import { PATIENT_STATUS } from "~/common/constants";
import { ScreeningQuestion } from "../../../components";
import { useGetCurrentPatient } from "../../../hooks";
import { ROUTES } from "../../../router";

export const Screening = () => {
  const { data: user } = useGetCurrentPatient();

  if (user?.status === PATIENT_STATUS.ongoing_care)
    return <Navigate to={ROUTES.notFound} />;

  return <ScreeningQuestion />;
};
