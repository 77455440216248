import Modal from "~/common/components/Modal";
import { VitalSignsImage } from "../assets";

interface VitalSignsModalProps {
  show: boolean;
  onClose: () => void;
}
export const VitalSignsModal = ({ show, onClose }: VitalSignsModalProps) => {
  return (
    <Modal show={show} onClose={onClose}>
      <img src={VitalSignsImage} alt="explanation" />
    </Modal>
  );
};
