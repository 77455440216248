import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

import { Styled } from "../utils/types/styledInterface";

export const Tag = ({ children, className }: PropsWithChildren<Styled>) => (
  <div
    className={twMerge(
      "text flex items-center justify-center gap-1 rounded-3xl border-none px-2 py-1 font-medium",
      className,
    )}
  >
    {children}
  </div>
);
