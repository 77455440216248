import { Option } from "~/common/utils";
import { RegisterKeys } from "./symptomsSchema";

export const COMMON_INPUT_TYPES = { check: "check", text: "text" } as const;

export const SYMPTOMS_INPUT_TYPES = {
  ...COMMON_INPUT_TYPES,
  radio: "radio",
} as const;

export const defaultValueBySymptomInputType = {
  [SYMPTOMS_INPUT_TYPES.check]: false,
  [SYMPTOMS_INPUT_TYPES.radio]: undefined,
  [SYMPTOMS_INPUT_TYPES.text]: "",
} as const;

export type CommonInputTypes =
  (typeof COMMON_INPUT_TYPES)[keyof typeof COMMON_INPUT_TYPES];

export type SymptomsInputTypes =
  (typeof SYMPTOMS_INPUT_TYPES)[keyof typeof SYMPTOMS_INPUT_TYPES];

interface BasicForm {
  label?: string;
  value: RegisterKeys;
  extra_data?: FormExtraData;
}

export interface FormExtraData {
  title?: string;
  children?: SymptomBaseForm[];
}

interface TextForm extends BasicForm {
  type: typeof SYMPTOMS_INPUT_TYPES.text;
}

interface SymptomCheckForm extends BasicForm {
  type: typeof SYMPTOMS_INPUT_TYPES.check;
  excludeOptions?: RegisterKeys[];
  excludedBy?: RegisterKeys[];
}

export interface SymptomRadioForm extends BasicForm {
  type: typeof SYMPTOMS_INPUT_TYPES.radio;
  options: Option[];
}

export type SymptomBaseForm = SymptomRadioForm | SymptomCheckForm | TextForm;

export const NONE_VALUE: RegisterKeys = "symptoms.none.toggled";
