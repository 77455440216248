import { intakeSectionTitle } from "~/common/utils/consultations/intake";
import { PatientIntakeBundle } from "~/common/utils/consultations/intake/interfaces";
import { CheckListCard } from "../shared/ui/cards/CheckListCard";
import { IntakeCheckListCard } from "./IntakeCheckListCard";

export const IntakeCheckList = ({ ...props }: PatientIntakeBundle) => {
  const { intakes, total_sections: totalIntakes } = { ...props };

  return (
    <div className="relative flex size-full h-full flex-col items-center overflow-y-auto bg-primary-20 px-6 py-3 md:px-14 md:py-8">
      {intakes.map(({ id, status, order, code, ...intake }) => (
        <CheckListCard
          key={id}
          id={id}
          status={status}
          order={order}
          total={totalIntakes}
        >
          <IntakeCheckListCard
            intake={{ id, status, order, code, ...intake }}
            title={intakeSectionTitle[code]}
          />
        </CheckListCard>
      ))}
    </div>
  );
};
