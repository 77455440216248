import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface AnswerLayoutProps {
  children: ReactNode;
  className?: string;
  isPreview?: boolean;
}

export const AnswerLayout = ({
  children,
  className,
  isPreview,
}: AnswerLayoutProps) => (
  <div
    className={twMerge(
      "flex w-full flex-col items-start justify-center gap-2 rounded-lg bg-primary-50 font-medium leading-5 text-primary-600",
      className,
      isPreview && " bg-transparent",
    )}
  >
    {children}
  </div>
);
