import { z } from "zod";

import { replaceUnderscores } from "~/common/utils";
import { ConsultationInputData } from "~/common/utils/consultations/shared";
import { TEXT_MAX_LENGTH } from "../constants";

const singleInputSchema = (input: ConsultationInputData) => {
  const valueName = replaceUnderscores(input.value);
  let schema = z
    .string()
    .max(TEXT_MAX_LENGTH, "Maximum 2000 characters allowed");

  if (input.required) {
    schema = schema.min(1, `${valueName} is required`);
  }

  if (input.regex) {
    schema = schema.regex(
      input.regex,
      input.regexMessage ?? `Invalid ${valueName}`,
    );
  }

  return z.object({
    answer: z.object({
      value: schema,
    }),
  });
};

const multipleInputsSchema = (inputs: readonly ConsultationInputData[]) => {
  const schemaShape = inputs.reduce(
    (acc, input) => {
      const valueName = replaceUnderscores(input.value);
      let schema = z
        .string()
        .max(TEXT_MAX_LENGTH, "Maximum 2000 characters allowed");
      if (input.required) {
        schema = schema.min(1, `${valueName} is required`);
      }

      if (input.regex) {
        schema = schema.regex(
          input.regex,
          input.regexMessage ?? `Invalid ${valueName}`,
        );
      }
      acc[input.value] = schema;
      return acc;
    },
    {} as Record<string, z.ZodString | z.ZodOptional<z.ZodString>>,
  );

  return z.object({
    answer: z.object(schemaShape),
  });
};

export const createAudioSchema = (
  minDuration: number,
  maxDuration: number,
  isOptional: boolean,
) => {
  const durationSchema = z
    .number()
    .min(
      minDuration,
      `Audio duration should be at least ${minDuration} seconds, please record a new one.`,
    )
    .max(
      maxDuration,
      `Audio duration should be maximum ${maxDuration} seconds, please record a new one.`,
    );
  const answerSchema = z.object({ duration: durationSchema });
  return z.object({
    answer: isOptional
      ? answerSchema
          .nullable()
          .transform((value) => (value?.duration === 0 ? null : value))
      : answerSchema,
  });
};

export const createStringSchema = (
  inputs: readonly ConsultationInputData[],
) => {
  if (inputs.length === 1) {
    const input = inputs[0];
    return singleInputSchema(input);
  }

  return multipleInputsSchema(inputs);
};

export const dateSchema = z.object({
  date: z
    .string()
    .min(1, "Please select a date.")
    .refine(
      (val) => {
        const date = new Date(val);
        const year = date.getFullYear();
        return year > 1900;
      },
      {
        message: "Please enter a valid year",
      },
    ),
});

export type DateType = z.infer<typeof dateSchema>;

export const dateFormSchema = z.object({
  answer: dateSchema,
});

export type DateFormType = z.infer<typeof dateFormSchema>;
