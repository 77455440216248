import { z } from "zod";

export const cancelRequestSchema = z.object({
  justification: z
    .string()
    .max(1000, "Please ensure the note does not exceed 1000 characters.")
    .optional(),
});

export type CancelRequestFormValues = z.infer<typeof cancelRequestSchema>;
