import axios, { AxiosInstance } from "axios";

import { AuthenticationResponse } from "~/common/utils/types";
import { env } from "../env";
import { api } from "./axios";

export interface LogInRequest {
  email: string;
  password: string;
}

const axiosInstance: AxiosInstance = axios.create({
  baseURL: env.VITE_API_URL,
  withCredentials: true,
});

export const login = async ({ email, password }: LogInRequest) => {
  const logInData = { email, password };
  const response = await axiosInstance.post<AuthenticationResponse>(
    "v1/patients/login",
    {
      ...logInData,
    },
  );

  return response.data;
};

export const getPatientIsAuth = async () => {
  const response = await api.get<boolean>("v1/auth/patients");

  return response.data;
};
