import React from "react";

import Modal from "~/common/components/Modal";
import { Button } from "~/common/components/ui/button";

interface DeleteFileModalProps {
  onClose: () => void;
  handleDelete: () => void;
  show: boolean;
  fileName: string;
}
const DeleteFileModal = ({
  onClose,
  handleDelete,
  show,
  fileName,
}: DeleteFileModalProps) => {
  const handleDeleteFile = () => {
    handleDelete();
    onClose();
  };

  const text = `Are you sure you want to delete ${fileName}?`;
  return (
    <Modal description={text} show={show} onClose={onClose} title="Delete file">
      <div className="flex w-full gap-2">
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleDeleteFile}>Delete</Button>
      </div>
    </Modal>
  );
};

export default DeleteFileModal;
