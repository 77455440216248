import Modal, { ModalProps } from "~/common/components/Modal";
import { Button } from "~/common/components/ui/button";

interface DeleteAudioModalProps extends ModalProps {
  handleDelete: () => void;
  pendingDeleteAudio?: boolean;
}

const DeleteAudioModal = ({
  handleDelete,
  onClose,
  show,
  pendingDeleteAudio,
}: DeleteAudioModalProps) => {
  const handleDeleteAudio = () => {
    handleDelete();
    onClose();
  };
  return (
    <Modal
      title="Delete recording"
      description="Are you sure you want to delete recording?"
      onClose={onClose}
      show={show}
    >
      <div className="flex w-full flex-row gap-2">
        <Button variant="outline" size="sm" onClick={onClose} type="button">
          Cancel
        </Button>
        <Button
          size="sm"
          onClick={handleDeleteAudio}
          type="button"
          isLoading={pendingDeleteAudio}
        >
          Delete
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteAudioModal;
