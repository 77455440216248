import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

import { Styled } from "~/common/utils";

export const H2 = ({ children, className }: PropsWithChildren<Styled>) => (
  <h2
    className={twMerge(
      "text-lg font-medium leading-5 md:text-xl md:font-semibold md:leading-6",
      className,
    )}
  >
    {children}
  </h2>
);
