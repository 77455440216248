import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";

import { CancelSubmitButtons } from "~/common/components";
import Modal from "~/common/components/Modal";
import TextArea from "~/common/components/ui/Textarea";
import {
  CancelRequestFormValues,
  cancelRequestSchema,
} from "~/patients/components/utils/scriptsSchemas";
import { useCancelScript } from "~/patients/hooks/queries/scripts/useCancelScript";

interface CancelRequestModalProps {
  show: boolean;
  handleClose: () => void;
  medicationName?: string;
  scriptToCancelId?: string;
}
export const CancelRequestModal = ({
  show,
  medicationName,
  handleClose,
  scriptToCancelId,
}: CancelRequestModalProps) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CancelRequestFormValues>({
    resolver: zodResolver(cancelRequestSchema),
  });

  const onClose = () => {
    handleClose();
    reset();
  };

  const { cancelScriptMutation, isPending } = useCancelScript(onClose);

  const onSubmit: SubmitHandler<CancelRequestFormValues> = (script) => {
    cancelScriptMutation({ id: scriptToCancelId, script });
  };

  return (
    <Modal
      show={show}
      onClose={onClose}
      className="md:w-1/3"
      title="Cancel request"
    >
      <div className="flex w-full flex-col gap-2">
        <p>
          Are you sure you want to cancel this request for
          <span className="font-semibold"> {medicationName}</span>?
        </p>
      </div>
      <form
        className="flex w-full flex-col items-end gap-6"
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextArea
          label="Reason (Optional)"
          error={errors.justification?.message}
          {...register("justification")}
        />
        <CancelSubmitButtons
          containerClassName="w-3/4"
          handleCancel={onClose}
          isSubmitting={isPending}
          cancelButtonLabel="Keep"
          submitButtonLabel="Cancel request"
        />
      </form>
    </Modal>
  );
};
