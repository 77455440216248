import {
  INTAKE_QUESTION_CODES,
  INTAKE_QUESTION_TYPES,
} from "~/common/utils/consultations/intake";
import { YesNoNotSure } from "~/common/utils/consultations/intake/intakeTypes";

export const yesNoNotSureQuestions = {
  [INTAKE_QUESTION_CODES.family_died_while_playing_sports]: {
    title:
      "Has anyone in your family died suddenly at a young age while playing sports?",
    type: INTAKE_QUESTION_TYPES.radio,
    options: YesNoNotSure,
  },
} as const;
