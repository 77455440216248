import { BUNDLES_CODES_VALUES } from "~/common/constants";
import { HEALTH_FACTORS_CODES_VALUES } from "~/common/utils";

export const healthFactorsTypeValues = HEALTH_FACTORS_CODES_VALUES;
export const bundlesTypeValues = BUNDLES_CODES_VALUES;
export const scheduleAppointmentTypeValues = "schedule-appointment";

export const orderTypeValues = [
  ...healthFactorsTypeValues,
  ...bundlesTypeValues,
  scheduleAppointmentTypeValues,
] as const;

export type OrderCodes = (typeof orderTypeValues)[number];
