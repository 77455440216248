import { useMutation } from "@tanstack/react-query";
import { generatePath, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { ErrorResponse, startScreening } from "~/patients/api";
import { ROUTES } from "~/patients/router";

export const useStartScreening = () => {
  const navigate = useNavigate();

  const { mutate: startScreeningMutation, isPending } = useMutation({
    mutationFn: (screening_id: string) => startScreening(screening_id),
    onSuccess: ({ id }) => {
      navigate(
        generatePath(ROUTES.screening, {
          id,
        }),
      );
    },
    onError: (error: ErrorResponse) => {
      const errorMessage =
        error.response?.status === 400
          ? "Previous sections must be completed before starting this section."
          : "An error occurred while starting this section. If the issue persists, please refresh the page.";

      toast.error(errorMessage);
    },
  });

  return { startScreeningMutation, isPending };
};
