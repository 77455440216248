import React from "react";

const EmptyBox = () => {
  return (
    <svg
      className="h-24 w-24"
      width="95"
      height="96"
      viewBox="0 0 95 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.0645 81.1305C5.6549 89.8919 11.5091 91.7156 15.6251 89.8919C23.5852 86.3651 44.2628 82.4245 65.4994 91.6871C93.6262 103.955 99.6107 85.4459 90.6341 66.7233C81.6575 48.0007 78.6148 29.0671 88.9631 18.6357C99.3115 8.20435 85.7696 -8.11408 61.7207 8.87227C46.1794 19.8495 34.7739 17.4052 28.6492 14.302C22.8768 11.3773 16.4573 8.31972 10.4427 10.7071C3.14479 13.604 0.26917 19.8847 11.3727 33.9158C27.1073 53.7991 -5.98387 61.4918 2.0645 81.1305Z"
        fill="#DEE9FF"
      />
      <path
        d="M16.6836 38.4507H72.5612V81.4239C72.5612 81.5114 72.5264 81.5953 72.4645 81.6572C72.4026 81.7191 72.3187 81.7539 72.2312 81.7539H17.0136C16.9261 81.7539 16.8421 81.7191 16.7802 81.6572C16.7184 81.5953 16.6836 81.5114 16.6836 81.4239V38.4507Z"
        fill="#97B8F7"
      />
      <path
        d="M58.5433 21.6104H32.5614L16.6836 38.4504H72.5612L58.5433 21.6104Z"
        fill="#418DF9"
      />
      <path
        d="M21.9761 14.874L32.5613 21.6101L16.6835 38.4502L4.65479 29.3084L21.9761 14.874Z"
        fill="#699CFF"
      />
      <path
        d="M69.1284 14.874L58.5432 21.6101L72.5611 38.4502L86.4497 29.3084L69.1284 14.874Z"
        fill="#699CFF"
      />
      <path
        d="M39.3874 15.2188C39.6861 15.0771 39.9185 14.8255 40.036 14.5165C40.1535 14.2076 40.1471 13.8651 40.0181 13.5608L35.2562 2.33232C35.1888 2.17327 35.0899 2.02951 34.9654 1.90967C34.841 1.78983 34.6936 1.69639 34.5322 1.63498C34.3707 1.57356 34.1985 1.54543 34.0258 1.55228C33.8532 1.55913 33.6838 1.60081 33.5277 1.67483L32.6103 2.10983C32.4542 2.18385 32.3147 2.28866 32.2001 2.41797C32.0856 2.54729 31.9984 2.69843 31.9437 2.86231C31.8891 3.0262 31.8681 3.19943 31.8822 3.37162C31.8962 3.5438 31.9449 3.71137 32.0253 3.86425L37.7045 14.6576C37.8584 14.9502 38.1195 15.1719 38.4331 15.2765C38.7467 15.3811 39.0886 15.3604 39.3874 15.2188Z"
        fill="#418DF9"
      />
      <path
        d="M45.3574 14.1302C45.5736 14.1148 45.7755 14.0165 45.9209 13.8557C46.0663 13.695 46.1439 13.4843 46.1376 13.2677L45.9024 5.274C45.8991 5.16077 45.8729 5.04938 45.8254 4.94654C45.7779 4.84369 45.7101 4.75151 45.6261 4.67553C45.5421 4.59955 45.4436 4.54135 45.3365 4.50441C45.2294 4.46748 45.1159 4.45258 45.003 4.46061L44.3388 4.50793C44.2258 4.51597 44.1157 4.54677 44.0149 4.59846C43.9142 4.65016 43.8249 4.72169 43.7525 4.80875C43.6801 4.89582 43.6261 4.99662 43.5936 5.10511C43.5611 5.2136 43.551 5.32753 43.5636 5.44005L44.463 13.3865C44.4873 13.6019 44.594 13.7996 44.7607 13.9382C44.9274 14.0768 45.1412 14.1456 45.3574 14.1302Z"
        fill="#418DF9"
      />
      <path
        d="M49.9663 17.0851C50.5609 17.4289 51.3957 17.1134 51.8587 16.3698L60.08 3.16631C60.5711 2.37754 60.4547 1.41618 59.824 1.05154L58.9449 0.543337C58.3142 0.178621 57.4229 0.557328 56.9844 1.37645L49.6421 15.0881C49.2286 15.8603 49.3716 16.7412 49.9663 17.0851Z"
        fill="#418DF9"
      />
    </svg>
  );
};

export default EmptyBox;
