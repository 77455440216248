import { ChangeEvent, useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { z } from "zod";

import { FormStateBottomBar } from "~/common/components";
import { updatePatientData } from "~/patients/api/services/settingsService";
import { PRONOUNS, RELATIONSHIPS } from "~/patients/constants";
import { useFormatHook } from "~/patients/hooks";
import { useUiStore } from "~/patients/stores";
import { updatePatientDataSchema } from "~/patients/utils";
import type {
  CurrentPatientData,
  PatientInformation,
  PronounsOptions,
  UpdateCurrentPatientData,
} from "~/patients/utils/types";
import Address, { AddressesSchemaType } from "./Address";
import DriversLicense from "./DriversLicense";
import EmergencyContact, {
  EmergencyContactsSchemaType,
} from "./EmergencyContact";
import { MyPharmacies } from "./myPharmacies/MyPharmacies";
import UserDetails from "./UserDetails";

export type AccountSchemaType = z.infer<typeof updatePatientDataSchema>;

interface AccountProps {
  currentPatient: PatientInformation;
}

const Account = ({ currentPatient }: AccountProps) => {
  const [driversLicense, setDriversLicense] = useState<string | undefined>();
  const { setUnsavedPatientAccountChanges } = useUiStore();
  const { formatPhoneNumber, transformedPatientData } = useFormatHook();
  useEffect(() => {
    setDriversLicense(currentPatient?.id_photo_path);
  }, [currentPatient]);

  const customPronouns =
    transformedPatientData(currentPatient).pronouns?.customPronouns;

  const {
    formState: { errors, isDirty, isSubmitSuccessful },
    handleSubmit,
    register,
    control,
    watch,
    reset,
    setValue,
  } = useForm<AccountSchemaType>({
    defaultValues: transformedPatientData(currentPatient),
    resolver: zodResolver(updatePatientDataSchema),
  });

  const registerPhoneNumberField = (
    fieldName: "emergency_contacts.0.phone_number" | "phone_number",
  ) => {
    register(fieldName, {
      setValueAs: (value: string) => formatPhoneNumber(value),
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        const formattedValue = formatPhoneNumber(e.target.value);
        setValue(fieldName, formattedValue);
      },
    });
  };

  useEffect(() => {
    registerPhoneNumberField("emergency_contacts.0.phone_number");
    registerPhoneNumberField("phone_number");
  }, []);

  const steps = [
    {
      render: () => (
        <UserDetails
          control={control}
          errors={errors}
          register={register}
          watch={watch}
          selectedPronoun={watch("pronouns.pronouns")}
          customPronouns={customPronouns}
        />
      ),
    },
    {
      render: () => (
        <Address
          register={(data: keyof AddressesSchemaType) =>
            register(`addresses.0.${data}`)
          }
          errors={errors.addresses?.[0]}
          control={control}
        />
      ),
    },
    {
      render: () => (
        <EmergencyContact
          errors={errors.emergency_contacts?.[0]}
          register={(data: keyof EmergencyContactsSchemaType) =>
            register(`emergency_contacts.0.${data}`)
          }
          control={control}
          otherRelationship={
            watch("emergency_contacts.0.relationship") === RELATIONSHIPS.OTHER
          }
        />
      ),
    },
    {
      render: () => <MyPharmacies />,
    },
    {
      render: () => (
        <DriversLicense
          driversLicense={driversLicense}
          setDriversLicense={setDriversLicense}
        />
      ),
    },
  ];

  const queryClient = useQueryClient();

  const { mutate: updatePatientDataMutation } = useMutation({
    mutationFn: ({ userData }: { userData: UpdateCurrentPatientData }) =>
      updatePatientData(userData),
    onError: () =>
      toast.error(
        "There was an issue updating your data. Please try again later.",
      ),
    onSuccess: (data: CurrentPatientData) => {
      queryClient
        .invalidateQueries({ queryKey: ["getCurrentPatient"] })
        .catch(() => {
          toast.error("Failed to refresh patient data.");
        });
      setUnsavedPatientAccountChanges(false);
      const patientData = transformedPatientData(data);
      setTimeout(() => reset(patientData), 3000);
    },
  });

  const onSubmit: SubmitHandler<z.infer<typeof updatePatientDataSchema>> = (
    data,
  ) => {
    let selectedPronouns = data.pronouns?.pronouns;
    if (
      data.pronouns?.pronouns === PRONOUNS.other &&
      data.pronouns?.customPronouns
    ) {
      selectedPronouns = data.pronouns.customPronouns;
    }
    const parts = selectedPronouns?.split("/");
    const pronouns: PronounsOptions | null = parts
      ? ({
          subject: parts[0],
          object: parts[1],
          possessive: parts[2],
        } as PronounsOptions)
      : null;

    const contact = {
      ...data.emergency_contacts[0],
      relationship:
        data.emergency_contacts[0].relationship === RELATIONSHIPS.OTHER &&
        data.emergency_contacts[0].customRelationship
          ? data.emergency_contacts[0].customRelationship
          : data.emergency_contacts[0].relationship,
    };

    const userData = {
      ...data,
      pronouns,
      emergency_contacts: [{ ...contact }],
    };
    updatePatientDataMutation({ userData });
  };

  useEffect(() => {
    setUnsavedPatientAccountChanges(isDirty);
  }, [isDirty, setUnsavedPatientAccountChanges]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="relative flex w-full flex-col items-center gap-6 md:gap-8"
    >
      {steps.map((step, index) => (
        <div
          className="flex w-full items-center justify-center px-6"
          key={index}
        >
          {step.render()}
        </div>
      ))}
      <FormStateBottomBar
        successMessage="Account information updated"
        isDirty={isDirty}
        isSubmitSuccessful={isSubmitSuccessful}
        handleCancel={() => reset()}
      />
    </form>
  );
};

export default Account;
