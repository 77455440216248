import { UseFormRegister, UseFormWatch } from "react-hook-form";

import { ErrorText } from "~/common/components";
import RadioButtonOption from "~/patients/components/ui/RadioButtonOption";
import {
  SymptomRadioForm,
  SymptomsFormValues,
} from "~/patients/utils/consultations/intake";

interface RadioButtonSymptomProps {
  symptom: SymptomRadioForm;
  register: UseFormRegister<SymptomsFormValues>;
  watch: UseFormWatch<SymptomsFormValues>;
  errorMessage?: string;
}

export const RadioButtonSymptom = ({
  symptom,
  errorMessage,
  watch,
  register,
}: RadioButtonSymptomProps) => {
  const { value, options } = symptom;
  return (
    <div className="flex flex-col gap-2">
      {options?.map((option, index) => (
        <RadioButtonOption
          key={index}
          label={option.label}
          value={option.value}
          isSelected={watch(value) === option.value}
          {...register(value)}
        />
      ))}
      {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
    </div>
  );
};
