import { CONSULTATIONS_INPUTS_TYPES } from "~/common/utils/consultations/shared";
import { PronounsOptions } from "~/patients";
import {
  INTAKE_QUESTION_CODES,
  INTAKE_QUESTION_TYPES,
} from "../intakeQuestionsData";

const TEXT_QUESTION_INPUT = [
  {
    id: "1",
    placeholder: "Enter your first thought",
    value: "value" as const,
    type: CONSULTATIONS_INPUTS_TYPES.text,
    required: true,
  },
];

export const textQuestionsWithPronouns = {
  [INTAKE_QUESTION_CODES.feel_about_partner]: {
    title: (pronouns?: PronounsOptions) =>
      `${pronouns?.possessive} spouse/partner is...`,
    type: INTAKE_QUESTION_TYPES.text,
    inputs: TEXT_QUESTION_INPUT,
  },
  [INTAKE_QUESTION_CODES.feel_about_family]: {
    title: (pronouns?: PronounsOptions) =>
      `${pronouns?.possessive} family makes ${pronouns?.object} feel...`,
    type: INTAKE_QUESTION_TYPES.text,
    inputs: TEXT_QUESTION_INPUT,
  },
  [INTAKE_QUESTION_CODES.thought_about_finances]: {
    title: (pronouns?: PronounsOptions) =>
      `When ${pronouns?.subject} thought about finances, ${pronouns?.subject}...`,
    type: INTAKE_QUESTION_TYPES.text,
    inputs: TEXT_QUESTION_INPUT,
  },
  [INTAKE_QUESTION_CODES.feel_about_money]: {
    title: (pronouns?: PronounsOptions) =>
      `Money makes ${pronouns?.object} feel...`,
    type: INTAKE_QUESTION_TYPES.text,
    inputs: TEXT_QUESTION_INPUT,
  },
  [INTAKE_QUESTION_CODES.feel_about_work]: {
    title: (pronouns?: PronounsOptions) =>
      `Work made ${pronouns?.object} feel...`,
    type: INTAKE_QUESTION_TYPES.text,
    inputs: TEXT_QUESTION_INPUT,
  },

  [INTAKE_QUESTION_CODES.faced_with_challenge]: {
    title: (pronouns?: PronounsOptions) =>
      `When ${pronouns?.subject} was faced with a challenging job or task, ${pronouns?.subject}...`,
    type: INTAKE_QUESTION_TYPES.text,
    inputs: TEXT_QUESTION_INPUT,
  },
  [INTAKE_QUESTION_CODES.feel_lonely]: {
    title: (pronouns?: PronounsOptions) =>
      ` When ${pronouns?.subject} felt lonely, ${pronouns?.subject}...`,
    type: INTAKE_QUESTION_TYPES.text,
    inputs: TEXT_QUESTION_INPUT,
  },
  [INTAKE_QUESTION_CODES.thought_about_friends]: {
    title: (pronouns?: PronounsOptions) =>
      ` When ${pronouns?.subject} thought about ${pronouns?.possessive} friends, ${pronouns?.subject}...`,
    type: INTAKE_QUESTION_TYPES.text,
    inputs: TEXT_QUESTION_INPUT,
  },
  [INTAKE_QUESTION_CODES.enjoyed_most]: {
    title: (pronouns?: PronounsOptions) =>
      `What ${pronouns?.subject} enjoyed most was...`,
    type: INTAKE_QUESTION_TYPES.text,
    inputs: TEXT_QUESTION_INPUT,
  },
  [INTAKE_QUESTION_CODES.to_relax]: {
    title: (pronouns?: PronounsOptions) => `To relax, ${pronouns?.subject}...`,
    type: INTAKE_QUESTION_TYPES.text,
    inputs: TEXT_QUESTION_INPUT,
  },
  [INTAKE_QUESTION_CODES.health_makes_feel]: {
    title: (pronouns?: PronounsOptions) =>
      `Health makes ${pronouns?.object} feel...`,
    type: INTAKE_QUESTION_TYPES.text,
    inputs: TEXT_QUESTION_INPUT,
  },
  [INTAKE_QUESTION_CODES.thought_medical_condition]: {
    title: (pronouns?: PronounsOptions) =>
      `When ${pronouns?.subject} thought about ${pronouns?.possessive} medical condition, ${pronouns?.subject}...`,
    type: INTAKE_QUESTION_TYPES.text,
    inputs: TEXT_QUESTION_INPUT,
  },
  [INTAKE_QUESTION_CODES.held_baby]: {
    title: (pronouns?: PronounsOptions) =>
      `When ${pronouns?.subject} held ${pronouns?.possessive} baby, ${pronouns?.subject}...`,
    type: INTAKE_QUESTION_TYPES.text,
    inputs: TEXT_QUESTION_INPUT,
  },
  [INTAKE_QUESTION_CODES.being_mother]: {
    title: "Being mother is...",
    type: INTAKE_QUESTION_TYPES.text,
    inputs: TEXT_QUESTION_INPUT,
  },
  [INTAKE_QUESTION_CODES.future_is]: {
    title: (pronouns?: PronounsOptions) =>
      `${pronouns?.subject} feels ${pronouns?.possessive} future is...`,
    type: INTAKE_QUESTION_TYPES.text,
    inputs: TEXT_QUESTION_INPUT,
  },
  [INTAKE_QUESTION_CODES.most_of_the_time_feels]: {
    title: (pronouns?: PronounsOptions) =>
      ` Most of the time ${pronouns?.subject} feels...`,
    type: INTAKE_QUESTION_TYPES.text,
    inputs: TEXT_QUESTION_INPUT,
  },
  [INTAKE_QUESTION_CODES.does_whatever]: {
    title: (pronouns?: PronounsOptions) =>
      `Whatever ${pronouns?.subject} does, ${pronouns?.subject}...`,
    type: INTAKE_QUESTION_TYPES.text,
    inputs: TEXT_QUESTION_INPUT,
  },
} as const;
