import { z } from "zod";

import { Statuses } from "~/common/utils/types/checkInTypes";

export const radioButtonSchema = z.object({
  answer: z.string().refine((val) => val, {
    message: "Please select an answer to continue.",
  }),
  further_description: z.string().optional(),
});

export const statusSchema = z.object({
  answer: z.enum(Statuses, {
    errorMap: () => ({ message: "Please select an answer to continue." }),
  }),
});

export const weightSchema = z.object({
  weight: z
    .number()
    .min(0, "Please enter a valid weight")
    .max(600, "Please enter a valid weight"),
  weightUnit: z.enum(["lbs", "kgs"]),
});

export const fileSchema = z.object({
  file: z.number().gte(1),
});

export const audioSchema = z.object({
  audioSrc: z.string(),
  audioDuration: z
    .number()
    .min(
      15,
      "Audio duration should be at least 15 seconds, please record a new one.",
    )
    .optional(),
});

export const heartRateSchema = z.object({
  heartRate: z
    .number()
    .min(0, "Please enter a valid heart rate")
    .max(200, "Please enter a valid heart rate"),
});

export const vitalSignsSchema = z
  .object({
    systolicValue: z
      .number()
      .min(0, "Please enter a valid systolic value")
      .max(300, "Please enter a valid systolic value"),
    diastolicValue: z
      .number()
      .min(0, "Please enter a valid diastolic value")
      .max(200, "Please enter a valid diastolic value"),
    heartRate: z
      .number()
      .min(0, "Please enter a valid heart rate")
      .max(500, "Please enter a valid heart rate"),
  })
  .refine((data) => data.systolicValue >= data.diastolicValue, {
    path: ["systolicValue"],
    message: "Systolic value must be grater than diastolic value.",
  });
