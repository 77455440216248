import React from "react";

import { Table } from "~/common/components/table";
import { TableHead } from "~/common/components/table/TableHead";
import { TableHeader } from "~/common/components/table/TableHeader";
import { PatientMedicationDetail } from "~/common/types";
import { PatientMedicationTableRow } from "./PatientMedicationTableRow";

interface PatientMedicationTableProps {
  patientMedicationDetail: PatientMedicationDetail;
}

export const PatientMedicationTable = ({
  patientMedicationDetail,
}: PatientMedicationTableProps) => {
  return (
    <div className="w-full overflow-auto rounded-xl border border-gray-300">
      <Table>
        <TableHead className="border-neutral-700 bg-neutral-25">
          <tr>
            <TableHeader>Strength</TableHeader>
            <TableHeader>Take</TableHeader>
            <TableHeader>Frequency</TableHeader>
            <TableHeader>Prescribed days</TableHeader>
          </tr>
        </TableHead>
        <tbody className="bg-white text-sm text-gray-70">
          <PatientMedicationTableRow {...patientMedicationDetail} />
        </tbody>
      </Table>
    </div>
  );
};
