import { PharmacyQueryType } from "~/common/utils/types/pharmacyTypes";
import { useFormatHook } from "~/patients/hooks/useFormatHook";

export const useGetPharmacyDefaultValues = () => {
  const { formatPhoneNumber } = useFormatHook();

  const defaultValues = {
    pharmacy: {
      name: "",
      phone_number: "",
      address: {
        street: "",
        city: "",
        state: undefined,
        zipcode: "",
      },
    },
    is_default: false,
  };

  const getDefaultValues = (formData?: PharmacyQueryType) =>
    formData
      ? {
          ...formData,
          pharmacy: {
            ...formData.pharmacy,
            phone_number: formatPhoneNumber(formData.pharmacy.phone_number),
            address: {
              ...formData.pharmacy.address,
              zipcode: formData.pharmacy.address.zipcode.toString(),
            },
          },
        }
      : defaultValues;

  return { getDefaultValues };
};
