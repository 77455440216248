import { Outlet, useNavigate } from "react-router-dom";

import NavMenu from "~/common/components/ui/NavMenu";
import { HeaderInfo } from "../components";
import { useGetCurrentPatient } from "../hooks/queries/useGetCurrentPatient";
import { useUiStore } from "../stores";
import { useNavMenuStore } from "../stores/useNavMenuStore";
import { getHomeLayoutMenuOptions } from "../utils/getHomeLayoutMenuOptions";

export const HomeLayout = () => {
  const { data: user } = useGetCurrentPatient();
  const { setSideBarOpen } = useUiStore();
  const { totalToDoCheckIns } = useNavMenuStore();
  const navigate = useNavigate();

  const menuOptions = getHomeLayoutMenuOptions(navigate, totalToDoCheckIns);

  return (
    <div className="relative flex h-screen-calculated w-full flex-col text-sm">
      <NavMenu
        headerInfo={
          <HeaderInfo
            subtitle={`Welcome back, ${user?.name}`}
            title="How are you doing?"
          />
        }
        menuOptions={menuOptions}
        handleOpenSideBar={() => setSideBarOpen(true)}
      />
      <Outlet />
    </div>
  );
};
