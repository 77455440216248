import { twMerge } from "tailwind-merge";

import { IconWrapper } from "~/patients/ui";

interface DotIconProps {
  className?: string;
}

export const DotIcon = ({ className }: DotIconProps) => (
  <IconWrapper className={twMerge("size-3", className)}>
    <svg viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9.40002" cy="9.5" r="9" fill="currentColor" />
    </svg>
  </IconWrapper>
);
