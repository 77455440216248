import {
  Control,
  FieldPath,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";

import Input from "~/common/components/ui/Input";
import Select from "~/common/components/ui/select/Select";
import { DIRECTIONS } from "~/common/constants";
import {
  formatPhoneNumberField,
  PharmacyFormValuesType,
  SignUpPatientSchemaType,
} from "~/patients/utils";
import { getUsaStates } from "~/patients/utils/getUsaStates";
import { FormErrors } from "~/patients/utils/types/forms";
import SignUpFormLayout from "../SignUpFormLayout";
import SignUpProgress from "../SignUpProgress";

interface DefaultPharmacyFormProps<T extends FieldValues> {
  handleGoBack: () => void;
  totalSteps: number;
  register: UseFormRegister<SignUpPatientSchemaType>;
  onNext: () => Promise<void>;
  step: number;
  pharmacyStateKey: FieldPath<T>;
  errors?: FormErrors<PharmacyFormValuesType>;
  control?: Control<T>;
}

export const DefaultPharmacyForm = <T extends FieldValues>({
  handleGoBack,
  totalSteps,
  onNext,
  register,
  step,
  pharmacyStateKey,
  errors,
  control,
}: DefaultPharmacyFormProps<T>) => {
  return (
    <>
      <SignUpProgress step={step} totalSteps={totalSteps} />

      <SignUpFormLayout
        title="Add your default pharmacy"
        description="You’ll be able to add/modify your pharmacies in the settings page. "
        handleGoBack={handleGoBack}
        handleData={onNext}
      >
        <Input
          id="name"
          label="Pharmacy name"
          {...register("pharmacy.name")}
          error={errors?.name?.message}
        />

        <Input
          id="phoneNumber"
          label="Phone number"
          {...register("pharmacy.phone_number")}
          {...formatPhoneNumberField<SignUpPatientSchemaType>(
            "pharmacy.phone_number",
            register,
          )}
          error={errors?.phone_number?.message}
        />

        <Input
          id="street"
          label="Street number and name"
          {...register("pharmacy.address.street")}
          error={errors?.address?.street?.message}
        />

        <div className="flex flex-col gap-4 md:flex-row md:gap-2">
          <Input
            id="city"
            label="City"
            {...register("pharmacy.address.city")}
            error={errors?.address?.city?.message}
          />

          <Select
            error={errors?.address?.state?.message}
            direction={DIRECTIONS.top}
            label="State"
            placeholder={"Select State"}
            id={pharmacyStateKey}
            options={getUsaStates()}
            control={control}
            containerClassName="gap-2"
          />

          <Input
            id="zipcode"
            label="ZIP code"
            {...register("pharmacy.address.zipcode")}
            error={errors?.address?.zipcode?.message}
          />
        </div>
      </SignUpFormLayout>
    </>
  );
};
