import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

import { Styled } from "../utils";
import { WarningIcon } from "./icons";

export const WarningMessage = ({
  children,
  className,
}: PropsWithChildren<Styled>) => (
  <div className="flex gap-2">
    <div className="flex w-fit py-1">
      <WarningIcon />
    </div>
    <p className={twMerge("text-sm leading-5 text-gray-70", className)}>
      {children}
    </p>
  </div>
);
