import { ORDER, ORDER_BY } from "~/common/constants";
import { PatientMedication, PatientMedicationStatus } from "~/common/types";
import { api, PaginatedServiceResponse } from "./axios";

export const getPatientMedications = async (
  status: PatientMedicationStatus,
) => {
  const { data } = await api.get<PaginatedServiceResponse<PatientMedication[]>>(
    `/v1/patients/medications`,
    {
      params: {
        page_size: 100,
        page: 1,
        status,
        order_by: ORDER_BY.genericName,
        order: ORDER.ascendant,
      },
    },
  );

  return data;
};

export const getPatientMedication = async (id: string) => {
  const { data } = await api.get<PatientMedication>(
    `/v1/patients/medications/${id}`,
  );

  return data;
};
