import React from "react";
import { twMerge } from "tailwind-merge";

import { DotIcon } from "../icons/DotIcon";

interface SignUpProgressProps {
  totalSteps: number;
  step: number;
  className?: string;
}

const SignUpProgress = ({
  step,
  totalSteps,
  className,
}: SignUpProgressProps) => {
  const array = Array.from({ length: totalSteps - 1 }, (_, index) => index + 1);
  return (
    <div className="flex h-fit w-full items-center px-8 py-8 md:w-2/3 md:p-0 md:pt-10">
      <DotIcon className={twMerge("text-blue-500", className)} />
      {array.map((_, index) => (
        <React.Fragment key={index}>
          <div
            className={twMerge(
              "h-0.5 w-full bg-blue-300",
              index < step - 1 ? "bg-blue-500" : "bg-blue-300",
            )}
          />
          <DotIcon
            className={twMerge(
              index < step - 1 ? "text-blue-500" : "text-blue-300",
              className,
            )}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

export default SignUpProgress;
