import { z } from "zod";

import { pharmacySchema } from "./pharmacySchema";

const baseSchema = z.object({
  medication: z.string(),
  comment: z.string().optional(),
  pharmacy: z.string({ required_error: "Please select a pharmacy" }),
});

const existingPharmacySchema = baseSchema.extend({
  isNewPharmacy: z.literal(false),
});

export const newPharmacySchema = baseSchema.extend({
  isNewPharmacy: z.literal(true),
  newPharmacy: pharmacySchema,
});

export const requestScriptSchema = z.discriminatedUnion("isNewPharmacy", [
  existingPharmacySchema,
  newPharmacySchema,
]);

export const baseResolveIssueSchema = z.object({
  medication: z.string(),
  reason: z
    .string({ required_error: "Please select a pharmacy" })
    .min(1, "Please provide a reason.")
    .max(1000, "Please ensure the note does not exceed 1000 characters."),
  pharmacy: z.string({ required_error: "Please select a pharmacy" }),
  pre_authorization_request: z.boolean(),
});

const resolveIssueExistingPharmacySchema = baseResolveIssueSchema.extend({
  isNewPharmacy: z.literal(false),
});

export const resolveIssueNewPharmacySchema = baseResolveIssueSchema.extend({
  isNewPharmacy: z.literal(true),
  newPharmacy: pharmacySchema,
});

export const resolveIssueSchema = z.discriminatedUnion("isNewPharmacy", [
  resolveIssueExistingPharmacySchema,
  resolveIssueNewPharmacySchema,
]);
