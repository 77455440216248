import {
  SCREENING_QUESTION_CODES,
  SCREENING_QUESTION_TYPES,
} from "../screeningQuestionsData";

export const audioQuestions = {
  [SCREENING_QUESTION_CODES.how_can_we_help]: {
    title: "What's going on and how can I help you?",
    type: SCREENING_QUESTION_TYPES.audio,
    minDuration: 15,
    maxDuration: 60,
  },
} as const;
