import BackArrow from "../icons/BackArrow";
import CrossIcon from "../icons/CrossIcon";

interface CloseButtonProps {
  onClose: () => void;
}

export const CloseButton = ({ onClose }: CloseButtonProps) => (
  <button
    onClick={onClose}
    className="flex w-full items-center justify-start gap-1 md:justify-end"
  >
    <CrossIcon className="hidden md:flex" />
    <BackArrow className="flex w-5 md:hidden" />
    <p className="flex w-fit font-medium text-gray-70 md:hidden">Back</p>
  </button>
);
