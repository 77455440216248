import { useQueryClient } from "@tanstack/react-query";

import { MedicationStatusModal } from "~/common/types";
import { RequestScriptFormValuesType } from "~/patients/utils";
import { useCreateScript } from "./useCreateScript";

export const useOnSubmitScript = (
  handleClose: () => void,
  setMedicationStatusModal: (value: MedicationStatusModal) => void,
) => {
  const queryClient = useQueryClient();

  const handleOnSuccess = () => {
    void queryClient.invalidateQueries({ queryKey: ["getMyMedications"] });
    setMedicationStatusModal({
      isOpen: true,
      isSuccess: true,
    });
    handleClose();
  };

  const handleOnError = () =>
    setMedicationStatusModal({
      isOpen: true,
      isSuccess: false,
    });

  const {
    createScriptMutation,
    createScriptWithPharmacy,
    isPendingCreateScript,
  } = useCreateScript(handleOnSuccess, handleOnError);

  const requestScript = (
    formData: RequestScriptFormValuesType,
    medicationId: string,
  ) => {
    formData.isNewPharmacy
      ? createScriptWithPharmacy({
          pharmacy: formData.newPharmacy,
          medicationId: medicationId,
          comment: formData.comment,
        })
      : createScriptMutation({
          patient_medication_id: medicationId,
          patient_pharmacy_id: formData.pharmacy,
          comment: formData.comment,
        });
  };

  return { requestScript, isPendingCreateScript };
};
