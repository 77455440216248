import { differenceInDays, parseISO } from "date-fns";

export const getOverdueText = (date: string) => {
  const today = new Date();
  const diffInDays = differenceInDays(parseISO(date), today);

  switch (diffInDays) {
    case 0:
      return "Available until today";
    case 1:
      return "Available until tomorrow";
    default:
      return `Available for ${diffInDays} days`;
  }
};
