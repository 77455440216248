export const PATIENT_MEDICATION_STATUS = {
  all: "all",
  active: "active",
  discontinued: "discontinued",
  paused: "paused",
} as const;

export const MEDICATION_TYPES = {
  benzodiazepine: "benzodiazepine",
  antidepressant: "antidepressant",
  psychostimulant: "psychostimulant",
  sedativeHypnotic: "sedative-hypnotic",
  moodStabilizer: "mood-stabilizer",
  other: "other",
};
