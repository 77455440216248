import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { getPreviousIntakeQuestion } from "~/patients/api";

export const useGetIntakePreviousQuestion = () => {
  const queryClient = useQueryClient();

  const { mutate: goBackOneQuestionMutation, isPending } = useMutation({
    mutationFn: (patientIntakeId: string) =>
      getPreviousIntakeQuestion(patientIntakeId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["getCurrentIntakeQuestion"],
      });
    },
    onError: () =>
      toast.error(
        "Unable to go back to the previous question. If the issue persists, please refresh the page.",
      ),
  });

  return { goBackOneQuestionMutation, isPending };
};
