import { APPOINTMENT_DURATIONS } from "~/common/constants";
import { PatientBundleData } from "~/common/types";
import {
  QuestionCodes,
  QuestionTypes,
} from "~/common/utils/types/questionTypes";
import { FeedQuestion } from "~/providers/utils/types/interactions";
import { OrderCodes } from "~/providers/utils/types/orderTypeValues";
import { InterfaceWithId } from "./types";

export const INPUT_TYPES = {
  free_text: "free-text",
  yes_no_radio: "yes-no-radio",
  file: "file",
  weight: "weight",
  heart_rate: "heart-rate",
  blood_pressure: "blood-pressure",
  status: "status",
  audio: "audio",
} as const;

export const STATUS_TYPES = {
  improved: "improved",
  same: "same",
  worse: "worse",
} as const;

export const Statuses = [
  STATUS_TYPES.improved,
  STATUS_TYPES.same,
  STATUS_TYPES.worse,
] as const;

export const CHECKIN_STATUS = {
  pending: "pending",
  in_progress: "in_progress",
  completed: "completed",
  reviewed: "reviewed",
  missed: "missed",
  expired: "expired",
} as const;

export const INTERACTION_CREATION_TYPES = {
  patient_created: "patient_created",
  special_request: "special_request",
  schedule: "schedule",
  bundle: "bundle",
} as const;

export const PATIENT_INTERACTION_TYPES = {
  checkin: "checkin",
  schedule: "schedule",
  bundle: "bundle",
} as const;

export const INTERVALS = {
  monthly: "monthly",
  weekly: "weekly",
  daily: "daily",
} as const;

export const CreatedByProviderInteractions = [
  INTERACTION_CREATION_TYPES.schedule,
  INTERACTION_CREATION_TYPES.bundle,
  INTERACTION_CREATION_TYPES.special_request,
];

export const InteractionTypes = [
  ...CreatedByProviderInteractions,
  INTERACTION_CREATION_TYPES.patient_created,
];

export type InputsTypes = (typeof INPUT_TYPES)[keyof typeof INPUT_TYPES];
export type StatusTypes = keyof typeof STATUS_TYPES;
export type CheckinStatus = keyof typeof CHECKIN_STATUS;
export type InteractionCreationTypes = keyof typeof INTERACTION_CREATION_TYPES;
export type PatientInteractionsTypes = keyof typeof PATIENT_INTERACTION_TYPES;
export type Intervals = keyof typeof INTERVALS;

export interface PatientCheckInInput {
  id: string;
  mandatory: boolean;
  type: InputsTypes;
  presigned_url?: string | null;
}

export interface PatientCheckInQuestion {
  order: number;
  inputs: PatientCheckInInput[];
  code: QuestionCodes;
  type: QuestionTypes;
  id: string;
  title?: string;
  subtitle?: string;
}

export interface BasePatientData {
  id: string;
  name: string;
  last_name: string;
}

export interface WeightAnswer {
  answer_type: "weight";
  value?: number;
  unit?: "lbs" | "kgs";
}

export interface HeartRateAnswer {
  answer_type: typeof INPUT_TYPES.heart_rate;
  value?: number;
}

export interface BloodPressureAnswer {
  answer_type: typeof INPUT_TYPES.blood_pressure;
  systolic?: number;
  diastolic?: number;
}
export interface FreeTextAnswer {
  answer_type: "free-text";
  value?: string;
}

export interface YesNoRadioAnswer {
  answer_type: "yes-no-radio";
  value?: string;
}

export interface AudioAnswer {
  answer_type: typeof INPUT_TYPES.audio;
  file?: File;
  path?: string;
  duration?: number;
  audio_src?: string;
  presigned_url?: string;
  transcribed_at?: string;
  transcribed_text?: string;
  transcription_status?: string;
}

export interface FileAnswer {
  fileName: string;
  answer_type: typeof INPUT_TYPES.file;
  file?: File;
  path?: string;
  presigned_url?: string;
}

export interface StatusAnswer {
  answer_type: "status";
  value: StatusTypes;
}

export type AnswerValueType =
  | FreeTextAnswer
  | WeightAnswer
  | YesNoRadioAnswer
  | FileAnswer
  | AudioAnswer
  | HeartRateAnswer
  | BloodPressureAnswer
  | StatusAnswer;

export interface InputAnswer {
  id?: string;
  answer?: AnswerValueType;
}
export interface QuestionAnswer {
  question_id: string;
  inputs: InputAnswer[];
}

export const HEAlTH_FACTORS_CODES = {
  weight: "weight",
  basic_labs: "basic-labs",
  blood_pressure_heart_rate: "blood-pressure-and-heart-rate",
  blood_pressure_heart_rate_recurrent:
    "blood-pressure-and-heart-rate-recurrent",
  status_check: "status-check",
} as const;

export const SIDE_EFFECTS_CODES = {
  antidepressants_side_effects: "antidepressants-side-effects",
  benzodiazepines_side_effects: "benzodiazepines-side-effects",
  psychostimulants_side_effects: "psychostimulants-side-effects",
} as const;

export type SideEffectCodes =
  (typeof SIDE_EFFECTS_CODES)[keyof typeof SIDE_EFFECTS_CODES];

export const SIDE_EFFECTS_CODES_VALUES = [
  SIDE_EFFECTS_CODES.antidepressants_side_effects,
  SIDE_EFFECTS_CODES.benzodiazepines_side_effects,
  SIDE_EFFECTS_CODES.psychostimulants_side_effects,
] as const;

export const HEALTH_FACTORS_CODES_VALUES = [
  HEAlTH_FACTORS_CODES.weight,
  HEAlTH_FACTORS_CODES.basic_labs,
  HEAlTH_FACTORS_CODES.blood_pressure_heart_rate,
  HEAlTH_FACTORS_CODES.blood_pressure_heart_rate_recurrent,
  HEAlTH_FACTORS_CODES.status_check,
] as const;

export type HealthFactorsCodes =
  (typeof HEAlTH_FACTORS_CODES)[keyof typeof HEAlTH_FACTORS_CODES];

export const CHECK_IN_CODES = {
  schedule_appointment: "schedule-appointment",
  ...SIDE_EFFECTS_CODES,
  ...HEAlTH_FACTORS_CODES,
} as const;

export const CHECK_IN_CODES_VALUES = [
  CHECK_IN_CODES.antidepressants_side_effects,
  CHECK_IN_CODES.benzodiazepines_side_effects,
  CHECK_IN_CODES.psychostimulants_side_effects,
  CHECK_IN_CODES.weight,
  CHECK_IN_CODES.basic_labs,
  CHECK_IN_CODES.blood_pressure_heart_rate,
  CHECK_IN_CODES.status_check,
] as const;

export type CheckInCodes = (typeof CHECK_IN_CODES)[keyof typeof CHECK_IN_CODES];

export const SideEffectsCheckinCodes: CheckInCodes[] = [
  CHECK_IN_CODES.antidepressants_side_effects,
  CHECK_IN_CODES.benzodiazepines_side_effects,
  CHECK_IN_CODES.psychostimulants_side_effects,
];

export const BloodPressureAndHeartRate: CheckInCodes[] = [
  CHECK_IN_CODES.blood_pressure_heart_rate,
];

export interface CheckInBase extends InterfaceWithId {
  code: CheckInCodes;
  med_type: string;
  title: string;
  subtitle?: string;
}

export interface PatientCheckIn extends CheckInBase {
  version: number;
  questions: PatientCheckInQuestion[];
  days_to_expiry: number;
  days_until_due: number;
  description?: string;
}

export interface PatientCheckInBase extends InterfaceWithId {
  status: string;
  start_date: string;
  due_date: string;
  expiration_date: string;
  completed_at: string;
  checkin: CheckInBase;
  extra_data: ExtraData;
  note?: string;
  patient_bundle?: PatientBundleData;
}

export interface PatientCheckInData extends PatientCheckInBase {
  patient: BasePatientData;
  checkin: PatientCheckIn;
  reviewed_at?: string;
  internal_note?: string;
}

export interface UploadedFile {
  question_id: string;
  input_id: string;
  presigned_url?: string;
  file?: File;
}
export interface CheckInFormProps {
  question: PatientCheckInQuestion;
  handleGoBack: (answer?: QuestionAnswer) => void;
  handleNext: (data: QuestionAnswer) => void;
  isLastQuestion: boolean;
  existingAnswer?: QuestionAnswer;
  isSubmitting?: boolean;
}
export interface CheckinFileFormProps extends CheckInFormProps {
  removeQuestionAnswerById: (questionId: string) => void;
}

export interface GetPresignedUrlData {
  patientCheckInId: string;
  questionId: string;
  inputId: string;
  fileName: string;
  file: File;
}
export interface CompletedAnswerData {
  question_id: string;
  input_id: string;
  question?: FeedQuestion;
  id: string;
  presigned_url: string | null;
  value: AnswerValueType;
}
export interface CheckInWithAnswers extends PatientCheckInData {
  answers: CompletedAnswerData[];
}

export const CHECK_IN_TYPES = {
  all: "all",
  bundles: "bundles",
  checkins: "checkins",
} as const;

export type CheckInType = (typeof CHECK_IN_TYPES)[keyof typeof CHECK_IN_TYPES];

export interface BasicCheckIn {
  id: string;
  code: OrderCodes;
  type: CheckInType;
}

export type AppointmentDuration =
  (typeof APPOINTMENT_DURATIONS)[keyof typeof APPOINTMENT_DURATIONS];
export interface ExtraData {
  duration: AppointmentDuration;
}
