import { generateDiscontinuationTitle } from "./medications/generateDiscontinuationTitle";
import { formatDate } from "./orders";

export const generateDatesRange = (
  start_date: string,
  discontinued_at?: string,
) => {
  const startedOn = `Started on ${formatDate(start_date)}`;
  if (!discontinued_at) return startedOn;
  return `${startedOn} - ${generateDiscontinuationTitle(discontinued_at)}`;
};
