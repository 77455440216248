import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

import TextArea from "~/common/components/ui/Textarea";
import { checkInUtilities } from "~/common/utils/checkIn/checkInUtilities";
import {
  CheckInFormProps,
  INPUT_TYPES,
  InputAnswer,
} from "~/common/utils/types/checkInTypes";
import RadioButtonOption from "~/patients/components/ui/RadioButtonOption";
import { questionTextByCodes } from "~/patients/constants";
import { radioButtonSchema } from "~/patients/utils/check-ins";
import { QuestionsLayout } from "../QuestionsLayout";

export type RadioButtonFormValues = z.infer<typeof radioButtonSchema>;

export const RadioButtonForm = ({
  question,
  handleGoBack,
  handleNext,
  existingAnswer,
  isLastQuestion,
  isSubmitting = false,
}: CheckInFormProps) => {
  const { getInputOfType, getAnswerOfType } = checkInUtilities();

  const defaultYesNoAnswer = getAnswerOfType(
    INPUT_TYPES.yes_no_radio,
    question.inputs,
    existingAnswer,
  );
  const defaultFurtherDescriptionAnswer = getAnswerOfType(
    INPUT_TYPES.free_text,
    question.inputs,
    existingAnswer,
  );

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
  } = useForm<RadioButtonFormValues>({
    resolver: zodResolver(radioButtonSchema),
    defaultValues: {
      answer: defaultYesNoAnswer?.value,
      further_description: defaultFurtherDescriptionAnswer?.value,
    },
  });

  const inputOptions = {
    freeText: {
      render: (key: string) => (
        <TextArea
          rows={6}
          key={key}
          containerClassName="gap-0"
          className="focus:border-blue-500"
          placeholder="Enter further description (optional)"
          id="further_description"
          {...register("further_description")}
        />
      ),
    },
    radioButton: {
      render: (options: string[]) =>
        options.map((option, index) => (
          <RadioButtonOption
            key={index}
            label={option}
            value={option}
            isSelected={watch("answer") === option}
            {...register("answer")}
          />
        )),
    },
  };

  const formatAnswer = (data: RadioButtonFormValues) => {
    const inputs: InputAnswer[] = [
      {
        id: getInputOfType(INPUT_TYPES.yes_no_radio, question.inputs)?.id,
        answer: {
          answer_type: INPUT_TYPES.yes_no_radio,
          value: data.answer,
        },
      },
    ];

    if (data.further_description) {
      inputs.push({
        id: getInputOfType(INPUT_TYPES.free_text, question.inputs)?.id,
        answer: {
          answer_type: INPUT_TYPES.free_text,
          value: data.further_description,
        },
      });
    }
    return {
      question_id: question.id,
      inputs,
    };
  };

  const saveAnswers: SubmitHandler<RadioButtonFormValues> = (data) => {
    handleNext(formatAnswer(data));
  };

  const goBack = () => {
    const data = getValues();
    handleGoBack(formatAnswer(data));
  };

  const radioInput = question.inputs.find(
    (input) => input.type === INPUT_TYPES.yes_no_radio,
  );

  const freeTextInput = question.inputs.find(
    (input) => input.type === INPUT_TYPES.free_text,
  );

  return (
    <QuestionsLayout
      question={questionTextByCodes[question.code]}
      handleNext={handleSubmit(saveAnswers)}
      handleBack={goBack}
      disableNext={!watch("answer")}
      errors={errors.answer?.message}
      isLastQuestion={isLastQuestion}
      isSubmitting={isSubmitting}
    >
      <>
        {radioInput && inputOptions.radioButton.render(["yes", "no"])}

        {watch("answer") &&
          freeTextInput &&
          inputOptions.freeText.render(freeTextInput.id)}
      </>
    </QuestionsLayout>
  );
};
