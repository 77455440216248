import {
  baseOptionSchema,
  toggleSchema,
  toggleTextExtraDataSchema,
} from "./baseSchemas";
import { SymptomBaseForm, SYMPTOMS_INPUT_TYPES } from "./symptomsBasicTypes";

export const foodWeightPreoccupationsSchema = baseOptionSchema
  .extend({
    food_binging: toggleTextExtraDataSchema,
    food_restricting: toggleSchema,
    purging: toggleSchema,
  })
  .refine(
    (data) => {
      if (data.toggled)
        return (
          data.food_binging.toggled ||
          data.food_restricting.toggled ||
          data.purging.toggled
        );

      return true;
    },
    {
      message:
        "Please select at least one pattern of preoccupation you experiencing.",
      path: ["toggled"],
    },
  );

export const foodWeightPreoccupationsForm: SymptomBaseForm = {
  label: "Preoccupation with weight or food",
  value: "symptoms.food_weight_preoccupations.toggled",
  type: SYMPTOMS_INPUT_TYPES.check,
  extra_data: {
    title:
      "What patterns of preoccupation with weight or food are you noticing?",
    children: [
      {
        label: "Food binging",
        value: "symptoms.food_weight_preoccupations.food_binging.toggled",
        type: SYMPTOMS_INPUT_TYPES.check,
        extra_data: {
          title: undefined,
          children: [
            {
              value:
                "symptoms.food_weight_preoccupations.food_binging.extra_data",
              label: undefined,
              type: SYMPTOMS_INPUT_TYPES.text,
              extra_data: undefined,
            },
          ],
        },
      },
      {
        label: "Food restricting",
        value: "symptoms.food_weight_preoccupations.food_restricting.toggled",
        type: SYMPTOMS_INPUT_TYPES.check,
        extra_data: undefined,
      },
      {
        label: "Purging",
        value: "symptoms.food_weight_preoccupations.purging.toggled",
        type: SYMPTOMS_INPUT_TYPES.check,
        extra_data: undefined,
      },
    ],
  },
};
