import { Button } from "~/common/components";
import { BUTTON_VARIANTS } from "~/common/constants";
import { IntakeStatusData } from "~/common/utils/consultations/intake/interfaces";
import { CheckListCardLayout } from "~/patients/components/consultations/shared/ui/cards/CheckListCardLayout";
import { useIntakeItem } from "~/patients/hooks/consultations";

interface IntakeCheckListCardProps {
  intake: IntakeStatusData;
  title: string;
}

export const IntakeCheckListCard = ({
  intake,
  title,
}: IntakeCheckListCardProps) => {
  const { id, duration, status } = intake;
  const {
    handleComplete,
    isUnavailable,
    isCompleted,
    isPending,
    isInProgress,
  } = useIntakeItem(id, status);

  const subtitle = isCompleted ? "Completed" : duration;
  const buttonLabel = isInProgress ? "Resume" : "Start";

  return (
    <CheckListCardLayout
      title={title}
      subtitle={subtitle}
      isCompleted={isCompleted}
      isUnavailable={isUnavailable}
      isPending={isPending}
    >
      {isPending && (
        <Button
          variant={BUTTON_VARIANTS.outline_black}
          size="sm"
          onClick={handleComplete}
        >
          {buttonLabel}
        </Button>
      )}
    </CheckListCardLayout>
  );
};
