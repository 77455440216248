import { FieldErrors, FieldValues } from "react-hook-form";

export const getErrors = <T extends FieldValues>(
  key: string,
  fieldErrors?: FieldErrors<T>,
): string | undefined =>
  key
    .split(".")
    .reduce<FieldErrors<T> | undefined>(
      (acc, key) =>
        acc && key in acc ? (acc[key] as FieldErrors<T>) : undefined,
      fieldErrors,
    )?.message as string | undefined;
