import {
  SCREENING_QUESTION_CODES,
  SCREENING_QUESTION_TYPES,
  YesNo,
} from "~/common/utils/consultations/screening";

export const yesNoQuestions = {
  [SCREENING_QUESTION_CODES.currently_seeing_therapist]: {
    title: "Are you currently seeing a therapist?",
    type: SCREENING_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [SCREENING_QUESTION_CODES.seen_therapist_past]: {
    title: "Have you ever seen a therapist in the past?",
    type: SCREENING_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [SCREENING_QUESTION_CODES.currently_working]: {
    title: "Are you currently working?",
    type: SCREENING_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [SCREENING_QUESTION_CODES.taking_any_medication]: {
    title: "Are you currently taking any medications?",
    type: SCREENING_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [SCREENING_QUESTION_CODES.seen_psychiatrist_in_the_past]: {
    title: "Have you seen a psychiatrist in the past?",
    type: SCREENING_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [SCREENING_QUESTION_CODES.been_hospitalized_for_psych_reasons]: {
    title: "Have you been hospitalized for psychiatric reasons in the past?",
    type: SCREENING_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [SCREENING_QUESTION_CODES.taken_psych_medications]: {
    title: "Have you taken psychiatric medications in the past?",
    type: SCREENING_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [SCREENING_QUESTION_CODES.symptoms_do_not_understand]: {
    title:
      "Have you ever experienced physical symptoms that you don’t understand?",
    type: SCREENING_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [SCREENING_QUESTION_CODES.enduring_symptoms_do_not_understand]: {
    title:
      "Have you ever experienced physical symptoms that are enduring or persistent and that you don’t understand?",
    type: SCREENING_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [SCREENING_QUESTION_CODES.symptoms_you_fear]: {
    title: "Have you ever experienced physical symptoms that you fear?",
    type: SCREENING_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [SCREENING_QUESTION_CODES.have_you_ever_lied]: {
    title:
      "Have you ever shaded the truth to get out of a difficult situation?",
    type: SCREENING_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [SCREENING_QUESTION_CODES.drug_use_caused_problems]: {
    title: "My drug use gets me into trouble.",
    type: SCREENING_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [SCREENING_QUESTION_CODES.drink_to_much]: {
    title: "I drink too much.",
    type: SCREENING_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [SCREENING_QUESTION_CODES.feeling_empty]: {
    title: "No matter what I do or who I am with, I feel empty.",
    type: SCREENING_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [SCREENING_QUESTION_CODES.people_leave_me]: {
    title: "People always leave me, and I don't know why.",
    type: SCREENING_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [SCREENING_QUESTION_CODES.harm_myself]: {
    title: "Sometimes I harm myself to relieve tension.",
    type: SCREENING_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [SCREENING_QUESTION_CODES.friends_think_i_drink_too_much]: {
    title: "My friends think I drink too much or use too many drugs.",
    type: SCREENING_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [SCREENING_QUESTION_CODES.can_not_stand_disappointment]: {
    title: "I get enraged when people disappoint me.",
    type: SCREENING_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [SCREENING_QUESTION_CODES.people_told_i_do_dangerous_things]: {
    title: "People have told me that I do dangerous things.",
    type: SCREENING_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [SCREENING_QUESTION_CODES.worrisome_attitude_with_food]: {
    title: "My attitude towards food is worrisome.",
    type: SCREENING_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [SCREENING_QUESTION_CODES.do_not_like_my_body]: {
    title: "No matter what people say, I don’t like the way my body looks.",
    type: SCREENING_QUESTION_TYPES.radio,
    options: YesNo,
  },
} as const;
