import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { LoadingState } from "~/common/components";
import {
  CHECKIN_STATUS,
  checkInUtilities,
  QuestionAnswer,
} from "~/common/utils";
import {
  useGetActualPatientCheckIn,
  usePatientCheckin,
} from "~/patients/hooks";
import { useGetCurrentPatient } from "~/patients/hooks/queries/useGetCurrentPatient";
import { ROUTES } from "~/patients/router";
import { checkInIntroductionTexts } from "~/patients/utils";
import { CheckInFormLayout } from "./CheckInFormLayout";
import { CheckInState } from "./CheckInState";
import { FormRenderer } from "./forms/FormRenderer";
import { IntroductionCheckIn } from "./IntroductionCheckIn";

export const PatientCheckInForms = () => {
  const { data: user } = useGetCurrentPatient();

  const [currentQuestionNumber, setCurrentQuestionNumber] = useState<
    null | number
  >(null);
  const [answers, setAnswers] = useState<QuestionAnswer[]>([]);
  const { getExistingAnswerOfQuestion } = checkInUtilities();

  const { id } = useParams();
  const navigate = useNavigate();
  const { data: patientCheckInData, isLoading } =
    useGetActualPatientCheckIn(id);

  if (patientCheckInData?.status === CHECKIN_STATUS.expired)
    navigate(ROUTES.notFound);

  const {
    checkin: checkIn,
    id: checkInId,
    note: providersMessage,
  } = { ...patientCheckInData };

  const totalAmountQuestions = checkIn?.questions.length ?? 0;
  const isLastQuestion =
    (totalAmountQuestions === 0 ||
      currentQuestionNumber === totalAmountQuestions - 1) &&
    !!checkInId;

  const {
    isSubmitAnswerSuccess,
    isSubmitAnswerPending,
    isUploadFilePending,
    handleNext,
    handleGoBack,
    removeQuestionAnswerById,
  } = usePatientCheckin({
    checkInId,
    setAnswers,
    isLastQuestion,
    answers,
    setCurrentQuestionNumber,
  });

  const { preferred_reference: providerName } = { ...user?.provider };

  if (isLoading) return <LoadingState className="h-screen-calculated" />;

  const isCompletedCheckIn =
    patientCheckInData?.status === CHECKIN_STATUS.completed;

  if (isCompletedCheckIn)
    return (
      <CheckInState
        isSuccess={isSubmitAnswerSuccess || isCompletedCheckIn}
        code={checkIn?.code}
      />
    );

  if (!checkIn || !id) return null;

  const { title, description: introductionDescription } =
    checkInIntroductionTexts({
      patientCheckInData,
      providerName,
    })[checkIn.code];

  switch (currentQuestionNumber) {
    case null:
      return (
        <IntroductionCheckIn
          handleStart={() => handleNext()}
          title={title}
          description={introductionDescription}
          code={checkIn.code}
          note={providersMessage}
        />
      );

    case totalAmountQuestions:
      return !isSubmitAnswerPending ? (
        <CheckInState isSuccess={isSubmitAnswerSuccess} code={checkIn?.code} />
      ) : null;

    default:
      return (
        <CheckInFormLayout
          actualQuestion={currentQuestionNumber + 1}
          totalQuestions={totalAmountQuestions}
          description={checkIn.questions[currentQuestionNumber].title}
          title={title}
        >
          <FormRenderer
            question={checkIn.questions[currentQuestionNumber]}
            handleNext={handleNext}
            handleGoBack={handleGoBack}
            existingAnswer={getExistingAnswerOfQuestion(
              answers,
              checkIn.questions[currentQuestionNumber].id,
            )}
            isLastQuestion={isLastQuestion}
            removeQuestionAnswerById={removeQuestionAnswerById}
            isSubmitting={isSubmitAnswerPending || isUploadFilePending}
          />
        </CheckInFormLayout>
      );
  }
};
