import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { CustomError } from "~/common/types/errorTypes";
import { updateMyPharmacy } from "~/patients/api/pharmacies";
import { CreateNewPharmacyFormValuesType } from "~/patients/utils";

interface MutationFunctionProps {
  pharmacy: CreateNewPharmacyFormValuesType;
  pharmacyId: string;
}

export const useUpdateMyPharmacy = (handleCloseModal: () => void) => {
  const queryClient = useQueryClient();

  const { mutate: updateMyPharmacyMutation, isPending } = useMutation({
    mutationFn: ({ pharmacy, pharmacyId }: MutationFunctionProps) =>
      updateMyPharmacy(pharmacy, pharmacyId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["getPatientPharmacies"],
      });
      handleCloseModal();
    },
    onError: (error: CustomError) => {
      const customError = error.response.data.detail;
      toast.error(customError ?? "Something went wrong, try again later!");
    },
  });

  return { updateMyPharmacyMutation, isPending };
};
