import qs from "qs";

import { ORDER, ORDER_BY } from "~/common/constants";
import {
  CHECKIN_STATUS,
  CheckinStatus,
  CheckInWithAnswers,
  GetPresignedUrlData,
  InteractionCreationTypes,
  PatientCheckInBase,
  PatientCheckInData,
  QuestionAnswer,
} from "~/common/utils/types/checkInTypes";
import { api, PaginatedServiceResponse } from "./axios";

export const getPatientCheckIn = async (checkInId: string) => {
  const res = await api.get<PatientCheckInData>(
    `/v1/patients/checkins/${checkInId}`,
  );
  return res.data;
};

export const submitPatientCheckin = async (
  patientCheckinId: string,
  answers?: QuestionAnswer[],
) => {
  const res = await api.post<string>(
    `/v1/patients/checkins/${patientCheckinId}/answers`,
    answers,
  );

  return res.data;
};

export const getCheckIns = async (
  active: boolean,
  status?: CheckinStatus,
  creationTypes?: InteractionCreationTypes[],
) => {
  const res = await api.get<PaginatedServiceResponse<PatientCheckInBase[]>>(
    "/v1/patients/checkins",
    {
      params: {
        status,
        page_size: 100,
        order: ORDER.descendant,
        order_by:
          status === CHECKIN_STATUS.completed
            ? ORDER_BY.completedDate
            : ORDER_BY.creationDate,
        creation_type: creationTypes,
        active: active,
        days_since_completion: 3650,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    },
  );
  return res.data;
};

export const createOneTimeStatusCheckIn = async () => {
  const res = await api.post<PatientCheckInBase>(
    `/v1/patients/checkins/status-check`,
    null,
  );

  return res.data;
};

export const getPresignedUrl = async ({
  patientCheckInId,
  questionId,
  inputId,
  fileName,
}: GetPresignedUrlData) => {
  const res = await api.get<string>(
    `/v1/patients/presigned_url/${patientCheckInId}/${questionId}/${inputId}/${fileName}`,
  );
  return res.data;
};

export const getCheckInWithAnswers = async (checkinId: string) => {
  const res = await api.get<CheckInWithAnswers>(
    `/v1/patients/checkins/${checkinId}/answers`,
  );
  return res.data;
};
