import { matchPath, NavigateFunction } from "react-router-dom";

import { ROUTES } from "../router";

interface PatientMedicationMenuOptions {
  label: string;
  onClick: () => void;
  current?: boolean;
  enabled: boolean;
}

export const getMedicationLayoutMenuOptions = (navigate: NavigateFunction) => {
  const actualPath = window.location.pathname;

  const menuOptions: PatientMedicationMenuOptions[] = [
    {
      label: "Medications",
      current: !!matchPath(ROUTES.medications, actualPath),
      onClick: () => navigate(ROUTES.medications),
      enabled: true,
    },
    {
      label: "Requests",
      current: !!matchPath(ROUTES.requests, actualPath),
      onClick: () => navigate(ROUTES.requests),
      enabled: true,
    },
  ];

  return { menuOptions };
};
