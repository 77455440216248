import { create } from "zustand";

import { OnBoardingPatientData } from "../utils/patientOnBoardings/types";

export interface OnBoardingPatientStoreState {
  patient?: OnBoardingPatientData;
  onBoardingStep: number;
  setPatient(patient?: OnBoardingPatientData): void;
  setOnBoardingStep(onBoardingStep: number): void;
}

export const useOnBoardingPatientStore = create<OnBoardingPatientStoreState>()(
  (set) => ({
    patient: undefined,
    setPatient: (patient?: OnBoardingPatientData) => {
      set(() => ({ patient }));
    },
    onBoardingStep: 0,
    setOnBoardingStep: (onBoardingStep: number) => {
      set(() => ({ onBoardingStep }));
    },
  }),
);
