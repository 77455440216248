import { INPUT_TYPES, QuestionAnswer } from "../types/checkInTypes";
import { checkInUtilities } from "./checkInUtilities";

interface UpdateAnswersPathProps {
  questionId: string;
  inputId: string;
  presigned_url: string;
  answer: QuestionAnswer;
}

const { getPathFromUrl } = checkInUtilities();
export const updateAnswersPath = ({
  answer,
  questionId,
  inputId,
  presigned_url,
}: UpdateAnswersPathProps) => {
  if (answer.question_id !== questionId) {
    return answer;
  }

  const updatedInputs = answer.inputs.map((input) => {
    if (
      !(
        input.id === inputId &&
        (input.answer?.answer_type === INPUT_TYPES.file ||
          input.answer?.answer_type === INPUT_TYPES.audio)
      )
    ) {
      return input;
    }
    return {
      ...input,
      answer: {
        ...input.answer,
        file: undefined,
        path: getPathFromUrl(presigned_url),
      },
    };
  });

  return {
    ...answer,
    inputs: updatedInputs,
  };
};
