import { QuestionAnswer } from "~/common/utils/types/checkInTypes";
import { useSubmitCheckin } from "./useSubmitCheckIn";

interface UsePatientCheckinProps {
  checkInId?: string;
  setAnswers: React.Dispatch<React.SetStateAction<QuestionAnswer[]>>;
  isLastQuestion: boolean;
  answers: QuestionAnswer[];
  setCurrentQuestionNumber: React.Dispatch<React.SetStateAction<number | null>>;
}
export const usePatientCheckin = ({
  checkInId,
  answers,
  setCurrentQuestionNumber,
  setAnswers,
  isLastQuestion,
}: UsePatientCheckinProps) => {
  const {
    submitCheckin,
    isSubmitAnswerSuccess,
    isSubmitAnswerPending,
    isUploadFilePending,
  } = useSubmitCheckin({
    checkInId,
    answers,
    setCurrentQuestionNumber,
  });

  const handleGoBack = (answer?: QuestionAnswer) => {
    answer && saveAnswer(answer);
    setCurrentQuestionNumber((prev) => (prev ? prev - 1 : null));
  };

  const saveAnswer = (data: QuestionAnswer) => {
    setAnswers((prev) => {
      const answerIndex = prev.findIndex(
        ({ question_id }) => question_id === data.question_id,
      );
      if (answerIndex === -1) return [...prev, data];

      const updatedAnswers = [...prev];
      updatedAnswers[answerIndex] = data;
      return updatedAnswers;
    });
  };

  const handleNext = (data?: QuestionAnswer) => {
    if (isLastQuestion) {
      if (!data) {
        submitCheckin(answers);
        return;
      }
      const answerIndex = answers.findIndex(
        ({ question_id }) => question_id === data.question_id,
      );
      const existAnswer = answerIndex !== -1;
      if (!existAnswer) {
        submitCheckin([...answers, data]);
        return;
      }
      const updatedAnswers = [...answers];
      updatedAnswers[answerIndex] = data;
      submitCheckin(updatedAnswers);
      return;
    }
    data && saveAnswer(data);
    setCurrentQuestionNumber((prev) => (prev !== null ? prev + 1 : 0));
  };

  const removeQuestionAnswerById = (id: string) => {
    setAnswers((prevAnswers) =>
      prevAnswers.filter((answer) => answer.question_id !== id),
    );
  };

  return {
    isSubmitAnswerSuccess,
    isSubmitAnswerPending,
    isUploadFilePending,
    handleNext,
    handleGoBack,
    removeQuestionAnswerById,
  };
};
