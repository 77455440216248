import { useMutation } from "@tanstack/react-query";

import { checkInUtilities, updateAnswersPath } from "~/common/utils/checkIn";
import {
  GetPresignedUrlData,
  QuestionAnswer,
} from "~/common/utils/types/checkInTypes";
import { uploadFileS3 } from "~/patients/api";
import { getPresignedUrl } from "~/patients/api/checkIn";

interface UseUploadFileS3Props {
  checkInId?: string;
}

export const useUploadFilesS3 = ({ checkInId }: UseUploadFileS3Props) => {
  const { getInputAnswerWithFile, getFile } = checkInUtilities();

  const { mutateAsync: uploadFileMutation, isPending: isUploadFilePending } =
    useMutation({
      mutationFn: uploadFileS3,
    });

  const { mutateAsync: generatePresignedUrl } = useMutation({
    mutationFn: async (data: GetPresignedUrlData) =>
      await getPresignedUrl(data),
  });

  const handleUploadFileAnswers = async (answers: QuestionAnswer[]) => {
    const updatedAnswers: QuestionAnswer[] = [];
    await Promise.all(
      answers.map(async (answer) => {
        const inputAnswer = getInputAnswerWithFile(answer.inputs);
        const file = getFile(inputAnswer);

        if (inputAnswer?.id && checkInId && file) {
          const { id: inputId } = inputAnswer;
          const { question_id: questionId } = answer;

          const presigned_url = await generatePresignedUrl({
            patientCheckInId: checkInId,
            questionId,
            inputId,
            file,
            fileName: `file.${file.name.split(".").pop()}`,
          });

          await uploadFileMutation({
            file,
            presigned_url,
          });

          const updatedAnswer = updateAnswersPath({
            answer,
            questionId,
            inputId,
            presigned_url,
          });

          updatedAnswers.push(updatedAnswer);
        } else {
          updatedAnswers.push(answer);
        }
      }),
    );
    return updatedAnswers;
  };

  return {
    handleUploadFileAnswers,
    isUploadFilePending,
  };
};
