import React from "react";
import { twMerge } from "tailwind-merge";

interface TickIconProps {
  className?: string;
}
const TickIcon = ({ className }: TickIconProps) => {
  return (
    <svg
      className={twMerge("h-4 w-4 text-primary-600", className)}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8332 4.66602L6.97124 11.5279C6.71089 11.7883 6.28878 11.7883 6.02843 11.5279L3.1665 8.66602"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default TickIcon;
