import { RelationshipStatuses } from "~/common/utils";
import {
  SCREENING_QUESTION_CODES,
  SCREENING_QUESTION_TYPES,
} from "~/common/utils/consultations/screening";

export const relationshipQuestions = {
  [SCREENING_QUESTION_CODES.relationship_status]: {
    title: "What is your relationship status?",
    type: SCREENING_QUESTION_TYPES.radio,
    options: RelationshipStatuses,
  },
} as const;
