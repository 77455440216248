import { create } from "zustand";
import { persist } from "zustand/middleware";

export interface UiStore {
  sideBarOpen: boolean;
  setSideBarOpen(sideBarOpen: boolean): void;
  unsavedPatientAccountChanges: boolean;
  setUnsavedPatientAccountChanges(unsaved: boolean): void;
}

export const useUiStore = create<UiStore>()(
  persist(
    (set) => ({
      sideBarOpen: false,
      unsavedPatientAccountChanges: false,
      setSideBarOpen: (sideBarOpen: boolean) => {
        set(() => ({ sideBarOpen }));
      },
      setUnsavedPatientAccountChanges: (unsaved: boolean) => {
        set(() => ({ unsavedPatientAccountChanges: unsaved }));
      },
    }),
    {
      name: "useUiStore",
    },
  ),
);
