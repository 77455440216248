import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface SideBarItemProps {
  selected?: boolean;
  children?: ReactNode;
  className?: string;
  onClick?: () => void;
}

const SideBarItem = ({
  children,
  className,
  selected,
  onClick,
}: SideBarItemProps) => {
  return (
    <button
      onClick={onClick}
      className={twMerge(
        "flex w-full gap-2 border-l-4 border-transparent p-4 pl-5 text-left font-semibold text-gray-600 hover:bg-blue-200",
        selected && " border-primary-500 bg-primary-100 text-primary-500",
        className,
      )}
    >
      {children}
    </button>
  );
};

export default SideBarItem;
