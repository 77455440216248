export const PATIENT_SCRIPT_STATUS = {
  pending: "pending",
  sent_to_pharmacy: "sent_to_pharmacy",
  canceled: "canceled",
} as const;

export const statusColors = {
  [PATIENT_SCRIPT_STATUS.canceled]: "bg-red-100 text-red-600",
  [PATIENT_SCRIPT_STATUS.pending]: "bg-orange-10 text-orange-70",
  [PATIENT_SCRIPT_STATUS.sent_to_pharmacy]: "bg-green-200 text-green-800",
} as const;

export const statusName = {
  [PATIENT_SCRIPT_STATUS.canceled]: "Canceled",
  [PATIENT_SCRIPT_STATUS.pending]: "Pending",
  [PATIENT_SCRIPT_STATUS.sent_to_pharmacy]: "Sent to pharmacy",
} as const;

export const SCRIPT_STATUS_VALUES = [
  PATIENT_SCRIPT_STATUS.pending,
  PATIENT_SCRIPT_STATUS.sent_to_pharmacy,
  PATIENT_SCRIPT_STATUS.canceled,
] as const;

export const SCRIPT_STATUS_OPTIONS = [
  { value: PATIENT_SCRIPT_STATUS.pending, label: statusName.pending },
  {
    value: PATIENT_SCRIPT_STATUS.sent_to_pharmacy,
    label: statusName.sent_to_pharmacy,
  },
  { value: PATIENT_SCRIPT_STATUS.canceled, label: statusName.canceled },
];

export const PATIENT_SCRIPT_STATUS_FINAL_STATES_VALUES = [
  PATIENT_SCRIPT_STATUS.canceled,
  PATIENT_SCRIPT_STATUS.sent_to_pharmacy,
] as const;
