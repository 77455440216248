export const BasicLabsIcon = () => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.813995" width="32" height="32" rx="16" fill="#FBDEEA" />
    <path
      d="M18.2003 11.1869L21.867 14.8535M18.2003 11.1869L20.4807 8.66666L24.1473 12.413L14.0692 23.199C13.0641 24.2747 11.3675 24.3035 10.3264 23.2624C9.34091 22.2769 9.30643 20.6901 10.2482 19.6627L18.2003 11.1869ZM24.1473 22.4167C24.1473 23.4292 23.3265 24.25 22.314 24.25C21.3015 24.25 20.4807 23.4292 20.4807 22.4167C20.4807 21.8362 21.0832 20.7162 21.5974 19.8661C21.9293 19.3174 22.6987 19.3174 23.0306 19.8661C23.5448 20.7162 24.1473 21.8362 24.1473 22.4167Z"
      stroke="#EC5D88"
      strokeWidth="2"
    />
  </svg>
);
