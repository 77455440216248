import React, { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

interface NoteDataProps {
  title: string;
  note: string;
  seeMoreButton?: boolean;
}

export const NoteData = ({ title, note, seeMoreButton }: NoteDataProps) => {
  const [showFullNote, setShowFullNote] = useState(false);
  const [shouldHideContent, setShouldHideContent] = useState(false);
  const toggleShowFullNote = () => setShowFullNote((prev) => !prev);

  const contentRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      const { scrollHeight, clientHeight } = contentRef.current;
      setShouldHideContent(scrollHeight > clientHeight);
    }
  }, [note]);

  return (
    <div className="flex flex-col gap-1">
      <div className="flex flex-col gap-1">
        <h1>{title}</h1>
        <p
          ref={contentRef}
          className={twMerge(
            "break-words break-all font-light italic text-gray-10",
            seeMoreButton && !showFullNote
              ? "line-clamp-3 max-h-20"
              : "line-clamp-none max-h-full overflow-auto",
          )}
        >
          {note}
        </p>
      </div>
      {shouldHideContent && seeMoreButton && (
        <button
          onClick={toggleShowFullNote}
          className="w-fit leading-5 text-primary-600 hover:underline"
        >
          {showFullNote ? "View less" : "View more"}
        </button>
      )}
    </div>
  );
};
