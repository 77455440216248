import { twMerge } from "tailwind-merge";

import { IconProps } from "~/common/utils/types";

export const CheckIcon = ({ className, ...props }: IconProps) => (
  <svg
    className={twMerge("h-6 w-4 text-blue-600", className)}
    viewBox="0 0 14 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.5 9.5C0.5 5.91015 3.41015 3 7 3C10.5899 3 13.5 5.91015 13.5 9.5C13.5 13.0899 10.5899 16 7 16C3.41015 16 0.5 13.0899 0.5 9.5Z"
      fill="white"
    />
    <path
      d="M0.5 9.5C0.5 5.91015 3.41015 3 7 3C10.5899 3 13.5 5.91015 13.5 9.5C13.5 13.0899 10.5899 16 7 16C3.41015 16 0.5 13.0899 0.5 9.5Z"
      stroke="currentColor"
    />
    <path
      d="M9.73847 7.78882L6.21587 11.3114C6.08222 11.4451 5.86553 11.4451 5.73188 11.3114L4.2627 9.84223"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
);
