import {
  INTAKE_QUESTION_CODES,
  INTAKE_QUESTION_TYPES,
  YES_NO_NOT_SURE_TYPES,
  YES_NO_TYPES,
} from "~/common/utils/consultations/intake";

const radioWithOptionalTextOptionsSection3 = {
  [INTAKE_QUESTION_CODES.are_you_currently_experiencing_any_sexual_issues]: {
    title: "Are you currently experiencing any sexual issues?",
    type: INTAKE_QUESTION_TYPES.radio_with_optional_text,
    options: [
      {
        value: YES_NO_TYPES.yes,
        asksExtraInfo: true,
        placeholderAsksExtraInfo:
          "Please provide more details about your sexual issues.",
      },
      {
        value: YES_NO_TYPES.no,
      },
    ],
  },
  [INTAKE_QUESTION_CODES.used_cocaine_ecstasy_mdma_opioids_pain_medicines_or_amphetamines]:
    {
      title:
        "Have you ever used cocaine, ecstasy, MDMA, opioids, pain medicines or amphetamines?",
      type: INTAKE_QUESTION_TYPES.radio_with_optional_text,
      options: [
        {
          value: YES_NO_TYPES.yes,
          asksExtraInfo: true,
          placeholderAsksExtraInfo:
            "Please provide more details about your usage.",
        },
        {
          value: YES_NO_TYPES.no,
        },
      ],
    },
  [INTAKE_QUESTION_CODES.do_you_use_mushrooms_psilocybin_lsd]: {
    title: "Do you use mushrooms, psilocybin or LSD?",
    type: INTAKE_QUESTION_TYPES.radio_with_optional_text,
    options: [
      {
        value: YES_NO_TYPES.yes,
        asksExtraInfo: true,
        placeholderAsksExtraInfo:
          "Please provide more details about your usage.",
      },
      {
        value: YES_NO_TYPES.no,
      },
    ],
  },
  [INTAKE_QUESTION_CODES.gamble_frequently]: {
    title: "Do you gamble frequently?",
    type: INTAKE_QUESTION_TYPES.radio_with_optional_text,
    options: [
      {
        value: YES_NO_TYPES.yes,
        asksExtraInfo: true,
        placeholderAsksExtraInfo:
          "Please provide more details about your gambling.",
      },
      {
        value: YES_NO_TYPES.no,
      },
    ],
  },
  [INTAKE_QUESTION_CODES.find_yourself_preoccupied_with_sexual_behavior]: {
    title:
      "Do you find yourself overly preoccupied with sex, sexual thoughts, or sexual behaviors?",
    type: INTAKE_QUESTION_TYPES.radio_with_optional_text,
    options: [
      {
        value: YES_NO_TYPES.yes,
        asksExtraInfo: true,
        placeholderAsksExtraInfo: "Please describe further.",
      },
      {
        value: YES_NO_TYPES.no,
      },
    ],
  },
} as const;

const radioWithOptionalTextQuestionsSection4 = {
  [INTAKE_QUESTION_CODES.are_you_having_relationship_stresses]: {
    title: "Are you having relationship stresses?",
    type: INTAKE_QUESTION_TYPES.radio_with_optional_text,
    options: [
      {
        value: YES_NO_TYPES.yes,
        asksExtraInfo: true,
        placeholderAsksExtraInfo:
          "Please provide more details about your relationship stresses.",
      },
      {
        value: YES_NO_TYPES.no,
      },
    ],
  },
  [INTAKE_QUESTION_CODES.family_members_with_mood_disorders]: {
    title: "Do you have any family members with mood disorders?",
    type: INTAKE_QUESTION_TYPES.radio_with_optional_text,
    options: [
      {
        value: YES_NO_NOT_SURE_TYPES.yes,
        asksExtraInfo: true,
        placeholderAsksExtraInfo:
          "Please describe in more detail who was affected, and the nature of the disorder.",
      },
      {
        value: YES_NO_NOT_SURE_TYPES.no,
      },
      {
        value: YES_NO_NOT_SURE_TYPES.notSure,
      },
    ],
  },
  [INTAKE_QUESTION_CODES.family_members_with_anxiety_problems]: {
    title: "Do you have any family members with anxiety problems?",
    type: INTAKE_QUESTION_TYPES.radio_with_optional_text,
    options: [
      {
        value: YES_NO_NOT_SURE_TYPES.yes,
        asksExtraInfo: true,
        placeholderAsksExtraInfo:
          "Please provide details about the family member(s) with anxiety.",
      },
      {
        value: YES_NO_NOT_SURE_TYPES.no,
      },
      {
        value: YES_NO_NOT_SURE_TYPES.notSure,
      },
    ],
  },
  [INTAKE_QUESTION_CODES.family_members_with_addiction_problems]: {
    title: "Do you have any family members with addiction problems?",
    type: INTAKE_QUESTION_TYPES.radio_with_optional_text,
    options: [
      {
        value: YES_NO_NOT_SURE_TYPES.yes,
        asksExtraInfo: true,
        placeholderAsksExtraInfo:
          "Please provide details about the family member(s) with addiction.",
      },
      {
        value: YES_NO_NOT_SURE_TYPES.no,
      },
      {
        value: YES_NO_NOT_SURE_TYPES.notSure,
      },
    ],
  },
  [INTAKE_QUESTION_CODES.family_history_of_adhd]: {
    title: "Do you have a family history of ADHD?",
    type: INTAKE_QUESTION_TYPES.radio_with_optional_text,
    options: [
      {
        value: YES_NO_NOT_SURE_TYPES.yes,
        asksExtraInfo: true,
        placeholderAsksExtraInfo:
          "Please provide details about the family member(s) with ADHD.",
      },
      {
        value: YES_NO_NOT_SURE_TYPES.no,
      },
      {
        value: YES_NO_NOT_SURE_TYPES.notSure,
      },
    ],
  },
} as const;

const radioWithOptionalTextQuestionsSection5 = {
  [INTAKE_QUESTION_CODES.any_specific_work_stresses]: {
    title: "Do you have any specific work stresses?",
    type: INTAKE_QUESTION_TYPES.radio_with_optional_text,
    options: [
      {
        value: YES_NO_TYPES.yes,
        asksExtraInfo: true,
        placeholderAsksExtraInfo:
          "Please provide more details about your work stresses.",
      },
      {
        value: YES_NO_TYPES.no,
      },
    ],
  },
} as const;

export const radioWithOptionalTextQuestions = {
  ...radioWithOptionalTextOptionsSection3,
  ...radioWithOptionalTextQuestionsSection4,
  ...radioWithOptionalTextQuestionsSection5,
};
