import { PasswordRequirement } from "./types";

export const passwordRequirements: PasswordRequirement[] = [
  {
    condition: "At least 8 characters",
    valid: (password) => !!password && password.length > 7,
  },
  {
    condition: "At least 1 uppercase letter",
    valid: (password) => /[A-Z]/.test(password),
  },
  {
    condition: "At least 1 lowercase letter",
    valid: (password) => /[a-z]/.test(password),
  },
  {
    condition: "At least 1 number",
    valid: (password) => /[0-9]/.test(password),
  },
  {
    condition: "At least 1 special character, (e.g. ! _ @ # ?)",
    valid: (password) =>
      // eslint-disable-next-line  no-useless-escape
      /[`!@#$%^&*()_\-+=\[\]{};':"\\|,.<>\/?~ ]/.test(password),
  },
];
