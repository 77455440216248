import { twMerge } from "tailwind-merge";

import { Button } from "~/common/components";
import TickIcon from "~/common/components/icons/TickIcon";
import { PATIENT_SCRIPT_STATUS } from "~/common/constants";
import { BUTTON_VARIANTS } from "~/common/constants/variants";
import { PatientScriptsStatusTypes } from "~/common/types/scriptsTypes";
import { formatDate } from "~/providers/utils";
import { ScriptStatus } from "./ScriptStatus";

interface ScriptsCardProps {
  date: string;
  status: PatientScriptsStatusTypes;
  title: string;
  subtitle: string;
  isPast?: boolean;
  preAuthRequest: boolean;
  commentMessage?: string;
  handleCancelRequest?: () => void;
  handleResolveIssue?: () => void;
}

export const ScriptsCard = ({
  date,
  status,
  title,
  subtitle,
  isPast = false,
  preAuthRequest,
  handleCancelRequest,
  commentMessage,
  handleResolveIssue,
}: ScriptsCardProps) => (
  <div
    className={twMerge(
      "flex flex-col rounded-lg border border-primary-200 bg-primary-50  text-gray-70",
      isPast && "border-neutral-700 bg-neutral-25",
    )}
  >
    <div className="flex items-center justify-between border-b border-primary-200 p-3">
      <p className="text-lg font-semibold leading-5">{formatDate(date)}</p>
      <ScriptStatus status={status} />
    </div>

    <div className="flex flex-col gap-3 p-3">
      <div className="flex flex-col justify-between gap-2 md:flex-row md:items-center md:gap-0">
        <div className="flex flex-col gap-1.5">
          <p className="text-lg font-medium leading-5">{title}</p>
          <p className="text-base font-light text-gray-10">{subtitle}</p>
        </div>
        {status === PATIENT_SCRIPT_STATUS.sent_to_pharmacy &&
          (preAuthRequest ? (
            <div className="flex flex-col gap-1 rounded-md border border-neutral-700 bg-white p-3 text-gray-35">
              <p className="flex items-center gap-2">
                <TickIcon className="text-gray-35" /> Pre-authorization
                requested
              </p>
              {commentMessage && (
                <p className="block truncate pl-6 md:hidden">
                  {commentMessage}
                </p>
              )}
            </div>
          ) : (
            !isPast && (
              <Button
                onClick={handleResolveIssue}
                size="sm"
                className="w-full md:w-fit"
              >
                Resolve an issue
              </Button>
            )
          ))}
        {status === PATIENT_SCRIPT_STATUS.pending && (
          <Button
            className="w-full whitespace-nowrap bg-white  md:w-fit"
            size="sm"
            variant={BUTTON_VARIANTS.outline_black}
            onClick={handleCancelRequest}
          >
            Cancel request
          </Button>
        )}
      </div>
      {commentMessage && (
        <div className="hidden items-center gap-2 rounded-md border border-neutral-700 bg-white p-3 italic leading-5 text-gray-35 md:flex">
          {commentMessage}
        </div>
      )}
    </div>
  </div>
);
