import { zodResolver } from "@hookform/resolvers/zod";
import { UseMutateAsyncFunction } from "@tanstack/react-query";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { z } from "zod";

import {
  AudioQuestionData,
  CompletedAudioAnswer,
  ConsultationAudioAnswer,
} from "~/common/utils/consultations/shared";
import {
  AudioRecorder,
  ConsultationQuestionsLayout,
} from "~/patients/components";
import { useHandleConsultationAudio } from "~/patients/hooks/consultations/useHandleConsultationAudio";
import { createAudioSchema } from "~/patients/utils/consultations";

export interface ConsultationAudioFormProps {
  question: AudioQuestionData;
  handleGoBack: () => void;
  onSubmit: (answer: ConsultationAudioAnswer) => void;
  isSubmitting: boolean;
  disableBack: boolean;
  handleUploadAudio: (file: File) => Promise<void>;
  pendingUploadAudio: boolean;
  deleteAudioMutation: UseMutateAsyncFunction<null, Error, void, unknown>;
  pendingDeleteAudio: boolean;
  isOptional: boolean;
  completedAnswer?: CompletedAudioAnswer;
}

export const ConsultationAudioForm = ({
  question,
  handleGoBack,
  onSubmit,
  isSubmitting,
  disableBack,
  handleUploadAudio,
  pendingUploadAudio,
  deleteAudioMutation,
  pendingDeleteAudio,
  isOptional,
  completedAnswer,
}: ConsultationAudioFormProps) => {
  const { title, supertitle, minDuration, maxDuration } = question;

  const duration = completedAnswer?.duration
    ? parseInt(completedAnswer.duration)
    : undefined;

  const schema = createAudioSchema(minDuration, maxDuration, isOptional);
  type AudioFormValues = z.infer<typeof schema>;

  const {
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<AudioFormValues>({
    defaultValues: {
      answer: isOptional && !duration ? null : { duration },
    },
    resolver: zodResolver(schema),
  });

  const {
    audioFile,
    audioRecorderRef,
    deleteRecordingAndGoBack,
    setRecordedAudio,
    handleDeleteAudio,
    disableNextButton,
    submitButtonLabel,
  } = useHandleConsultationAudio(
    handleGoBack,
    setValue,
    reset,
    deleteAudioMutation,
    watch("answer.duration"),
    completedAnswer,
    isOptional,
  );

  const saveAnswers: SubmitHandler<AudioFormValues> = async (answer) => {
    if (audioFile) {
      try {
        await handleUploadAudio(audioFile);
      } catch (error) {
        toast.error(
          "There was an error saving your audio. If the issue persists, please reload the page.",
        );
        return;
      }
    } else if (isOptional) {
      answer = { answer: null };
    }

    onSubmit(answer);
  };

  return (
    <ConsultationQuestionsLayout
      handleNext={handleSubmit(saveAnswers)}
      handleBack={deleteRecordingAndGoBack}
      disableNext={disableNextButton}
      isSubmitting={isSubmitting || pendingUploadAudio}
      title={title}
      supertitle={supertitle}
      disableBack={disableBack}
      submitButtonLabel={submitButtonLabel}
    >
      <AudioRecorder
        ref={audioRecorderRef}
        setAudio={setRecordedAudio}
        handleDeleteAudio={handleDeleteAudio}
        pendingDeleteAudio={pendingDeleteAudio}
        error={errors.answer?.duration?.message}
        defaultAudioSrc={completedAnswer?.presigned_url}
        defaultAudioDuration={duration}
      />
    </ConsultationQuestionsLayout>
  );
};
