import { PATIENT_STATUS } from "~/common/constants";

export const getConsultationText = (
  status: keyof typeof PATIENT_STATUS,
  providerName: string,
) => {
  if (status === PATIENT_STATUS.screening_submitted)
    return `${providerName} is reviewing your request and will be in touch with you very soon.`;

  if (status === PATIENT_STATUS.referred_after_screening)
    return "Your consultation has ended. Please contact our team if you have any questions.";

  return "Your input is essential to your ongoing wellness!";
};
