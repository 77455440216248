import { useQuery } from "@tanstack/react-query";

import { getIntake } from "~/patients/api";

export const useGetIntake = (intakeId?: string, enabled = true) => {
  const { data, isLoading } = useQuery({
    queryFn: () => getIntake(intakeId ?? ""),
    queryKey: ["getIntake", intakeId],
    enabled: !!intakeId && enabled,
  });

  return { data, isLoading };
};
