export const MailBoxIcon = () => (
  <svg
    width="133"
    height="132"
    viewBox="0 0 133 132"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M85.6389 25.7426C85.6389 25.7426 71.52 43.6266 48.9297 32.9589C26.3394 22.2913 3.74904 51.1567 23.2018 68.7269C42.6546 86.2971 23.6186 95.9519 36.834 106.72C51.8543 118.959 64.9311 94.141 76.8537 99.7886C103.168 112.253 122.662 107.319 118.269 92.886C110.559 67.5513 100.699 65.9032 108.229 52.7255C115.759 39.5478 99.1402 12.1481 85.6389 25.7426Z"
      fill="#DEE9FF"
    />
    <path
      d="M77.9527 75.2048L76.8373 81.1933L71.5244 109.661L61.9541 107.874L68.0905 74.9889L68.3823 73.4219L77.9527 75.2048Z"
      fill="#699CFF"
    />
    <path
      d="M101.522 60.884L98.2218 78.576C98.2121 78.6278 98.1923 78.6773 98.1635 78.7215C98.1347 78.7657 98.0975 78.8038 98.054 78.8336C98.0105 78.8634 97.9615 78.8844 97.9099 78.8953C97.8583 78.9061 97.805 78.9068 97.7532 78.897L51.3906 70.25L56.8475 40.9922L92.0534 47.5585C98.4344 48.7484 102.673 54.7132 101.522 60.884Z"
      fill="#A2BDFF"
    />
    <path
      d="M66.7507 54.9467L63.4765 72.5014L39.1406 67.9625L42.4148 50.4078C43.6202 43.9445 50.0455 39.7208 56.7655 40.9741C63.4856 42.2275 67.9562 48.4832 66.7507 54.9467Z"
      fill="#699CFF"
    />
    <path
      d="M78.0792 63.1535C76.2859 62.819 75.1026 61.0761 75.4404 59.2661C75.7781 57.4561 77.5099 56.2565 79.3032 56.5912C79.8749 56.6986 80.4079 56.9554 80.8481 57.3357C81.2883 57.7159 81.6199 58.206 81.8092 58.756L104.462 62.981L103.85 66.2623L81.1973 62.0372C80.8224 62.4821 80.3365 62.8197 79.7888 63.0158C79.2411 63.2119 78.6513 63.2594 78.0792 63.1535Z"
      fill="#418DF9"
    />
    <path
      d="M65.9425 55.2684L62.7525 72.3719L39.8271 68.0961L43.0174 50.9925C44.1919 44.6953 50.2762 40.5474 56.6068 41.7281C62.9374 42.9089 67.117 48.9712 65.9425 55.2684Z"
      fill="#418DF9"
    />
    <path
      d="M78.7217 61.4961C79.6434 61.4961 80.3906 60.7489 80.3906 59.8271C80.3906 58.9054 79.6434 58.1582 78.7217 58.1582C77.7999 58.1582 77.0527 58.9054 77.0527 59.8271C77.0527 60.7489 77.7999 61.4961 78.7217 61.4961Z"
      fill="white"
    />
    <path
      d="M104.725 62.968L102.724 73.6968C102.715 73.743 102.689 73.7839 102.65 73.8105C102.611 73.8371 102.563 73.8472 102.517 73.8385L97.2571 72.8575C97.2479 72.8558 97.2399 72.8505 97.2346 72.8428C97.2294 72.8352 97.2274 72.8258 97.2291 72.8167L99.2562 61.948C99.2579 61.9389 99.2632 61.9308 99.2709 61.9256C99.2785 61.9203 99.2879 61.9183 99.2971 61.92L104.697 62.9272C104.706 62.9289 104.714 62.9341 104.719 62.9418C104.725 62.9494 104.727 62.9589 104.725 62.968Z"
      fill="#699CFF"
    />
    <path
      d="M47.4141 61.96C47.6356 63.5895 49.4237 65.5263 49.4237 65.5263C46.6991 65.7194 45.2235 64.1786 44.5886 63.2423C43.8036 63.4691 42.9968 63.6123 42.1817 63.6694C36.5881 64.0659 31.7729 60.414 31.4252 55.5087C31.0774 50.6033 35.3295 46.3087 40.923 45.9123C46.5166 45.5159 51.3315 49.1675 51.6792 54.073C51.9054 57.2637 50.1836 60.1966 47.4141 61.96Z"
      fill="white"
    />
    <path
      d="M63.4996 72.5122C63.2463 73.1533 58.6142 92.2535 43.6244 89.4577C27.1871 86.6749 39.0766 67.957 39.0766 67.957L63.4996 72.5122Z"
      fill="#A2BDFF"
    />
    <path
      d="M41.5519 56.4729C42.32 56.4729 42.9426 55.8502 42.9426 55.0822C42.9426 54.3141 42.32 53.6914 41.5519 53.6914C40.7838 53.6914 40.1611 54.3141 40.1611 55.0822C40.1611 55.8502 40.7838 56.4729 41.5519 56.4729Z"
      fill="#699CFF"
    />
    <path
      d="M35.3259 55.8666C36.0986 55.8666 36.725 55.2402 36.725 54.4675C36.725 53.6948 36.0986 53.0684 35.3259 53.0684C34.5532 53.0684 33.9268 53.6948 33.9268 54.4675C33.9268 55.2402 34.5532 55.8666 35.3259 55.8666Z"
      fill="#699CFF"
    />
    <path
      d="M47.7185 57.0658C48.4912 57.0658 49.1176 56.4394 49.1176 55.6667C49.1176 54.894 48.4912 54.2676 47.7185 54.2676C46.9458 54.2676 46.3193 54.894 46.3193 55.6667C46.3193 56.4394 46.9458 57.0658 47.7185 57.0658Z"
      fill="#699CFF"
    />
    <path
      d="M77.953 75.2048L76.8376 81.1933L68.0908 74.9889L68.3826 73.4219L77.953 75.2048Z"
      fill="#418DF9"
    />
  </svg>
);
