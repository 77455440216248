import { useParams } from "react-router-dom";

import { LoadingState } from "~/common/components";
import {
  useGetCurrentIntakeBundle,
  useGetCurrentPatient,
} from "~/patients/hooks";
import { useCompleteIntake } from "~/patients/hooks/queries/consultations/intake/useCompleteIntake";
import { ConsultationCompleted } from "../../shared";

interface ScreeningCompletedScreenProps {
  handleGoBackFinalScreen: () => void;
}
export const IntakeCompletedScreen = ({
  handleGoBackFinalScreen,
}: ScreeningCompletedScreenProps) => {
  const { data: user } = useGetCurrentPatient();

  const {
    preferred_reference: providerName,
    profile_picture_path: providerPictureSrc,
  } = { ...user?.provider };
  const { id: patientIntakeId } = useParams();
  const { completeIntakeMutation, isPending } = useCompleteIntake();
  const { data, isLoading, isFetching } = useGetCurrentIntakeBundle();

  if (!data) return;

  const { sections_completed, total_sections } = data;
  const sectionCompleted = (sections_completed ?? 0) + 1;
  const isFinalIntake = sectionCompleted === total_sections;

  const title = isFinalIntake
    ? "Intake completed!"
    : `You’ve completed Section ${sectionCompleted}!`;

  const subtitle = isFinalIntake
    ? `${providerName} is reviewing your request and will be in touch with you very soon.`
    : `You can’t edit your answers once you move to the next section.`;

  const handleCompleteIntake = () =>
    patientIntakeId && completeIntakeMutation(patientIntakeId);

  if (isFetching) return <LoadingState />;

  return (
    <ConsultationCompleted
      handleGoBackFinalScreen={handleGoBackFinalScreen}
      title={title}
      subtitle={subtitle}
      isFinal={isFinalIntake}
      providerPictureSrc={providerPictureSrc}
      isLoading={isLoading}
      isPending={isPending}
      handleComplete={handleCompleteIntake}
    />
  );
};
