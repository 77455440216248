import React from "react";

const BlueCheckIcon = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
        fill="#C1D4FB"
      />
      <path
        d="M40.2784 72.7522C58.2132 72.7522 72.7522 58.2132 72.7522 40.2785C72.7522 22.3437 58.2132 7.80469 40.2784 7.80469C22.3437 7.80469 7.80469 22.3437 7.80469 40.2785C7.80469 58.2132 22.3437 72.7522 40.2784 72.7522Z"
        fill="#6192F3"
      />
      <path
        d="M38.6367 46.1125L40.0902 47.4863L40.0902 47.4863L38.6367 46.1125ZM51.4572 35.4597C52.2159 34.657 52.1802 33.3912 51.3775 32.6324C50.5747 31.8737 49.3089 31.9094 48.5502 32.7121L51.4572 35.4597ZM37.0749 46.1125L35.6214 47.4863H35.6214L37.0749 46.1125ZM33.7875 39.7227C33.0288 38.92 31.7629 38.8843 30.9602 39.643C30.1574 40.4017 30.1218 41.6676 30.8805 42.4703L33.7875 39.7227ZM40.0902 47.4863L51.4572 35.4597L48.5502 32.7121L37.1831 44.7387L40.0902 47.4863ZM38.5284 44.7387L33.7875 39.7227L30.8805 42.4703L35.6214 47.4863L38.5284 44.7387ZM37.1831 44.7387C37.541 44.3601 38.1705 44.3601 38.5284 44.7387L35.6214 47.4863C36.8418 48.7775 38.8697 48.7775 40.0902 47.4863L37.1831 44.7387Z"
        fill="#F8F0FF"
      />
    </svg>
  );
};

export default BlueCheckIcon;
