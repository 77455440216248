import { QuestionAnswerLayoutProps } from "~/common/components/answers";
import { checkInUtilities } from "~/common/utils/checkIn/checkInUtilities";
import {
  CompletedAnswerData,
  INPUT_TYPES,
  PatientCheckInQuestion,
} from "~/common/utils/types/checkInTypes.ts";
import { questionTextByCodes } from "~/patients/constants";
import { updateAnswerValuesByType } from "~/patients/utils";

interface GetAnswerProps {
  answerData: CompletedAnswerData;
  answers?: CompletedAnswerData[];
  questions?: PatientCheckInQuestion[];
  isPreview?: boolean;
}

interface QuestionTextByCodes {
  title: string;
  subtitle?: JSX.Element;
  plainSubtitle?: string;
}

const { getQuestion, getSubmittedAnswerOfType } = checkInUtilities();
export const getAnswerProps = ({
  answerData,
  answers,
  questions,
  isPreview,
}: GetAnswerProps): QuestionAnswerLayoutProps => {
  const furtherDescriptionAnswer = getSubmittedAnswerOfType(
    answerData.question_id,
    INPUT_TYPES.free_text,
    answers,
  );

  const questionCode =
    answerData.question?.code ??
    getQuestion(answerData.question_id, questions)?.code;

  const answerValues: QuestionAnswerLayoutProps = {
    question: questionCode
      ? (questionTextByCodes[questionCode] as QuestionTextByCodes).plainSubtitle
      : "",
    furtherDescription: furtherDescriptionAnswer?.value,
  };

  return updateAnswerValuesByType(answerData, answerValues, isPreview);
};
