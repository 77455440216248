import { CancelSubmitButtons } from "~/common/components";
import Modal from "~/common/components/Modal";

interface ConfirmExitModalProps {
  onClose: () => void;
  show: boolean;
  onConfirm?: () => void;
}

const ConfirmExitModal = ({
  onClose,
  show,
  onConfirm,
}: ConfirmExitModalProps) => (
  <Modal className="h-fit w-96" title="Exit" show={show} onClose={onClose}>
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-3 font-light">
        <p>Are you sure you want to exit?</p>
        <p>
          Don’t worry, we’ve saved your place! So you can pick up where you left
          off at any time.
        </p>
      </div>
      <CancelSubmitButtons
        handleCancel={onClose}
        cancelButtonLabel="Cancel"
        handleSubmit={onConfirm}
        submitButtonLabel="Continue"
      />
    </div>
  </Modal>
);

export default ConfirmExitModal;
