import React from "react";
import { twMerge } from "tailwind-merge";

import { IconProps } from "~/common/utils/types";

export const StopIcon = ({ className }: IconProps) => (
  <svg
    className={twMerge("h-10 w-10 text-white", className)}
    width="39"
    height="40"
    viewBox="0 0 39 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.623535"
      y="0.697266"
      width="38"
      height="39"
      rx="6"
      fill="currentColor"
    />
  </svg>
);
