import {
  DailyFrequencies,
  SCREENING_QUESTION_CODES,
  SCREENING_QUESTION_TYPES,
} from "~/common/utils/consultations/screening";

const supertitle =
  "Over the last two weeks how often have you been bothered by...";

export const dailyFrequenciesQuestions = {
  [SCREENING_QUESTION_CODES.little_interest_on_doing_things]: {
    title: "Little interest or pleasure in doing things?",
    supertitle,
    type: SCREENING_QUESTION_TYPES.radio,
    options: DailyFrequencies,
  },
  [SCREENING_QUESTION_CODES.feeling_down]: {
    title: "Feeling down, depressed, or hopeless?",
    supertitle,
    type: SCREENING_QUESTION_TYPES.radio,
    options: DailyFrequencies,
  },
  [SCREENING_QUESTION_CODES.trouble_falling_staying_asleep]: {
    title: "Trouble falling or staying asleep, or sleeping too much?",
    supertitle,
    type: SCREENING_QUESTION_TYPES.radio,
    options: DailyFrequencies,
  },
  [SCREENING_QUESTION_CODES.feeling_tired]: {
    title: "Feeling tired or having little energy?",
    supertitle,
    type: SCREENING_QUESTION_TYPES.radio,
    options: DailyFrequencies,
  },
  [SCREENING_QUESTION_CODES.poor_appetite]: {
    title: "Poor appetite or over eating?",
    supertitle,
    type: SCREENING_QUESTION_TYPES.radio,
    options: DailyFrequencies,
  },
  [SCREENING_QUESTION_CODES.feeling_bad_about_yourself]: {
    title:
      "Feeling bad about yourself — or that you are a failure or have let yourself or your family down?",
    supertitle,
    type: SCREENING_QUESTION_TYPES.radio,
    options: DailyFrequencies,
  },
  [SCREENING_QUESTION_CODES.trouble_concentrating]: {
    title:
      "Trouble concentrating on things, such as reading the newspaper or watching television?",
    supertitle,
    type: SCREENING_QUESTION_TYPES.radio,
    options: DailyFrequencies,
  },
  [SCREENING_QUESTION_CODES.moving_or_speaking_slowly]: {
    title:
      "Moving or speaking so slowly that other people could have noticed? Or the opposite — being so fidgety or restless that you have been moving around a lot more than usual",
    supertitle,
    type: SCREENING_QUESTION_TYPES.radio,
    options: DailyFrequencies,
  },
  [SCREENING_QUESTION_CODES.suicidal_thoughts]: {
    title:
      "Thoughts that you would be better off dead or of hurting yourself in some way?",
    supertitle,
    type: SCREENING_QUESTION_TYPES.radio,
    options: DailyFrequencies,
  },
  [SCREENING_QUESTION_CODES.feeling_nervous]: {
    title: "Feeling nervous, anxious, or on edge?",
    supertitle,
    type: SCREENING_QUESTION_TYPES.radio,
    options: DailyFrequencies,
  },
  [SCREENING_QUESTION_CODES.uncontrollable_worrying]: {
    title: "Not being able to stop or control worrying?",
    supertitle,
    type: SCREENING_QUESTION_TYPES.radio,
    options: DailyFrequencies,
  },
  [SCREENING_QUESTION_CODES.worrying_too_much]: {
    title: "Worrying too much about different things?",
    supertitle,
    type: SCREENING_QUESTION_TYPES.radio,
    options: DailyFrequencies,
  },
  [SCREENING_QUESTION_CODES.trouble_relaxing]: {
    title: "Trouble relaxing?",
    supertitle,
    type: SCREENING_QUESTION_TYPES.radio,
    options: DailyFrequencies,
  },
  [SCREENING_QUESTION_CODES.too_restless]: {
    title: "Being so restless that it's hard to sit still?",
    supertitle,
    type: SCREENING_QUESTION_TYPES.radio,
    options: DailyFrequencies,
  },
  [SCREENING_QUESTION_CODES.easily_annoyed]: {
    title: "Becoming easily annoyed or irritable?",
    supertitle,
    type: SCREENING_QUESTION_TYPES.radio,
    options: DailyFrequencies,
  },
  [SCREENING_QUESTION_CODES.feeling_afraid]: {
    title: "Feeling afraid as if something awful might happen?",
    supertitle,
    type: SCREENING_QUESTION_TYPES.radio,
    options: DailyFrequencies,
  },
} as const;
