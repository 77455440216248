import React from "react";
import { twMerge } from "tailwind-merge";

import { IconProps } from "~/common/utils";

export const LockIcon = ({ className, ...props }: IconProps) => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={twMerge("h-14, w-14", className)}
    >
      <rect width="50" height="50" rx="25" fill={props.fill ?? "#C1D4FB"} />
      <path
        d="M20.4987 21.6244V19.3739C20.4987 16.8882 22.5138 14.873 24.9996 14.873C27.4854 14.873 29.5005 16.8882 29.5005 19.3739V21.6244M20.7238 35.1271H29.2755C30.5358 35.1271 31.166 35.1271 31.6474 34.8818C32.0709 34.666 32.4151 34.3218 32.6309 33.8983C32.8762 33.4169 32.8762 32.7867 32.8762 31.5264V25.2251C32.8762 23.9647 32.8762 23.3346 32.6309 22.8532C32.4151 22.4297 32.0709 22.0854 31.6474 21.8697C31.166 21.6244 30.5358 21.6244 29.2755 21.6244H20.7238C19.4634 21.6244 18.8332 21.6244 18.3518 21.8697C17.9284 22.0854 17.5841 22.4297 17.3683 22.8532C17.123 23.3346 17.123 23.9647 17.123 25.2251V31.5264C17.123 32.7867 17.123 33.4169 17.3683 33.8983C17.5841 34.3218 17.9284 34.666 18.3518 34.8818C18.8332 35.1271 19.4634 35.1271 20.7238 35.1271Z"
        stroke={props.stroke ?? "#6192F3"}
        strokeWidth="2.25045"
        strokeLinecap="round"
      />
    </svg>
  );
};
