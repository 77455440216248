import React from "react";

import {
  QuestionAnswerLayout,
  QuestionAnswerLayoutProps,
} from "./QuestionAnswerLayout";

export const RenderAnswer = ({
  question,
  description,
  answer,
  furtherDescription,
  children,
  isPreview,
  answerClassName,
}: QuestionAnswerLayoutProps) => {
  return (
    <QuestionAnswerLayout
      question={question}
      answerClassName={answerClassName}
      description={description}
      answer={answer}
      furtherDescription={furtherDescription}
      isPreview={isPreview}
    >
      {children}
    </QuestionAnswerLayout>
  );
};
