import {
  INTAKE_QUESTION_CODES,
  INTAKE_QUESTION_TYPES,
} from "~/common/utils/consultations/intake";
import { YesNoNA } from "~/common/utils/consultations/intake/intakeTypes";
import { YesNo } from "../../../screening";

export const yesNoNAQuestions = {
  [INTAKE_QUESTION_CODES.im_in_good_physical_health]: {
    title: "I am in good physical health.",
    type: INTAKE_QUESTION_TYPES.radio,
    options: YesNoNA,
  },
  [INTAKE_QUESTION_CODES.i_have_no_major_health_worries]: {
    title: "I have no major health worries.",
    type: INTAKE_QUESTION_TYPES.radio,
    options: YesNoNA,
  },
  [INTAKE_QUESTION_CODES.there_are_people_i_can_turn_to_if_i_need_help]: {
    title: "There are people I can turn to if I need help.",
    type: INTAKE_QUESTION_TYPES.radio,
    options: YesNoNA,
  },
  [INTAKE_QUESTION_CODES.i_feel_all_alone_in_the_world]: {
    title: "I feel all alone in the world.",
    type: INTAKE_QUESTION_TYPES.radio,
    options: YesNoNA,
  },
  [INTAKE_QUESTION_CODES.im_satisfied_with_my_family_life]: {
    title: "I am satisfied with my family life.",
    type: INTAKE_QUESTION_TYPES.radio,
    options: YesNoNA,
  },
  [INTAKE_QUESTION_CODES.my_relationship_with_my_partner_is_fulfilling]: {
    title: "My relationship with my partner/spouse is fulfilling.",
    type: INTAKE_QUESTION_TYPES.radio,
    options: YesNoNA,
  },
  [INTAKE_QUESTION_CODES.i_have_enough_money_to_meet_my_needs]: {
    title: "I have enough money to meet my needs.",
    type: INTAKE_QUESTION_TYPES.radio,
    options: YesNoNA,
  },
  [INTAKE_QUESTION_CODES.i_dont_worry_about_finances]: {
    title: "I don’t worry about finances.",
    type: INTAKE_QUESTION_TYPES.radio,
    options: YesNoNA,
  },
  [INTAKE_QUESTION_CODES.im_satisfied_with_my_job_or_school_situation]: {
    title: "I am satisfied with my job (or school) situation.",
    type: INTAKE_QUESTION_TYPES.radio,
    options: YesNoNA,
  },
  [INTAKE_QUESTION_CODES.my_career_or_school_is_rewarding_and_fulfilling]: {
    title: "My career (or school) is rewarding and fulfilling.",
    type: INTAKE_QUESTION_TYPES.radio,
    options: YesNoNA,
  },
  [INTAKE_QUESTION_CODES.i_find_it_easy_to_find_enjoyable_and_relaxing_activities]:
    {
      title: "I find it easy to find enjoyable and relaxing activities.",
      type: INTAKE_QUESTION_TYPES.radio,
      options: YesNo,
    },
  [INTAKE_QUESTION_CODES.i_know_how_to_have_fun]: {
    title: "I know how to have fun.",
    type: INTAKE_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [INTAKE_QUESTION_CODES.i_feel_optimistic_about_my_future]: {
    title: "I feel optimistic about my future.",
    type: INTAKE_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [INTAKE_QUESTION_CODES.i_have_always_looked_forward_to_becoming_a_mother]: {
    title: "I have always looked forward to becoming a mother.",
    type: INTAKE_QUESTION_TYPES.radio,
    options: YesNoNA,
  },
  [INTAKE_QUESTION_CODES.motherhood_fills_me_with_joy]: {
    title: "Motherhood fills me with joy.",
    type: INTAKE_QUESTION_TYPES.radio,
    options: YesNoNA,
  },
} as const;
