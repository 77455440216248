import {
  CheckInCodes,
  CompletedAnswerData,
  INPUT_TYPES,
  SideEffectsCheckinCodes,
} from "~/common/utils/types/checkInTypes";

export const getFilteredAnswers = (
  checkinCode: CheckInCodes,
  answers?: CompletedAnswerData[],
): CompletedAnswerData[] | undefined => {
  if (!SideEffectsCheckinCodes.includes(checkinCode)) return answers;

  const filteredAnswers = answers?.filter(
    (answer) => answer.value.answer_type !== INPUT_TYPES.free_text,
  );
  return filteredAnswers?.slice(0, 2);
};
