import { isPast } from "date-fns";
import { twMerge } from "tailwind-merge";

import { PATIENT_MEDICATION_STATUS } from "~/common/constants/medications";
import { PatientMedicationDetail } from "~/common/types";
import { useGetCurrentPatient } from "~/patients/hooks/queries/useGetCurrentPatient";
import { formatDate, generateDiscontinuationTitle } from "~/providers/utils";
import { PatientMedicationDetailsCard } from "./PatientMedicationDetailsLayout";
import { PatientMedicationTable } from "./PatientMedicationTable";

interface CurrentTreatmentProps {
  patientMedication: PatientMedicationDetail;
}

export const CurrentTreatment = ({
  patientMedication,
}: CurrentTreatmentProps) => {
  const { start_date, discontinued_at } = patientMedication;
  const { data: user } = useGetCurrentPatient();

  const { signature_name: providerName } = { ...user?.provider };
  const isDiscontinued = discontinued_at && isPast(new Date(discontinued_at));

  return (
    <div className="flex flex-col gap-3">
      {isDiscontinued && (
        <p className="text-gray-10">
          {formatDate(start_date)} - {formatDate(discontinued_at)}
        </p>
      )}
      <PatientMedicationDetailsCard
        treatmentStatus={
          isDiscontinued
            ? PATIENT_MEDICATION_STATUS.discontinued
            : PATIENT_MEDICATION_STATUS.active
        }
      >
        <h3 className="text-base font-semibold leading-5 text-gray-70">
          {isDiscontinued
            ? generateDiscontinuationTitle(discontinued_at)
            : formatDate(patientMedication.start_date)}
        </h3>
        <div
          className={twMerge(
            "flex w-full flex-col gap-2 overflow-auto rounded-md",
            !isDiscontinued && "border border-gray-300 bg-white p-3",
          )}
        >
          <p className="leading-5 text-gray-10">Message from {providerName}</p>
          <p className="italic text-gray-70">
            {patientMedication.message_to_patient}
          </p>
        </div>
        {!isDiscontinued && (
          <PatientMedicationTable patientMedicationDetail={patientMedication} />
        )}
      </PatientMedicationDetailsCard>
    </div>
  );
};
