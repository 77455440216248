export const MIN_RECORDING_TIME = 15;
export const MAX_RECORDING_TIME = 60;

export const RECORDING_STATES = {
  RECORDING: "recording",
  INACTIVE: "inactive",
  PAUSED: "paused",
} as const;

export const getDescriptiveText = (
  recordingTimer: number,
  isRecording: boolean,
  superTitle?: string,
) => {
  const introduction = superTitle ?? "";
  if (recordingTimer === 0 && !isRecording) {
    return `${introduction} Record an audio between 15 seconds and 1 minute`;
  }
  if (recordingTimer === MAX_RECORDING_TIME && !isRecording) {
    return "1 minute limit reached!";
  }
  if (isRecording && recordingTimer < MIN_RECORDING_TIME) {
    return "Please record at least 15 seconds";
  }
};
