import { Navigate } from "react-router-dom";

import { PATIENT_STATUS } from "~/common/constants";
import { PatientCheckInForms } from "../components/checkIn";
import { useGetCurrentPatient } from "../hooks/queries/useGetCurrentPatient";
import { ROUTES } from "../router";

export const PatientCheckIn = () => {
  const { data: user } = useGetCurrentPatient();

  if (user?.status !== PATIENT_STATUS.ongoing_care)
    return <Navigate to={ROUTES.notFound} />;

  return <PatientCheckInForms />;
};
