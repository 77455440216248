import { INTAKE_QUESTION_CODES } from "./intake/data/intakeQuestionsData";
import { SCREENING_QUESTION_CODES } from "./screening";

export const CONSULTATIONS_CODES = {
  initial_screening: "initial_screening",
  initial_intake: "initial_intake",
} as const;

export type ConsultationsBundleCodes =
  (typeof CONSULTATIONS_CODES)[keyof typeof CONSULTATIONS_CODES];

export const CONSULTATION_QUESTION_CODES = {
  ...SCREENING_QUESTION_CODES,
  ...INTAKE_QUESTION_CODES,
} as const;

export type ConsultationQuestionCodes =
  (typeof CONSULTATION_QUESTION_CODES)[keyof typeof CONSULTATION_QUESTION_CODES];
