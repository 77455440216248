"use client";

import { useState } from "react";

import { LoadingState } from "~/common/components";
import NavMenu from "~/common/components/ui/NavMenu";
import Account from "~/patients/components/account/Account";
import { useGetCurrentPatient } from "../hooks/queries/useGetCurrentPatient";
import { useUiStore } from "../stores/useUiStore";

enum NavMenuOptions {
  Account = "account",
  OuraRing = "ouraRing",
}

export const Settings = () => {
  const { setSideBarOpen } = useUiStore();
  const [selectedMenuValue, setSelectedMenuValue] = useState<NavMenuOptions>(
    NavMenuOptions.Account,
  );

  const menuOptions = [
    {
      label: "Account",
      onClick: () => {
        setSelectedMenuValue(NavMenuOptions.Account);
      },
      value: NavMenuOptions.Account,
      enabled: true,
    },
  ];

  const { data: currentPatient, isLoading } = useGetCurrentPatient();

  return (
    <div className="flex h-screen-calculated w-full flex-col gap-6 text-sm md:gap-8">
      <NavMenu
        title="Settings"
        selectedMenuValue={selectedMenuValue}
        menuOptions={menuOptions}
        handleOpenSideBar={() => setSideBarOpen(true)}
      />
      {isLoading && <LoadingState className="h-screen-calculated" />}
      {selectedMenuValue === NavMenuOptions.Account && currentPatient && (
        <Account currentPatient={currentPatient} />
      )}
      {selectedMenuValue === NavMenuOptions.OuraRing && (
        <h1 className="px-8"> OuraRing</h1>
      )}
    </div>
  );
};
