import { PropsWithChildren, useState } from "react";
import { useNavigate } from "react-router-dom";

import CrossIcon from "~/common/components/icons/CrossIcon";
import { Button } from "~/common/components/ui/button";
import { ROUTES } from "~/patients/router";
import ConfirmExitModal from "../ConfirmExitModal";
import { QuestionaryProgress } from "../ConsultationProgress";

interface ConsultationFormLayoutProps extends PropsWithChildren {
  currentSection: number;
  totalSteps: number;
  description?: string;
  title?: string;
}

export const ConsultationFormLayout = ({
  children,
  totalSteps,
  currentSection,
  title,
}: ConsultationFormLayoutProps) => {
  const [showCloseModal, setShowCloseModal] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="flex h-screen-calculated w-full flex-col items-center gap-12 bg-primary-50 px-6 py-4 text-gray-70 md:gap-12 md:p-9">
      <div className="flex w-full flex-col items-center justify-center gap-3 md:w-2/3">
        <div className="flex w-full items-center justify-between">
          <p className="w-full text-left text-base text-gray-30 md:text-center md:text-base">
            {title}
          </p>
          <Button
            onClick={() => setShowCloseModal(true)}
            variant="outline-none"
            className="absolute right-6 w-fit p-0 md:flex md:items-start"
          >
            <CrossIcon className="h-5 w-5" />
            <span className="hidden md:flex">Close</span>
          </Button>
        </div>
        <QuestionaryProgress step={currentSection} totalSteps={totalSteps} />
      </div>
      {children}
      <ConfirmExitModal
        onClose={() => setShowCloseModal(false)}
        show={showCloseModal}
        onConfirm={() => navigate(ROUTES.leadPatientHome)}
      />
    </div>
  );
};
