import { Navigate, useSearchParams } from "react-router-dom";

import { EmptyState, LoadingState } from "~/common/components";
import { PATIENT_STATUS } from "~/common/constants";
import { useDeleteQueryParams } from "~/common/hooks";
import {
  CHECKIN_STATUS,
  InteractionTypes,
  PatientCheckInBase,
} from "~/common/utils/types/checkInTypes";
import {
  CompletedCheckinCard,
  CompletedCheckInDrawer,
} from "~/patients/components";
import { useGetPatientCheckIns } from "~/patients/hooks";
import { useGetCurrentPatient } from "~/patients/hooks/queries/useGetCurrentPatient";
import { ROUTES } from "~/patients/router";

export const CompletedCheckins = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { deleteQueryParams } = useDeleteQueryParams();

  const { data: user, isLoading: isUserLoading } = useGetCurrentPatient();

  const patientCheckinId = searchParams.get("id");

  const handleCloseCheckInDrawer = () => deleteQueryParams(["id"]);

  const handleViewCheckIn = (checkIn: PatientCheckInBase) =>
    setSearchParams({ id: checkIn.id });

  const { data: completedCheckIns, isLoading: isLoadingCompletedCheckIns } =
    useGetPatientCheckIns(
      false,
      true,
      CHECKIN_STATUS.completed,
      InteractionTypes,
    );

  const { data: patientCheckIns } = { ...completedCheckIns };

  const selectedCheckIn = patientCheckIns?.find(
    (checkin) => checkin.id === patientCheckinId,
  );

  if (isLoadingCompletedCheckIns || isUserLoading) return <LoadingState />;

  if (user?.status !== PATIENT_STATUS.ongoing_care)
    return <Navigate to={ROUTES.notFound} />;

  return (
    <>
      {patientCheckIns && patientCheckIns.length > 0 ? (
        <div className="flex flex-col gap-3 p-6 lg:px-32 lg:py-9">
          {patientCheckIns.map((checkIn) => (
            <CompletedCheckinCard
              patientCheckIn={checkIn}
              key={checkIn.id}
              handleViewCheckin={() => handleViewCheckIn(checkIn)}
            />
          ))}
        </div>
      ) : (
        <EmptyState message="There are no completed check-ins yet." />
      )}
      <CompletedCheckInDrawer
        show={!!patientCheckinId}
        patientCheckIn={selectedCheckIn}
        handleClose={handleCloseCheckInDrawer}
      />
    </>
  );
};
