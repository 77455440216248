import {
  CompletePatientScreeningResponse,
  PatientScreeningBundle,
  PatientScreeningQuestionResponse,
  ScreeningDataResponse,
  ScreeningQuestionAnswer,
  StartPatientScreeningResponse,
} from "~/common/utils/consultations/screening";
import { api } from "~/patients/api";

export const screeningPath = "/v1/patients/screenings/";

export const getCurrentScreeningQuestion = async (
  patient_screening_id: string,
) => {
  const { data } = await api.get<PatientScreeningQuestionResponse | null>(
    `${screeningPath}${patient_screening_id}/current`,
  );

  return data;
};

export const getScreening = async (screening_id: string) => {
  const { data } = await api.get<ScreeningDataResponse>(
    `${screeningPath}${screening_id}`,
  );

  return data;
};

export const goBackOneQuestion = async (patient_screening_id: string) => {
  const { data } = await api.post<null>(
    `${screeningPath}${patient_screening_id}/previous`,
    {},
  );

  return { data };
};

export const answerQuestion = async (
  answer: ScreeningQuestionAnswer,
  patient_screening_id: string,
) => {
  const { data } = await api.post<PatientScreeningQuestionResponse | null>(
    `${screeningPath}${patient_screening_id}/answers`,
    answer,
  );

  return { data };
};

export const completeScreening = async (patient_screening_id: string) => {
  const { data } = await api.post<CompletePatientScreeningResponse | null>(
    `${screeningPath}${patient_screening_id}/complete`,
    {},
  );

  return data;
};

export const startScreening = async (screening_id: string) => {
  const { data } = await api.post<StartPatientScreeningResponse>(
    `${screeningPath}${screening_id}/start`,
    {},
  );

  return data;
};

export const screeningIntroScreenVisit = async () => {
  const response = await api.patch<void>(
    "/v1/patients/screening-intro-screen/visit",
    null,
  );
  return response.data;
};

export const getCurrentScreeningBundle = async () => {
  const { data } = await api.get<PatientScreeningBundle>(
    `${screeningPath}current/bundles`,
  );
  return data;
};
