import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { twMerge } from "tailwind-merge";
import { z } from "zod";

import { replaceUnderscores } from "~/common/utils";
import { YES_NO_NA_TYPES } from "~/common/utils/consultations/intake";
import {
  CompletedRadioAnswer,
  ConsultationRadioAnswer,
  RadioQuestionData,
} from "~/common/utils/consultations/shared";
import { ConsultationQuestionsLayout } from "~/patients/components";
import RadioButtonOption from "~/patients/components/ui/RadioButtonOption";
import { createRadioButtonSchema } from "~/patients/utils";

export interface RadioFormProps {
  question: RadioQuestionData;
  handleGoBack: () => void;
  onSubmit: (answer: ConsultationRadioAnswer) => void;
  isSubmitting: boolean;
  disableBack: boolean;
  completedAnswer: CompletedRadioAnswer;
}

export const RadioForm = ({
  question,
  handleGoBack,
  onSubmit,
  isSubmitting,
  disableBack,
  completedAnswer,
}: RadioFormProps) => {
  const { options, title, supertitle, subtitle } = question;
  const schema = createRadioButtonSchema(options);

  type RadioFormValues = z.infer<typeof schema>;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<RadioFormValues>({
    defaultValues: { answer: completedAnswer?.value },
    resolver: zodResolver(schema),
  });

  const saveAnswers: SubmitHandler<RadioFormValues> = (data) => {
    const input: ConsultationRadioAnswer = {
      answer: { value: data.answer },
    };
    onSubmit(input);
  };

  return (
    <ConsultationQuestionsLayout
      handleNext={handleSubmit(saveAnswers)}
      handleBack={handleGoBack}
      disableNext={!watch("answer")}
      errors={errors.answer?.message}
      isSubmitting={isSubmitting}
      title={title}
      subtitle={subtitle}
      supertitle={supertitle}
      disableBack={disableBack}
    >
      {options.map((option) => (
        <RadioButtonOption
          key={option}
          label={replaceUnderscores(option)}
          value={option}
          labelClassName={twMerge(option === YES_NO_NA_TYPES.na && "uppercase")}
          isSelected={watch("answer") === option}
          {...register("answer")}
        />
      ))}
    </ConsultationQuestionsLayout>
  );
};
