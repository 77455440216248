import BlueCheckIcon from "~/common/components/icons/BlueCheckIcon";
import { WaveDesktop, WaveMobile } from "~/patients/assets";
import { useBreakpoint } from "~/patients/hooks";

export const AllSetCard = () => {
  const isDesktop = useBreakpoint("md");
  return (
    <div className="relative flex flex-row items-center justify-between overflow-hidden rounded-lg bg-primary-500 p-4 text-white md:px-6 ">
      <div className="flex h-full flex-col justify-center ">
        <h1 className="text-xl font-medium">All set!</h1>
        <p className="w-5/6 text-base font-light md:w-full">
          You have successfully completed all your check-ins!
        </p>
      </div>
      <div className="z-50">
        <BlueCheckIcon />
      </div>
      <img
        className="absolute -right-1 top-0 h-full"
        src={isDesktop ? WaveDesktop : WaveMobile}
        alt="Wave"
      />
    </div>
  );
};
