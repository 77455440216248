import { Button } from "~/common/components";
import { BUTTON_VARIANTS } from "~/common/constants";
import { ScreeningStatusData } from "~/common/utils/consultations/screening";
import { CheckListCardLayout } from "~/patients/components/consultations/shared/ui/cards/CheckListCardLayout";
import { useScreeningItem } from "~/patients/hooks/consultations";

interface ScreeningCheckListCardProps {
  screening: ScreeningStatusData;
  title: string;
}

export const ScreeningCheckListCard = ({
  screening,
  title,
}: ScreeningCheckListCardProps) => {
  const { id, status, duration } = screening;
  const {
    handleComplete,
    isUnavailable,
    isCompleted,
    isPending,
    isInProgress,
  } = useScreeningItem(id, status);

  const subtitle = isCompleted ? "Completed" : duration;
  const buttonLabel = isInProgress ? "Resume" : "Start";

  return (
    <CheckListCardLayout
      title={title}
      subtitle={subtitle}
      isCompleted={isCompleted}
      isPending={isPending}
      isUnavailable={isUnavailable}
    >
      {isPending && (
        <Button
          variant={BUTTON_VARIANTS.outline_black}
          size="sm"
          onClick={handleComplete}
        >
          {buttonLabel}
        </Button>
      )}
    </CheckListCardLayout>
  );
};
