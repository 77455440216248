export const RecordingAnimation = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="114"
    height="49"
    viewBox="0 0 114 49"
    fill="none"
  >
    <g id="bars">
      <rect
        id="bar"
        y="21.7148"
        width="5.42857"
        height="5.42857"
        rx="2.71429"
        fill="#E36732"
      >
        <animate
          attributeName="y"
          values="21.7148;8.30469;19.1621;0.162109;16.4482;0.162109;19.1621;8.30469;21.7148"
          begin="0s"
          dur="4s"
          repeatCount="indefinite"
          fill="freeze"
          calcMode="linear"
          keyTimes="0;0.125;0.25;0.375;0.5;0.625;0.75;0.875;1"
        />
        <animate
          attributeName="height"
          values="5.42857;32.5714;10.8571;48.8571;16.2857;48.8571;10.8571;32.5714;5.42857"
          begin="0s"
          dur="4s"
          repeatCount="indefinite"
          fill="freeze"
          calcMode="linear"
          keyTimes="0;0.125;0.25;0.375;0.5;0.625;0.75;0.875;1"
        />
      </rect>
      <rect
        id="bar_2"
        x="13.5713"
        y="21.7148"
        width="5.42857"
        height="5.42857"
        rx="2.71429"
        fill="#E36732"
      >
        <animate
          attributeName="x"
          values="13.5713;13.5715;13.5715;13.5713;13.5715;13.5713;13.5715;13.5715;13.5713"
          begin="0s"
          dur="4s"
          repeatCount="indefinite"
          fill="freeze"
          calcMode="linear"
          keyTimes="0;0.125;0.25;0.375;0.5;0.625;0.75;0.875;1"
        />
        <animate
          attributeName="y"
          values="21.7148;16.4482;8.30469;13.7334;8.30469;13.7334;8.30469;16.4482;21.7148"
          begin="0s"
          dur="4s"
          repeatCount="indefinite"
          fill="freeze"
          calcMode="linear"
          keyTimes="0;0.125;0.25;0.375;0.5;0.625;0.75;0.875;1"
        />
        <animate
          attributeName="height"
          values="5.42857;16.2857;32.5714;21.7143;32.5714;21.7143;32.5714;16.2857;5.42857"
          begin="0s"
          dur="4s"
          repeatCount="indefinite"
          fill="freeze"
          calcMode="linear"
          keyTimes="0;0.125;0.25;0.375;0.5;0.625;0.75;0.875;1"
        />
      </rect>
      <rect
        id="bar_3"
        x="27.1428"
        y="21.7148"
        width="5.42857"
        height="5.42857"
        rx="2.71429"
        fill="#E36732"
      >
        <animate
          attributeName="y"
          values="21.7148;11.0195;20.5195;16.4482;19.1621;16.4482;20.5195;11.0195;21.7148"
          begin="0s"
          dur="4s"
          repeatCount="indefinite"
          fill="freeze"
          calcMode="linear"
          keyTimes="0;0.125;0.25;0.375;0.5;0.625;0.75;0.875;1"
        />
        <animate
          attributeName="height"
          values="5.42857;27.1429;8.14286;16.2857;10.8571;16.2857;8.14286;27.1429;5.42857"
          begin="0s"
          dur="4s"
          repeatCount="indefinite"
          fill="freeze"
          calcMode="linear"
          keyTimes="0;0.125;0.25;0.375;0.5;0.625;0.75;0.875;1"
        />
      </rect>
      <rect
        id="bar_4"
        x="40.7141"
        y="21.7148"
        width="5.42857"
        height="5.42857"
        rx="2.71429"
        fill="#E36732"
      >
        <animate
          attributeName="y"
          values="21.7148;13.7334;2.87598;13.7334;21.877;13.7334;2.87598;13.7334;21.7148"
          begin="0s"
          dur="4s"
          repeatCount="indefinite"
          fill="freeze"
          calcMode="linear"
          keyTimes="0;0.125;0.25;0.375;0.5;0.625;0.75;0.875;1"
        />
        <animate
          attributeName="height"
          values="5.42857;21.7143;43.4286;21.7143;5.42857;21.7143;43.4286;21.7143;5.42857"
          begin="0s"
          dur="4s"
          repeatCount="indefinite"
          fill="freeze"
          calcMode="linear"
          keyTimes="0;0.125;0.25;0.375;0.5;0.625;0.75;0.875;1"
        />
      </rect>
      <rect
        id="bar_5"
        x="54.2856"
        y="21.7148"
        width="5.42857"
        height="5.42857"
        rx="2.71429"
        fill="#E36732"
      >
        <animate
          attributeName="y"
          values="21.7148;0.162109;13.7334;0.162109;1.51953;0.162109;13.7334;0.162109;21.7148"
          begin="0s"
          dur="4s"
          repeatCount="indefinite"
          fill="freeze"
          calcMode="linear"
          keyTimes="0;0.125;0.25;0.375;0.5;0.625;0.75;0.875;1"
        />
        <animate
          attributeName="height"
          values="5.42857;48.8571;21.7143;48.8571;46.1429;48.8571;21.7143;48.8571;5.42857"
          begin="0s"
          dur="4s"
          repeatCount="indefinite"
          fill="freeze"
          calcMode="linear"
          keyTimes="0;0.125;0.25;0.375;0.5;0.625;0.75;0.875;1"
        />
      </rect>
      <rect
        id="bar_6"
        x="67.8572"
        y="21.7148"
        width="5.42857"
        height="5.42857"
        rx="2.71429"
        fill="#E36732"
      >
        <animate
          attributeName="y"
          values="21.7148;2.87598;17.8047;11.0195;8.30469;11.0195;17.8047;2.87598;21.7148"
          begin="0s"
          dur="4s"
          repeatCount="indefinite"
          fill="freeze"
          calcMode="linear"
          keyTimes="0;0.125;0.25;0.375;0.5;0.625;0.75;0.875;1"
        />
        <animate
          attributeName="height"
          values="5.42857;43.4286;13.5714;27.1429;32.5714;27.1429;13.5714;43.4286;5.42857"
          begin="0s"
          dur="4s"
          repeatCount="indefinite"
          fill="freeze"
          calcMode="linear"
          keyTimes="0;0.125;0.25;0.375;0.5;0.625;0.75;0.875;1"
        />
      </rect>
      <rect
        id="bar_7"
        x="81.4287"
        y="21.7148"
        width="5.42857"
        height="5.42857"
        rx="2.71429"
        fill="#E36732"
      >
        <animate
          attributeName="x"
          values="81.4287;81.4285;81.4285;81.4285;81.4285;81.4285;81.4285;81.4285;81.4287"
          begin="0s"
          dur="4s"
          repeatCount="indefinite"
          fill="freeze"
          calcMode="linear"
          keyTimes="0;0.125;0.25;0.375;0.5;0.625;0.75;0.875;1"
        />
        <animate
          attributeName="y"
          values="21.7148;15.0908;12.376;19.1621;17.8047;19.1621;12.376;15.0908;21.7148"
          begin="0s"
          dur="4s"
          repeatCount="indefinite"
          fill="freeze"
          calcMode="linear"
          keyTimes="0;0.125;0.25;0.375;0.5;0.625;0.75;0.875;1"
        />
        <animate
          attributeName="height"
          values="5.42857;19;24.4286;10.8571;13.5714;10.8571;24.4286;19;5.42857"
          begin="0s"
          dur="4s"
          repeatCount="indefinite"
          fill="freeze"
          calcMode="linear"
          keyTimes="0;0.125;0.25;0.375;0.5;0.625;0.75;0.875;1"
        />
      </rect>
      <rect
        id="bar_8"
        x="95"
        y="21.7148"
        width="5.42857"
        height="5.42857"
        rx="2.71429"
        fill="#E36732"
      >
        <animate
          attributeName="y"
          values="21.7148;8.30469;20.5195;13.7334;16.4482;13.7334;20.5195;8.30469;21.7148"
          begin="0s"
          dur="4s"
          repeatCount="indefinite"
          fill="freeze"
          calcMode="linear"
          keyTimes="0;0.125;0.25;0.375;0.5;0.625;0.75;0.875;1"
        />
        <animate
          attributeName="height"
          values="5.42857;32.5714;8.14286;21.7143;16.2857;21.7143;8.14286;32.5714;5.42857"
          begin="0s"
          dur="4s"
          repeatCount="indefinite"
          fill="freeze"
          calcMode="linear"
          keyTimes="0;0.125;0.25;0.375;0.5;0.625;0.75;0.875;1"
        />
      </rect>
      <rect
        id="bar_9"
        x="108.572"
        y="21.7148"
        width="5.42857"
        height="5.42857"
        rx="2.71429"
        fill="#E36732"
      >
        <animate
          attributeName="y"
          values="21.7148;16.4482;8.30469;15.0908;6.94824;15.0908;8.30469;16.4482;21.7148"
          begin="0s"
          dur="4s"
          repeatCount="indefinite"
          fill="freeze"
          calcMode="linear"
          keyTimes="0;0.125;0.25;0.375;0.5;0.625;0.75;0.875;1"
        />
        <animate
          attributeName="height"
          values="5.42857;16.2857;32.5714;19;35.2857;19;32.5714;16.2857;5.42857"
          begin="0s"
          dur="4s"
          repeatCount="indefinite"
          fill="freeze"
          calcMode="linear"
          keyTimes="0;0.125;0.25;0.375;0.5;0.625;0.75;0.875;1"
        />
      </rect>
    </g>
  </svg>
);
