import { Drawer } from "~/common/components/ui/Drawer";
import { checkInUtilities } from "~/common/utils/checkIn/checkInUtilities.ts";
import {
  CHECK_IN_CODES,
  PatientCheckInBase,
} from "~/common/utils/types/checkInTypes.ts";
import { useGetPatientCheckInWithAnswers } from "~/patients/hooks/queries/check-ins/useGetPatientCheckInWithAnswers.ts";
import { CompletedAnswers } from "./CompletedAnswers";
import { ScheduledAppointment } from "./ScheduledAppointment";

interface CompletedCheckInDrawerProps {
  show: boolean;
  handleClose: () => void;
  patientCheckIn?: PatientCheckInBase;
}
export const CompletedCheckInDrawer = ({
  show,
  handleClose,
  patientCheckIn,
}: CompletedCheckInDrawerProps) => {
  const { formatCheckInCode } = checkInUtilities();

  const { data: checkInWithAnswers, isLoading } =
    useGetPatientCheckInWithAnswers({
      patientCheckInId: patientCheckIn?.id,
    });

  return (
    <Drawer isOpen={show} onClose={handleClose}>
      {patientCheckIn?.checkin.code === CHECK_IN_CODES.schedule_appointment ? (
        <ScheduledAppointment details={checkInWithAnswers} />
      ) : (
        <CompletedAnswers
          details={checkInWithAnswers}
          medication={formatCheckInCode(patientCheckIn?.checkin.med_type)}
          isLoading={isLoading}
        />
      )}
    </Drawer>
  );
};
