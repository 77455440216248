import { UseFormWatch } from "react-hook-form";

import {
  JobsFormValues,
  ListJobsFormValues,
} from "~/patients/utils/consultations/intakes";

export const useJobItem = () => {
  const addEmptyJob = (append: (value: JobsFormValues) => void) =>
    append({
      date_ended: "",
      date_started: "",
      job_title: "",
      place_of_employment: "",
      why_did_you_leave: "",
    });

  const isEmptyJobInput = (
    index: number,
    fields: JobsFormValues[],
    watch: UseFormWatch<ListJobsFormValues>,
  ) => {
    if (index >= fields.length) return true;
    const jobs = watch(`answer.jobs_you_have_held.${index}`);
    return (
      !jobs.date_ended &&
      !jobs.date_started &&
      !jobs.job_title &&
      !jobs.place_of_employment &&
      !jobs.why_did_you_leave
    );
  };

  return { addEmptyJob, isEmptyJobInput };
};
