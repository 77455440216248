import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { ErrorResponse, signUp, SignUpResponse } from "~/patients/api";
import { SignUpUserDataRequest } from "~/patients/utils";

export const useCreateActivePatient = (
  onSignUpSuccess: (data: SignUpResponse) => void,
) => {
  const { mutate: signUpMutation, isPending } = useMutation({
    mutationFn: ({
      userData,
      token,
    }: {
      userData: SignUpUserDataRequest;
      token: string;
    }) => signUp(userData, token),
    onSuccess: (data) => {
      onSignUpSuccess(data);
    },
    onError: (error: ErrorResponse) => {
      const errorMessage =
        error.response?.data.detail ??
        "There was an issue registering your account. Please try again later.";
      toast.error(errorMessage);
    },
  });

  return { signUpMutation, isPending };
};
