import { useMutation, useQueryClient } from "@tanstack/react-query";

import { setDefaultPharmacy } from "~/patients/api/pharmacies";

export const useSetDefaultPharmacy = () => {
  const queryClient = useQueryClient();

  const { mutate: setDefaultPharmacyMutation, isPending } = useMutation({
    mutationFn: (pharmacyId: string) => setDefaultPharmacy(pharmacyId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["getPatientPharmacies"],
      });
    },
  });

  return { setDefaultPharmacyMutation, isPending };
};
