import { ResolveIssueFormValuesType } from "~/patients/utils";
import { useResolveIssue } from "./useResolveIssue";

export const useOnSubmitIssue = (handleClose: () => void) => {
  const { resolveIssueMutation, resolveIssueWithPharmacy, isPending } =
    useResolveIssue(handleClose);

  const requestResolveIssue = (
    formData: ResolveIssueFormValuesType,
    resolveIssueId?: string,
  ) => {
    formData.isNewPharmacy
      ? resolveIssueWithPharmacy({
          id: resolveIssueId,
          pharmacy: formData.newPharmacy,
          formData: {
            pre_authorization_request: formData.pre_authorization_request,
            reason: formData.reason,
          },
        })
      : resolveIssueMutation({
          id: resolveIssueId,
          formData: {
            patient_pharmacy_id: formData.pharmacy,
            pre_authorization_request: formData.pre_authorization_request,
            reason: formData.reason,
          },
        });
  };

  return { requestResolveIssue, isPending };
};
