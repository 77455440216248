import { create } from "zustand";
import { persist } from "zustand/middleware";

export interface NavMenuStore {
  totalToDoCheckIns?: number;
  setTotalToDoCheckIns: (total: number) => void;
}

export const useNavMenuStore = create<NavMenuStore>()(
  persist(
    (set) => ({
      totalToDoCheckIns: undefined,
      setTotalToDoCheckIns: (totalToDoCheckIns: number) => {
        set(() => ({ totalToDoCheckIns }));
      },
    }),
    {
      name: "useNavMenuStore",
    },
  ),
);
