import { UseFormRegister } from "react-hook-form";

import { TransitionEffect } from "~/common/components";
import TextArea from "~/common/components/ui/Textarea";
import {
  ConsultationMultiCheckAnswer,
  ConsultationMultiCheckValue,
  MultiCheckOptions,
} from "~/common/utils/consultations/shared";
import QuestionaryCheckBox from "./QuestionaryCheckBox";

export interface MultiCheckOptionProps {
  option: MultiCheckOptions;
  selectedValues: ConsultationMultiCheckValue[];
  register: UseFormRegister<ConsultationMultiCheckAnswer>;
  handleCheck: (option: MultiCheckOptions, selectedIndex: number) => void;
}
export const MultiCheckOption = ({
  option,
  selectedValues,
  register,
  handleCheck,
}: MultiCheckOptionProps) => {
  const selectedIndex = selectedValues.findIndex(
    (item) => item.value === option.value,
  );
  const isSelected = selectedIndex !== -1;

  return (
    <div key={option.value} className="flex flex-col gap-2">
      {option.title && (
        <h3 className="pt-2 text-lg font-medium leading-5">{option.title}</h3>
      )}
      <QuestionaryCheckBox
        id={option.value}
        value={option.value}
        checked={isSelected}
        onChange={() => handleCheck(option, selectedIndex)}
      >
        <span className="first-letter:capitalize">{option.label}</span>
      </QuestionaryCheckBox>

      {isSelected && option.asksExtraInfo && (
        <TransitionEffect>
          <TextArea
            rows={4}
            placeholder="Enter further description (optional)"
            className="text-base font-normal placeholder:text-base placeholder:not-italic placeholder:text-gray-60"
            {...register(`answer.values.${selectedIndex}.extraData`)}
          />
        </TransitionEffect>
      )}
    </div>
  );
};
