import { useNavigate } from "react-router-dom";

import { InternalError } from "~/common/assets";
import { DescriptiveErrorLayout } from "~/common/components/layouts/DescriptiveErrorLayout";
import { useGetCurrentPatient } from "../hooks/queries/useGetCurrentPatient";
import { getHomePath } from "../utils";

export const InternalErrorScreen = () => {
  const { data: user } = useGetCurrentPatient();
  const navigate = useNavigate();
  const homePath = getHomePath(user?.status);

  return (
    <DescriptiveErrorLayout
      errorType="500"
      title="Internal Server Error"
      description="Sorry, there were some technical issues while processing your request."
      handleBackToHome={() => navigate(homePath)}
    >
      <img src={InternalError} alt="error 500" />
    </DescriptiveErrorLayout>
  );
};
