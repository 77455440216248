import { INTAKE_CODES } from "./intakeQuestionsData";

export const intakeSectionTitle = {
  [INTAKE_CODES.ASSOCIATIONS]: "Associations",
  [INTAKE_CODES.EDUCATION_WORK_LEISURE]: "Education, work & leisure",
  [INTAKE_CODES.THE_PEOPLE_IN_YOUR_LIFE]: "The people in your life",
  [INTAKE_CODES.WHAT_ARE_YOU_EXPERIENCING]: "What are you experiencing",
  [INTAKE_CODES.WHERE_YOUR_STORY_BEGINS]: "Where your story begins",
  [INTAKE_CODES.YOUR_HEALTH_HISTORY_INTAKE]: "Your health story",
} as const;
