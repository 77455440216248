export const BUTTON_VARIANTS = {
  primary: "primary",
  outline: "outline",
  outline_black: "outline-black",
  outline_white: "outline-white",
  outline_none: "outline-none",
  tertiary_link: "tertiary-link",
  outline_none_underline: "outline-none-underline",
} as const;

export const TABLE_DATA_VARIANTS = {
  outlined: "outlined",
} as const;

export const RADIO_BUTTON_VARIANTS = {
  simple: "simple",
  button: "button",
  left_radio: "left_radio",
} as const;
