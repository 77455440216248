import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import { RECORDING_STATES } from "~/patients/utils";
import { useRecordingStore } from "../stores/useRecordingStore";

export const useAudioRecorder = () => {
  const [recordingTimer, setRecordingTimer] = useState(0);
  const [recordedBlob, setRecordedBlob] = useState<Blob | undefined>();

  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const intervalRef = useRef<number | null>(null);

  const { setUserRecording, userRecording } = useRecordingStore();

  const isRecording =
    mediaRecorderRef.current?.state === RECORDING_STATES.RECORDING;

  const stopRecordingAction = (stream: MediaStream) => {
    const blob = new Blob(audioChunksRef.current, {
      type: "audio/wav",
    });
    setRecordedBlob(blob);
    audioChunksRef.current = [];
    stream.getTracks().forEach((track) => track.stop());
    clearInterval(intervalRef.current!);
    setUserRecording(false);
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      setUserRecording(true);
      setRecordingTimer(0);

      mediaRecorderRef.current.onstop = () => stopRecordingAction(stream);

      mediaRecorderRef.current.start();

      intervalRef.current = window.setInterval(() => {
        setRecordingTimer((prevTime) => prevTime + 1);
      }, 1000);
    } catch (error) {
      if (error instanceof Error) {
        if (error.name === "NotAllowedError") {
          toast.error(
            "Microphone access denied. Please allow access to start recording.",
          );
        } else if (error.name === "NotFoundError") {
          toast.error(
            "No microphone found. Please ensure your microphone is connected.",
          );
        } else {
          toast.error("An error occurred while trying to start recording.");
        }
      } else {
        toast.error("An error occurred while trying to start recording.");
      }
    }
  };

  const stopRecording = () => mediaRecorderRef.current?.stop();

  const stopAndDeleteRecording = () => {
    stopRecording();
    setRecordedBlob(undefined);
  };

  useEffect(() => {
    if (!isRecording && intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  }, [isRecording]);

  useEffect(() => {
    if (userRecording || !isRecording) return;
    stopAndDeleteRecording();
  }, [userRecording]);

  return {
    recordingTimer,
    recordedBlob,
    isRecording,
    stopRecording,
    startRecording,
  };
};
