import { CloseButton, SectionHeader, Typography } from "~/common/components";
import { TYPOGRAPHY_VARIANTS } from "~/common/utils";

interface HeaderProps {
  title: string;
  handleGoBack: () => void;
  subtitle?: string;
  superTitle?: string;
}

export const Header = ({
  title,
  subtitle,
  superTitle,
  handleGoBack,
}: HeaderProps) => (
  <div className="sticky z-20 flex h-fit w-full border-b border-primary-100 text-base shadow-sm">
    <div className="flex w-full flex-col gap-4 bg-primary-20 p-6 pb-4 md:px-14 md:pt-8">
      <CloseButton onClose={handleGoBack} />
      <div className="flex w-full flex-col gap-2">
        {superTitle && (
          <Typography variant={TYPOGRAPHY_VARIANTS.secondary}>
            {superTitle}
          </Typography>
        )}
        <SectionHeader className="text-3xl font-medium">{title}</SectionHeader>
        {subtitle && (
          <Typography variant={TYPOGRAPHY_VARIANTS.secondary}>
            {subtitle}
          </Typography>
        )}
      </div>
    </div>
  </div>
);
