import {
  CHECK_IN_CODES,
  checkInUtilities,
  formatMedicationName,
  PatientCheckInData,
  weeksSince,
} from "~/common/utils";
import { IntroductionText } from "~/patients/components";

export interface IntroductionTextsProps {
  patientCheckInData?: PatientCheckInData;
  providerName?: string;
}

const { formatCheckInCode } = checkInUtilities();

export const checkInIntroductionTexts = ({
  patientCheckInData,
  providerName,
}: IntroductionTextsProps) => {
  const { start_date, extra_data, patient_bundle, checkin } = {
    ...patientCheckInData,
  };

  const { medication } = { ...patient_bundle?.patient_medication };
  const weeksSinceStartedMedication = weeksSince(start_date);
  const medicationName = medication?.generic_name;

  return {
    [CHECK_IN_CODES.antidepressants_side_effects]: {
      title: `${formatMedicationName(medication)} Side Effects`,
      description: (
        <IntroductionText>
          <p>
            It&apos;s been about {weeksSinceStartedMedication} since you started{" "}
            {medicationName}. Let&apos;s check-in to see how you&apos;re doing.
          </p>
          <p>Please answer the following questions as accurately as you can.</p>
        </IntroductionText>
      ),
    },
    [CHECK_IN_CODES.benzodiazepines_side_effects]: {
      title: `${formatMedicationName(medication)} Side Effects`,
      description: (
        <IntroductionText>
          <p>
            It&apos;s been about {weeksSinceStartedMedication} since you started{" "}
            {medicationName}. Let&apos;s check-in to see how you&apos;re doing.{" "}
          </p>
          <p>Please answer the following questions as accurately as you can.</p>
        </IntroductionText>
      ),
    },
    [CHECK_IN_CODES.basic_labs]: {
      title: formatCheckInCode(checkin?.code),
      description: (
        <IntroductionText>
          <p>Please obtain bloodwork containing the following tests:</p>
          <p>Results should be from within the last 90 days.</p>
          <p className="font-medium">
            CBC with platelets, TSH with reflex, Basic metabolic profile, Liver
            function tests, B12, Folate, Vitamin D
          </p>
        </IntroductionText>
      ),
    },
    [CHECK_IN_CODES.psychostimulants_side_effects]: {
      title: `${formatMedicationName(medication)} Side Effects`,
      description: (
        <IntroductionText>
          <p>
            It&apos;s been about {weeksSinceStartedMedication} since you started{" "}
            {medicationName}. Let&apos;s check-in to see how you&apos;re doing.{" "}
          </p>
          <p>Please answer the following questions as accurately as you can.</p>
        </IntroductionText>
      ),
    },
    [CHECK_IN_CODES.status_check]: {
      title: formatCheckInCode(checkin?.code),
      description: (
        <IntroductionText>
          Let {providerName} know what&apos;s going on. Find a time and place
          where you would be comfortable sharing a brief recording.
        </IntroductionText>
      ),
    },
    [CHECK_IN_CODES.blood_pressure_heart_rate]: {
      title: formatCheckInCode(checkin?.code),
      description: (
        <IntroductionText>
          It&apos;s time to record your current blood pressure and heart rate.
          You&apos;ll need access to a blood pressure cuff.
        </IntroductionText>
      ),
    },
    [CHECK_IN_CODES.blood_pressure_heart_rate_recurrent]: {
      title: formatCheckInCode(checkin?.code),
      description: (
        <IntroductionText>
          It&apos;s time to record your current blood pressure and heart rate.
          You&apos;ll need access to a blood pressure cuff.
        </IntroductionText>
      ),
    },
    [CHECK_IN_CODES.weight]: {
      title: formatCheckInCode(checkin?.code),
      description: (
        <IntroductionText>
          It&apos;s time to record your current weight. You&apos;ll need access
          to a scale.
        </IntroductionText>
      ),
    },
    [CHECK_IN_CODES.schedule_appointment]: {
      title: formatCheckInCode(checkin?.code),
      description: (
        <IntroductionText>
          <p>
            Please contact {providerName} to schedule a {extra_data?.duration}{" "}
            appointment.
          </p>
          <p>
            Once your appointment is scheduled you can click below to{" "}
            <span className="font-medium">mark this check-in as done. </span>
          </p>
        </IntroductionText>
      ),
    },
  };
};
