import { twMerge } from "tailwind-merge";

import {
  AnswersRenderer,
  DateSquaredLayout,
  H2,
  LoadingState,
  NoteData,
} from "~/common/components";
import { checkInUtilities, CheckInWithAnswers } from "~/common/utils";
import { useGetCurrentPatient } from "~/patients/hooks/queries/useGetCurrentPatient";
import { isSideEffectCode } from "~/providers/utils";

interface CompletedAnswersProps {
  details?: CheckInWithAnswers;
  medication: string;
  isLoading: boolean;
}

export const CompletedAnswers = ({
  details,
  medication,
  isLoading,
}: CompletedAnswersProps) => {
  const { formatCheckInCode } = checkInUtilities();

  const { data: user } = useGetCurrentPatient();
  const { signature_name: providerName } = { ...user?.provider };
  const { checkin, completed_at, note, answers } = { ...details };

  if (isLoading) return <LoadingState className="h-screen-calculated" />;

  return (
    <div className="flex w-full flex-col gap-4 overflow-y-auto">
      <div className="inset-0 flex w-full justify-between">
        <div className="flex w-full flex-col items-start justify-center">
          <H2>{formatCheckInCode(checkin?.code)}</H2>
          {checkin && isSideEffectCode(checkin?.code) && (
            <p className="font-light">{medication}</p>
          )}
        </div>
        {completed_at && <DateSquaredLayout date={completed_at} />}
      </div>
      <div className="flex flex-col gap-4">
        {note && (
          <NoteData
            seeMoreButton
            title={`Message from ${providerName}`}
            note={note}
          />
        )}
        {answers && (
          <AnswersRenderer
            answerClassName="p-2"
            className={twMerge(note && "pt-6")}
            answers={answers}
            questions={checkin?.questions}
          />
        )}
      </div>
    </div>
  );
};
