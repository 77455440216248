import { ReactNode } from "react";
import { isAfter } from "date-fns";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { Button, H2 } from "~/common/components";
import { ChevronIcon } from "~/common/components/icons";
import { BUTTON_SIZES, BUTTON_VARIANTS, DIRECTIONS } from "~/common/constants";
import { PATIENT_MEDICATION_STATUS } from "~/common/constants/medications";
import { PatientMedicationStatus } from "~/common/types";
import { ROUTES } from "~/patients/router";

interface CurrentPastMedicationDetailsProps {
  title: ReactNode;
  subtitle: string;
  status?: PatientMedicationStatus;
  handleRequestScript?: () => void;
  handleClick: () => void;
  latestScriptRequestDate?: string;
  discontinuedAt?: string;
}

export const PatientMedicationCard = ({
  title,
  subtitle,
  status,
  discontinuedAt,
  latestScriptRequestDate,
  handleRequestScript,
  handleClick,
}: CurrentPastMedicationDetailsProps) => {
  const isCurrent = discontinuedAt
    ? isAfter(new Date(discontinuedAt), new Date())
    : true;

  const navigate = useNavigate();

  return (
    <div
      className={twMerge(
        "flex h-full justify-between rounded-lg border border-primary-200 bg-primary-50 text-gray-70 transition-all duration-500",
        status === PATIENT_MEDICATION_STATUS.discontinued &&
          "border-neutral-700 bg-neutral-25",
      )}
    >
      <div className="flex w-full flex-col gap-4 px-4 py-4 md:flex-row md:items-center md:justify-between">
        <div className="flex flex-col gap-1">
          <p className="text-sm  font-medium leading-5 tracking-wide text-gray-10">
            {subtitle}
          </p>
          <H2 className="capitalize">{title}</H2>
        </div>
        {isCurrent && (
          <div className="flex w-fit">
            {latestScriptRequestDate ? (
              <Button
                className="whitespace-nowrap"
                size={BUTTON_SIZES.sm}
                variant={BUTTON_VARIANTS.outline_black}
                onClick={() => navigate(ROUTES.requests)}
              >
                View request
              </Button>
            ) : (
              handleRequestScript && (
                <Button
                  className="whitespace-nowrap"
                  size={BUTTON_SIZES.sm}
                  onClick={handleRequestScript}
                >
                  Request prescription
                </Button>
              )
            )}
          </div>
        )}
      </div>
      <button
        onClick={handleClick}
        className={twMerge(
          "h-full w-fit border-l border-primary-200 px-3",
          status === PATIENT_MEDICATION_STATUS.discontinued &&
            "border-neutral-700",
        )}
      >
        <ChevronIcon direction={DIRECTIONS.right} className="text-gray-70" />
      </button>
    </div>
  );
};
