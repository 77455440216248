import React, { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

interface TableProps
  extends PropsWithChildren,
    React.TableHTMLAttributes<HTMLTableElement> {
  className?: string;
}

export const Table = ({ children, className, ...props }: TableProps) => (
  <table
    className={twMerge(
      "size-full table-fixed border-separate border-spacing-0 text-left",
      className,
    )}
    {...props}
  >
    {children}
  </table>
);
