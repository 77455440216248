import { PropsWithChildren } from "react";

import { Button } from "../ui/button";
import ErrorLayout from "./ErrorLayout";

interface DescriptiveErrorLayoutProps extends PropsWithChildren {
  handleBackToHome: () => void;
  errorType: string;
  title: string;
  description: string;
}
export const DescriptiveErrorLayout = ({
  children,
  errorType,
  title,
  description,
  handleBackToHome,
}: DescriptiveErrorLayoutProps) => (
  <ErrorLayout>
    <div className="flex h-full flex-col items-center justify-center gap-6">
      {children}
      <div className="flex w-full flex-col  items-center gap-9">
        <div className="flex w-full flex-col  items-center gap-4">
          <h1 className="font-outfit text-7xl font-semibold">{errorType}</h1>
          <div className="flex w-full flex-col items-center gap-2">
            <h2 className="text-semibold font-outfit text-2xl">{title}</h2>
            <p className="w-3/4 text-center">{description}</p>
          </div>
        </div>
        <Button onClick={handleBackToHome} className="w-fit" size="sm">
          Back to Home
        </Button>
      </div>
    </div>
  </ErrorLayout>
);
