import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { IntakeQuestionAnswer } from "~/common/utils/consultations/intake/interfaces";
import { answerIntakeQuestion } from "~/patients/api";

interface AnswerIntakeQuestionProps {
  patientIntakeId: string;
  answer: IntakeQuestionAnswer;
}

export const useAnswerIntakeQuestion = () => {
  const queryClient = useQueryClient();

  const { mutate: answerQuestionMutation, isPending } = useMutation({
    mutationFn: ({ patientIntakeId, answer }: AnswerIntakeQuestionProps) =>
      answerIntakeQuestion(patientIntakeId, answer),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["getCurrentIntakeQuestion"],
      });
    },
    onError: () =>
      toast.error(
        "An error occurred while submitting your answer. Please try again.",
      ),
  });

  return { answerQuestionMutation, isPending };
};
