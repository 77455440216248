import { useSearchParams } from "react-router-dom";

export const useDeleteQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const deleteQueryParams = (params: string[]) => {
    const newParams = new URLSearchParams(searchParams);
    params.map((param) => newParams.delete(param));
    setSearchParams(newParams);
  };

  return { deleteQueryParams };
};
