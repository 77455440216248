import { useEffect } from "react";
import {
  FieldErrors,
  UseFormClearErrors,
  UseFormGetFieldState,
  UseFormRegister,
  UseFormResetField,
  UseFormWatch,
} from "react-hook-form";

import { getErrors } from "~/common/utils";
import {
  RegisterKeys,
  SymptomBaseForm,
  SymptomsFormValues,
} from "~/patients/utils/consultations/intake";
import {
  defaultValueBySymptomInputType,
  NONE_VALUE,
  SYMPTOMS_INPUT_TYPES,
} from "~/patients/utils/consultations/intake/";

interface useSymptomsProps {
  symptom: SymptomBaseForm;
  register: UseFormRegister<SymptomsFormValues>;
  watch: UseFormWatch<SymptomsFormValues>;
  clearErrors: UseFormClearErrors<SymptomsFormValues>;
  resetField: UseFormResetField<SymptomsFormValues>;
  getFieldState: UseFormGetFieldState<SymptomsFormValues>;
  errors: FieldErrors<SymptomsFormValues>;
  toggleExclusiveOptionModal: () => void;
  isNested?: boolean;
}

export const useSymptoms = ({
  symptom,
  register,
  watch,
  clearErrors,
  resetField,
  getFieldState,
  errors,
  toggleExclusiveOptionModal,
  isNested = false,
}: useSymptomsProps) => {
  const isSelected = !!watch(symptom.value);
  const isParentSelected = !!symptom.extra_data && isSelected;

  const isNestedParentWithTextChildren =
    isNested &&
    symptom.extra_data?.children?.some(
      (child) => child.type === SYMPTOMS_INPUT_TYPES.text,
    );

  const symptomsSelected = Object.values(watch("symptoms") ?? {}).some(
    (symptom) => symptom?.toggled,
  );

  const noneSymptomSelected = watch("symptoms.none.toggled");

  const hasChildrenToggled = symptom.extra_data?.children?.some(
    (child) =>
      child.type === SYMPTOMS_INPUT_TYPES.check && !!watch(child.value),
  );

  const { invalid: invalidSymptomInput } = getFieldState(symptom.value);

  const shouldClearSymptomsError =
    hasChildrenToggled && isParentSelected && invalidSymptomInput;

  const errorMessage = getErrors(symptom.value, errors);

  const resetRelatedCheckBoxOptions = (options: RegisterKeys[]) => {
    options.forEach((option) => {
      resetField(option, { defaultValue: false });
    });
  };

  const handleCheckboxChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (symptom.type !== SYMPTOMS_INPUT_TYPES.check) return;

    const { checked } = e.target;

    if (symptom.value === NONE_VALUE && checked && symptomsSelected) {
      toggleExclusiveOptionModal();
      return;
    }

    noneSymptomSelected &&
      symptom.value !== NONE_VALUE &&
      resetField(NONE_VALUE, { defaultValue: false });

    checked &&
      !!symptom.excludeOptions &&
      resetRelatedCheckBoxOptions(symptom.excludeOptions);

    checked &&
      !!symptom.excludedBy &&
      resetRelatedCheckBoxOptions(symptom.excludedBy);

    await register(symptom.value).onChange(e);

    !checked &&
      symptom.extra_data?.children?.forEach((child: SymptomBaseForm) => {
        const defaultValue = defaultValueBySymptomInputType[child.type];
        resetField(child.value, { defaultValue: defaultValue });
      });

    clearErrors(symptom.value);
  };

  useEffect(() => {
    if (!shouldClearSymptomsError) return;
    clearErrors(symptom.value);
  }, [shouldClearSymptomsError]);

  return {
    isSelected,
    isParentSelected,
    isNestedParentWithTextChildren,
    handleCheckboxChange,
    shouldClearSymptomsError,
    errorMessage,
  };
};
