import { api, intakePath, screeningPath } from "~/patients/api";

export const intakeAudioPath = `${intakePath}/:patientIntakeId`;
export const screeningAudioPath = `${screeningPath}/:patientScreeningId`;

export const getConsultationPresignedUrl = async (basePath: string) => {
  const { data } = await api.get<string>(`${basePath}/presigned_url`);

  return data;
};

export const deleteConsultationAudio = async (basePath: string) => {
  const { data } = await api.delete<null>(`${basePath}/audio`);

  return data;
};
