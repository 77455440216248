import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { Button, LoadingState, Typography } from "~/common/components";
import SuccessErrorLayout from "~/common/components/layouts/SuccessErrorLayout";
import SuccessIcon from "~/common/components/layouts/SuccessIcon";
import { useVerifyEmail } from "~/patients/hooks/queries/useVerifyEmail";
import { ROUTES } from "~/patients/router/routes";
import { usePatientIsAuth } from "../hooks";

export const VerifiedEmail = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("data-token");
  const { patientId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const onSuccess = () => {
    queryClient.removeQueries({
      queryKey: ["getCurrentPatient"],
      exact: true,
    });
  };

  const { verifyEmailMutation, isError, isSuccess } = useVerifyEmail(onSuccess);
  const { data: isLoggedIn, isLoading } = usePatientIsAuth();

  useEffect(() => {
    patientId && token && verifyEmailMutation({ patientId, token });
  }, [patientId, token, verifyEmailMutation]);

  const handleButtonClick = () => {
    queryClient.clear();
    navigate(ROUTES.login);
  };

  if (isError)
    return (
      <SuccessErrorLayout
        className="h-screen-calculated w-full"
        title="Something went wrong"
        description="We couldn't verify your email. Please try again or contact our support team if the problem persists."
        descriptionClassName="text-lg px-6"
      />
    );

  if (isLoading) return <LoadingState />;

  if (isSuccess)
    return (
      <div className="flex h-screen-calculated w-full flex-col items-center justify-center gap-8 bg-primary-50 p-8">
        <SuccessIcon className="h-24 w-24" />
        <h2 className="text-center font-outfit text-3xl font-semibold">
          Email Verified
        </h2>
        <div className="space-y-4">
          <Typography className="text-center">
            Your email address <br /> was successfully verified
          </Typography>
        </div>
        <Button onClick={handleButtonClick} className="w-full sm:w-96">
          {isLoggedIn ? "Go to home" : "Proceed to login"}
        </Button>
      </div>
    );

  return <LoadingState className="h-screen-calculated" />;
};
