import React from "react";

interface VideoCameraIconProps {
  className?: string;
}
const VideoCameraIcon = ({ className }: VideoCameraIconProps) => {
  return (
    <svg
      width="22"
      height="15"
      viewBox="0 0 22 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15.5 4.16667L16.2881 3.90398C17.7101 3.42996 18.4211 3.19296 18.9871 3.33507C19.4825 3.45947 19.9115 3.7687 20.1862 4.19936C20.5 4.69133 20.5 5.44081 20.5 6.93976V8.06024C20.5 9.55919 20.5 10.3087 20.1862 10.8006C19.9115 11.2313 19.4825 11.5405 18.9871 11.6649C18.4211 11.807 17.7101 11.57 16.2881 11.096L15.5 10.8333M4.7 13.5H12.3C13.4201 13.5 13.9802 13.5 14.408 13.282C14.7843 13.0903 15.0903 12.7843 15.282 12.408C15.5 11.9802 15.5 11.4201 15.5 10.3V4.7C15.5 3.5799 15.5 3.01984 15.282 2.59202C15.0903 2.21569 14.7843 1.90973 14.408 1.71799C13.9802 1.5 13.4201 1.5 12.3 1.5H4.7C3.5799 1.5 3.01984 1.5 2.59202 1.71799C2.21569 1.90973 1.90973 2.21569 1.71799 2.59202C1.5 3.01984 1.5 3.57989 1.5 4.7V10.3C1.5 11.4201 1.5 11.9802 1.71799 12.408C1.90973 12.7843 2.21569 13.0903 2.59202 13.282C3.01984 13.5 3.57989 13.5 4.7 13.5Z"
        stroke="#233285"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default VideoCameraIcon;
