import { PropsWithChildren } from "react";
import { Transition } from "@headlessui/react";
import { twMerge } from "tailwind-merge";

import { ANIMATION_DURATIONS, AnimationDurationType } from "~/common/constants";

interface TransitionEffectProps extends PropsWithChildren {
  show?: boolean;
  appear?: boolean;
  duration?: AnimationDurationType;
  className?: string;
}

export const TransitionEffect = ({
  show = true,
  children,
  duration = ANIMATION_DURATIONS.normal,
  appear = true,
  className,
}: TransitionEffectProps) => (
  <Transition
    show={show}
    appear={appear}
    enter={twMerge(
      `transition-opacity`,
      duration === ANIMATION_DURATIONS.fast && "duration-200",
      duration === ANIMATION_DURATIONS.normal && "duration-300",
      duration === ANIMATION_DURATIONS.moderate && "duration-400",
      duration === ANIMATION_DURATIONS.slow && "duration-500",
    )}
    className={className}
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave={twMerge(
      `transition-opacity`,
      duration === ANIMATION_DURATIONS.fast && "duration-200",
      duration === ANIMATION_DURATIONS.normal && "duration-300",
      duration === ANIMATION_DURATIONS.moderate && "duration-400",
      duration === ANIMATION_DURATIONS.slow && "duration-500",
    )}
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    {children}
  </Transition>
);
