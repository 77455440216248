import { useQuery } from "@tanstack/react-query";

import { intakeAvailable } from "~/common/utils/consultations/intake";
import { getCurrentIntakeQuestion } from "~/patients/api";
import { useGetCurrentPatient } from "~/patients/hooks";

export const useGetCurrentIntakeQuestion = (
  patientIntakeId?: string,
  enabled = true,
) => {
  const { data: user } = useGetCurrentPatient();

  const enableQuery =
    !!user && intakeAvailable(user.status) && !!patientIntakeId && enabled;

  const { data, isLoading, isFetching } = useQuery({
    queryFn: () => getCurrentIntakeQuestion(patientIntakeId ?? ""),
    queryKey: ["getCurrentIntakeQuestion", user, patientIntakeId],
    enabled: enableQuery,
  });

  return { data, isLoading, isFetching };
};
