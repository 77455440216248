import React from "react";

import { IconProps } from "~/common/utils";

export const LocationIcon = ({ className }: IconProps) => {
  return (
    <svg
      width="16"
      height="19"
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.3335 7.83268C11.3335 9.67363 9.84111 11.166 8.00016 11.166C6.15921 11.166 4.66683 9.67363 4.66683 7.83268C4.66683 5.99173 6.15921 4.49935 8.00016 4.49935C9.84111 4.49935 11.3335 5.99173 11.3335 7.83268Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M14.6668 7.83268C14.6668 11.5146 9.66683 17.8327 8.00016 17.8327C6.3335 17.8327 1.3335 11.5146 1.3335 7.83268C1.3335 4.15078 4.31826 1.16602 8.00016 1.16602C11.6821 1.16602 14.6668 4.15078 14.6668 7.83268Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};
