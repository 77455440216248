import { twMerge } from "tailwind-merge";

import { DIRECTIONS } from "~/common/constants";
import {
  Directions,
  getChevronDirectionClass,
  IconProps,
} from "~/common/utils";

export interface ArrowIconProps extends IconProps {
  direction?: Directions;
}

export const ChevronIcon = ({
  className,
  direction = DIRECTIONS.left,
  ...props
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    stroke="currentColor"
    viewBox="0 0 8 16"
    className={twMerge(
      "size-4 text-gray-70",
      getChevronDirectionClass(direction),
      className,
    )}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.71026 0.638579C8.02476 0.908146 8.06118 1.38162 7.79161 1.69611L2.20998 8.20802L7.79161 14.7199C8.06118 15.0344 8.02476 15.5079 7.71026 15.7775C7.39577 16.047 6.92229 16.0106 6.65273 15.6961L0.652726 8.69611C0.411982 8.41525 0.411982 8.0008 0.652726 7.71993L6.65273 0.719928C6.92229 0.405433 7.39577 0.369012 7.71026 0.638579Z"
    />
  </svg>
);
