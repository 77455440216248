import { UseFormTrigger } from "react-hook-form";

import { useOnBoardingNewPatientStore } from "~/patients/stores/useOnBoardingNewPatientStore";
import {
  NewPatientSchemaType,
  SignUpPatientSchemaType,
} from "~/patients/utils";
import { useSubmitNewPatientSignUp } from "./useSubmitNewPatientSignUp";

interface useSignUpNewPatientProps {
  values: NewPatientSchemaType;
  trigger: UseFormTrigger<SignUpPatientSchemaType>;
  dataToken?: string | null;
}
export const useSignUpNewPatient = ({
  values,
  trigger,
  dataToken,
}: useSignUpNewPatientProps) => {
  const { onBoardingNewPatientStep, setOnBoardingNewPatientStep } =
    useOnBoardingNewPatientStore();

  const { onSubmitSignUp, isPending } = useSubmitNewPatientSignUp({
    values,
    trigger,
    dataToken,
  });

  const onBack = () => {
    setOnBoardingNewPatientStep(onBoardingNewPatientStep - 1);
  };

  const updatePatientData = () => {
    setOnBoardingNewPatientStep(onBoardingNewPatientStep + 1);
  };

  const onNext = async (schemaName: keyof NewPatientSchemaType) => {
    const isValid = await trigger(schemaName);
    if (isValid) {
      updatePatientData();
    }
  };

  return {
    onNext,
    onBack,
    isPending,
    onSubmitSignUp,
  };
};
