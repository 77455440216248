import {
  INTAKE_QUESTION_CODES,
  INTAKE_QUESTION_TYPES,
  YES_NO_TYPES,
} from "~/common/utils/consultations/intake";

const radioWithTextOptionsSection3 = {
  [INTAKE_QUESTION_CODES.do_you_have_any_known_allergies_to_medications]: {
    title: "Do you have any known allergies to medications?",
    type: INTAKE_QUESTION_TYPES.radio_with_text,
    options: [
      {
        value: YES_NO_TYPES.yes,
        asksExtraInfo: true,
        placeholderAsksExtraInfo: "Please enter allergies.",
      },
      {
        value: YES_NO_TYPES.no,
      },
    ],
  },
  [INTAKE_QUESTION_CODES.undergone_any_surgeries]: {
    title: "Have you undergone any surgeries?",
    type: INTAKE_QUESTION_TYPES.radio_with_text,
    options: [
      {
        value: YES_NO_TYPES.yes,
        asksExtraInfo: true,
        placeholderAsksExtraInfo: "Enter surgeries and dates.",
        labelAsksExtraInfo:
          "Please describe what kind of surgeries and when this happened.",
      },
      {
        value: YES_NO_TYPES.no,
      },
    ],
  },

  [INTAKE_QUESTION_CODES.do_you_smoke_cigarettes_or_use_tobacco_products]: {
    title: "Do you smoke cigarettes or use tobacco products or vaporizers?",
    type: INTAKE_QUESTION_TYPES.radio_with_text,
    options: [
      {
        value: YES_NO_TYPES.yes,
        asksExtraInfo: true,
        placeholderAsksExtraInfo: "Please describe further.",
        labelAsksExtraInfo: "How much do you smoke and how often? ",
      },
      {
        value: YES_NO_TYPES.no,
      },
    ],
  },
} as const;

export const radioWithTextQuestions = {
  ...radioWithTextOptionsSection3,
};
