import { useState } from "react";
import { FieldArrayMethodProps, UseFormReset } from "react-hook-form";

import {
  ConsultationMultiCheckAnswer,
  ConsultationMultiCheckValue,
  MultiCheckOptions,
} from "~/common/utils/consultations/shared";

interface UseExclusiveOptionHandlerArgs {
  selectedValues: ConsultationMultiCheckValue[];
  options: MultiCheckOptions[];
  reset: UseFormReset<ConsultationMultiCheckAnswer>;
  remove: (index?: number | number[] | undefined) => void;
  append: (
    value: ConsultationMultiCheckValue | ConsultationMultiCheckValue[],
    options?: FieldArrayMethodProps | undefined,
  ) => void;
}

export const useExclusiveOptionHandler = ({
  selectedValues,
  options,
  reset,
  remove,
  append,
}: UseExclusiveOptionHandlerArgs) => {
  const [pendingState, setPendingState] = useState<{
    option: MultiCheckOptions | null;
    selectedIndex: number | null;
  }>({ option: null, selectedIndex: null });

  const [isExclusiveOptionModalOpen, setIsExclusiveOptionModalOpen] =
    useState(false);

  const handleCheck = (option: MultiCheckOptions, selectedIndex: number) => {
    const isExclusiveOptionSelected = selectedValues?.some((selected) =>
      options.some(
        (opt) => opt.value === selected.value && opt.isExclusiveOption,
      ),
    );

    const areOptionsSelected = selectedValues.length > 0;

    if (
      option.isExclusiveOption &&
      !isExclusiveOptionSelected &&
      areOptionsSelected
    ) {
      setPendingState({ option, selectedIndex });
      setIsExclusiveOptionModalOpen(true);
      return;
    }

    if (isExclusiveOptionSelected && !option.isExclusiveOption) {
      const exclusiveOptionIndex = selectedValues.findIndex((selected) =>
        options.some(
          (opt) => opt.value === selected.value && opt.isExclusiveOption,
        ),
      );
      remove(exclusiveOptionIndex);
    }

    if (selectedIndex !== -1) {
      remove(selectedIndex);
    } else {
      append({ value: option.value });
    }
  };

  const onCloseIsExclusiveOption = () => {
    setIsExclusiveOptionModalOpen(false);
    setPendingState({
      option: null,
      selectedIndex: null,
    });
  };

  const onConfirmIsExclusiveOption = () => {
    if (!pendingState.option) return;
    reset(
      {
        answer: { values: [{ value: pendingState.option.value }] },
      },
      { keepValues: false },
    );
    onCloseIsExclusiveOption();
  };

  return {
    handleCheck,
    onConfirmIsExclusiveOption,
    onCloseIsExclusiveOption,
    isExclusiveOptionModalOpen,
    setIsExclusiveOptionModalOpen,
  };
};
