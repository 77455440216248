import React, { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import EmptyBox from "~/common/components/icons/EmptyBox";

interface EmptyStateProps {
  className?: string;
  message: string;
  icon?: ReactNode;
}

export const EmptyState = ({ message, className, icon }: EmptyStateProps) => {
  return (
    <div
      className={twMerge(
        "flex h-full w-full flex-col items-center justify-center gap-4",
        className,
      )}
    >
      {icon ?? <EmptyBox />}
      <p className="text-center text-base leading-5 text-gray-60">{message}</p>
    </div>
  );
};
