import { useMutation, useQueryClient } from "@tanstack/react-query";

import { createMyPharmacy } from "~/patients/api/pharmacies";
import { CreateNewPharmacyFormValuesType } from "~/patients/utils";

export const useCreateMyPharmacy = (handleCloseModal: () => void) => {
  const queryClient = useQueryClient();

  const { mutate: createMyPharmacyMutation, isPending } = useMutation({
    mutationFn: (pharmacy: CreateNewPharmacyFormValuesType) =>
      createMyPharmacy(pharmacy),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["getPatientPharmacies"],
      });
      handleCloseModal();
    },
  });

  return { createMyPharmacyMutation, isPending };
};
