import { CreateNewPharmacyFormValuesType } from "~/patients/utils";
import { useCreateMyPharmacy } from "./useCreateMyPharmacy";
import { useUpdateMyPharmacy } from "./useUpdateMyPharmacy";

interface upsertPharmacyProps {
  formData: CreateNewPharmacyFormValuesType;
  pharmacyId?: string;
}

export const useUpsertMyPharmacy = (onClose: () => void) => {
  const { createMyPharmacyMutation, isPending: isCreatePharmacyPending } =
    useCreateMyPharmacy(onClose);
  const { updateMyPharmacyMutation, isPending: isUpdatePharmacyPending } =
    useUpdateMyPharmacy(onClose);

  const upsertPharmacy = ({ formData, pharmacyId }: upsertPharmacyProps) => {
    pharmacyId &&
      updateMyPharmacyMutation({
        pharmacy: {
          ...formData,
          is_default: formData.is_default,
        },
        pharmacyId: pharmacyId,
      });

    !pharmacyId &&
      createMyPharmacyMutation({
        ...formData,
        is_default: formData.is_default,
      });
  };

  const isPending = isCreatePharmacyPending || isUpdatePharmacyPending;

  return { upsertPharmacy, isPending };
};
