import { TableData } from "~/common/components/table";
import { MedicationFrequency, MedicationStrength } from "~/common/types";

interface PatientMedicationTableRowProps {
  medication_strength: MedicationStrength;
  take: string;
  frequency: MedicationFrequency;
  prescribed_quantity: number;
}

export const PatientMedicationTableRow = ({
  medication_strength,
  take,
  frequency,
  prescribed_quantity,
}: PatientMedicationTableRowProps) => {
  const { unit, amount, form } = medication_strength;
  const { abbreviation, description } = frequency;

  return (
    <tr className="border-b border-gray-300 py-4 last:border-none">
      <TableData className="py-4">{`${amount} ${unit} ${form}`}</TableData>
      <TableData className="py-4">{take}</TableData>
      <TableData className="py-4">{`${description} (${abbreviation})`}</TableData>
      <TableData className="py-4">{prescribed_quantity}</TableData>
    </tr>
  );
};
