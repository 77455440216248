import { UseFormRegisterReturn } from "react-hook-form";
import { z } from "zod";

import { PsychNowLogo } from "~/common/assets";
import { Button } from "~/common/components/ui/button";
import { ProvidersMessage } from "~/patients/components/ProvidersMessage";
import { termsAndConditionsSchema } from "~/patients/utils/PatientSchemas";
import { FormErrors } from "~/patients/utils/types/forms";
import { TermsAndConditions } from "../commonForms";

export type TermsAndConditionsSchemaType = z.infer<
  typeof termsAndConditionsSchema
>;

interface IntroductionPatientFormProps {
  register: (
    data: keyof TermsAndConditionsSchemaType,
  ) => UseFormRegisterReturn<string>;
  onNext: () => Promise<void>;
  errors: FormErrors<TermsAndConditionsSchemaType> | undefined;
  email: string;
  name: string;
}

const IntroductionPatientForm = ({
  onNext,
  register,
  errors,
  email,
  name,
}: IntroductionPatientFormProps) => (
  <div className="flex h-screen-calculated flex-col items-center justify-center gap-[5.7rem] px-8 md:w-2/3 md:py-24 xl:w-1/3 xl:justify-center xl:gap-14 xl:p-0">
    <div className="flex flex-col items-center gap-16">
      <img src={PsychNowLogo} alt="PsychNow logo" className="w-1/2 md:w-1/3" />
      <ProvidersMessage greeting="Looking forward to meeting you,">
        <p className="font-semibold">Welcome {name}, It’s good to see you!</p>
        <p>
          Your email address for this account is{" "}
          <span className="font-semibold">{email}</span>. Please accept the
          terms and conditions to start your journey.
        </p>
      </ProvidersMessage>
    </div>
    <div className="flex flex-col items-center gap-6">
      <TermsAndConditions
        register={(data: keyof TermsAndConditionsSchemaType) =>
          register(`${data}`)
        }
        error={errors?.termsAndConditions?.message}
      />
      <Button
        size="md"
        onClick={onNext}
        className="w-1/3 px-12 md:w-1/3 md:px-10"
        type="submit"
      >
        Continue
      </Button>
    </div>
  </div>
);

export default IntroductionPatientForm;
