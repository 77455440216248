import { twMerge } from "tailwind-merge";

import { IconProps } from "~/common/utils/types";

export const ClockIcon = ({ className, ...props }: IconProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={twMerge(className)}
    {...props}
  >
    <path
      d="M9.36841 5.74426V10.2434C9.36841 10.2437 9.36856 10.244 9.36881 10.2442L12.3684 12.4943"
      stroke="currentColor"
      strokeWidth={props.strokeWidth ?? 2}
      strokeLinecap="round"
    />
    <path
      d="M18.3684 10.2443C18.3684 14.9268 14.5724 18.7228 9.88989 18.7228C5.20734 18.7228 1.41138 14.9268 1.41138 10.2443C1.41138 5.56171 5.20734 1.76575 9.88989 1.76575C14.5724 1.76575 18.3684 5.56171 18.3684 10.2443Z"
      stroke="currentColor"
      strokeWidth={props.strokeWidth ?? 2}
    />
  </svg>
);
