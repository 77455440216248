import { CONSULTATIONS_QUESTION_TYPES } from "~/common/utils/consultations/shared";
import {
  SCREENING_MULTI_CHECK_VALUES,
  SCREENING_QUESTION_CODES,
} from "../screeningQuestionsData";

export const multiCheckQuestions = {
  [SCREENING_QUESTION_CODES.motivation_for_care]: {
    title: "What has motivated you to seek care today?",
    subtitle: "More than one option can be selected.",
    type: CONSULTATIONS_QUESTION_TYPES.multi_check,
    options: [
      {
        value: SCREENING_MULTI_CHECK_VALUES.motivated_to_seek_care,
        label: "I am personally motivated to seek care",
        asksExtraInfo: false,
      },
      {
        value:
          SCREENING_MULTI_CHECK_VALUES.family_or_friend_encouraging_to_seek_care,
        label: "A family member or friend is encouraging me to seek care",
        asksExtraInfo: false,
      },
      {
        value: SCREENING_MULTI_CHECK_VALUES.prompt_or_legal_issue,
        label: "A work or legal issue is prompting me to seek care",
        asksExtraInfo: false,
      },
      {
        value: SCREENING_MULTI_CHECK_VALUES.another_reason,
        label: "Another reason",
        asksExtraInfo: true,
      },
    ],
  },
} as const;
