import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { TransitionEffect } from "~/common/components";
import SuccessErrorLayout from "~/common/components/layouts/SuccessErrorLayout";
import { Button } from "~/common/components/ui/button";
import { CHECK_IN_CODES, CheckInCodes } from "~/common/utils";
import { ROUTES } from "~/patients/router";

const errorMessage = {
  title: "Something went wrong",
  description:
    "We had an error processing your information, please try again later.",
};

const successMessage = (code?: CheckInCodes) => ({
  title: "Success!",
  description:
    code === CHECK_IN_CODES.schedule_appointment
      ? "Thanks for scheduling an appointment. This process is essential to your ongoing wellness."
      : "Thanks for checking in. Your input is essential to your ongoing wellness.",
});

interface CheckInStateProps {
  isSuccess?: boolean;
  code?: CheckInCodes;
}

export const CheckInState = ({ isSuccess, code }: CheckInStateProps) => (
  <TransitionEffect>
    <div className="flex h-screen-calculated w-full flex-col items-center justify-center gap-16 bg-primary-50 px-6 py-4 text-gray-70 md:gap-20 md:p-9">
      <SuccessErrorLayout
        success={isSuccess}
        title={isSuccess ? successMessage(code).title : errorMessage.title}
        description={
          isSuccess
            ? successMessage(code).description
            : errorMessage.description
        }
        className="xl:w-1/4"
      >
        <Link
          className={twMerge(isSuccess ? "w-full" : "text-base font-semibold")}
          to={ROUTES.home}
        >
          <Button>Back to Home</Button>
        </Link>
      </SuccessErrorLayout>
    </div>
  </TransitionEffect>
);
