import {
  NONE_VALUE,
  SymptomBaseForm,
  SYMPTOMS_INPUT_TYPES,
} from "./symptomsBasicTypes";
import { RegisterKeys } from "./symptomsSchema";

interface GetToggleExtraData {
  label: string;
  value: RegisterKeys;
  extraDataValue: RegisterKeys;
  extraDataLabel?: string;
}

const getToggleTextExtraDataForm = ({
  label,
  value,
  extraDataValue,
  extraDataLabel,
}: GetToggleExtraData): SymptomBaseForm => ({
  label,
  value,
  type: "check",
  extra_data: {
    title: undefined,
    children: [
      {
        value: extraDataValue,
        label: extraDataLabel,
        type: "text",
        extra_data: undefined,
      },
    ],
  },
});

export const attentionFocusIssuesSymptom = getToggleTextExtraDataForm({
  label: "Attention and focus issues",
  value: "symptoms.attention_focus_issues.toggled",
  extraDataValue: "symptoms.attention_focus_issues.extra_data",
});

export const anxietySymptom = getToggleTextExtraDataForm({
  label: "Anxiety",
  value: "symptoms.anxiety.toggled",
  extraDataValue: "symptoms.anxiety.extra_data",
});

export const panicAttacksSymptom = getToggleTextExtraDataForm({
  label: "Panic attacks",
  value: "symptoms.panic_attacks.toggled",
  extraDataValue: "symptoms.panic_attacks.extra_data",
  extraDataLabel:
    "Please describe what you are noticing. How long do they last and what do they feel like?",
});

export const increasedSpendingOrShoppingSymptom = getToggleTextExtraDataForm({
  label: "Increased spending or shopping",
  value: "symptoms.increased_spending_or_shopping.toggled",
  extraDataValue: "symptoms.increased_spending_or_shopping.extra_data",
});

export const nightMaresSymptom = getToggleTextExtraDataForm({
  label: "Nightmares",
  value: "symptoms.nightmares.toggled",
  extraDataValue: "symptoms.nightmares.extra_data",
});

export const flashbacksSymptom = getToggleTextExtraDataForm({
  label: "Flashbacks",
  value: "symptoms.flashbacks.toggled",
  extraDataValue: "symptoms.flashbacks.extra_data",
});

export const memoryProblemsSymptom = getToggleTextExtraDataForm({
  label: "Memory problems",
  value: "symptoms.memory_problems.toggled",
  extraDataValue: "symptoms.memory_problems.extra_data",
  extraDataLabel: "How long have these been happening?",
});

export const obsessionsSymptom = getToggleTextExtraDataForm({
  label: "Obsessions",
  value: "symptoms.obsessions.toggled",
  extraDataValue: "symptoms.obsessions.extra_data",
  extraDataLabel: "How much, how often and about what are your obsessions?",
});

export const compulsionsSymptom = getToggleTextExtraDataForm({
  label: "Compulsions",
  value: "symptoms.compulsions.toggled",
  extraDataValue: "symptoms.compulsions.extra_data",
  extraDataLabel: "How much, how often and what are your compulsions?",
});

export const diminishedInterestsSymptom = getToggleTextExtraDataForm({
  label: "Diminished interest in previously enjoyable things",
  value: "symptoms.diminished_interests.toggled",
  extraDataValue: "symptoms.diminished_interests.extra_data",
});

export const irritabilitySymptom = getToggleTextExtraDataForm({
  label: "Irritability",
  value: "symptoms.irritability.toggled",
  extraDataValue: "symptoms.irritability.extra_data",
});

export const grandIdeasSymptom = getToggleTextExtraDataForm({
  label: "Grand ideas / Feeling “on top of the world”",
  value: "symptoms.grand_ideas.toggled",
  extraDataValue: "symptoms.grand_ideas.extra_data",
});

export const impulsivitySymptom = getToggleTextExtraDataForm({
  label: "Impulsivity",
  value: "symptoms.impulsivity.toggled",
  extraDataValue: "symptoms.impulsivity.extra_data",
});

export const otherSymptom = getToggleTextExtraDataForm({
  label: "Other",
  value: "symptoms.other.toggled",
  extraDataValue: "symptoms.other.extra_data",
});

export const noneOfTheAboveSymptom: SymptomBaseForm = {
  label: "I am not experiencing any symptoms",
  value: NONE_VALUE,
  type: SYMPTOMS_INPUT_TYPES.check,
};
