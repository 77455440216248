import { H2, LockIcon, Typography } from "~/common/components";
import { TYPOGRAPHY_VARIANTS } from "~/common/utils";

interface ScreeningCompletedAndIntakeStartedCardProps {
  title: string;
}

export const ScreeningCompletedAndIntakeStartedCard = ({
  title,
}: ScreeningCompletedAndIntakeStartedCardProps) => (
  <div className="flex w-full rounded-lg border border-primary-200 bg-primary-100 p-4">
    <div className="flex w-full flex-col gap-2 text-gray-35">
      <H2 className="text-xl">{title}</H2>
      <Typography
        variant={TYPOGRAPHY_VARIANTS.light}
        className="w-11/12 text-base text-gray-10"
      >
        You’ll be able to schedule our first meeting once you’ve completed Part
        1.
      </Typography>
    </div>
    <div className="flex h-full items-center">
      <LockIcon />
    </div>
  </div>
);
