import { twMerge } from "tailwind-merge";

import { IconProps } from "~/common/utils";

export const SquaredTick = ({ className, ...props }: IconProps) => (
  <svg
    className={twMerge("size-16  text-primary-600", className)}
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="55" height="55" rx="6" fill="#DDE7FD" />
    <path
      d="M37.1663 21.457L24.7291 33.8943C24.2572 34.3662 23.4921 34.3662 23.0203 33.8943L17.833 28.707"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
