import { BundleCodes, BUNDLES_CODES_VALUES } from "~/common/constants/bundles";
import {
  CheckInCodes,
  SIDE_EFFECTS_CODES_VALUES,
  SideEffectCodes,
} from "~/common/utils";
import { OrderCodes } from "../types/orderTypeValues";

export const isBundleCode = (code: OrderCodes): code is BundleCodes =>
  BUNDLES_CODES_VALUES.includes(code as BundleCodes);

export const isSideEffectCode = (code: CheckInCodes): code is SideEffectCodes =>
  SIDE_EFFECTS_CODES_VALUES.includes(code as SideEffectCodes);
