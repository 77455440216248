import { twMerge } from "tailwind-merge";

import { Tag } from "~/common/components/Tag";
import { statusColors, statusName } from "~/common/constants";
import { PatientScriptsStatusTypes } from "~/common/types/scriptsTypes";

interface ScriptStatusProps {
  status: PatientScriptsStatusTypes;
}

export const ScriptStatus = ({ status }: ScriptStatusProps) => (
  <Tag
    className={twMerge("w-fit px-2.5 py-0.5 font-normal", statusColors[status])}
  >
    {statusName[status]}
  </Tag>
);
