import { ComponentPropsWithoutRef } from "react";

import PlusIcon from "~/patients/components/icons/PlusIcon";

export const AddElementButton = ({
  title,
  ...props
}: ComponentPropsWithoutRef<"button"> & { title: string }) => (
  <button
    className="flex w-full items-center gap-1 rounded-lg border border-dashed border-gray-90 px-4 py-3"
    type="button"
    {...props}
  >
    <PlusIcon className="w-3 fill-primary-100 text-gray-70" strokeWidth={3} />
    {title}
  </button>
);
