import {
  BalanceIcon,
  BasicLabsIcon,
  ScheduleAppointmentIcon,
  SideEffectsIcon,
  StatusCheckIcon,
  VitalSignsIcon,
} from "~/common/components/icons/Orders";
import { checkInUtilities } from "~/common/utils/checkIn";
import { CHECK_IN_CODES } from "~/common/utils/types/checkInTypes";

const { formatCheckInCode } = checkInUtilities();
export const checkInBasicData = {
  [CHECK_IN_CODES.antidepressants_side_effects]: {
    name: formatCheckInCode(CHECK_IN_CODES.antidepressants_side_effects),
    icon: SideEffectsIcon,
    description:
      "asks patients to report relevant side effects 2, 4, and 8 weeks after they begin their medication. Patients have 48 hours to respond to each check.",
    bgColor: "bg-interaction-side-effects",
  },
  [CHECK_IN_CODES.benzodiazepines_side_effects]: {
    name: formatCheckInCode(CHECK_IN_CODES.benzodiazepines_side_effects),
    icon: SideEffectsIcon,
    description:
      "asks patients to report relevant side effects 2, 4, and 8 weeks after they begin their medication. Patients have 48 hours to respond to each check.",
    bgColor: "bg-interaction-side-effects",
  },
  [CHECK_IN_CODES.basic_labs]: {
    name: formatCheckInCode(CHECK_IN_CODES.basic_labs),
    icon: BasicLabsIcon,
    description:
      "asks patients to report basic labs. Requirements: CBC w/ platelets, TSH with reflex, Basic metabolic profile, liver function test, B12, Folate and Vitamin D.",
    bgColor: "bg-interaction-health-factors",
  },
  [CHECK_IN_CODES.psychostimulants_side_effects]: {
    name: formatCheckInCode(CHECK_IN_CODES.psychostimulants_side_effects),
    icon: SideEffectsIcon,
    description:
      "asks patients to report relevant side effects 2, 4, and 8 weeks after they begin their medication. Patients have 48 hours to respond to each check.",
    bgColor: "bg-interaction-side-effects",
  },
  [CHECK_IN_CODES.status_check]: {
    name: formatCheckInCode(CHECK_IN_CODES.status_check),
    icon: StatusCheckIcon,
    description: "asks for a progress update and voice recording",
    bgColor: "bg-interaction-status",
  },
  [CHECK_IN_CODES.blood_pressure_heart_rate]: {
    name: formatCheckInCode(CHECK_IN_CODES.blood_pressure_heart_rate),
    icon: VitalSignsIcon,
    description:
      "asks patients to take their blood pressure and heart rate an hour after taking a dose. Instructions are included.",
    bgColor: "bg-interaction-health-factors",
  },
  [CHECK_IN_CODES.blood_pressure_heart_rate_recurrent]: {
    name: formatCheckInCode(CHECK_IN_CODES.blood_pressure_heart_rate),
    icon: VitalSignsIcon,
    description:
      "asks patients to take their blood pressure and heart rate an hour after taking a dose. Instructions are included.",
    bgColor: "bg-interaction-health-factors",
  },
  [CHECK_IN_CODES.weight]: {
    name: formatCheckInCode(CHECK_IN_CODES.weight),
    icon: BalanceIcon,
    description: "asks patients to submit their weight.",
    bgColor: "bg-interaction-health-factors",
  },
  [CHECK_IN_CODES.schedule_appointment]: {
    name: formatCheckInCode(CHECK_IN_CODES.schedule_appointment),
    icon: ScheduleAppointmentIcon,
    description:
      "gives instructions to the patient to schedule a live encounter with you",
    bgColor: "bg-primary-50",
  },
} as const;
