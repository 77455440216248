import { useQuery } from "@tanstack/react-query";

import { getScreening } from "~/patients/api";

export const useGetScreening = (screeningId?: string, enabled = true) => {
  const { data, isLoading } = useQuery({
    queryFn: () => getScreening(screeningId ?? ""),
    queryKey: ["getScreening", screeningId],
    enabled: !!screeningId && enabled,
  });

  return { data, isLoading };
};
