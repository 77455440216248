import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";

import { LoadingState } from "~/common/components";
import { PaymentIntentResponse } from "~/patients/api/payments";
import { useIntakeIntroScreenVisit } from "~/patients/hooks";
import { useCreatePaymentIntent } from "~/patients/hooks/queries/payments/useCreatePaymentIntent";
import { ROUTES } from "~/patients/router";
import { CheckoutForm } from "./CheckoutForm";

export const LeadPayment = () => {
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [amount, setAmount] = useState<number | null>(null);
  const navigate = useNavigate();
  const onSuccess = () => navigate(ROUTES.completeIntakeConsultation);

  const { intakeIntroScreenVisitMutation } =
    useIntakeIntroScreenVisit(onSuccess);

  const onCreatePaymentIntentSuccess = async (data: PaymentIntentResponse) => {
    if (data.amount === 0) return intakeIntroScreenVisitMutation();
    setClientSecret(data.client_secret);
    setAmount(data.amount);
    try {
      const stripe = await loadStripe(data.provider_publishable_key, {
        locale: "en",
      });
      setStripe(stripe);
    } catch {
      navigate(ROUTES.notFound);
    }
  };

  const { createPaymentIntentMutation } = useCreatePaymentIntent(
    onCreatePaymentIntentSuccess,
  );

  useEffect(() => {
    createPaymentIntentMutation();
  }, [createPaymentIntentMutation]);

  if (!stripe || !clientSecret || !amount) {
    return <LoadingState className="h-screen-calculated" />;
  }

  return (
    <Elements stripe={stripe} options={{ clientSecret }}>
      <CheckoutForm amount={amount} />
    </Elements>
  );
};
