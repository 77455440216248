import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

import { PatientScreeningQuestionResponse } from "~/common/utils/consultations/screening";
import { getCurrentScreeningQuestion } from "~/patients/api";
import { ROUTES } from "~/patients/router";

export const useGetCurrentScreeningQuestion = (
  patient_screening_id?: string,
) => {
  const navigate = useNavigate();
  const { data, isLoading, error } = useQuery<
    PatientScreeningQuestionResponse | null,
    AxiosError
  >({
    queryFn: () => getCurrentScreeningQuestion(patient_screening_id ?? ""),
    queryKey: ["getCurrentScreeningQuestion", patient_screening_id],
    enabled: !!patient_screening_id,
    retry: 2,
  });

  if (error?.response?.status === 400) {
    navigate(ROUTES.screeningAlreadyCompleted);
  }

  return { data, isLoading };
};
