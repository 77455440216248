import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { logout } from "~/patients/api/logout";
import { useUiStore } from "~/patients/stores";

export const useLogoutPatient = () => {
  const { setSideBarOpen } = useUiStore();
  const queryClient = useQueryClient();

  const { mutate: logoutMutation, isPending } = useMutation({
    mutationFn: logout,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["getPatientIsAuth"],
        exact: true,
      });
      queryClient.clear();
      setSideBarOpen(false);
    },
    onError: () =>
      toast.error(
        "An error occurred while trying to log out. Please try again later.",
      ),
  });

  return { logoutMutation, isPending };
};
