import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UseFormTrigger } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { getAuthenticatedPatient } from "~/patients/api";
import { ROUTES } from "~/patients/router";
import { useOnBoardingPatientStore } from "~/patients/stores";
import {
  ActivePatientSchemaType,
  SignUpPatientSchemaType,
  SignUpUserDataRequest,
} from "~/patients/utils";
import { EmergencyContact } from "~/providers/utils";
import { useFormatHook } from "../../useFormatHook";
import { useCreateActivePatient } from "./useCreateActivePatient";

interface useSubmitActivePatientSignUpProps {
  values: ActivePatientSchemaType;
  trigger: UseFormTrigger<SignUpPatientSchemaType>;
  dataToken: string;
  patientEmail: string;
}
export const useSubmitActivePatientSignUp = ({
  values,
  trigger,
  dataToken,
  patientEmail,
}: useSubmitActivePatientSignUpProps) => {
  const { patient, setPatient, setOnBoardingStep } =
    useOnBoardingPatientStore();

  const { pronounsAsObject } = useFormatHook();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: getPatient } = useMutation({
    mutationFn: getAuthenticatedPatient,
    onSuccess: () => {
      queryClient.clear();
      navigate(ROUTES.home);
      setPatient();
      setOnBoardingStep(0);
    },
    onError: () => {
      navigate(ROUTES.login);
      setOnBoardingStep(0);
    },
  });

  const onSignUpSuccess = () => {
    setPatient();
    if (!patient) return;
    getPatient();
  };

  const { signUpMutation, isPending } = useCreateActivePatient(onSignUpSuccess);

  const onSubmitSignUp = async () => {
    const isValidData = await trigger();

    if (!isValidData) return;

    const pronouns = pronounsAsObject(values.pronouns);

    const contact: EmergencyContact = {
      ...values.emergencyContact,
      relationship:
        values.emergencyContact.customRelationship ??
        values.emergencyContact.relationship,
    };

    const user: SignUpUserDataRequest = {
      email: patientEmail,
      ...values.phone_number,
      ...values.names,
      pronouns: pronouns,
      password: values.password.password,
      address: values.address,
      emergency_contacts: [{ ...contact }],
      patient_pharmacy: { pharmacy: values.pharmacy, is_default: true },
    };

    signUpMutation({ userData: user, token: dataToken });
  };

  return {
    onSubmitSignUp,
    isPending,
  };
};
