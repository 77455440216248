import {
  INTAKE_QUESTION_CODES,
  INTAKE_QUESTION_TYPES,
} from "../intakeQuestionsData";

export const symptomsQuestions = {
  [INTAKE_QUESTION_CODES.symptoms_experience]: {
    title: "What symptoms are you experiencing? Please select all that apply.",
    type: INTAKE_QUESTION_TYPES.symptoms_experienced_multi_select,
  },
} as const;
