import { useMutation } from "@tanstack/react-query";

import { verifyEmail } from "~/patients/api/emailVerification";

export const useVerifyEmail = (onSuccess: () => void) => {
  const {
    mutate: verifyEmailMutation,
    isPending,
    isError,
    isSuccess,
  } = useMutation({
    mutationFn: ({ patientId, token }: { patientId: string; token: string }) =>
      verifyEmail(patientId, token),
    onSuccess,
  });

  return { verifyEmailMutation, isPending, isError, isSuccess };
};
