import { useState } from "react";

import { EmptyState, LoadingState } from "~/common/components";
import { Script } from "~/common/types/scriptsTypes";
import { formatMedicationName } from "~/common/utils";
import { formatPharmacyInfo } from "~/common/utils/formatPharmacy/formatPharmacyInfo";
import { SCRIPT_LIFE } from "~/patients/constants";
import { useGetPatientScripts } from "~/patients/hooks/";
import { getScriptCommentMessage } from "~/patients/utils";
import { CancelRequestModal } from "./CancelRequestModal";
import { ResolveIssueModal } from "./ResolveIssueModal";
import { ScriptsCard } from "./ScriptCard";

export const CurrentScripts = () => {
  const [scriptToResolveIssue, setScriptToResolveIssue] = useState<Script>();
  const [scriptToCancel, setScriptToCancel] = useState<Script>();
  const handlePatientScriptClose = () => setScriptToCancel(undefined);
  const handleResolveIssueClose = () => setScriptToResolveIssue(undefined);

  const { data: patientScripts, isLoading } = useGetPatientScripts(
    SCRIPT_LIFE.current,
  );

  if (isLoading) return <LoadingState />;

  if (!patientScripts?.length)
    return (
      <EmptyState
        message="You have no scripts requests yet."
        className="min-h-80"
      />
    );

  return (
    <>
      <div className="flex flex-col gap-4">
        {!!patientScripts?.length &&
          patientScripts.map((script) => (
            <ScriptsCard
              preAuthRequest={script.pre_authorization_request}
              key={script.id}
              title={formatMedicationName(script.patient_medication.medication)}
              subtitle={formatPharmacyInfo(script.patient_pharmacy.pharmacy)}
              date={script.created_at}
              status={script.status}
              handleCancelRequest={() => setScriptToCancel(script)}
              handleResolveIssue={() => setScriptToResolveIssue(script)}
              commentMessage={getScriptCommentMessage(script.comments)}
            />
          ))}
      </div>
      <CancelRequestModal
        show={!!scriptToCancel}
        handleClose={handlePatientScriptClose}
        medicationName={formatMedicationName(
          scriptToCancel?.patient_medication.medication,
        )}
        scriptToCancelId={scriptToCancel?.id}
      />
      <ResolveIssueModal
        key={scriptToResolveIssue?.id}
        show={!!scriptToResolveIssue}
        onClose={handleResolveIssueClose}
        medication={
          scriptToResolveIssue?.patient_medication.medication.generic_name
        }
        scriptToResolveIssueId={scriptToResolveIssue?.id}
      />
    </>
  );
};
