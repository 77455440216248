import type { AxiosError } from "axios";

import { logout } from "~/providers/api/logout";
import { ROUTES } from "~/providers/router";
import { useTeamMemberStore } from "~/providers/stores/useTeamMemberStore";

export const errorInterceptor = async (error: AxiosError) => {
  if (error?.response?.status === 401) {
    const { teamMember, logout: storeLogout } = useTeamMemberStore.getState();
    if (teamMember) {
      await logout();
      storeLogout();
      window.location.href = ROUTES.login;
    }
  }

  if (error?.response?.status === 404) {
    window.location.href = ROUTES.notFound;
  }

  if (error?.response?.status === 500) {
    window.location.href = ROUTES.internalError;
  }
  return Promise.reject(error);
};
