import { FieldPath, FieldValues, UseFormRegister } from "react-hook-form";

import { formatPhoneNumber } from "./formatPhoneNumber";

export const formatPhoneNumberField = <T extends FieldValues>(
  field: FieldPath<T>,
  register: UseFormRegister<T>,
) => {
  return register(field, {
    setValueAs: (value: string) => formatPhoneNumber(value),
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      const formattedValue = formatPhoneNumber(e.target.value);
      e.target.value = formattedValue;
    },
  });
};
