import { isFuture, parseISO } from "date-fns";

import { formatDate } from "../orders";

export const generateDiscontinuationTitle = (discontinued_at?: string) => {
  if (!discontinued_at) return;
  const date = formatDate(discontinued_at);
  const prevText = isFuture(parseISO(discontinued_at))
    ? "Discontinued as of "
    : "Discontinued on";
  return `${prevText} ${date}`;
};
