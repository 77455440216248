export const RECURRING_ORDERS_TYPES = {
  status_check: "Status check",
  benzodiazepine_med_change: "Benzodiazepine medication change",
  psychostimulants_med_change: "Psychostimulants medication change",
  antidepressant_med_change: "Antidepressant medication change",
  basic_labs: "Basic labs",
  vital_signs: "Blood pressure and heart rate",
  weight: "Weight",
};

export const recurringOrdersTypesOptions = [
  {
    value: "status_check",
    label: RECURRING_ORDERS_TYPES.status_check,
  },
  {
    value: "basic_labs",
    label: RECURRING_ORDERS_TYPES.basic_labs,
  },
  {
    value: "weight",
    label: RECURRING_ORDERS_TYPES.weight,
  },
  { value: "vital signs", label: RECURRING_ORDERS_TYPES.vital_signs },
  {
    value: "benzodiazepine_med_change",
    label: RECURRING_ORDERS_TYPES.benzodiazepine_med_change,
  },
  {
    value: "psychostimulants_med_change",
    label: RECURRING_ORDERS_TYPES.psychostimulants_med_change,
  },
  {
    value: "antidepressant_med_change",
    label: RECURRING_ORDERS_TYPES.antidepressant_med_change,
  },
];

export const recurringOrdersTypesValues = Object.keys(
  RECURRING_ORDERS_TYPES,
).map((key) => key) as [string, ...string[]];
