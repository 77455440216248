import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

import { ErrorText, Typography } from "~/common/components";
import { replaceUnderscores } from "~/common/utils";
import {
  RadioGroupAnswer,
  RadioGroupCompletedAnswer,
  RadioGroupQuestionData,
} from "~/common/utils/consultations/shared";
import RadioButtonOption from "~/patients/components/ui/RadioButtonOption";
import { createRadioGroupSchema } from "~/patients/utils";
import { ConsultationQuestionsLayout } from "../../shared";

export interface RadioGroupFormProps {
  question: RadioGroupQuestionData;
  handleGoBack: () => void;
  onSubmit: (answer: RadioGroupAnswer) => void;
  isSubmitting: boolean;
  disableBack: boolean;
  completedAnswer?: RadioGroupCompletedAnswer;
}

export const RadioGroupForm = ({
  disableBack,
  handleGoBack,
  isSubmitting,
  question,
  onSubmit,
  completedAnswer,
}: RadioGroupFormProps) => {
  const { options, title } = question;

  const schema = createRadioGroupSchema(options);

  type RadioGroupFormValues = z.infer<typeof schema>;

  const {
    handleSubmit,
    watch,
    register,
    formState: { errors },
  } = useForm<RadioGroupFormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      answer: completedAnswer,
    },
  });

  const selectedValues = watch("answer.values") || [];

  const saveAnswers: SubmitHandler<RadioGroupFormValues> = (data) => {
    onSubmit(data);
  };

  return (
    <ConsultationQuestionsLayout
      handleNext={handleSubmit(saveAnswers)}
      handleBack={handleGoBack}
      isSubmitting={isSubmitting}
      disableNext={!selectedValues.length}
      title={title}
      disableBack={disableBack}
    >
      {options.map((option, index) => (
        <div key={index} className="flex flex-col gap-2">
          <Typography className="font-medium text-gray-5">
            {option.title}
          </Typography>
          {option.group.map((group) => (
            <RadioButtonOption
              key={group}
              label={replaceUnderscores(group)}
              value={group}
              isSelected={watch(`answer.values.${index}.value`) === group}
              {...register(`answer.values.${index}.value`)}
            />
          ))}
          <ErrorText>
            {errors?.answer?.values?.[index]?.value?.message}
          </ErrorText>
        </div>
      ))}
    </ConsultationQuestionsLayout>
  );
};
