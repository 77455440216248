import React from "react";
import { twMerge } from "tailwind-merge";

import { IconProps } from "~/common/utils/types";

export const MicrophoneIcon = ({ className }: IconProps) => (
  <svg
    className={twMerge("w-10 text-white", className)}
    viewBox="0 0 40 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.2635 29.63C29.2635 34.91 24.9836 39.19 19.7036 39.19C14.4236 39.19 10.1436 34.91 10.1436 29.65V9.56C10.1436 4.28 14.4236 0 19.7036 0C24.9836 0 29.2635 4.28 29.2635 9.56V29.63ZM35.5936 24.6496C35.5936 23.6496 36.3936 22.8496 37.3936 22.8496V22.8596C38.3636 22.8596 39.1936 23.6496 39.1936 24.6596V29.8496C39.1936 35.2296 37.0136 40.1096 33.4936 43.6296C30.3936 46.7296 26.2436 48.7696 21.6236 49.2196V55.5996H29.7936C30.7936 55.5996 31.5936 56.3996 31.5936 57.3996C31.5936 58.3996 30.7936 59.1996 29.7936 59.1996H9.61361C8.61361 59.1996 7.81361 58.3996 7.81361 57.3996C7.81361 56.3996 8.61361 55.5996 9.61361 55.5996H18.0236V49.2396C7.98361 48.3796 0.203613 39.8296 0.203613 29.5996V24.6496C0.203613 23.6496 1.00361 22.8496 2.00361 22.8496C3.00361 22.8496 3.80361 23.6496 3.80361 24.6496V29.8396C3.80361 38.6896 11.0736 45.8696 19.9436 45.7496C28.6636 45.6096 35.5936 38.3396 35.5936 29.5996V24.6496Z"
      fill="currentColor"
    />
  </svg>
);
