import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormResetField,
  UseFormWatch,
} from "react-hook-form";

import { QuestionAnswer } from "~/common/utils/types/checkInTypes";
import { useOnBoardingNewPatientStore } from "~/patients/stores/useOnBoardingNewPatientStore";
import {
  AddressSchemaType,
  NamesAndEmailSchemaType,
  NewPatientSchemaType,
  SignUpPatientSchemaType,
} from "~/patients/utils";
import PatientAddressForm from "../commonForms/PatientAddressForm";
import PatientMobileForm from "../commonForms/PatientMobileForm";
import PatientPasswordForm, {
  PasswordsSchemaType,
} from "../commonForms/PatientPasswordForm";
import { NewPatientNameAndEmailForm } from "./NewPatientNameAndEmailForm";

export const STEPS_LENGTH = 6;

interface NewSignUpRendererProps {
  onNext: (schemaName: keyof NewPatientSchemaType) => Promise<void>;
  handleRegistration: () => Promise<void>;
  handleGoBack: (answer?: QuestionAnswer) => void;
  register: UseFormRegister<SignUpPatientSchemaType>;
  resetField: UseFormResetField<SignUpPatientSchemaType>;
  watch: UseFormWatch<SignUpPatientSchemaType>;
  errors: FieldErrors<NewPatientSchemaType>;
  dataToken: string;
  control?: Control<SignUpPatientSchemaType>;
  isPending?: boolean;
}
export const NewPatientSignUpRenderer = ({
  register,
  onNext,
  handleGoBack,
  errors,
  handleRegistration,
  control,
  watch,
  isPending = false,
}: NewSignUpRendererProps) => {
  const { onBoardingNewPatientStep } = useOnBoardingNewPatientStore();
  const progressBarStep = onBoardingNewPatientStep + 1;

  const steps = [
    (totalSteps: number) => (
      <NewPatientNameAndEmailForm
        onNext={() => onNext("namesAndEmail")}
        totalSteps={totalSteps}
        register={(data: keyof NamesAndEmailSchemaType) =>
          register(`namesAndEmail.${data}`)
        }
        errors={errors.namesAndEmail}
        step={progressBarStep}
      />
    ),
    (totalSteps: number) => (
      <PatientMobileForm
        handleGoBack={handleGoBack}
        onNext={() => onNext("phone_number")}
        totalSteps={totalSteps}
        register={register}
        errors={errors.phone_number}
        step={progressBarStep}
      />
    ),
    (totalSteps: number) => (
      <PatientAddressForm
        handleGoBack={handleGoBack}
        onNext={() => onNext("address")}
        totalSteps={totalSteps}
        register={(data: keyof AddressSchemaType) =>
          register(`address.${data}`)
        }
        errors={errors.address}
        control={control}
        step={progressBarStep}
        addressStateKey="address.state"
      />
    ),
    (totalSteps: number) => (
      <PatientPasswordForm
        handleGoBack={handleGoBack}
        onNext={handleRegistration}
        totalSteps={totalSteps}
        register={(data: keyof PasswordsSchemaType) =>
          register(`password.${data}`)
        }
        errors={errors.password}
        enteredPassword={watch("password.password")}
        pendingRequest={isPending}
        step={progressBarStep}
      />
    ),
  ];

  return steps[onBoardingNewPatientStep](steps.length);
};
