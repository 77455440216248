import { Button, H2, Typography } from "~/common/components";
import VideoCameraIcon from "~/common/components/icons/VideoCameraIcon";
import { BUTTON_VARIANTS } from "~/common/constants";
import { TYPOGRAPHY_VARIANTS } from "~/common/utils";

interface AppointmentScheduledCardProps {
  title: string;
}

export const AppointmentScheduledCard = ({
  title,
}: AppointmentScheduledCardProps) => (
  <div className="flex flex-col gap-4 rounded-lg border border-primary-600 bg-primary-500 p-4 text-white">
    <div className="flex w-full flex-col gap-2 border-b border-primary-300 pb-2">
      <H2 className="text-xl">{title}</H2>
    </div>
    <div className="flex w-full flex-col gap-3">
      <div className="flex w-full flex-col items-start justify-between">
        <Typography
          className="text-lg text-white"
          variant={TYPOGRAPHY_VARIANTS.light}
        >
          Scheduled for:
        </Typography>
        <Typography className="text-lg text-white">
          Monday, May 25 at 3:00 PM
        </Typography>
      </div>
      <Button
        variant={BUTTON_VARIANTS.outline_none}
        size="sm"
        onClick={() => null}
        className="w-3/4 bg-white text-primary-900"
      >
        <div className="flex items-center justify-start gap-2 text-base">
          <VideoCameraIcon />
          Online consultation
        </div>
      </Button>
    </div>
  </div>
);
