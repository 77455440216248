import React from "react";
import { twMerge } from "tailwind-merge";

interface MenuIconProps {
  className?: string;
}
const MenuIcon = ({ className }: MenuIconProps) => {
  return (
    <svg
      className={twMerge("h-7 w-7 text-gray-600", className)}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.25 8.75H23.75M6.25 15H23.75M6.25 21.25H23.75"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default MenuIcon;
