import { Button } from "~/common/components";
import SuccessErrorLayout from "~/common/components/layouts/SuccessErrorLayout";
import Modal from "~/common/components/Modal";
import { MedicationStatusModal } from "~/common/types/medicationTypes";

interface RequestScriptStatusModalProps {
  medicationStatusModal?: MedicationStatusModal;
  setMedicationStatusModal: (value: MedicationStatusModal) => void;
}

export const RequestScriptStatusModal = ({
  medicationStatusModal,
  setMedicationStatusModal,
}: RequestScriptStatusModalProps) => {
  const handleOnClose = () =>
    setMedicationStatusModal({
      ...medicationStatusModal,
      isOpen: false,
    });

  const title = medicationStatusModal?.isSuccess
    ? "Request successfully sent!"
    : "Something went wrong";

  const description = medicationStatusModal?.isSuccess
    ? ""
    : "Something went wrong. Please try again.";

  const buttonVariant = medicationStatusModal?.isSuccess
    ? "primary"
    : "outline-none-underline";

  return (
    <Modal
      show={medicationStatusModal?.isOpen}
      onClose={handleOnClose}
      hideCloseIcon
    >
      <SuccessErrorLayout
        success={medicationStatusModal?.isSuccess}
        title={title}
        description={description}
        className="md:w-full"
      >
        <Button onClick={handleOnClose} variant={buttonVariant}>
          Back to My medications
        </Button>
      </SuccessErrorLayout>
    </Modal>
  );
};
