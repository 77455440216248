import { z } from "zod";

import { usaStates } from "~/patients/constants/usaStates";

export const baseAddressSchema = z.object({
  street: z.string().min(1, {
    message: "Street number and name is required",
  }),
  city: z.string().min(1, {
    message: "City is required",
  }),
  state: z.enum(usaStates, {
    errorMap: () => ({ message: "State is required" }),
  }),
  zipcode: z.string().length(5, "ZIP Code must have 5 digits."),
});

export const pharmacySchema = z.object({
  name: z.string().min(1, "Pharmacy name is required"),
  phone_number: z
    .string()
    .min(1, "Phone number required")
    .regex(/^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/, {
      message: "Please enter phone number with format: (123) 456-7890",
    }),
  address: baseAddressSchema,
});

export const createNewPharmacySchema = z.object({
  pharmacy: pharmacySchema,
  is_default: z.boolean(),
});
