import {
  MedicationBasicData,
  MedicationGenericData,
  MedicationStrength,
  PatientMedicationStrength,
} from "~/common/types";

export const formatMedicationName = (
  medication?: MedicationBasicData | MedicationGenericData,
) => `${medication?.generic_name} (${medication?.brand})`;

export const formatMedicationNameWithStrength = (
  medication: MedicationBasicData | MedicationGenericData,
  strength: PatientMedicationStrength | MedicationStrength,
) => `${formatMedicationName(medication)}, ${strength.amount}${strength.unit}`;
