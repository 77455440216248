import { twMerge } from "tailwind-merge";

import { Styled } from "../utils";
import Spinner from "./ui/Spinner";

export const LoadingState = ({ className }: Styled) => (
  <div
    className={twMerge("flex size-full items-center justify-center", className)}
  >
    <Spinner />
  </div>
);
