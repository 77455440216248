import React, { ComponentPropsWithoutRef, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import { ErrorText } from "./typography";

interface SwitchProps extends ComponentPropsWithoutRef<"input"> {
  children?: ReactNode;
  error?: string;
  className?: string;
  inputClassName?: string;
}

export const Switch = React.forwardRef<HTMLInputElement, SwitchProps>(
  ({ id, children, error, className, ...props }: SwitchProps, ref) => (
    <div className="flex w-fit flex-col gap-2 text-gray-70">
      <label
        className={twMerge("flex cursor-pointer items-center gap-2", className)}
        htmlFor={id}
      >
        <input
          type="checkbox"
          className="peer sr-only"
          id={id}
          ref={ref}
          {...props}
        />
        <div
          className={twMerge(
            "peer relative h-5 w-9 min-w-9 rounded-full bg-gray-200",
            "after:absolute after:start-[0.125rem] after:top-[0.125rem]",
            "after:h-4 after:w-4 after:rounded-full after:border after:bg-white after:shadow-sm after:transition-all ",
            "peer-checked:bg-primary-500 peer-checked:after:translate-x-full peer-checked:after:bg-white rtl:peer-checked:after:-translate-x-full",
          )}
        />
        {children}
      </label>
      {error?.trim() && <ErrorText>{error}</ErrorText>}
    </div>
  ),
);

Switch.displayName = "Switch";
