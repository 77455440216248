import {
  CompletePatientIntakeResponse,
  CurrentIntakeQuestion,
  IntakeDataResponse,
  IntakeQuestion,
  IntakeQuestionAnswer,
  PatientIntakeBundle,
  StartPatientIntakeResponse,
} from "~/common/utils/consultations/intake/interfaces";
import { api } from "~/patients/api";

export const intakePath = "/v1/patients/intakes";

export const intakeIntroScreenVisit = async () => {
  const response = await api.patch<void>(
    "/v1/patients/intake-intro-screen/visit",
    null,
  );
  return response.data;
};

export const getCurrentIntakeBundle = async () => {
  const { data } = await api.get<PatientIntakeBundle>(
    `${intakePath}/current/bundles`,
  );
  return data;
};

export const getIntake = async (intakeId: string) => {
  const { data } = await api.get<IntakeDataResponse>(
    `${intakePath}/${intakeId}`,
  );

  return data;
};

export const getCurrentIntakeQuestion = async (patientIntakeId: string) => {
  const { data } = await api.get<CurrentIntakeQuestion>(
    `${intakePath}/${patientIntakeId}/current`,
  );

  return data;
};

export const answerIntakeQuestion = async (
  patientIntakeId: string,
  answer: IntakeQuestionAnswer,
) => {
  const { data } = await api.post<string>(
    `${intakePath}/${patientIntakeId}/answers`,
    answer,
  );

  return data;
};

export const getPreviousIntakeQuestion = async (patientIntakeId: string) => {
  const { data } = await api.post<IntakeQuestion>(
    `${intakePath}/${patientIntakeId}/previous`,
    null,
  );

  return data;
};

export const startIntake = async (intakeId: string) => {
  const { data } = await api.post<StartPatientIntakeResponse>(
    `${intakePath}/${intakeId}/start`,
  );

  return data;
};

export const completeIntake = async (patientIntakeId: string) => {
  const { data } = await api.post<CompletePatientIntakeResponse | null>(
    `${intakePath}/${patientIntakeId}/complete`,
  );

  return data;
};
