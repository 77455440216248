export const RELATIONSHIPS = {
  CHILD: "child",
  FRIEND: "friend",
  PARENT: "parent",
  SIBLING: "sibling",
  SPOUSE: "spouse",
  OTHER: "other",
};

export const relationshipsOptions = [
  { value: RELATIONSHIPS.CHILD, label: "Child" },
  { value: RELATIONSHIPS.FRIEND, label: "Friend" },
  { value: RELATIONSHIPS.PARENT, label: "Parent" },
  { value: RELATIONSHIPS.SIBLING, label: "Sibling" },
  { value: RELATIONSHIPS.SPOUSE, label: "Spouse" },
  { value: RELATIONSHIPS.OTHER, label: "Other" },
];

export const relationshipValues = Object.values(RELATIONSHIPS) as [
  string,
  ...string[],
];
