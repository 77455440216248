import { SCREENING_QUESTION_CODES } from "~/common/utils/consultations/screening";
import {
  CONSULTATIONS_INPUTS_TYPES,
  CONSULTATIONS_QUESTION_TYPES,
} from "~/common/utils/consultations/shared";

export const textQuestions = {
  [SCREENING_QUESTION_CODES.tell_us_about_therapist]: {
    title: "Please tell us about your therapist",
    type: CONSULTATIONS_QUESTION_TYPES.text,
    inputs: [
      {
        id: "1",
        label: "Name of therapist",
        placeholder: "Enter name",
        value: "name",
        type: CONSULTATIONS_INPUTS_TYPES.text,
        required: true,
      },
      {
        id: "2",
        label: "Email of therapist",
        placeholder: "Enter email address",
        value: "email",
        regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        regexMessage: "Invalid email address",
        type: CONSULTATIONS_INPUTS_TYPES.text,
        required: true,
      },
      {
        id: "3",
        label: "Phone number",
        value: "phone_number",
        placeholder: "(---) --- - ----",
        regex: /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/,
        regexMessage: "Invalid phone number",
        type: CONSULTATIONS_INPUTS_TYPES.phone_number,
        required: true,
      },
    ],
  },
  [SCREENING_QUESTION_CODES.who_lives_with_you]: {
    title: "Who lives at home with you currently?",
    type: CONSULTATIONS_QUESTION_TYPES.text,
    inputs: [
      {
        id: "1",
        type: CONSULTATIONS_INPUTS_TYPES.text_area,
        value: "living_with",
        placeholder: "e.g., spouse, brother, parents.",
        required: true,
      },
    ],
  },
  [SCREENING_QUESTION_CODES.what_is_your_job]: {
    title: "What is your job?",
    type: CONSULTATIONS_QUESTION_TYPES.text,
    inputs: [
      {
        id: "1",
        placeholder: "Please write a few words about your occupation.",
        value: "job",
        type: CONSULTATIONS_INPUTS_TYPES.text_area,
        required: true,
      },
    ],
  },
  [SCREENING_QUESTION_CODES.describe_medical_issues]: {
    title: "Please describe any medical issues",
    type: CONSULTATIONS_QUESTION_TYPES.text,
    inputs: [
      {
        id: "1",
        placeholder: "Enter further description",
        value: "medical_issues",
        type: CONSULTATIONS_INPUTS_TYPES.text_area,
        required: true,
      },
    ],
  },
} as const;

export type ScreeningTextQuestionValues =
  (typeof textQuestions)[keyof typeof textQuestions]["inputs"][number]["value"];
