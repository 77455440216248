import Skeleton from "react-loading-skeleton";

const array = Array.from({ length: 3 }, (_, index) => index + 1);

export const LoadingPatientsTable = () => {
  return (
    <tbody className="text-[0.94rem] text-gray-700">
      {array.map((_, index) => (
        <tr className="border-b border-gray-300" key={index}>
          <td className="px-6 py-5" colSpan={3}>
            {" "}
            <Skeleton />
          </td>
        </tr>
      ))}
    </tbody>
  );
};
