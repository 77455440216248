import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { ErrorResponse, signUpNewPatient } from "~/patients/api";
import { SignUpNewPatientDataRequest } from "~/patients/utils";

export const useCreateNewPatient = (onSignUpSuccess: () => void) => {
  const { mutate: signUpMutation, isPending } = useMutation({
    mutationFn: ({ newPatient }: { newPatient: SignUpNewPatientDataRequest }) =>
      signUpNewPatient(newPatient),
    onSuccess: onSignUpSuccess,
    onError: (error: ErrorResponse) => {
      const errorMessage =
        error.response?.data.detail ??
        "There was an issue registering your account. Please try again later.";
      toast.error(errorMessage);
    },
  });

  return { signUpMutation, isPending };
};
