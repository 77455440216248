import { useNavigate } from "react-router-dom";

import { Button, H2, Typography } from "~/common/components";
import { BUTTON_VARIANTS } from "~/common/constants";
import { TYPOGRAPHY_VARIANTS } from "~/common/utils";
import { ROUTES } from "~/patients/router";

interface IntakeSubmittedCardProps {
  title: string;
}

export const IntakeSubmittedCard = ({ title }: IntakeSubmittedCardProps) => {
  const navigate = useNavigate();

  return (
    <div className="flex w-full rounded-lg border border-primary-600 bg-primary-500 p-4 text-white">
      <div className="flex w-full flex-col gap-2 ">
        <H2 className="text-xl">{title}</H2>
        <Typography
          variant={TYPOGRAPHY_VARIANTS.light}
          className="w-11/12 text-base text-neutral-50"
        >
          Our first meeting
        </Typography>

        <Button
          onClick={() => navigate(ROUTES.schedulingInstructions)}
          variant={BUTTON_VARIANTS.outline_black}
          size="sm"
        >
          Scheduling instructions
        </Button>
      </div>
    </div>
  );
};
