import { useQuery, useQueryClient } from "@tanstack/react-query";

import { getCurrentPatient } from "~/patients/api/services/authService";

interface UseGetCurrentPatientProps {
  enabled?: boolean;
  retry?: number;
}
export const useGetCurrentPatient = (props?: UseGetCurrentPatientProps) => {
  const { enabled, retry } = { enabled: true, retry: 4, ...(props ?? {}) };
  const queryClient = useQueryClient();

  const patientIsLoggedIn = !!queryClient.getQueryData(["getPatientIsAuth"]);
  const isEnabled = enabled && patientIsLoggedIn;

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryFn: () => getCurrentPatient(),
    queryKey: ["getCurrentPatient"],
    staleTime: 1000 * 60 * 30, // 30 minutes,
    enabled: isEnabled,
    retry,
  });
  return { data, isLoading, isFetching, refetch };
};
