import { useEffect } from "react";
import {
  generatePath,
  Navigate,
  useNavigate,
  useParams,
} from "react-router-dom";

import { LoadingState } from "~/common/components";
import { PATIENT_STATUS } from "~/common/constants";
import { CONSULTATION_STATUSES } from "~/common/utils";
import { intakeSectionTitle } from "~/common/utils/consultations/intake";
import { StartConsultationSection } from "~/patients/components/consultations";
import { useGetCurrentPatient, useGetIntake } from "~/patients/hooks";
import { useStartIntake } from "~/patients/hooks/queries/consultations/intake/useStartIntake";
import { ROUTES } from "~/patients/router";

export const StartIntake = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: user } = useGetCurrentPatient();

  const providerImage = user?.provider.profile_picture_path;

  const screeningCompleted =
    user?.status === PATIENT_STATUS.screening_completed ||
    user?.status === PATIENT_STATUS.intake_started;

  const userCanAccessIntake = !!user?.email_verified_at && screeningCompleted;

  const { data: intakeData, isLoading } = useGetIntake(id, userCanAccessIntake);

  const { startIntakeMutation, isPending } = useStartIntake();
  const {
    intake,
    patient_intake_id: patientIntakeId,
    status,
  } = {
    ...intakeData,
  };

  const redirectToScreeningIfStarted = () => {
    if (status === CONSULTATION_STATUSES.in_progress && patientIntakeId) {
      navigate(
        generatePath(ROUTES.intake, {
          id: patientIntakeId,
        }),
      );
    }
    if (status === CONSULTATION_STATUSES.completed) {
      navigate(ROUTES.screeningAlreadyCompleted);
    }
  };

  useEffect(redirectToScreeningIfStarted, [redirectToScreeningIfStarted]);

  if (!userCanAccessIntake) return <Navigate to={ROUTES.notFound} />;

  if (isLoading) return <LoadingState className="h-screen-calculated" />;

  return (
    intake && (
      <StartConsultationSection
        section={intake.order}
        duration={intake.duration}
        title={intakeSectionTitle[intake.code]}
        handleContinue={() => startIntakeMutation(intake.id)}
        handleGoBack={() => navigate(ROUTES.leadPatientHome)}
        pendingContinue={isPending}
        providerImageSrc={providerImage}
      />
    )
  );
};
