import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";

import { ErrorText } from "~/common/components";
import {
  CompletedIntakeSymptomsAnswer,
  IntakeSymptomsAnswer,
  IntakeSymptomsQuestionData,
} from "~/common/utils/consultations/intake/interfaces";
import {
  ConfirmExclusiveOptionModal,
  ConsultationQuestionsLayout,
} from "~/patients/components";
import {
  NONE_VALUE,
  symptomsBaseForm,
  SymptomsFormValues,
  symptomsSchema,
} from "~/patients/utils/consultations/intake";
import { SymptomRenderer } from "./inputs/SymptomRenderer";

interface IntakeSymptomsFormProps {
  question: IntakeSymptomsQuestionData;
  handleGoBack: () => void;
  onSubmit: (answer: IntakeSymptomsAnswer) => void;
  isSubmitting: boolean;
  disableBack: boolean;
  completedAnswer?: CompletedIntakeSymptomsAnswer;
}

export const IntakeSymptomsForm = ({
  question,
  handleGoBack,
  onSubmit,
  isSubmitting,
  completedAnswer,
  disableBack,
}: IntakeSymptomsFormProps) => {
  const [exclusiveOptionModal, setExclusiveOptionModalOpen] = useState(false);
  const { title, subtitle } = question;
  const {
    handleSubmit,
    register,
    watch,
    clearErrors,
    resetField,
    getFieldState,
    reset,
    setValue,
    formState: { errors },
  } = useForm<SymptomsFormValues>({
    defaultValues: completedAnswer ? { symptoms: completedAnswer } : undefined,
    resolver: zodResolver(symptomsSchema),
  });

  const toggleExclusiveOptionModal = () =>
    setExclusiveOptionModalOpen((prev) => !prev);

  const handleConfirmKillerOption = () => {
    toggleExclusiveOptionModal();
    reset({ symptoms: {} }, { keepValues: false });
    setValue(NONE_VALUE, true);
  };

  const onSave: SubmitHandler<SymptomsFormValues> = ({ symptoms }) =>
    onSubmit({ answer: symptoms });

  const noSymptomsSelected = !Object.values(watch("symptoms") ?? {}).some(
    (symptom) => symptom?.toggled,
  );

  return (
    <ConsultationQuestionsLayout
      handleNext={handleSubmit(onSave)}
      handleBack={handleGoBack}
      isSubmitting={isSubmitting}
      title={title}
      subtitle={subtitle}
      disableBack={disableBack}
      disableNext={noSymptomsSelected}
    >
      <div
        onSubmit={handleSubmit(onSave)}
        className="flex w-full flex-col gap-4"
      >
        <div className="flex flex-col gap-2">
          {symptomsBaseForm.map((symptom) => (
            <SymptomRenderer
              key={symptom.value}
              symptom={symptom}
              register={register}
              watch={watch}
              errors={errors}
              clearErrors={clearErrors}
              resetField={resetField}
              getFieldState={getFieldState}
              toggleExclusiveOptionModal={toggleExclusiveOptionModal}
            />
          ))}
        </div>
        {errors.symptoms?.root?.message && (
          <ErrorText>{errors.symptoms?.root?.message}</ErrorText>
        )}
      </div>
      <ConfirmExclusiveOptionModal
        isOpen={exclusiveOptionModal}
        onClose={toggleExclusiveOptionModal}
        onConfirm={handleConfirmKillerOption}
        exclusiveOptionName="I am not experiencing any symptoms"
      />
    </ConsultationQuestionsLayout>
  );
};
