import type { Control, UseFormRegisterReturn } from "react-hook-form";
import { z } from "zod";

import { CardInputsContainer } from "~/common/components";
import Input from "~/common/components/ui/Input";
import Select from "~/common/components/ui/select/Select";
import { relationshipsOptions } from "~/patients/constants/relationships";
import { emergencyContactSchema } from "~/patients/utils/PatientSchemas";
import { FormErrors } from "~/patients/utils/types";
import { PatientSchemaType } from "./UserDetails";

export type EmergencyContactsSchemaType = z.infer<
  typeof emergencyContactSchema
>;

interface EmergencyContactProps {
  register: (
    data: keyof EmergencyContactsSchemaType,
  ) => UseFormRegisterReturn<string>;
  errors: FormErrors<EmergencyContactsSchemaType> | undefined;
  control?: Control<PatientSchemaType>;
  customRelationship?: string;
  otherRelationship: boolean;
}

const EmergencyContact = ({
  register,
  errors,
  control,
  otherRelationship,
}: EmergencyContactProps) => (
  <CardInputsContainer title="Emergency contact">
    <div className="flex w-full flex-col gap-4">
      <div className="flex w-full flex-col gap-4 md:flex-row md:gap-2">
        <Input
          id="emergency_contact_name"
          label="First name"
          {...register("name")}
          error={errors?.name?.message}
        />
        <Input
          id="emergency_contact_last_name"
          label="Last name"
          {...register("last_name")}
          error={errors?.last_name?.message}
        />
      </div>
      <div className="flex w-full flex-col gap-4 md:flex-row md:gap-2">
        <Input
          id="emergency_contact_email"
          label="Email address"
          {...register("email")}
          error={errors?.email?.message}
        />

        <Input
          id="emergency_contact_mobile_number"
          label="Mobile number"
          {...register("phone_number")}
          error={errors?.phone_number?.message}
        />
      </div>
      <div className="flex flex-col gap-3 md:flex-row md:gap-2">
        <Select
          error={errors?.relationship?.message}
          label="Relationship"
          placeholder={"Select relationship"}
          id="emergency_contacts.0.relationship"
          options={relationshipsOptions}
          control={control}
          buttonClassName="py-3"
          containerClassName="gap-2"
        />
        <div className="flex h-full w-full flex-col items-end">
          {otherRelationship && (
            <>
              <div className="md:h-5" />
              <Input
                id="customRelationship"
                placeholder="Enter relationship"
                {...register("customRelationship")}
                error={errors?.customRelationship?.message}
              />
            </>
          )}
        </div>
      </div>
    </div>
  </CardInputsContainer>
);

export default EmergencyContact;
