import {
  CompletedConsultationCard,
  PendingConsultationCard,
} from "~/patients/components/consultations/shared/ui/cards";

interface ConsultationCardProps {
  completed: boolean;
  title: string;
  subtitle?: string;
  totalSections: number;
  currentSection: number;
  duration: string;
  handleCompleteConsultation: () => void;
}

export const ConsultationCard = ({
  completed,
  title,
  subtitle,
  totalSections,
  currentSection,
  duration,
  handleCompleteConsultation,
}: ConsultationCardProps) => {
  return (
    <>
      {completed ? (
        <CompletedConsultationCard title={title} />
      ) : (
        <PendingConsultationCard
          title={title}
          duration={duration}
          totalSections={totalSections}
          currentSection={currentSection}
          subtitle={subtitle}
          handleCompleteConsultation={handleCompleteConsultation}
        />
      )}
    </>
  );
};
