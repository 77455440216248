import { Navigate, useNavigate } from "react-router-dom";

import { ConsultationIntroScreen } from "~/patients/components";
import { useGetCurrentPatient } from "~/patients/hooks/queries/useGetCurrentPatient";
import { ROUTES } from "~/patients/router/routes";
import { PatientInformation } from "~/patients/utils";

const getIntakeIntroScreenMessage = (user?: PatientInformation) => (
  <div className="flex flex-col gap-4 text-base font-light text-gray-700">
    <p>Hi {user?.name}!</p>
    <p>The next step of your consultation begins now!</p>
    <p>
      It starts with some online questions, followed by an in-person or virtual
      meeting with me.
    </p>
    <div className="space-y-0">
      <p>Looking forward to meeting you</p>
      <p className="text-xl font-semibold">{user?.provider.signature_name}</p>
    </div>
  </div>
);

export const IntakeIntroScreen = () => {
  const navigate = useNavigate();
  const { data: user } = useGetCurrentPatient();

  if (user?.settings?.intake_first_screen_visited)
    return <Navigate to={ROUTES.completeIntakeConsultation} />;

  return (
    <ConsultationIntroScreen
      handleContinue={() => navigate(ROUTES.payment)}
      content={getIntakeIntroScreenMessage(user)}
      profilePicturePath={user?.provider.profile_picture_path}
    />
  );
};
