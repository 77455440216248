import React from "react";

export const ErrorBoundaryFallback = () => {
  return (
    <main className="flex h-screen flex-col items-center justify-center">
      <h2 className="mb-10 font-outfit text-2xl">
        Something went terribly wrong!
      </h2>
    </main>
  );
};
