import {
  AudioAnswer,
  BloodPressureAndHeartRate,
  BloodPressureAnswer,
  CHECK_IN_CODES,
  CheckInCodes,
  CompletedAnswerData,
  FileAnswer,
  FreeTextAnswer,
  HeartRateAnswer,
  INPUT_TYPES,
  InputAnswer,
  InputsTypes,
  PatientCheckInInput,
  PatientCheckInQuestion,
  QuestionAnswer,
  SideEffectsCheckinCodes,
  StatusAnswer,
  WeightAnswer,
  YesNoRadioAnswer,
} from "~/common/utils/types/checkInTypes";
import {
  BasicLabs,
  BasicLabsDesktop,
  HealthFactors,
  HealthFactorsDesktop,
  ScheduleAppointment,
  ScheduleAppointmentDesktop,
  SideEffects,
  SideEffectsDesktop,
  StatusCheck,
  StatusCheckDesktop,
  Weight,
  WeightDesktop,
} from "~/patients/assets/index";

interface AnswerTypeMap {
  weight: WeightAnswer;
  status: StatusAnswer;
  file: FileAnswer;
  audio: AudioAnswer;
  "free-text": FreeTextAnswer;
  "yes-no-radio": YesNoRadioAnswer;
  "heart-rate": HeartRateAnswer;
  "blood-pressure": BloodPressureAnswer;
}

export const checkInUtilities = () => {
  const formatCheckInCode = (code?: string) =>
    code
      ?.split("-")
      .map((word, index) =>
        index === 0
          ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          : word.toLowerCase(),
      )
      ?.join(" ") ?? "";

  const getExistingAnswerOfQuestion = (
    allAnswers: QuestionAnswer[],
    questionId: string,
  ) => allAnswers.find((answer) => answer.question_id === questionId);

  const getInputOfType = (
    type: InputsTypes,
    allInputs: PatientCheckInInput[],
  ) => allInputs.find((input) => input.type === type);

  const getPathFromUrl = (url: string): string =>
    new URL(url).pathname.substring(1);

  const getAnswerOfType = <T extends keyof AnswerTypeMap>(
    type: T,
    allInputs: PatientCheckInInput[],
    allAnswers?: QuestionAnswer,
  ): AnswerTypeMap[T] | undefined =>
    allAnswers?.inputs.find(
      (inputAnswer) => inputAnswer.id === getInputOfType(type, allInputs)?.id,
    )?.answer as AnswerTypeMap[T];

  const getImagesByCode = (code: CheckInCodes) => {
    if (SideEffectsCheckinCodes.includes(code)) {
      return [SideEffects, SideEffectsDesktop];
    }
    if (BloodPressureAndHeartRate.includes(code)) {
      return [HealthFactors, HealthFactorsDesktop];
    }
    switch (code) {
      case CHECK_IN_CODES.schedule_appointment:
        return [ScheduleAppointment, ScheduleAppointmentDesktop];

      case CHECK_IN_CODES.weight:
        return [Weight, WeightDesktop];

      case CHECK_IN_CODES.basic_labs:
        return [BasicLabs, BasicLabsDesktop];

      default:
        return [StatusCheck, StatusCheckDesktop];
    }
  };

  const getInputAnswerWithFile = (inputsAnswer: InputAnswer[]) =>
    inputsAnswer.find(
      (inputAnswer) =>
        inputAnswer.answer?.answer_type === INPUT_TYPES.file ||
        inputAnswer.answer?.answer_type === INPUT_TYPES.audio,
    );

  const getFile = (inputAnswer?: InputAnswer) =>
    inputAnswer?.answer?.answer_type === INPUT_TYPES.audio ||
    inputAnswer?.answer?.answer_type === INPUT_TYPES.file
      ? inputAnswer.answer.file
      : undefined;

  const getQuestion = (
    questionId: string,
    questions?: PatientCheckInQuestion[],
  ) => questions?.find((question) => question.id === questionId);

  const getSubmittedAnswerOfType = <T extends keyof AnswerTypeMap>(
    questionId: string,
    type: T,
    answers?: CompletedAnswerData[],
  ): AnswerTypeMap[T] | undefined =>
    answers?.find(
      (answer) =>
        answer.value.answer_type === type && answer.question_id === questionId,
    )?.value as AnswerTypeMap[T];

  return {
    formatCheckInCode,
    getExistingAnswerOfQuestion,
    getInputOfType,
    getAnswerOfType,
    getImagesByCode,
    getPathFromUrl,
    getInputAnswerWithFile,
    getQuestion,
    getSubmittedAnswerOfType,
    getFile,
  };
};
