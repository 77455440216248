import { ReactNode } from "react";

export const QUESTION_TYPES = {
  yes_no_with_free_text: "yes-no-with-free-text",
  file: "file",
  weight: "weight",
  vital_signs: "vital-signs",
  audio: "audio",
  status: "status",
} as const;

export type QuestionTypes =
  (typeof QUESTION_TYPES)[keyof typeof QUESTION_TYPES];

export const QUESTIONS_CODES = {
  upload_basic_labs: "upload-basic-labs",
  blood_pressure_heart_rate: "blood-pressure-heart-rate",
  weight: "weight",
  gastrointestinal_issues: "gastrointestinal-issues",
  neurological: "neurological",
  sexual: "sexual",
  worrisome_thoughts: "worrisome-thoughts",
  any_other_side_effects: "any-other-side-effects",
  drowsiness_sleepiness: "drowsiness-sleepiness",
  dulling_of_thinking: "dulling-of-thinking",
  tremor_confusion_anxiety: "tremor-confusion-anxiety",
  anxiety_symptoms_not_improving: "anxiety-symptoms-not-improving",
  dry_mouth: "dry-mouth",
  headache: "headache",
  palpitations: "palpitations",
  sweating: "sweating",
  insomnia: "insomnia",
  loss_of_appetite: "loss-of-appetite",
  emotional_status: "emotional-status",
  audio_tell_us_how_you_are_doing: "audio-tell-us-how-you-are-doing",
} as const;

export type QuestionCodes =
  (typeof QUESTIONS_CODES)[keyof typeof QUESTIONS_CODES];

export interface QuestionData {
  title: string;
  subtitle?: ReactNode;
}
