import React from "react";

export const StatusCheckIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="16" fill="#E9DEF9" />
    <path
      d="M7.29797 10.0054L7.29797 21.9945M11.649 12.4374L11.649 19.5624M16 6.0166L16 25.9834M20.351 10.622V21.378M24.702 12.4374V19.5624"
      stroke="#9371C4"
      strokeWidth="2"
    />
  </svg>
);
