import { CONSULTATIONS_QUESTION_TYPES } from "~/common/utils/consultations/shared";
import { INTAKE_QUESTION_CODES } from "../intakeQuestionsData";

export const multiCheckWithBackendOptions = {
  [INTAKE_QUESTION_CODES.symptoms_reported]: {
    title:
      "Which of the following symptoms reported, do you consider to be the most impactful or significant to your life?",
    type: CONSULTATIONS_QUESTION_TYPES.multi_check,
  },
} as const;
