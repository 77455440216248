import { useMutation } from "@tanstack/react-query";

import { QuestionAnswer } from "~/common/utils/types/checkInTypes";
import { submitPatientCheckin } from "~/patients/api/checkIn";
import { useUploadFilesS3 } from "./useUploadFileS3";

interface UseSubmitCheckinProps {
  checkInId?: string;
  answers: QuestionAnswer[];
  setCurrentQuestionNumber: React.Dispatch<React.SetStateAction<number | null>>;
}
export const useSubmitCheckin = ({
  checkInId,
  setCurrentQuestionNumber,
}: UseSubmitCheckinProps) => {
  const { handleUploadFileAnswers, isUploadFilePending } = useUploadFilesS3({
    checkInId,
  });

  const {
    mutate: submitCheckin,
    isSuccess: isSubmitAnswerSuccess,
    isPending: isSubmitAnswerPending,
  } = useMutation({
    mutationFn: async (allAnswers?: QuestionAnswer[]) => {
      let updatedAnswers;
      if (allAnswers) {
        updatedAnswers = await handleUploadFileAnswers(allAnswers);
      }
      await submitPatientCheckin(checkInId ?? "", updatedAnswers);
    },
    onSettled: () => {
      setCurrentQuestionNumber((prev) => (prev !== null ? prev + 1 : 0));
    },
  });

  return {
    submitCheckin,
    isSubmitAnswerSuccess,
    isSubmitAnswerPending,
    isUploadFilePending,
  };
};
