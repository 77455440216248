import { differenceInDays, parseISO } from "date-fns";

export const ALERT_DUE_DATES = {
  today: "Due today",
  overdue: "Overdue",
} as const;

export const getDateText = (dueDateString: string): string => {
  const today = new Date();
  const dueDate = parseISO(dueDateString);
  const diffInDays = differenceInDays(dueDate, today);

  switch (diffInDays) {
    case 0:
      return "Due today";
    case 1:
      return "Due tomorrow";
    default:
      if (diffInDays < 0) {
        return "Overdue";
      }
      return `Due in ${diffInDays} days`;
  }
};
