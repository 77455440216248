import React from "react";

import AuthenticationLayout from "~/common/components/layouts/AuthenticationLayout";
import LogInForm from "~/patients/components/LogInForm";
import { patientWelcomeText } from "../constants/constants";

export const Login = () => {
  return (
    <AuthenticationLayout welcomeText={patientWelcomeText}>
      <LogInForm />
    </AuthenticationLayout>
  );
};
