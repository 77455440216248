import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { ErrorResponse } from "~/patients/api";
import { resendEmailVerification } from "~/patients/api/emailVerification";

export const useSendEmailVerification = () => {
  const { mutate: sendEmail, isPending } = useMutation<
    void,
    ErrorResponse,
    { showSuccessMessage: boolean }
  >({
    mutationFn: resendEmailVerification,
    onError: (error: ErrorResponse) => {
      if (error.response?.status === 429) {
        toast.error(
          "Please wait for a minute before requesting the email resend if you haven't received it yet.",
        );
      } else {
        toast.error("There was an error sending the mail. Try again later!");
      }
    },
    onSuccess: (_, variables) => {
      variables.showSuccessMessage && toast.success("Verification email sent!");
    },
  });
  return { sendEmail, isPending };
};
