import {
  INTAKE_QUESTION_CODES,
  INTAKE_QUESTION_TYPES,
} from "~/common/utils/consultations/intake";
import { YesNo } from "~/common/utils/types";

export const yesNoQuestions = {
  [INTAKE_QUESTION_CODES.out_of_your_body]: {
    title:
      "Do you ever feel like you are out of your body/ not present/ winding up missing large chunks of time?",
    type: INTAKE_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [INTAKE_QUESTION_CODES.do_you_have_any_past_history_of_genetic_testing]: {
    title: "Do you have any past history of genetic testing?",
    type: INTAKE_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [INTAKE_QUESTION_CODES.have_you_had_any_medical_or_psychiatric_hospitalizations]:
    {
      title: "Have you had any medical or psychiatric hospitalizations?",
      type: INTAKE_QUESTION_TYPES.radio,
      options: YesNo,
    },
  [INTAKE_QUESTION_CODES.have_you_participated_in_any_other_outpatient_programs_or_treatments]:
    {
      title:
        "Have you participated in any other outpatient programs or treatments? (e.g. partial hospitalization, intensive outpatient, substance abuse treatment, DBT, CBT, eating disorder treatment, ketamine therapy)",
      type: INTAKE_QUESTION_TYPES.radio,
      options: YesNo,
    },
  [INTAKE_QUESTION_CODES.have_you_had_any_abnormal_ekgs_in_the_past]: {
    title: "Have you had any abnormal EKGs in the past?",
    type: INTAKE_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [INTAKE_QUESTION_CODES.have_you_had_any_heart_murmurs]: {
    title: "Have you had any heart murmurs?",
    type: INTAKE_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [INTAKE_QUESTION_CODES.have_you_ever_been_diagnosed_with_or_experienced_any_heart_arrhythmia]:
    {
      title:
        "Have you ever been diagnosed with or experienced any heart arrhythmia?",
      type: INTAKE_QUESTION_TYPES.radio,
      options: YesNo,
    },
  [INTAKE_QUESTION_CODES.are_you_currently_pregnant]: {
    title: "Are you currently pregnant?",
    type: INTAKE_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [INTAKE_QUESTION_CODES.do_you_drink_alcohol]: {
    title: "Do you drink alcohol?",
    type: INTAKE_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [INTAKE_QUESTION_CODES.do_you_use_marijuana]: {
    title: "Do you use marijuana?",
    type: INTAKE_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [INTAKE_QUESTION_CODES.do_you_use_cocaine]: {
    title: "Do you use cocaine?",
    type: INTAKE_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [INTAKE_QUESTION_CODES.do_you_use_ecstasy_mdma]: {
    title: "Do you use ecstasy/MDMA?",
    type: INTAKE_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [INTAKE_QUESTION_CODES.do_you_use_amphetamines]: {
    title:
      "Do you use amphetamines, including stimulants that are not prescribed?",
    type: INTAKE_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [INTAKE_QUESTION_CODES.do_you_use_opioids]: {
    title: "Do you use opioids?",
    type: INTAKE_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [INTAKE_QUESTION_CODES.do_you_have_any_children]: {
    title: "Do you have any children?",
    type: INTAKE_QUESTION_TYPES.radio,
    options: YesNo,
  },
  [INTAKE_QUESTION_CODES.have_you_held_any_previous_jobs]: {
    title: "Have you held any previous jobs?",
    type: INTAKE_QUESTION_TYPES.radio,
    options: YesNo,
  },
} as const;
