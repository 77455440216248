import { useQuery } from "@tanstack/react-query";

import {
  CheckinStatus,
  InteractionCreationTypes,
} from "~/common/utils/types/checkInTypes";
import { getCheckIns } from "~/patients/api/checkIn";

export const useGetPatientCheckIns = (
  active: boolean,
  enabled: boolean,
  status?: CheckinStatus,
  creationTypes?: InteractionCreationTypes[],
) => {
  const { data, isLoading } = useQuery({
    queryFn: () => getCheckIns(active, status, creationTypes),
    queryKey: ["getCheckins", status, creationTypes, active],
    enabled,
  });
  return { data, isLoading };
};
