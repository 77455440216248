import { generatePath, useNavigate } from "react-router-dom";

import { CONSULTATION_STATUSES } from "~/common/utils";
import { useGetScreening } from "~/patients/hooks";
import { ROUTES } from "~/patients/router";
import {
  isCompletedConsultation,
  isNextConsultation,
  isUnavailableConsultation,
} from "~/patients/utils";

export const useScreeningItem = (id: string, status: string) => {
  const isInProgress = status === CONSULTATION_STATUSES.in_progress;

  const { data: completeScreeningInformation } = useGetScreening(
    id,
    isInProgress,
  );

  const { patient_screening_id: patientScreeningId } = {
    ...completeScreeningInformation,
  };

  const navigate = useNavigate();

  const handleComplete = () => {
    if (isInProgress && patientScreeningId) {
      navigate(generatePath(ROUTES.screening, { id: patientScreeningId }));
    } else {
      navigate(generatePath(ROUTES.startScreening, { id }));
    }
  };

  const isUnavailable = isUnavailableConsultation(status);
  const isCompleted = isCompletedConsultation(status);
  const isPending = isNextConsultation(status);

  return {
    handleComplete,
    isInProgress,
    isUnavailable,
    isCompleted,
    isPending,
  };
};
