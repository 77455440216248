import {
  CompletedListMedicationsAnswer,
  CompletedScreeningAnswer,
  ListMedicationQuestionData,
  SCREENING_QUESTION_TYPES,
  ScreeningQuestionAnswer,
  ScreeningQuestionData,
} from "~/common/utils/consultations/screening";
import {
  AgeQuestionData,
  AudioQuestionData,
  CompletedAudioAnswer,
  CompletedMultiCheckAnswer,
  CompletedRadioAnswer,
  CompletedTextAnswer,
  DateQuestionData,
  MultiCheckQuestionData,
  RadioQuestionData,
  TextQuestionData,
} from "~/common/utils/consultations/shared";
import {
  DateForm,
  ListMedicationsForm,
  MultiCheckForm,
  RadioForm,
  ScreeningAudioForm,
  TextForm,
} from "~/patients/components/consultations";
import { DateType } from "~/patients/utils/consultations/screenings";

interface QuestionRendererProps {
  handleGoBack: () => void;
  question: ScreeningQuestionData;
  isOptional: boolean;
  onSubmit: (answer: ScreeningQuestionAnswer) => void;
  isSubmitting?: boolean;
  answer?: CompletedScreeningAnswer;
  disableBack?: boolean;
}
export const ScreeningQuestionRenderer = ({
  question,
  answer,
  isOptional,
  handleGoBack,
  onSubmit,
  isSubmitting = false,
  disableBack = false,
}: QuestionRendererProps) => {
  const formContent = {
    [SCREENING_QUESTION_TYPES.radio]: (
      <RadioForm
        question={question as RadioQuestionData}
        handleGoBack={handleGoBack}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        disableBack={disableBack}
        completedAnswer={answer as CompletedRadioAnswer}
      />
    ),
    [SCREENING_QUESTION_TYPES.multi_check]: (
      <MultiCheckForm
        question={question as MultiCheckQuestionData}
        handleGoBack={handleGoBack}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        disableBack={disableBack}
        completedAnswer={answer as CompletedMultiCheckAnswer}
      />
    ),
    [SCREENING_QUESTION_TYPES.text]: (
      <TextForm
        question={question as TextQuestionData}
        handleGoBack={handleGoBack}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        disableBack={disableBack}
        completedAnswer={answer as CompletedTextAnswer}
        isOptional={isOptional}
      />
    ),
    [SCREENING_QUESTION_TYPES.list_medications]: (
      <ListMedicationsForm
        question={question as ListMedicationQuestionData}
        handleGoBack={handleGoBack}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        disableBack={disableBack}
        completedAnswers={answer as CompletedListMedicationsAnswer}
      />
    ),
    [SCREENING_QUESTION_TYPES.audio]: (
      <ScreeningAudioForm
        question={question as AudioQuestionData}
        handleGoBack={handleGoBack}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        completedAnswer={answer as CompletedAudioAnswer}
        disableBack={disableBack}
        isOptional={isOptional}
      />
    ),
    [SCREENING_QUESTION_TYPES.date]: (
      <DateForm
        question={question as DateQuestionData}
        handleGoBack={handleGoBack}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        completedAnswer={answer as DateType}
        disableBack={disableBack}
      />
    ),
    [SCREENING_QUESTION_TYPES.age]: (
      <DateForm
        question={question as AgeQuestionData}
        handleGoBack={handleGoBack}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        completedAnswer={answer as DateType}
        disableBack={disableBack}
      />
    ),
  };

  return formContent[question.type];
};
