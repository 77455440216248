import { useState } from "react";
import { FieldErrors, UseFormRegisterReturn } from "react-hook-form";

import { ApproximateDatesMessage, TrashIcon } from "~/common/components";
import DatePicker from "~/common/components/ui/DatePicker";
import Input from "~/common/components/ui/Input";
import { getTodayDate } from "~/common/utils";
import { ConfirmDeleteListItem } from "~/patients/components/consultations/shared/ui";
import { HospitalType } from "~/patients/utils/consultations/intakes";

interface HospitalFormProps {
  register: (data: keyof HospitalType) => UseFormRegisterReturn<string>;
  handleDelete: () => void;
  isHospitalDataEmpty: boolean;
  errors?: FieldErrors<HospitalType>;
}

export const HospitalForm = ({
  register,
  handleDelete,
  isHospitalDataEmpty,
  errors,
}: HospitalFormProps) => {
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const toggleConfirmDeleteModal = () =>
    setShowConfirmDeleteModal((prev) => !prev);

  const today = getTodayDate();

  return (
    <div className="flex w-full flex-col items-end gap-4 border-b border-neutral-700 pb-4">
      <Input
        id="name"
        label="Name of the hospital"
        placeholder="Enter name."
        error={errors?.name?.message}
        {...register(`name`)}
      />
      <Input
        label="Reasons for admission"
        placeholder="Please enter your reasons for admission."
        error={errors?.reasons_for_admission?.message}
        {...register(`reasons_for_admission`)}
      />
      <DatePicker
        id="date_started"
        label="Date started*"
        max={today}
        {...register("date_started")}
        error={errors?.date_started?.message}
      />
      <DatePicker
        id="date_completed"
        label="Date completed*"
        isOptional
        max={today}
        {...register("date_completed")}
        error={errors?.date_completed?.message}
      />
      <ApproximateDatesMessage />
      <button
        className="flex w-fit items-center gap-1.5 text-gray-10"
        type="button"
        onClick={isHospitalDataEmpty ? handleDelete : toggleConfirmDeleteModal}
      >
        Delete <TrashIcon className="text-gray-10" />
      </button>
      <ConfirmDeleteListItem
        onClose={toggleConfirmDeleteModal}
        show={showConfirmDeleteModal}
        onConfirm={handleDelete}
        description="Are you sure you want to delete this hospital admission?"
      />
    </div>
  );
};
