import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { cancelScript } from "~/patients/api/scripts";
import { CancelRequestFormValues } from "~/patients/components";

interface mutationFnProps {
  id?: string;
  script: CancelRequestFormValues;
}

export const useCancelScript = (handleClose: () => void) => {
  const queryClient = useQueryClient();

  const { mutate: cancelScriptMutation, isPending } = useMutation({
    mutationFn: ({ id, script }: mutationFnProps) => cancelScript(script, id),
    onError: () =>
      toast.error(
        "There was an issue canceling the script. Please try again later",
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["getPatientScripts"],
      });
      handleClose();
    },
  });

  return { cancelScriptMutation, isPending };
};
