export const OpenEnvelope = () => (
  <svg
    width="107"
    height="105"
    viewBox="0 0 107 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.0953 17.4102C27.0953 17.4102 43.0253 29.4985 67.2431 6.89521C88.7509 -13.1782 106.312 18.4933 106.435 33.6605C106.594 53.3082 84.9271 69.0288 95.4421 81.9334C105.957 94.838 74.5896 116.148 57.6841 97.7057C36.6543 74.7642 30.9571 93.4042 18.9701 93.4042C10.367 93.4042 -7.29608 72.0276 4.63161 56.1241C14.6686 42.7418 9.19463 38.3 6.54343 33.6605C2.71986 26.9692 11.8009 8.80704 27.0953 17.4102Z"
      fill="#DEE9FF"
    />
    <path
      d="M89.8906 72.7526L31.4039 90.7091C30.4348 91.0063 29.3874 90.9063 28.492 90.4313C27.5966 89.9563 26.9265 89.145 26.6292 88.176L19.7897 65.9035L14.1117 47.4069L14.0017 47.0532L14.7807 46.1546L40.1981 16.8472C40.3145 16.7115 40.467 16.6117 40.6379 16.5592C40.8088 16.5067 40.9911 16.5038 41.1635 16.5508L78.6635 26.5544L79.7963 26.8554L80.1452 27.9834L92.4238 67.9735C92.7203 68.9431 92.6202 69.9908 92.1453 70.8868C91.6704 71.7827 90.8596 72.4537 89.8906 72.7526Z"
      fill="#418DF9"
    />
    <path
      d="M80.1454 27.9827L57.978 54.098V52.6641L78.6637 26.5537L79.7965 26.8548L80.1454 27.9827Z"
      fill="#208AF2"
    />
    <path
      d="M89.9587 72.7302C89.9152 72.7446 89.8683 72.7446 89.8249 72.7302L54.0264 59.4384C53.9975 59.4274 53.9716 59.4098 53.9507 59.387C53.9299 59.3642 53.9147 59.3369 53.9063 59.3071C53.8979 59.2774 53.8967 59.2461 53.9026 59.2158C53.9085 59.1855 53.9215 59.1569 53.9404 59.1325L59.9243 51.6671L79.5681 27.1438C79.5896 27.1159 79.6183 27.0945 79.6512 27.0819C79.684 27.0694 79.7198 27.0661 79.7544 27.0725C79.7889 27.079 79.8211 27.0948 79.8473 27.1184C79.8734 27.1419 79.8926 27.1722 79.9026 27.2059L92.3389 67.7166L92.4204 67.9747C92.7158 68.9318 92.6232 69.9667 92.1628 70.8563C91.7023 71.7458 90.9107 72.4188 89.9587 72.7302Z"
      fill="#A2BDFF"
    />
    <path
      d="M51.7409 57.9359L51.416 58.6147L14.3554 47.1629C14.3215 47.1524 14.2854 47.1513 14.251 47.1599C14.2166 47.1684 14.1851 47.1861 14.1601 47.2112C14.135 47.2363 14.1172 47.2677 14.1087 47.3021C14.1002 47.3366 14.1012 47.3727 14.1117 47.4065L14.0017 47.0528L14.7807 46.1543L51.7409 57.9359Z"
      fill="#208AF2"
    />
    <path
      d="M53.6191 59.5609L31.447 90.6517C31.4215 90.6903 31.3822 90.7177 31.3371 90.7281C30.3744 91.0048 29.3416 90.8924 28.4611 90.415C27.5805 89.9376 26.9227 89.1335 26.6292 88.1759L19.7897 65.9034L14.1117 47.4067C14.1012 47.3728 14.1002 47.3367 14.1088 47.3023C14.1173 47.2679 14.135 47.2364 14.1601 47.2113C14.1852 47.1863 14.2167 47.1685 14.2511 47.16C14.2855 47.1515 14.3216 47.1525 14.3555 47.163L51.4157 58.6147L53.5184 59.2647C53.5475 59.2738 53.574 59.2897 53.5959 59.311C53.6177 59.3323 53.6341 59.3584 53.6439 59.3873C53.6538 59.4161 53.6566 59.4469 53.6523 59.4771C53.648 59.5073 53.6366 59.536 53.6191 59.5609Z"
      fill="#A2BDFF"
    />
    <path
      d="M89.8894 72.7533L31.4063 90.7096C30.5209 90.9786 29.568 90.9183 28.7235 90.5396C27.8791 90.161 27.2001 89.4896 26.812 88.6495C26.7466 88.506 26.7175 88.3486 26.7275 88.1912C26.7374 88.0338 26.7861 87.8813 26.8691 87.7472L50.1768 49.781C50.2986 49.5827 50.4885 49.4356 50.7109 49.3673C50.9334 49.299 51.1731 49.3142 51.3852 49.41L91.9828 67.755C92.1267 67.8195 92.2525 67.9185 92.349 68.0432C92.4455 68.1679 92.5098 68.3145 92.5361 68.47C92.6863 69.3832 92.501 70.3199 92.0146 71.1072C91.5281 71.8944 90.7733 72.4791 89.8894 72.7533Z"
      fill="#699CFF"
    />
  </svg>
);
