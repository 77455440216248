import { EmptyState, LoadingState } from "~/common/components";
import { formatMedicationName } from "~/common/utils";
import { formatPharmacyInfo } from "~/common/utils/formatPharmacy/formatPharmacyInfo";
import { SCRIPT_LIFE } from "~/patients/constants/constants";
import { useGetPatientScripts } from "~/patients/hooks";
import { getScriptCommentMessage } from "~/patients/utils";
import { ScriptsCard } from "./ScriptCard";

export const PastScripts = () => {
  const { data: patientScripts, isLoading } = useGetPatientScripts(
    SCRIPT_LIFE.past,
  );

  if (isLoading) return <LoadingState />;

  if (!patientScripts?.length)
    return (
      <EmptyState
        message="You have no past scripts requests."
        className="min-h-80"
      />
    );

  return (
    <div className="flex h-full flex-col gap-4">
      {!!patientScripts?.length &&
        patientScripts.map((script) => (
          <ScriptsCard
            preAuthRequest={script.pre_authorization_request}
            key={script.id}
            title={formatMedicationName(script.patient_medication.medication)}
            subtitle={formatPharmacyInfo(script.patient_pharmacy.pharmacy)}
            date={script.created_at}
            status={script.status}
            commentMessage={getScriptCommentMessage(script.comments)}
            isPast
          />
        ))}
    </div>
  );
};
