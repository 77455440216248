import {
  Control,
  FieldPath,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";

import Input from "~/common/components/ui/Input";
import Select from "~/common/components/ui/select/Select";
import {
  formatPhoneNumberField,
  FormErrors,
  PharmacyFormValuesType,
} from "~/patients/utils";
import { getUsaStates } from "../utils/getUsaStates";

interface NewPharmacyFormInputsProps<TFieldValues extends FieldValues> {
  register: UseFormRegister<TFieldValues>;
  errors?: FormErrors<PharmacyFormValuesType>;
  control: Control<TFieldValues>;
  pharmacyStateField: FieldPath<TFieldValues>;
}

export const NewPharmacyFormInputs = <TFieldValues extends FieldValues>({
  register,
  errors,
  control,
  pharmacyStateField,
}: NewPharmacyFormInputsProps<TFieldValues>) => (
  <div className="flex flex-col gap-4 border-t border-neutral-700 pt-4">
    <Input
      id="name"
      label="Pharmacy name"
      {...register("newPharmacy.name" as FieldPath<TFieldValues>)}
      error={errors?.name?.message}
    />
    <Input
      id="phoneNumber"
      label="Phone number"
      {...register("newPharmacy.phone_number" as FieldPath<TFieldValues>)}
      {...formatPhoneNumberField<TFieldValues>(
        "newPharmacy.phone_number" as FieldPath<TFieldValues>,
        register,
      )}
      error={errors?.phone_number?.message}
    />
    <Input
      id="street"
      label="Street number and name"
      {...register("newPharmacy.address.street" as FieldPath<TFieldValues>)}
      error={errors?.address?.street?.message}
    />
    <div className="flex flex-col gap-4 md:flex-row md:gap-2">
      <Input
        id="city"
        label="City"
        {...register("newPharmacy.address.city" as FieldPath<TFieldValues>)}
        error={errors?.address?.city?.message}
      />
      <Select
        label="State"
        placeholder={"State"}
        id={pharmacyStateField}
        options={getUsaStates()}
        control={control}
        containerClassName="gap-2"
        error={errors?.address?.state?.message}
      />
      <Input
        id="zipcode"
        label="ZIP code"
        {...register("newPharmacy.address.zipcode" as FieldPath<TFieldValues>)}
        error={errors?.address?.zipcode?.message}
      />
    </div>
  </div>
);
