import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  Button,
  CloseButton,
  LoadingState,
  SectionHeader,
  TransitionEffect,
  Typography,
} from "~/common/components";
import { useGetCurrentPatient } from "../hooks";
import { useCompleteSchedule } from "../hooks/scheduling";
import { ROUTES } from "../router";
import { formatPhoneNumber } from "../utils";

export const SchedulingInstructions = () => {
  const navigate = useNavigate();

  const { data: patient, isLoading } = useGetCurrentPatient();

  const queryClient = useQueryClient();

  const onSuccess = async () => {
    navigate(ROUTES.leadPatientHome);
    toast.success("Appointment scheduled successfully");
    await queryClient.invalidateQueries({
      queryKey: ["getCurrentPatient"],
    });
  };

  const { completeScheduleMutation, isPending } =
    useCompleteSchedule(onSuccess);

  if (isLoading) return <LoadingState />;

  const handleMarkAsDone = () => {
    completeScheduleMutation();
  };

  const providerPhoneNumber = formatPhoneNumber(
    patient?.provider.phone_number ?? "",
  );

  return (
    <TransitionEffect className="flex justify-center">
      <div className="flex h-screen-calculated w-full flex-col gap-5 overflow-y-auto p-6 text-sm lg:w-1/2 lg:py-8">
        <CloseButton onClose={() => navigate(-1)} />
        <div className="flex flex-col gap-11">
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-2">
              <SectionHeader className="text-2xl">
                Consultation Part 2
              </SectionHeader>
              <SectionHeader className="text-4xl">
                Scheduling instructions
              </SectionHeader>
            </div>
            <Typography>
              {`Please call or text my office at `}
              <a
                href={`tel:${providerPhoneNumber}`}
                className="text-primary-500 underline"
              >
                {providerPhoneNumber}
              </a>
              {` to schedule an appointment.`}
            </Typography>
            <Typography>
              Once your appointment is scheduled, click below to mark this step
              as done.
            </Typography>
          </div>
          <Button isLoading={isPending} onClick={handleMarkAsDone}>
            Mark as done
          </Button>
        </div>
      </div>
    </TransitionEffect>
  );
};
