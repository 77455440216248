import React from "react";
import { twMerge } from "tailwind-merge";

import Modal from "~/common/components/Modal";
import { Button } from "~/common/components/ui/button";

interface UnsavedPatientModal {
  closeModal: () => void;
  className?: string;
  confirmLeaveSettings: () => void;
  show: boolean;
}
const UnsavedPatientModal = ({
  closeModal,
  className,
  confirmLeaveSettings,
  show,
}: UnsavedPatientModal) => {
  const handleLeaveSettings = () => {
    confirmLeaveSettings();
    closeModal();
  };

  return (
    <Modal
      className={twMerge("sm:w-1/4", className)}
      description="Are you sure you want to leave? Unsaved changes will be lost."
      show={show}
      onClose={closeModal}
      title="Unsaved changes"
    >
      <div className="flex justify-end ">
        <div className="flex w-full gap-2 sm:w-3/4">
          <Button variant="outline" size="sm" onClick={closeModal}>
            Cancel
          </Button>
          <Button onClick={handleLeaveSettings} size="sm">
            Leave
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UnsavedPatientModal;
