import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { twMerge } from "tailwind-merge";

import { PauseIcon, PlayIcon, TrashIcon } from "~/common/components/icons";
import DeleteAudioModal from "~/patients/modals/DeleteAudioModal";
import { formatDuration } from "~/patients/utils/formatDuration";

interface AudioPlayerProps {
  deleteAudio?: () => void;
  src: string;
  totalDuration: number;
  className?: string;
  iconsClassName?: string;
  thumbNailClassName?: string;
  pendingDeleteAudio?: boolean;
}

const AudioPlayer = ({
  deleteAudio,
  src,
  totalDuration,
  className,
  iconsClassName,
  thumbNailClassName,
  pendingDeleteAudio,
}: AudioPlayerProps) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [reproducedDuration, setReproducedDuration] = useState(0);

  const [deleteAudioModal, setDeleteAudioModal] = useState(false);
  const audioPlayer = useRef<HTMLAudioElement>(null);
  const progressBar = useRef<HTMLInputElement>(null);

  const togglePlayPause = async () => {
    if (audioPlayer.current) {
      try {
        if (audioPlayer.current.paused) {
          await audioPlayer.current.play();
          setIsPlaying(true);
        } else {
          audioPlayer.current.pause();
          setIsPlaying(false);
        }
      } catch (error) {
        toast.error(
          "Error attempting to play audio, please try from another browser.",
        );
      }
    }
  };

  const updateProgressBar = () => {
    if (audioPlayer.current && progressBar.current) {
      const currentTime = audioPlayer.current.currentTime;
      progressBar.current.value = currentTime.toString();
      setReproducedDuration(currentTime);
    }
  };

  const changeRange = () => {
    if (audioPlayer.current && progressBar.current) {
      audioPlayer.current.currentTime = parseInt(progressBar.current.value, 10);
    }
  };

  return (
    <div
      className={twMerge(
        "flex items-center justify-center gap-3 rounded-md border border-neutral-400 bg-white p-3 text-gray-5",
        className,
      )}
    >
      <audio
        ref={audioPlayer}
        onTimeUpdate={updateProgressBar}
        onEnded={() => setIsPlaying(false)}
      >
        <source src={src} type="audio/mpeg" />
        <track kind="captions" />
      </audio>
      <button onClick={togglePlayPause} type="button" className="size-4">
        {isPlaying ? (
          <PauseIcon
            className={twMerge("h-3 w-3 text-gray-5", iconsClassName)}
          />
        ) : (
          <PlayIcon
            className={twMerge("h-4 w-4 text-gray-5", iconsClassName)}
          />
        )}
      </button>
      <input
        type="range"
        className={twMerge(
          "h-1.5 w-full cursor-pointer rounded-full bg-neutral-60 transition-all duration-500",
          thumbNailClassName,
        )}
        id="audioProgress"
        ref={progressBar}
        max={totalDuration}
        min={0}
        defaultValue={0}
        onChange={changeRange}
      />
      <div className="flex text-sm">
        <p className="flex w-11">{formatDuration(reproducedDuration)} </p>
        <p className="flex w-14">/ {formatDuration(totalDuration)}</p>
      </div>
      {deleteAudio && (
        <>
          <button onClick={() => setDeleteAudioModal(true)} type="button">
            <TrashIcon />
          </button>
          <DeleteAudioModal
            show={deleteAudioModal}
            onClose={() => setDeleteAudioModal(false)}
            handleDelete={deleteAudio}
            pendingDeleteAudio={pendingDeleteAudio}
          />
        </>
      )}
    </div>
  );
};

export default AudioPlayer;
