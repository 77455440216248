import {
  Frequencies,
  SCREENING_QUESTION_CODES,
  SCREENING_QUESTION_TYPES,
} from "~/common/utils/consultations/screening";

export const frequenciesQuestions = {
  [SCREENING_QUESTION_CODES.problems_wrapping_final_details]: {
    title:
      "How often do you have trouble wrapping up the final details of a project, once the challenging parts have been done?",
    type: SCREENING_QUESTION_TYPES.radio,
    options: Frequencies,
  },
  [SCREENING_QUESTION_CODES.difficulty_order_in_organization]: {
    title:
      "How often do you have difficulty getting things in order when you have to do a task that requires organization?",
    type: SCREENING_QUESTION_TYPES.radio,
    options: Frequencies,
  },
  [SCREENING_QUESTION_CODES.problem_remembering_appointments]: {
    title:
      "How often do you have problems remembering appointments or obligations?",
    type: SCREENING_QUESTION_TYPES.radio,
    options: Frequencies,
  },
  [SCREENING_QUESTION_CODES.how_often_avoid_getting_started]: {
    title:
      "When you have a task that requires a lot of thought, how often do you avoid or delay getting started?",
    type: SCREENING_QUESTION_TYPES.radio,
    options: Frequencies,
  },
  [SCREENING_QUESTION_CODES.fidget_or_squirm_sit_down]: {
    title:
      "How often do you fidget or squirm with your hands or feet when you have to sit down for a long time?",
    type: SCREENING_QUESTION_TYPES.radio,
    options: Frequencies,
  },
  [SCREENING_QUESTION_CODES.feel_active_like_driven_by_motor]: {
    title:
      "How often do you feel overly active and compelled to do things, like you were driven by a motor?",
    type: SCREENING_QUESTION_TYPES.radio,
    options: Frequencies,
  },
} as const;
