import {
  CERO_TO_FIVE_TYPES,
  CONDITION_STATUS_TYPES,
  DAILY_FREQUENCIES_TYPES,
  FREQUENCIES_TYPES,
  RELATIONSHIP_TYPES,
  SEX_TYPES,
  YES_NO_TYPES,
} from "./data/screeningQuestionsData";

export const ConditionStatuses = [
  CONDITION_STATUS_TYPES.improved,
  CONDITION_STATUS_TYPES.same,
  CONDITION_STATUS_TYPES.worse,
] as const;

export const Sexes = [
  SEX_TYPES.female,
  SEX_TYPES.male,
  SEX_TYPES.not_say,
] as const;

export const YesNo = [YES_NO_TYPES.yes, YES_NO_TYPES.no] as const;

export const RelationshipStatuses = [
  RELATIONSHIP_TYPES.single,
  RELATIONSHIP_TYPES.married,
  RELATIONSHIP_TYPES.partnered,
  RELATIONSHIP_TYPES.separated,
  RELATIONSHIP_TYPES.divorced,
  RELATIONSHIP_TYPES.widowed,
] as const;

export const Frequencies = [
  FREQUENCIES_TYPES.never,
  FREQUENCIES_TYPES.rarely,
  FREQUENCIES_TYPES.sometimes,
  FREQUENCIES_TYPES.often,
  FREQUENCIES_TYPES.very_often,
] as const;

export const DailyFrequencies = [
  DAILY_FREQUENCIES_TYPES.never,
  DAILY_FREQUENCIES_TYPES.several_days,
  DAILY_FREQUENCIES_TYPES.more_than_half_the_days,
  DAILY_FREQUENCIES_TYPES.nearly_every_day,
] as const;

export const CeroToFive = [
  CERO_TO_FIVE_TYPES.cero,
  CERO_TO_FIVE_TYPES.one,
  CERO_TO_FIVE_TYPES.two,
  CERO_TO_FIVE_TYPES.three,
  CERO_TO_FIVE_TYPES.fourth,
  CERO_TO_FIVE_TYPES.five,
] as const;

export const screeningRadioOptions = [
  ...ConditionStatuses,
  ...Sexes,
  ...YesNo,
  ...RelationshipStatuses,
  ...Frequencies,
  ...DailyFrequencies,
  ...CeroToFive,
] as const;

export type ScreeningOptionsValues = (typeof screeningRadioOptions)[number];

export type ConditionStatusesType = typeof ConditionStatuses;
export type SexType = typeof Sexes;
export type YesNoType = typeof YesNo;
export type RelationshipType = typeof RelationshipStatuses;
export type FrequencyType = typeof Frequencies;
export type DailyFrequencyType = typeof DailyFrequencies;
export type CeroToFiveType = typeof CeroToFive;

export type ScreeningRadioValues =
  | ConditionStatusesType
  | SexType
  | YesNoType
  | RelationshipType
  | FrequencyType
  | DailyFrequencyType
  | CeroToFiveType;
