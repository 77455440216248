import React from "react";
import { UseFormRegister } from "react-hook-form";

import Input from "~/common/components/ui/Input";
import {
  formatPhoneNumberField,
  SignUpPatientSchemaType,
  UserMobileSchemaType,
} from "~/patients/utils";
import { FormErrors } from "~/patients/utils/types/forms";
import SignUpFormLayout from "../SignUpFormLayout";
import SignUpProgress from "../SignUpProgress";

interface PatientMobileFormProps {
  handleGoBack: () => void;
  totalSteps: number;
  register: UseFormRegister<SignUpPatientSchemaType>;
  onNext: () => Promise<void>;
  step: number;
  errors?: FormErrors<UserMobileSchemaType>;
}

const PatientMobileForm = ({
  onNext,
  register,
  handleGoBack,
  totalSteps,
  errors,
  step,
}: PatientMobileFormProps) => {
  return (
    <>
      <SignUpProgress step={step} totalSteps={totalSteps} />
      <SignUpFormLayout
        title="Your mobile number"
        handleGoBack={handleGoBack}
        handleData={onNext}
      >
        <Input
          id="phone_number"
          label="Mobile number"
          {...register("phone_number.phone_number")}
          {...formatPhoneNumberField<SignUpPatientSchemaType>(
            "phone_number.phone_number",
            register,
          )}
          error={errors?.phone_number?.message}
        />
      </SignUpFormLayout>
    </>
  );
};

export default PatientMobileForm;
