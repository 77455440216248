import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useMutation } from "@tanstack/react-query";

import { ROUTES } from "~/patients/router";

export const useStripePayment = () => {
  const stripe = useStripe();
  const elements = useElements();

  if (!stripe || !elements) {
    return;
  }

  return async () => {
    await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}${ROUTES.confirmPayment}`,
      },
    });
  };
};

export const useConfirmStripePayment = () => {
  const stripePayment = useStripePayment();

  const { mutate: createPaymentMutation, isPending } = useMutation({
    mutationFn: stripePayment,
  });

  return { createPaymentMutation, isPending };
};
