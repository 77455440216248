import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import MenuIcon from "~/common/components/icons/MenuIcon";
import { MenuOptionData } from "~/providers/utils/getPatientProfileMenuOptions";

interface NavMenuProps {
  title?: string;
  headerInfo?: ReactNode;
  menuOptions?: MenuOptionData[];
  selectedMenuValue?: string;
  handleOpenSideBar: () => void;
  status?: string;
}

const NavMenu = ({
  menuOptions,
  headerInfo,
  selectedMenuValue,
  title,
  handleOpenSideBar,
  status,
}: NavMenuProps) => {
  return (
    <div className="sticky top-0 z-20 flex h-fit w-full border-b border-primary-100 text-base shadow-sm">
      <div className="flex w-full flex-col gap-4 bg-primary-20 p-6 pb-0 md:p-8 md:px-14 md:pb-0">
        <button className="w-fit lg:hidden" onClick={handleOpenSideBar}>
          <MenuIcon />
        </button>
        {headerInfo}
        <div className="flex items-center gap-4">
          {title && (
            <h1 className="font-outfit text-3xl font-semibold text-gray-70">
              {title}
            </h1>
          )}
          {status && (
            <p className="flex h-2/3 items-center rounded-full bg-orange-10 px-4 text-sm text-orange-900">
              <span className="first-letter:capitalize">{status}</span>
            </p>
          )}
        </div>

        <div className="flex h-full flex-row justify-between gap-6 md:justify-normal">
          {menuOptions?.map(
            (option, key) =>
              option.enabled && (
                <button
                  key={key}
                  className={twMerge(
                    "h-full w-full py-2 font-medium text-gray-70 md:w-fit",
                    (option.current ??
                      (option.value && option.value === selectedMenuValue)) &&
                      "border-b-2 border-b-primary-500 !font-semibold !text-primary-500 ",
                    option.notificationAmount &&
                      "flex flex-row items-center justify-center gap-2",
                  )}
                  onClick={option.onClick}
                  type="button"
                >
                  {option.label}
                  {!!option.notificationAmount && (
                    <div className="flex h-5 w-5 items-center justify-center rounded-full bg-primary-600 text-xs font-normal text-white">
                      {option.notificationAmount}
                    </div>
                  )}
                </button>
              ),
          )}
        </div>
      </div>
    </div>
  );
};

export default NavMenu;
