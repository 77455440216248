export const ADMIT_TO_PRACTICE = {
  do_not_admit: "do_not_admit",
  admit: "admit",
} as const;

export const ADMIT_TO_PRACTICE_VALUES = [
  ADMIT_TO_PRACTICE.do_not_admit,
  ADMIT_TO_PRACTICE.admit,
] as const;

export const REFER_PATIENT = {
  not_referral_needed: "not_referral_needed",
  referral_provided: "referral_provided",
} as const;

export const REFER_PATIENT_VALUES = [
  REFER_PATIENT.not_referral_needed,
  REFER_PATIENT.referral_provided,
] as const;

export type ReferPatient = (typeof REFER_PATIENT)[keyof typeof REFER_PATIENT];
export type AdmitToPractice =
  (typeof ADMIT_TO_PRACTICE)[keyof typeof ADMIT_TO_PRACTICE];
