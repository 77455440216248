import { Navigate } from "react-router-dom";

import { PATIENT_STATUS } from "~/common/constants";
import { IntakeQuestion } from "~/patients/components";
import { useGetCurrentPatient } from "~/patients/hooks";
import { ROUTES } from "~/patients/router";

export const Intake = () => {
  const { data: user } = useGetCurrentPatient();

  if (user?.status === PATIENT_STATUS.ongoing_care)
    return <Navigate to={ROUTES.notFound} />;

  return <IntakeQuestion />;
};
