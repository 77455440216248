import { RelationshipStatuses } from "~/common/utils";
import {
  INTAKE_QUESTION_CODES,
  INTAKE_QUESTION_TYPES,
} from "../intakeQuestionsData";

export const relationshipQuestions = {
  [INTAKE_QUESTION_CODES.what_is_your_parental_marital_status]: {
    title: "What is your parental marital status?",
    type: INTAKE_QUESTION_TYPES.radio,
    options: RelationshipStatuses,
  },
} as const;
