import React from "react";
import { twMerge } from "tailwind-merge";

import { IconProps } from "~/common/utils/types";

export const PillsIcon = ({ className, ...props }: IconProps) => (
  <svg
    className={twMerge("h-6 w-6", className)}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.97567 20.1434C4.72963 20.9193 5.77122 21.3993 6.92172 21.3993C9.22274 21.3993 11.0881 19.4796 11.0881 17.1115C11.0881 15.9275 10.6217 14.8555 9.86778 14.0796M3.97567 20.1434C3.22171 19.3675 2.75537 18.2955 2.75537 17.1115C2.75537 14.7434 4.62071 12.8238 6.92172 12.8238C8.07223 12.8238 9.11382 13.3037 9.86778 14.0796M3.97567 20.1434L9.86778 14.0796M18.0627 7.53072L15.9795 3.81743C15.1167 2.27933 13.2056 1.75234 11.711 2.64036C10.2165 3.52838 9.7044 5.49514 10.5673 7.03324L12.6505 10.7465M18.0627 7.53072L20.1459 11.244C21.0088 12.7821 20.4967 14.7489 19.0021 15.6369C17.5076 16.5249 15.5965 15.9979 14.7336 14.4598L12.6505 10.7465M18.0627 7.53072L12.6505 10.7465"
      stroke="currentColor"
    />
  </svg>
);
