import { useState } from "react";
import { AxiosError } from "axios";

import { CardInputsContainer, ConfirmActionModal } from "~/common/components";
import { PharmacyBoxLayout } from "~/common/components/PharmacyBoxLayout";
import { PharmacyQueryType } from "~/common/utils/types/pharmacyTypes";
import PlusIcon from "~/patients/components/icons/PlusIcon";
import { useGetPatientPharmacies } from "~/patients/hooks/queries/pharmacy";
import {
  ErrorResponse,
  useDeleteMyPharmacy,
} from "~/patients/hooks/queries/pharmacy/useDeleteMyPharmacy";
import { PharmacyCard } from "./PharmacyCard";
import { PharmacyModalForm } from "./PharmacyModalForm";

export const MyPharmacies = () => {
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [pharmacyFormOpen, setPharmacyFormOpen] = useState(false);
  const [selectedPharmacy, setSelectedPharmacy] = useState<
    PharmacyQueryType | undefined
  >(undefined);
  const [pharmacyIdToDelete, setPharmacyIdToDelete] = useState<string>();

  const onDeleteClose = () => setPharmacyIdToDelete(undefined);
  const onErrorClose = () => setErrorModalOpen((prev) => !prev);
  const onError = (error: AxiosError<ErrorResponse>) => {
    setErrorModalOpen(true);
    setErrorMessage(
      `You ${
        error.response?.data?.detail.toLowerCase() ?? "An error ocurred"
      }.`,
    );
  };

  const onPharmacyFormClose = () => {
    setPharmacyFormOpen(false);

    setTimeout(() => {
      setSelectedPharmacy(undefined);
    }, 300);
  };

  const { data: patientPharmacies } = useGetPatientPharmacies();

  const { deletePharmacy, isPending } = useDeleteMyPharmacy(
    onDeleteClose,
    onError,
  );

  return (
    <>
      <CardInputsContainer title="My pharmacies">
        <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-3">
          {patientPharmacies?.map((patientPharmacy) => (
            <PharmacyCard
              patientPharmacy={patientPharmacy}
              key={patientPharmacy.id}
              setPharmacyIdToDelete={setPharmacyIdToDelete}
              setPharmacyForm={(pharmacy) => {
                setSelectedPharmacy(pharmacy);
                setPharmacyFormOpen(true);
              }}
            />
          ))}

          <PharmacyBoxLayout>
            <button
              type="button"
              className="flex h-full flex-col items-center justify-center"
              onClick={() => {
                setPharmacyFormOpen(true);
              }}
            >
              <div className="rounded-full bg-gray-100 p-2">
                <PlusIcon className="bg-gray-100 text-gray-600" />
              </div>
              <p className="text-base font-medium text-gray-70">
                Add new pharmacy
              </p>
            </button>
          </PharmacyBoxLayout>
        </div>
      </CardInputsContainer>
      <ConfirmActionModal
        cancelButtonClassName="w-32 self-end"
        confirmButtonClassName="w-32 self-end"
        title="Delete pharmacy"
        description="Are you sure you want to delete this pharmacy?"
        confirmButtonText="Delete"
        show={!!pharmacyIdToDelete}
        isLoading={isPending}
        onClose={onDeleteClose}
        onConfirm={() => {
          deletePharmacy(pharmacyIdToDelete ?? "");
        }}
      />
      <ConfirmActionModal
        title="Error"
        description={errorMessage}
        show={errorModalOpen}
        confirmButtonText="Ok"
        onClose={onErrorClose}
        cancelButtonClassName="hidden"
        onConfirm={onErrorClose}
      />
      <PharmacyModalForm
        show={pharmacyFormOpen}
        onClose={onPharmacyFormClose}
        selectedPharmacy={selectedPharmacy}
      />
    </>
  );
};
