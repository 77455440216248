import { twMerge } from "tailwind-merge";

import { IconProps } from "~/common/utils/types";

export const CalendarIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={twMerge("h-4 w-4 text-gray-60", className)}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 6.68229H14M6 3.34896H4.13333C3.3866 3.34896 3.01323 3.34896 2.72801 3.49428C2.47713 3.62211 2.27316 3.82609 2.14532 4.07697C2 4.36219 2 4.73556 2 5.48229V11.8823C2 12.629 2 13.0024 2.14532 13.2876C2.27316 13.5385 2.47713 13.7425 2.72801 13.8703C3.01323 14.0156 3.3866 14.0156 4.13333 14.0156H11.8667C12.6134 14.0156 12.9868 14.0156 13.272 13.8703C13.5229 13.7425 13.7268 13.5385 13.8547 13.2876C14 13.0024 14 12.629 14 11.8823V5.48229C14 4.73556 14 4.36219 13.8547 4.07697C13.7268 3.82609 13.5229 3.62211 13.272 3.49428C12.9868 3.34896 12.6134 3.34896 11.8667 3.34896H10M6 3.34896H10M6 3.34896V3.01563C6 2.46334 5.55228 2.01562 5 2.01562C4.44772 2.01562 4 2.46334 4 3.01563V3.34896M10 3.34896V3.01563C10 2.46334 10.4477 2.01562 11 2.01562C11.5523 2.01562 12 2.46334 12 3.01563V3.34896"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
