import { useQuery } from "@tanstack/react-query";

import { PatientMedicationStatus } from "~/common/types";
import { getPatientMedications } from "~/patients/api/medications";

export const useGetPatientMedications = (status: PatientMedicationStatus) => {
  const { data, isLoading } = useQuery({
    queryFn: () => getPatientMedications(status),
    queryKey: ["getMyMedications", status],
  });

  return { data, isLoading };
};
