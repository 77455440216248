import type { AxiosError } from "axios";

import { ROUTES } from "~/patients/router";
import { logout } from "../logout";

export const errorInterceptor = async (error: AxiosError) => {
  if (error?.response?.status === 401) {
    await logout();
    window.location.href = ROUTES.login;
  }

  if (error?.response?.status === 404) {
    window.location.href = ROUTES.notFound;
  }

  if (error?.response?.status === 500) {
    window.location.href = ROUTES.internalError;
  }
  return Promise.reject(error);
};
