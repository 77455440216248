import { PropsWithChildren } from "react";

interface CircularProgressBarProps extends PropsWithChildren {
  progress: number;
  maxValue: number;
  strokeWidth?: number;
}

export const CircularProgressBar = ({
  progress,
  children,
  maxValue,
  strokeWidth = 6,
}: CircularProgressBarProps) => {
  const radius = 50 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;
  const progressOffset = ((maxValue - progress) / maxValue) * circumference;

  return (
    <div className="relative flex h-32 w-32 items-center justify-center">
      <svg className="absolute" viewBox="0 0 100 100">
        <circle
          className="stroke-current text-neutral-700"
          cx="50"
          cy="50"
          r={radius}
          strokeWidth={strokeWidth}
          fill="transparent"
        />
        <circle
          className="stroke-current text-gray-5 transition-all duration-1000"
          cx="50"
          cy="50"
          r={radius}
          strokeWidth={strokeWidth}
          fill="transparent"
          strokeDasharray={circumference}
          strokeDashoffset={progressOffset}
          transform={`rotate(-90 50 50)`}
        />
      </svg>

      <div className="absolute flex h-full w-full items-center justify-center">
        <div className="z-10 p-3">{children}</div>
      </div>
    </div>
  );
};
