import { ReactNode } from "react";

interface CardInputsContainerProps {
  children: ReactNode;
  title: string;
}

export const CardInputsContainer = ({
  children,
  title,
}: CardInputsContainerProps) => {
  return (
    <div className="flex w-full flex-col gap-4 rounded-xl border md:max-w-[49rem]">
      <div className="rounded-t-xl border-b bg-primary-20 p-3 px-5 text-base">
        {title}
      </div>
      <div className="px-5 pb-3 pt-0">{children}</div>
    </div>
  );
};
