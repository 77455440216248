import { QUESTIONS_CODES } from "~/common/utils/types/index";
import { QuestionSubtitleLayout } from "../../components/checkIn/QuestionSubtitleLayout";

export const questionTextByCodes = {
  [QUESTIONS_CODES.upload_basic_labs]: {
    title: "Upload basic labs",
    subtitle: (
      <QuestionSubtitleLayout
        text="Requirements: CBC w/platelets, TSH with reflex, Basic metabolic profile,
      liver function tests, B12, Folate, Vitamin D."
      />
    ),
    plainSubtitle:
      "Requirements: CBC w/platelets, TSH with reflex, Basic metabolic profile, liver function tests, B12, Folate, Vitamin D.",
  },
  [QUESTIONS_CODES.blood_pressure_heart_rate]: {
    title: "Enter your blood pressure and heart rate.",
    subtitle: (
      <ol className="list-inside list-decimal">
        <li className="text-base leading-5">
          Measure your blood pressure while sitting in a chair, upright, still,
          with your feet flat on the floor.
        </li>
        <li className="text-base leading-5">
          Please take one measurement and enter the information below.
        </li>
      </ol>
    ),
    plainSubtitle:
      "Measure your blood pressure while sitting in a chair, upright, still, with your feet flat on the floor. Please take one measurement and enter the information below.",
  },
  [QUESTIONS_CODES.weight]: {
    title: "Enter your current weight",
  },
  [QUESTIONS_CODES.gastrointestinal_issues]: {
    title: "Are you experiencing any of the following side effects?",
    subtitle: (
      <QuestionSubtitleLayout text=" Gastrointestinal issues (nausea, diarrhea, constipation)" />
    ),
    plainSubtitle: "Gastrointestinal issues (nausea, diarrhea, constipation)",
  },
  [QUESTIONS_CODES.neurological]: {
    title: "Are you experiencing any of the following side effects?",
    subtitle: (
      <QuestionSubtitleLayout text="Neurological (fatigue, headache, cognitive sharpness)" />
    ),
    plainSubtitle: "Neurological (fatigue, headache, cognitive sharpness)",
  },
  [QUESTIONS_CODES.sexual]: {
    title: "Are you experiencing any of the following side effects?",
    subtitle: <QuestionSubtitleLayout text=" Sexual side effects" />,
    plainSubtitle: "Sexual side effects",
  },
  [QUESTIONS_CODES.worrisome_thoughts]: {
    title: "Are you experiencing any of the following side effects?",
    subtitle: (
      <QuestionSubtitleLayout text="Worrisome thoughts (worsening depression, suicidal thoughts, etc)" />
    ),
    plainSubtitle:
      "Worrisome thoughts (worsening depression, suicidal thoughts, etc)",
  },
  [QUESTIONS_CODES.any_other_side_effects]: {
    title: "Are you experiencing other side effects?",
    plainSubtitle: "Are you experiencing other side effects?",
  },
  [QUESTIONS_CODES.drowsiness_sleepiness]: {
    title: "Are you experiencing any of the following side effects?",
    subtitle: <QuestionSubtitleLayout text="Drowsiness" />,
    plainSubtitle: "Drowsiness",
  },
  [QUESTIONS_CODES.dulling_of_thinking]: {
    title: "Are you experiencing any of the following side effects?",
    subtitle: <QuestionSubtitleLayout text="Dulling of thinking" />,
    plainSubtitle: "Dulling of thinking",
  },
  [QUESTIONS_CODES.tremor_confusion_anxiety]: {
    title: "Are you experiencing any of the following side effects?",
    subtitle: (
      <QuestionSubtitleLayout text="Noticing any tremor or confusion or worsening anxiety" />
    ),
    plainSubtitle: "Noticing any tremor or confusion or worsening anxiety",
  },
  [QUESTIONS_CODES.anxiety_symptoms_not_improving]: {
    title: "Are you experiencing any of the following side effects?",
    subtitle: <QuestionSubtitleLayout text="Anxiety symptoms not improving" />,
    plainSubtitle: "Anxiety symptoms not improving",
  },
  [QUESTIONS_CODES.dry_mouth]: {
    title: "Are you experiencing any of the following side effects?",
    subtitle: <QuestionSubtitleLayout text=" Dry mouth" />,
    plainSubtitle: "Dry mouth",
  },
  [QUESTIONS_CODES.headache]: {
    title: "Are you experiencing any of the following side effects?",
    subtitle: <QuestionSubtitleLayout text=" Headache" />,
    plainSubtitle: "Headache",
  },
  [QUESTIONS_CODES.palpitations]: {
    title: "Are you experiencing any of the following side effects?",
    subtitle: <QuestionSubtitleLayout text="Palpitations" />,
    plainSubtitle: "Palpitations",
  },
  [QUESTIONS_CODES.sweating]: {
    title: "Are you experiencing any of the following side effects?",
    subtitle: <QuestionSubtitleLayout text="Sweating" />,
    plainSubtitle: "Sweating",
  },
  [QUESTIONS_CODES.insomnia]: {
    title: "Are you experiencing any of the following side effects?",
    subtitle: <QuestionSubtitleLayout text="Insomnia or sleep problems" />,
    plainSubtitle: "Insomnia or sleep problems",
  },
  [QUESTIONS_CODES.loss_of_appetite]: {
    title: "Are you experiencing any of the following side effects?",
    subtitle: <QuestionSubtitleLayout text="Loss of appetite" />,
    plainSubtitle: "Loss of appetite",
  },
  [QUESTIONS_CODES.emotional_status]: {
    title:
      "How would you describe your emotional status since you last reported?",
  },
  [QUESTIONS_CODES.audio_tell_us_how_you_are_doing]: {
    title: "Please take a minute to tell us how you are doing.",
  },
} as const;
