import { useState } from "react";

import { ChevronIcon } from "~/common/components";
import { DIRECTIONS } from "~/common/constants";
import { PATIENT_MEDICATION_STATUS } from "~/common/constants/medications";
import { PatientMedicationDetail } from "~/common/types";
import { useGetCurrentPatient } from "~/patients/hooks/queries/useGetCurrentPatient";
import { formatDate } from "~/providers/utils";
import { PatientMedicationDetailsCard } from "./PatientMedicationDetailsLayout";
import { PatientMedicationTable } from "./PatientMedicationTable";

interface TreatmentHistoryProps {
  patientMedication: PatientMedicationDetail;
}

export const TreatmentHistory = ({
  patientMedication,
}: TreatmentHistoryProps) => {
  const [showDetails, setShowDetails] = useState(false);
  const { data: user } = useGetCurrentPatient();
  const { signature_name: providerName } = { ...user?.provider };
  const toggleShowDetails = () => setShowDetails((prev) => !prev);

  const { start_date, discontinued_at } = patientMedication;

  return (
    <PatientMedicationDetailsCard
      treatmentStatus={PATIENT_MEDICATION_STATUS.discontinued}
    >
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-semibold leading-5 text-gray-70">
          {formatDate(start_date)}
          {discontinued_at && `- ${formatDate(discontinued_at)}`}
        </h3>
        <button onClick={toggleShowDetails}>
          <ChevronIcon
            direction={showDetails ? DIRECTIONS.top : DIRECTIONS.down}
          />
        </button>
      </div>
      {showDetails && (
        <>
          <div className="w-full overflow-auto rounded-md	border border-gray-300 bg-white p-3">
            <h3 className="font-medium text-gray-10">
              Message from {providerName}
            </h3>
            <p className="italic">{patientMedication.message_to_patient}</p>
          </div>
          <PatientMedicationTable patientMedicationDetail={patientMedication} />
        </>
      )}
    </PatientMedicationDetailsCard>
  );
};
