import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

import { Styled } from "../utils/types/styledInterface";

export const PharmacyBoxLayout = ({
  children,
  className,
}: PropsWithChildren<Styled>) => (
  <div
    className={twMerge(
      "flex h-44 flex-col justify-between rounded-lg border border-neutral-700 p-3 text-sm",
      className,
    )}
  >
    {children}
  </div>
);
