import { useQuery } from "@tanstack/react-query";

import { getPatientMedication } from "~/patients/api/medications";

export const useGetPatientMedication = (id: string) => {
  const { data, isLoading } = useQuery({
    queryFn: () => getPatientMedication(id),
    queryKey: ["getMedication", id],
  });

  return { data, isLoading };
};
