import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";

import AuthenticationLayout from "~/common/components/layouts/AuthenticationLayout";
import FormLayout from "~/common/components/layouts/FormLayout";
import SuccessErrorLayout from "~/common/components/layouts/SuccessErrorLayout";
import PasswordRequirements from "~/common/components/PasswordRequirements";
import { Button } from "~/common/components/ui/button";
import Input from "~/common/components/ui/Input";
import { resetPassword } from "~/patients/api/resetPassword";
import { ROUTES } from "~/patients/router";
import { patientWelcomeText } from "../constants/constants";
import { passwordSchema } from "../utils/PatientSchemas";

const errorMessage = {
  title: "Something went wrong",
  description:
    "Something went wrong with the password reset. Please try again.",
};
const successMessage = {
  title: "Password reset successful",
  description:
    "Your password has been successfully reset! Please return to the main screen to log in.",
};

type PasswordsFormValues = z.infer<typeof passwordSchema>;
export const ResetPassword = () => {
  const [showResetPasswordForm, setShowResetPasswordForm] = useState(true);
  const [successResetPassword, setSuccessResetPassword] = useState<boolean>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {
    formState: { errors },
    watch,
    handleSubmit,
    register,
  } = useForm<PasswordsFormValues>({
    resolver: zodResolver(passwordSchema),
  });

  const { mutate: handlePasswordResetMutation, isPending } = useMutation({
    mutationFn: ({ password, token }: { password: string; token: string }) =>
      resetPassword(password, token),
    onError: () => {
      setShowResetPasswordForm(false);
      setSuccessResetPassword(false);
    },
    onSuccess: () => {
      setShowResetPasswordForm(false);
      setSuccessResetPassword(true);
    },
  });

  const onSubmit: SubmitHandler<PasswordsFormValues> = (data) => {
    const token = searchParams.get("token");
    if (!token) return;
    handlePasswordResetMutation({ password: data.password, token: token });
  };
  return (
    <AuthenticationLayout hideHeaderMobile welcomeText={patientWelcomeText}>
      {showResetPasswordForm && (
        <div className="flex w-full flex-col items-center gap-8">
          <FormLayout
            title="Reset Password"
            submitLabel="Save changes"
            handleSubmit={handleSubmit(onSubmit)}
            pendingRequest={isPending}
          >
            <Input
              id="password"
              label="New password"
              type="password"
              {...register("password")}
              error={errors.password?.message}
            />
            <Input
              id="passwordConfirmation"
              label="Confirm password"
              type="password"
              placeholder="Re-enter password"
              {...register("passwordConfirmation")}
              error={errors.passwordConfirmation?.message}
            />
          </FormLayout>
          <PasswordRequirements password={watch("password")} />
        </div>
      )}
      {!showResetPasswordForm && (
        <SuccessErrorLayout
          success={successResetPassword}
          title={
            successResetPassword ? successMessage.title : errorMessage.title
          }
          description={
            successResetPassword
              ? successMessage.description
              : errorMessage.description
          }
        >
          {successResetPassword ? (
            <Button onClick={() => navigate(ROUTES.login)}>Log In</Button>
          ) : (
            <Link to={ROUTES.login} className="font-bold underline">
              Back to Login
            </Link>
          )}
        </SuccessErrorLayout>
      )}
    </AuthenticationLayout>
  );
};
