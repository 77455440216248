import {
  CeroToFive,
  SCREENING_QUESTION_CODES,
  SCREENING_QUESTION_TYPES,
} from "~/common/utils/consultations/screening";

export const ceroToFiveQuestions = {
  [SCREENING_QUESTION_CODES.living_alined_with_your_wishes]: {
    title:
      "On a scale of 0 to 5, how much do you feel like you are living in alignment with how you wish to be living?",
    type: SCREENING_QUESTION_TYPES.radio,
    subtitle: "0 (not at all) - 5 (completely)",
    options: CeroToFive,
  },
  [SCREENING_QUESTION_CODES.open_to_learn]: {
    title:
      "On a scale of 0 to 5, how open are you to learning new information about how to use your body's clues to resolve symptoms and get better psychologically?",
    type: SCREENING_QUESTION_TYPES.radio,
    subtitle: "0 (not at all) - 5 (completely)",
    options: CeroToFive,
  },
} as const;
