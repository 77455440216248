import React, { ReactNode } from "react";

import { BackgroundWaves, PsychNowLogo } from "~/common/assets";

interface ErrorScreenLayoutProps {
  children: ReactNode;
}
const ErrorLayout = ({ children }: ErrorScreenLayoutProps) => {
  return (
    <div
      className="flex h-screen-calculated items-center justify-center bg-cover  bg-center text-gray-70"
      style={{ backgroundImage: `url(${BackgroundWaves})` }}
    >
      {children}
      <div className="absolute bottom-0 flex w-full items-center justify-center p-12">
        <img src={PsychNowLogo} alt="Logo" />
      </div>
    </div>
  );
};

export default ErrorLayout;
