import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { z } from "zod";

import { TransitionEffect } from "~/common/components";
import {
  CompletedListMedicationsAnswer,
  ListMedicationQuestionData,
  ScreeningListMedicationsAnswer,
} from "~/common/utils/consultations/screening";
import {
  AddElementButton,
  ConsultationQuestionsLayout,
} from "~/patients/components/consultations/shared";
import { listMedicationSchema } from "~/patients/utils";
import { MedicationInput, MedicationInputValues } from "./inputs";

export type ListMedicationFormValues = z.infer<typeof listMedicationSchema>;
export interface ListMedicationsFormProps {
  question: ListMedicationQuestionData;
  handleGoBack: () => void;
  onSubmit: (answer: ScreeningListMedicationsAnswer) => void;
  isSubmitting: boolean;
  disableBack: boolean;
  completedAnswers: CompletedListMedicationsAnswer;
}

export const ListMedicationsForm = ({
  question,
  onSubmit,
  handleGoBack,
  isSubmitting,
  disableBack,
  completedAnswers,
}: ListMedicationsFormProps) => {
  const emptyMedication = { name: "", dose: "", frequency: "" };
  const { title, subtitle } = question;

  const {
    handleSubmit,
    watch,
    register,
    control,
    formState: { errors },
  } = useForm<ListMedicationFormValues>({
    defaultValues: { answer: { ...completedAnswers } },
    resolver: zodResolver(listMedicationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "answer.medications",
  });

  const saveAnswers: SubmitHandler<ListMedicationFormValues> = (data) =>
    onSubmit(data);

  const emptyMedicationInput = (index: number) =>
    index > fields.length ||
    (!watch(`answer.medications.${index}.name`) &&
      !watch(`answer.medications.${index}.dose`) &&
      !watch(`answer.medications.${index}.frequency`));

  const medications = watch("answer.medications") || [];
  const disableNext = medications.length === 0;

  return (
    <ConsultationQuestionsLayout
      handleNext={handleSubmit(saveAnswers)}
      handleBack={handleGoBack}
      disableNext={disableNext}
      isSubmitting={isSubmitting}
      title={title}
      subtitle={subtitle}
      disableBack={disableBack}
    >
      {fields.map((item, index) => (
        <TransitionEffect key={item.id}>
          <MedicationInput
            handleDelete={() => remove(index)}
            isMedicationDataEmpty={emptyMedicationInput(index)}
            register={(data: keyof MedicationInputValues) =>
              register(`answer.medications.${index}.${data}`)
            }
            errors={errors.answer?.medications?.[index]}
          />
        </TransitionEffect>
      ))}
      <AddElementButton
        onClick={() => append(emptyMedication)}
        title="Add medication"
      />
    </ConsultationQuestionsLayout>
  );
};
