export const SCREENING_QUESTION_CODES = {
  how_can_we_help: "how_can_we_help",
  motivation_for_care: "motivation_for_care",
  date_of_birth: "date_of_birth",
  sex: "sex",
  currently_seeing_therapist: "currently_seeing_therapist",
  tell_us_about_therapist: "tell_us_about_therapist",
  seen_therapist_past: "seen_therapist_past",
  who_lives_with_you: "who_lives_with_you",
  relationship_status: "relationship_status",
  currently_working: "currently_working",
  what_is_your_job: "what_is_your_job",
  describe_medical_issues: "describe_medical_issues",
  taking_any_medication: "taking_any_medication",
  list_medications: "list_medications",
  seen_psychiatrist_in_the_past: "seen_psychiatrist_in_the_past",
  been_hospitalized_for_psych_reasons: "been_hospitalized_for_psych_reasons",
  taken_psych_medications: "taken_psych_medications",
  problems_wrapping_final_details: "problems_wrapping_final_details",
  difficulty_order_in_organization: "difficulty_order_in_organization",
  problem_remembering_appointments: "problem_remembering_appointments",
  how_often_avoid_getting_started: "how_often_avoid_getting_started",
  fidget_or_squirm_sit_down: "fidget_or_squirm_sit_down",
  feel_active_like_driven_by_motor: "feel_active_like_driven_by_motor",
  little_interest_on_doing_things: "little_interest_on_doing_things",
  feeling_down: "feeling_down",
  trouble_falling_staying_asleep: "trouble_falling_staying_asleep",
  feeling_tired: "feeling_tired",
  poor_appetite: "poor_appetite",
  feeling_bad_about_yourself: "feeling_bad_about_yourself",
  trouble_concentrating: "trouble_concentrating",
  moving_or_speaking_slowly: "moving_or_speaking_slowly",
  suicidal_thoughts: "suicidal_thoughts",
  feeling_nervous: "feeling_nervous",
  uncontrollable_worrying: "uncontrollable_worrying",
  worrying_too_much: "worrying_too_much",
  trouble_relaxing: "trouble_relaxing",
  too_restless: "too_restless",
  easily_annoyed: "easily_annoyed",
  feeling_afraid: "feeling_afraid",
  symptoms_do_not_understand: "symptoms_do_not_understand",
  enduring_symptoms_do_not_understand: "enduring_symptoms_do_not_understand",
  symptoms_you_fear: "symptoms_you_fear",
  living_alined_with_your_wishes: "living_alined_with_your_wishes",
  open_to_learn: "open_to_learn",
  have_you_ever_lied: "have_you_ever_lied",
  drug_use_caused_problems: "drug_use_caused_problems",
  drink_to_much: "drink_to_much",
  feeling_empty: "feeling_empty",
  people_leave_me: "people_leave_me",
  harm_myself: "harm_myself",
  friends_think_i_drink_too_much: "friends_think_i_drink_too_much",
  can_not_stand_disappointment: "can_not_stand_disappointment",
  people_told_i_do_dangerous_things: "people_told_i_do_dangerous_things",
  worrisome_attitude_with_food: "worrisome_attitude_with_food",
  do_not_like_my_body: "do_not_like_my_body",
} as const;

export type ScreeningQuestionCodes =
  (typeof SCREENING_QUESTION_CODES)[keyof typeof SCREENING_QUESTION_CODES];

export const CONDITION_STATUS_TYPES = {
  improved: "improved",
  same: "same",
  worse: "worse",
} as const;

export const SEX_TYPES = {
  female: "female",
  male: "male",
  not_say: "prefer_not_to_say",
} as const;

export const YES_NO_TYPES = {
  yes: "yes",
  no: "no",
} as const;

export const RELATIONSHIP_TYPES = {
  single: "single",
  married: "married",
  partnered: "partnered",
  separated: "separated",
  divorced: "divorced",
  widowed: "widowed",
} as const;

export const FREQUENCIES_TYPES = {
  never: "never",
  rarely: "rarely",
  sometimes: "sometimes",
  often: "often",
  very_often: "very_often",
} as const;

export const DAILY_FREQUENCIES_TYPES = {
  never: "never",
  several_days: "several_days",
  more_than_half_the_days: "more_than_half_the_days",
  nearly_every_day: "nearly_every_day",
} as const;

export const CERO_TO_FIVE_TYPES = {
  cero: "0",
  one: "1",
  two: "2",
  three: "3",
  fourth: "4",
  five: "5",
} as const;

export const SCREENING_CODES = {
  how_can_we_help: "how_can_we_help",
  getting_to_know_you: "getting_to_know_you",
  your_health_history: "your_health_history",
  asrs: "asrs",
  phq9: "phq9",
  gad7: "gad7",
  more_about_you: "more_about_you",
} as const;

export type ScreeningCode =
  (typeof SCREENING_CODES)[keyof typeof SCREENING_CODES];

export const SCREENING_QUESTION_TYPES = {
  text: "text",
  radio: "radio",
  multi_check: "multi_check",
  list_medications: "list_medications",
  audio: "audio",
  date: "date",
  age: "age",
} as const;

export type ScreeningQuestionTypes =
  (typeof SCREENING_QUESTION_TYPES)[keyof typeof SCREENING_QUESTION_TYPES];

export const TOTAL_AMOUNT_OF_SCREENINGS = 7;

export const SCREENING_STATUSES = {
  completed: "completed",
  in_progress: "in_progress",
  pending: "pending",
  unavailable: "unavailable",
} as const;

export type ScreeningStatus =
  (typeof SCREENING_STATUSES)[keyof typeof SCREENING_STATUSES];

export const SCREENING_MULTI_CHECK_VALUES = {
  motivated_to_seek_care: "motivated_to_seek_care",
  family_or_friend_encouraging_to_seek_care:
    "family_or_friend_encouraging_to_seek_care",
  prompt_or_legal_issue: "prompt_or_legal_issue",
  another_reason: "another_reason",
} as const;

export const SCREENING_INPUT_TYPES = {
  text: "text",
  text_area: "text_area",
  phone_number: "phone_number",
} as const;
