import { ComponentPropsWithoutRef, PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

import { BlueWavesBackground } from "~/patients/assets";

interface WaveBackgroundLayoutProps
  extends ComponentPropsWithoutRef<"div">,
    PropsWithChildren {}

export const WaveBackgroundLayout = ({
  children,
  className,
}: WaveBackgroundLayoutProps) => (
  <div
    className={twMerge(
      "flex h-full min-h-screen-calculated w-screen flex-1 flex-col items-center bg-cover bg-center text-gray-800 md:gap-10",
      className,
    )}
    style={{ backgroundImage: `url(${BlueWavesBackground})` }}
  >
    {children}
  </div>
);
