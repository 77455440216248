import { z } from "zod";

export const JobInfoSchema = z.object({
  place_of_employment: z
    .string()
    .min(1, "Please provide the place of employment")
    .max(500, "Please provide something shorter than 500 characters"),
  job_title: z
    .string()
    .min(1, "Please provide your job title")
    .max(500, "Please provide something shorter than 500 characters"),
  date_started: z.string().min(1, "Please provide start date"),
  date_ended: z.string().min(1, "Please provide end date"),
  why_did_you_leave: z
    .string()
    .min(1, "Please provide the reason")
    .max(2000, "Please provide something shorter than 2000 characters"),
});

export const ListJobsSchema = z.object({
  answer: z.object({ jobs_you_have_held: z.array(JobInfoSchema) }),
});

export type ListJobsFormValues = z.infer<typeof ListJobsSchema>;
export type JobsFormValues = z.infer<typeof JobInfoSchema>;
