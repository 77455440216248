import { Navigate } from "react-router-dom";

import { LoadingState } from "~/common/components";
import { PATIENT_STATUS } from "~/common/constants";
import { useGetCurrentPatient } from "~/patients/hooks/queries";
import { LeadPayment } from "../components/consultations/payments";
import { ROUTES } from "../router";

export const Payment = () => {
  const { data: user, isFetching } = useGetCurrentPatient();

  if (isFetching) return <LoadingState />;

  if (user?.status === PATIENT_STATUS.ongoing_care)
    return <Navigate to={ROUTES.notFound} />;

  return <LeadPayment />;
};
