import { z } from "zod";

import {
  YES_NO_NA_TYPES,
  YES_NO_TYPES,
} from "~/common/utils/consultations/intake";
import { YesNoNA } from "~/common/utils/consultations/intake/intakeTypes";
import { MultiCheckOptions } from "~/common/utils/consultations/shared";
import { extractMultiCheckValues } from "../shared";

export const hospitalSchema = z.object({
  name: z.string().min(1, "Please enter medication name."),
  reasons_for_admission: z
    .string()
    .min(1, "Please specify a reason for admission."),
  date_started: z.string().min(1, "Please select a start date."),
  date_completed: z
    .string()
    .nullable()
    .transform((dateCompleted) => (!dateCompleted ? null : dateCompleted)),
});

export const therapistInThePastSchema = z.object({
  therapist_name: z.string().min(1, "Please enter medication name."),
  reason_for_therapy: z.string().min(1, "Please specify dose."),
  date_started: z.string().min(1, "Please select a start date."),
  date_completed: z
    .string()
    .nullable()
    .optional()
    .default(null)
    .transform((value) => (value === "" ? null : value)),
});

export const listHospitalsSchema = z.object({
  answer: z.object({ hospitals_admissions: z.array(hospitalSchema) }),
});

export type HospitalType = z.infer<typeof hospitalSchema>;

export interface CompletedHospitalType {
  hospitals_admissions: HospitalType[];
}

export type ListHospitalsType = z.infer<typeof listHospitalsSchema>;

export const therapistInThePastFormSchema = z.object({
  answer: therapistInThePastSchema,
});

export type TherapistInThePastFormValues = z.infer<
  typeof therapistInThePastFormSchema
>;

export type TherapistInThePastType = z.infer<typeof therapistInThePastSchema>;
export const indicatedTakingMedicationSchema = z
  .object({
    how_did_it_work: z
      .string({ message: "Please enter how did it work." })
      .min(1, "Please enter how did it work."),
    date_started: z.string().min(1, "Please select a start date."),
    date_stopped: z.string().optional().nullable(),
    why_it_stopped: z.string().nullable().optional(),
  })
  .superRefine((data, ctx) => {
    if (data.date_stopped) {
      if (!data.why_it_stopped || data.why_it_stopped.length === 0) {
        ctx.addIssue({
          path: ["why_it_stopped"],
          message: "Please provide a reason why it stopped.",
          code: z.ZodIssueCode.custom,
        });
      }

      if (new Date(data.date_stopped) < new Date(data.date_started)) {
        ctx.addIssue({
          path: ["date_stopped"],
          message: "Date stopped must be greater than date started.",
          code: z.ZodIssueCode.custom,
        });
      }
    }
  });

export const indicatedTakingMedicationsFormSchema = z.object({
  answer: z.object({
    medications_data: z.array(indicatedTakingMedicationSchema),
  }),
});

export type IndicatedTakingMedicationsFormValues = z.infer<
  typeof indicatedTakingMedicationsFormSchema
>;

export type IndicatedTakingMedicationsType = z.infer<
  typeof indicatedTakingMedicationSchema
>;

export const yearSchema = z
  .string()
  .optional()
  .nullable()
  .refine((val) => !val || (parseInt(val) > 1900 && /^\d{4}$/.test(val)), {
    message: "Please enter a valid year",
  })
  .transform((value) => (value === "" ? null : value));

export const radioWithTertiaryEducationSchema = z
  .object({
    did_you_attend: z.enum([YES_NO_TYPES.yes, YES_NO_TYPES.no]),
    name: z.string().optional(),
    degree: z.string().optional(),
    year_started: yearSchema,
    year_ended: yearSchema,
    graduated: z.boolean().optional(),
  })
  .superRefine((data, ctx) => {
    if (data.did_you_attend === YES_NO_TYPES.yes) {
      if (!data.name || data.name.length === 0) {
        ctx.addIssue({
          path: ["name"],
          message: "Please provide a name.",
          code: z.ZodIssueCode.custom,
        });
      }
      if (!data.degree || data.degree.length === 0) {
        ctx.addIssue({
          path: ["degree"],
          message: "Please provide a degree.",
          code: z.ZodIssueCode.custom,
        });
      }
      if (!data.year_started || data.year_started.length === 0) {
        ctx.addIssue({
          path: ["year_started"],
          message: "Please provide a year.",
          code: z.ZodIssueCode.custom,
        });
      }
    }
  });

export const radioWithTertiaryEducationAnswerSchema = z.object({
  answer: radioWithTertiaryEducationSchema,
});

export type RadioWithTertiaryEducationType = z.infer<
  typeof radioWithTertiaryEducationSchema
>;

export type RadioWithTertiaryEducationAnswerType = z.infer<
  typeof radioWithTertiaryEducationAnswerSchema
>;

export const radioWithOptionalTextSchema = z.object({
  value: z.string().min(1, "Please select an answer to continue."),
  extra_data: z
    .string()
    .max(2000, "Please ensure the note does not exceed 2000 characters.")
    .optional(),
});

export const radioWithOptionalTextAnswerSchema = z.object({
  answer: radioWithOptionalTextSchema,
});

export const radioWithTextSchema = radioWithOptionalTextSchema.superRefine(
  (data, ctx) => {
    if (data.value === YES_NO_TYPES.yes && !data.extra_data) {
      ctx.addIssue({
        code: "custom",
        path: ["extra_data"],
        message: "Please provide further information.",
      });
    }
  },
);

export const radioWithTextAnswerSchema = z.object({
  answer: radioWithTextSchema,
});

export type RadioWithTextType = z.infer<typeof radioWithOptionalTextSchema>;

export type RadioWithTextAnswerType = z.infer<
  typeof radioWithOptionalTextAnswerSchema
>;

export const highschoolSchema = z
  .object({
    did_you_attend: z.enum([YES_NO_TYPES.yes, YES_NO_TYPES.no]),
    graduation_year: yearSchema,
    what_kind_of_student_were: z
      .string()
      .max(2000, "Please ensure the note does not exceed 2000 characters.")
      .optional()
      .nullable(),
  })
  .superRefine((data, ctx) => {
    if (
      data.did_you_attend === YES_NO_TYPES.yes &&
      !data.what_kind_of_student_were
    ) {
      ctx.addIssue({
        code: "custom",
        path: ["what_kind_of_student_were"],
        message: "This field is required if you attended high school.",
      });
    }
  });

export const highschoolAnswerSchema = z.object({
  answer: highschoolSchema,
});

export type HighschoolType = z.infer<typeof highschoolSchema>;
export type HighschoolAnswerType = z.infer<typeof highschoolAnswerSchema>;

export const audioSchema = z.object({
  duration: z
    .number({ message: "Please record an audio." })
    .min(
      15,
      `Audio duration should be at least 15 seconds, please record a new one.`,
    )
    .max(
      60,
      `Audio duration should be maximum 60 seconds, please record a new one.`,
    ),
});

export const yesNoNaAudioSchema = z
  .object({
    answer: z.object({
      value: z.enum(YesNoNA, { message: "Please select an option." }),
      audio: audioSchema.optional(),
    }),
  })
  .superRefine((data, ctx) => {
    const { value, audio } = data.answer;

    if (value === YES_NO_NA_TYPES.yes && !audio) {
      ctx.addIssue({
        code: "custom",
        path: ["answer", "audio", "duration"],
        message: "Please describe further in an audio.",
      });
    }
  });

export const createMultiCheckWithCommonExtraDataSchema = (
  options: readonly MultiCheckOptions[],
) => {
  const values = extractMultiCheckValues(options);

  const valuesSchema = z.array(
    z.object({
      value: z.enum(values),
    }),
  );

  return z
    .object({
      answer: z.object({
        values: valuesSchema,
        extra_data: z.string().optional(),
      }),
    })
    .refine(
      (data) => {
        const asksCommonExtraDataRequired = data.answer.values.some(
          (value) =>
            options.find((option) => option.value === value.value)
              ?.asksCommonExtraData,
        );

        const hasCommonExtraData =
          asksCommonExtraDataRequired && !data.answer.extra_data;
        return !hasCommonExtraData;
      },
      {
        message: "Please provide a valid justification.",
        path: ["answer", "extra_data"],
      },
    );
};
