import { useMutation } from "@tanstack/react-query";

import { uploadFileS3 } from "~/patients/api";
import { getConsultationPresignedUrl } from "~/patients/api/consultations";

export const useUploadConsultationAudioS3 = (basePath: string) => {
  const {
    mutateAsync: generatePresignedUrl,
    isPending: pendingGeneratePresignedUrl,
  } = useMutation({
    mutationFn: async () => await getConsultationPresignedUrl(basePath),
  });

  const { mutateAsync: uploadAudioMutation, isPending } = useMutation({
    mutationFn: uploadFileS3,
  });

  const handleUploadAudio = async (file: File) => {
    const presigned_url = await generatePresignedUrl();
    await uploadAudioMutation({ file, presigned_url });
  };

  const pendingUploadAudio = pendingGeneratePresignedUrl || isPending;

  return { handleUploadAudio, pendingUploadAudio };
};
