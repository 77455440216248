import { Link } from "react-router-dom";

import { AnswerLayout } from "~/common/components/answers/AnswerLayout";
import { QuestionAnswerLayoutProps } from "~/common/components/answers/QuestionAnswerLayout";
import {
  CompletedAnswerData,
  INPUT_TYPES,
} from "~/common/utils/types/checkInTypes.ts";
import AudioPlayer from "~/patients/components/ui/AudioPlayer";
import {
  formatBloodPressureAnswer,
  formatWeightAnswer,
} from "./formatCheckInAnswers";

export const updateAnswerValuesByType = (
  answerData: CompletedAnswerData,
  answerValues: QuestionAnswerLayoutProps,
  isPreview?: boolean,
): QuestionAnswerLayoutProps => {
  switch (answerData.value.answer_type) {
    case INPUT_TYPES.weight: {
      return {
        ...answerValues,
        question: "Current weight",
        answer: formatWeightAnswer(answerData.value),
      };
    }

    case INPUT_TYPES.heart_rate: {
      return {
        ...answerValues,
        question: "Heart rate",
        answer: answerData.value.value + " BPM",
      };
    }

    case INPUT_TYPES.blood_pressure: {
      return {
        ...answerValues,
        question: "Blood pressure",
        answer: formatBloodPressureAnswer(answerData.value),
      };
    }

    case INPUT_TYPES.status: {
      return {
        ...answerValues,
        question: "Emotional status since last reported",
        answer: answerData.value.value,
      };
    }

    case INPUT_TYPES.file: {
      return {
        ...answerValues,
        description: answerValues.question,
        question: "Basic labs",
        children: (
          <AnswerLayout isPreview={isPreview} className="p-2">
            {answerData.presigned_url ? (
              <Link
                to={answerData.presigned_url}
                className="text-primary-600 underline"
              >
                {answerData.value.fileName}
              </Link>
            ) : (
              <p className="italic">File not found</p>
            )}
          </AnswerLayout>
        ),
      };
    }

    case INPUT_TYPES.audio: {
      return {
        question: "Audio recording",
        children: (
          <AnswerLayout
            isPreview={isPreview}
            className="border border-primary-200"
          >
            {answerData.presigned_url ? (
              <div className="flex w-full flex-col gap-3 p-2">
                <AudioPlayer
                  thumbNailClassName="blue-thumb bg-primary-200"
                  totalDuration={answerData.value.duration ?? 0}
                  src={answerData.presigned_url}
                  className="w-full border-none bg-transparent p-0 text-primary-600"
                  iconsClassName="text-primary-600"
                />
                {answerData.value.transcribed_text && (
                  <p className="text-sm font-light text-gray-10">
                    {answerData.value.transcribed_text}
                  </p>
                )}
              </div>
            ) : (
              <p className="italic text-primary-600">Audio not found.</p>
            )}
          </AnswerLayout>
        ),
      };
    }

    default:
      return {
        ...answerValues,
        answer: answerData.value.value,
      };
  }
};
