import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { deleteMyPharmacy } from "~/patients/api/pharmacies";

export interface ErrorResponse {
  detail: string;
}

export const useDeleteMyPharmacy = (
  handleCloseModal: () => void,
  handleError: (value: AxiosError<ErrorResponse>) => void,
) => {
  const queryClient = useQueryClient();

  const { mutate: deletePharmacy, isPending } = useMutation({
    mutationFn: (pharmacyId: string) => deleteMyPharmacy(pharmacyId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["getPatientPharmacies"],
      });
      handleCloseModal();
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      handleError(error);
    },
  });

  return { deletePharmacy, isPending };
};
