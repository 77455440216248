import { useParams } from "react-router-dom";

import { LoadingState } from "~/common/components";
import {
  intakeSectionTitle,
  TOTAL_AMOUNT_OF_INTAKES,
} from "~/common/utils/consultations/intake";
import { intakeQuestionDataByCodes } from "~/common/utils/consultations/intake/data";
import { IntakeQuestionAnswer } from "~/common/utils/consultations/intake/interfaces";
import {
  IntakeCompletedScreen,
  IntakeQuestionRenderer,
} from "~/patients/components/consultations/intake/renderers";
import { ConsultationFormLayout } from "~/patients/components/consultations/shared";
import {
  useAnswerIntakeQuestion,
  useGetCurrentIntakeQuestion,
  useGetIntakePreviousQuestion,
} from "~/patients/hooks";

export const IntakeQuestion = () => {
  const { id } = useParams();

  const { data: currentQuestion, isLoading } = useGetCurrentIntakeQuestion(id);
  const { goBackOneQuestionMutation } = useGetIntakePreviousQuestion();
  const { answerQuestionMutation, isPending } = useAnswerIntakeQuestion();

  if (!id) return null;

  const handleGoBack = () => goBackOneQuestionMutation(id);

  if (isLoading) return <LoadingState className="h-screen-calculated" />;

  if (!currentQuestion)
    return <IntakeCompletedScreen handleGoBackFinalScreen={handleGoBack} />;

  const {
    id: questionId,
    code,
    form: intake,
    answer,
    optional,
  } = currentQuestion;
  const question = intakeQuestionDataByCodes[code];
  const title = intakeSectionTitle[intake.code];
  const isFirstQuestion = questionId === intake.first_question_id;

  const onSubmit = (answer: IntakeQuestionAnswer) => {
    answerQuestionMutation({ patientIntakeId: id, answer });
  };

  return (
    <ConsultationFormLayout
      currentSection={intake.order}
      title={title}
      totalSteps={TOTAL_AMOUNT_OF_INTAKES}
    >
      <IntakeQuestionRenderer
        key={currentQuestion.id}
        question={question}
        handleGoBack={handleGoBack}
        onSubmit={onSubmit}
        disableBack={isFirstQuestion}
        answer={answer}
        isSubmitting={isPending}
        currentQuestionOptions={currentQuestion.options}
        isOptional={optional}
      />
    </ConsultationFormLayout>
  );
};
