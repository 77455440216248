import { Outlet, useNavigate } from "react-router-dom";

import NavMenu from "~/common/components/ui/NavMenu";
import { useUiStore } from "../stores";
import { getMedicationLayoutMenuOptions } from "../utils/getMedicationLayoutMenuOptions";

export const MedicationLayout = () => {
  const navigate = useNavigate();
  const { setSideBarOpen } = useUiStore();
  const { menuOptions } = getMedicationLayoutMenuOptions(navigate);

  return (
    <div className="flex h-screen-calculated w-full flex-col text-sm">
      <NavMenu
        title="My medications"
        menuOptions={menuOptions}
        handleOpenSideBar={() => setSideBarOpen(true)}
      />
      <div className="flex h-full flex-col gap-6 overflow-y-scroll p-6 lg:px-16 lg:py-8 xl:px-56">
        <Outlet />
      </div>
    </div>
  );
};
