import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { ScreeningQuestionAnswer } from "~/common/utils/consultations/screening";
import { answerQuestion } from "~/patients/api/consultations/screenings";

interface UseAnswerQuestionProps {
  patient_screening_id: string;
  answer: ScreeningQuestionAnswer;
}

export const useAnswerQuestion = () => {
  const queryClient = useQueryClient();

  const { mutate: answerQuestionMutation, isPending } = useMutation({
    mutationFn: ({ patient_screening_id, answer }: UseAnswerQuestionProps) =>
      answerQuestion(answer, patient_screening_id),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["getCurrentScreeningQuestion"],
      });
    },
    onError: () =>
      toast.error(
        "An error occurred while submitting your answer. Please try again.",
      ),
  });

  return { answerQuestionMutation, isPending };
};
