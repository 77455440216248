import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";

import Input from "~/common/components/ui/Input";
import TextArea from "~/common/components/ui/Textarea";
import { YES_NO_TYPES } from "~/common/utils/consultations/intake";
import { HighschoolQuestionData } from "~/common/utils/consultations/intake/interfaces";
import { ConsultationQuestionsLayout } from "~/patients/components";
import RadioButtonOption from "~/patients/components/ui/RadioButtonOption";
import {
  highschoolAnswerSchema,
  HighschoolAnswerType,
  HighschoolType,
} from "~/patients/utils/consultations/intakes";

export interface HighschoolFormProps {
  question: HighschoolQuestionData;
  handleGoBack: () => void;
  onSubmit: (answer: HighschoolAnswerType) => void;
  isSubmitting: boolean;
  disableBack: boolean;
  completedAnswer: HighschoolType;
}

export const HighschoolForm = ({
  question,
  handleGoBack,
  onSubmit,
  isSubmitting,
  disableBack,
  completedAnswer,
}: HighschoolFormProps) => {
  const { options, title } = question;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<HighschoolAnswerType>({
    defaultValues: {
      answer: { ...completedAnswer },
    },
    resolver: zodResolver(highschoolAnswerSchema),
  });

  const saveAnswers: SubmitHandler<HighschoolAnswerType> = (data) => {
    const attendedHighSchool = data.answer.did_you_attend;
    const formattedAnswer =
      attendedHighSchool === YES_NO_TYPES.yes
        ? data.answer
        : {
            did_you_attend: attendedHighSchool,
            graduation_year: null,
            what_kind_of_student_were: "",
          };

    onSubmit({ answer: formattedAnswer });
  };

  const valueSelected = watch("answer.did_you_attend");
  const optionSelected = options.find(({ value }) => value === valueSelected);

  return (
    <ConsultationQuestionsLayout
      handleNext={handleSubmit(saveAnswers)}
      handleBack={handleGoBack}
      disableNext={!valueSelected}
      errors={errors.answer?.message}
      isSubmitting={isSubmitting}
      title={title}
      disableBack={disableBack}
    >
      {options.map(({ value }) => (
        <RadioButtonOption
          key={value}
          label={value}
          value={value}
          isSelected={valueSelected === value}
          {...register("answer.did_you_attend")}
        />
      ))}
      {optionSelected?.asksExtraInfo && (
        <>
          <Input
            id="graduation_year"
            label="Graduation year (optional)"
            placeholder="YYYY"
            {...register("answer.graduation_year")}
            error={errors.answer?.graduation_year?.message}
            className="border-gray-300"
          />
          <TextArea
            key="what_kind_of_student_were"
            rows={5}
            label="What kind of student were you in math, science and english?"
            placeholder="Please provide more details about your performance in math, science, and english."
            id="what_kind_of_student_were"
            {...register("answer.what_kind_of_student_were")}
            error={errors.answer?.what_kind_of_student_were?.message}
            className="placeholder:not-italic"
          />
        </>
      )}
    </ConsultationQuestionsLayout>
  );
};
